import React, { useState, useEffect, FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';

//components
import { SettingsNavItems } from '../../../../molecules/settings';
import { Edit } from '../../../../atoms';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

//types
import { NavSettingsProps } from './SettingsNav.types';

//utils
import { removeAuthToken } from '../../../../../utils';

const SettingsNav: FC<NavSettingsProps> = ({ items }) => {
  const { t } = useL10N();

  const [active, setActive] = useState<string | null>(null);

  const { tab } = useParams<{ tab: string }>();
  const { push } = useHistory();

  useEffect(() => {
    const activeItem = items.filter((item) => {
      return item.type === tab;
    });
    setActive(activeItem[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const _signOut = () => {
    removeAuthToken();
    push('/auth/login');
  };

  return (
    <aside className="settings__item h-full flex flex-col justify-between">
      <div>
        {items.map((item) => (
          <SettingsNavItems item={item} active={active} key={item.id} />
        ))}
      </div>
      <div className="settings__item--content" onClick={_signOut}>
        <Edit />
        <span className="settings__item--content--title ">
          {t('header.signOut')}
        </span>
      </div>
    </aside>
  );
};

export default SettingsNav;

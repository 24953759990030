import React from 'react';

import { Svg } from '../../../atoms';

const BigBag = () => (
  <Svg
    width="34"
    height="28"
    viewBox="0 0 34 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2847 1.81498C13.2869 1.81498 11.71 3.42443 11.71 5.38968C11.71 7.34951 13.3249 8.96443 15.2847 8.96443H18.6287C20.5885 8.96443 22.2034 7.34951 22.2034 5.38968C22.2034 3.4299 20.5885 1.81498 18.6287 1.81498H15.2847ZM10 5.38968C10 2.49094 12.3316 0.10498 15.2847 0.10498H18.6287C21.5329 0.10498 23.9134 2.48547 23.9134 5.38968C23.9134 8.29388 21.5329 10.6744 18.6287 10.6744H15.2847C12.3805 10.6744 10 8.29388 10 5.38968Z"
      fill="#525F7F"
    />
    <path
      d="M0.540039 4.9989V8.69033C0.540039 12.208 3.40634 15.0309 6.8806 15.0309H26.2063C29.724 15.0309 32.5469 12.1647 32.5469 8.69033V4.9989H0.540039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.9988C0 4.52661 0.382812 4.1438 0.855 4.1438H32.9053C33.3775 4.1438 33.7603 4.52661 33.7603 4.9988V26.4959C33.7603 26.9681 33.3775 27.3509 32.9053 27.3509H0.855C0.382812 27.3509 0 26.9681 0 26.4959V4.9988ZM1.71 5.8538V25.6409H32.0503V5.8538H1.71Z"
      fill="#525F7F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.9988C0 4.52661 0.382812 4.1438 0.855 4.1438H32.8619C33.334 4.1438 33.7169 4.52661 33.7169 4.9988V8.69023C33.7169 12.6329 30.515 15.8858 26.5213 15.8858H7.19556C3.25287 15.8858 0 12.6839 0 8.69023V4.9988ZM1.71 5.8538V8.69023C1.71 11.7319 4.18967 14.1758 7.19556 14.1758H26.5213C29.563 14.1758 32.0069 11.6961 32.0069 8.69023V5.8538H1.71Z"
      fill="#525F7F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.855 11.505C17.3272 11.505 17.71 11.8878 17.71 12.36V18.06C17.71 18.5322 17.3272 18.915 16.855 18.915C16.3828 18.915 16 18.5322 16 18.06V12.36C16 11.8878 16.3828 11.505 16.855 11.505Z"
      fill="#525F7F"
    />
  </Svg>
);

export default BigBag;

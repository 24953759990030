import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { authLink } from './authLink';
import { httpLink } from './httpLink';
import { wsLink } from './wsLink';

export const links = split(({ query }) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' &&  definition.operation === "subscription";
}, wsLink, authLink.concat(httpLink));

import React, { FC, useContext } from 'react';

//components
import DeleteModal from '../modal/DeleteModal';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import {
  useDeleteApplicantMutation,
  useDeleteFileStoreByUserIdMutation,
} from '../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../contexts/ToastrContexts';

//types
import { DeleteCandidateProps } from './DeleteCandidate.types';

//enums
import { ToastrTypesEnums } from '../../../enums';

const DeleteCandidate: FC<DeleteCandidateProps> = ({
  closeModal,
  applicant_ids,
  onDeleteCompleted,
}) => {
  // hook
  const { t } = useL10N();

  // context
  const toast = useContext(toastrContext);

  // graphql hooks
  const [deleteFileStoreByUserId] = useDeleteFileStoreByUserIdMutation({
    variables: { fileableId: applicant_ids },
    onCompleted: ({ delete_file: deleteFile }) => {
      !!deleteFile?.statusIsOk ? handleDeleteSuccess() : handleDeleteFailed();
    },
    onError: () => handleDeleteFailed(),
  });

  const [deleteApplicant, { loading }] = useDeleteApplicantMutation({
    variables: { ids: applicant_ids },
    onCompleted: ({ delete_applicants: deleteApplicants }) => {
      if (deleteApplicants?.returning) {
        deleteApplicants?.returning.forEach((applicant) =>
          !!applicant?.file_store
            ? deleteFileStoreByUserId()
            : handleDeleteSuccess()
        );
      }
    },
    onError: () => handleDeleteFailed(),
  });

  // handlers
  const handleDeleteSuccess = () => {
    closeModal();
    toast?.add(ToastrTypesEnums.SUCCESS, t('candidate.deletedSuccess'));
    onDeleteCompleted && onDeleteCompleted();
  };

  const handleDeleteFailed = () => {
    toast?.add(ToastrTypesEnums.DANGER, t('candidate.deletedError'));
  };

  const onConfirmDelete = () => deleteApplicant();

  return (
    <DeleteModal
      loading={loading}
      closeModal={closeModal}
      onComplete={onConfirmDelete}
      title={t('job.deleteCandidateTitle')}
    />
  );
};

export default DeleteCandidate;

import React, { FC, useState } from 'react';

//components
import { ArrowDown } from '../../../atoms';
import {
  DefaultDropdown,
  Checkbox,
  JobActionsDropdown,
  CustomModal,
  DeleteCandidate,
} from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//types
import { JobActionsProps } from './JobActions.types';

import { ModalTypesEnums } from '../../../../enums';
import useWindowSize from '../../../../hooks/useWindowSize';

const JobActions: FC<JobActionsProps> = ({ all, onSelect, selected, onSendMessage }) => {
  const { t } = useL10N();
  const { width } = useWindowSize();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);
  return (
    <>
      <CustomModal
        open={showDeleteModal}
        closeModal={closeDeleteModal}
        title="Delete"
        type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
      >
        <DeleteCandidate closeModal={closeDeleteModal} applicant_ids={selected} />
      </CustomModal>
      <div className="job__actions--content">
        <Checkbox
          inputProps={{
            id: '0',
            name: 'all',
            checked: all,
            onChange: onSelect,
          }}
          labelProps={{
            children: t('button.selectAll'),
            className: 'job__actions--content--all',
          }}
        />
        {selected.length > 0 && (
          <DefaultDropdown
            triggerProps={{
              label: t('dropdown.bulkActions'),
              className: 'text-tertiary',
              after: <ArrowDown />,
            }}
          >
            <JobActionsDropdown
              onSendMessage={onSendMessage}
              onDeleteCandidate={openDeleteModal}
              showSendMessageButton
            />
          </DefaultDropdown>
        )}
      </div>
    </>
  );
};

export default JobActions;

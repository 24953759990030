import React from 'react';

import { Svg } from '../../../atoms';

const Upload = () => (
  <Svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1725 5.00004L5.58651 11.586C5.39549 11.7705 5.24312 11.9912 5.13831 12.2352C5.03349 12.4792 4.97831 12.7417 4.97601 13.0072C4.9737 13.2728 5.0243 13.5362 5.12486 13.7819C5.22543 14.0277 5.37393 14.251 5.56172 14.4388C5.7495 14.6266 5.97281 14.7751 6.2186 14.8757C6.46439 14.9762 6.72775 15.0269 6.99331 15.0245C7.25887 15.0222 7.52131 14.9671 7.76532 14.8622C8.00932 14.7574 8.23001 14.6051 8.41451 14.414L14.8285 7.82804C15.5571 7.07363 15.9603 6.06322 15.9512 5.01444C15.9421 3.96565 15.5214 2.9624 14.7798 2.22077C14.0381 1.47913 13.0349 1.05846 11.9861 1.04935C10.9373 1.04023 9.92692 1.44341 9.17251 2.17204L2.75751 8.75704C1.63219 9.88236 1 11.4086 1 13C1 14.5915 1.63219 16.1177 2.75751 17.243C3.88282 18.3684 5.40907 19.0005 7.00051 19.0005C8.59194 19.0005 10.1182 18.3684 11.2435 17.243L17.5005 11"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Upload;

import { useContext, useEffect } from 'react';
import { AuthContext, authContext } from '../../../contexts/AuthContext';
import { Nullable } from '../../../interfaces/types/Nullable.types';

import { useAuthUserQuery, UserFieldsFragment } from '../../gen/graphql';

const useAuth = (): Nullable<AuthContext> => {
  const auth = useContext(authContext);
  const query = useAuthUserQuery();
  const { data: { auth_user = [] } = {}, loading, error } = query;

  useEffect(() => {
    if (auth_user && !loading && !error) {
      auth?.setUser(auth_user[0] as UserFieldsFragment);
    }
  }, [auth_user, auth, loading, error]);

  return auth;
};

export default useAuth;

import { useCallback, useState } from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  return { isOpen, onClick, closeModal };
};

export default useModal;

import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Edit: FC<IconProps> = ({ type = 1 }) => {
  const primary = (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83345 2.59352H2.66679C2.22476 2.59352 1.80084 2.76911 1.48828 3.08167C1.17572 3.39424 1.00012 3.81816 1.00012 4.26019V13.4269C1.00012 13.8689 1.17572 14.2928 1.48828 14.6054C1.80084 14.9179 2.22476 15.0935 2.66679 15.0935H11.8335C12.2755 15.0935 12.6994 14.9179 13.012 14.6054C13.3245 14.2928 13.5001 13.8689 13.5001 13.4269V9.26019M12.3218 1.41519C12.4755 1.256 12.6594 1.12903 12.8628 1.04168C13.0661 0.954335 13.2848 0.908358 13.5061 0.906435C13.7274 0.904512 13.9469 0.946682 14.1517 1.03048C14.3565 1.11428 14.5426 1.23804 14.6991 1.39453C14.8556 1.55102 14.9794 1.7371 15.0632 1.94193C15.147 2.14676 15.1891 2.36622 15.1872 2.58752C15.1853 2.80882 15.1393 3.02752 15.052 3.23086C14.9646 3.4342 14.8376 3.61811 14.6785 3.77185L7.52345 10.9269H5.16679V8.57019L12.3218 1.41519Z"
        stroke="#778CA3"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
  const secondary = (
    <Svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.51225H3C2.46957 3.51225 1.96086 3.72296 1.58579 4.09803C1.21071 4.47311 1 4.98181 1 5.51225V16.5122C1 17.0427 1.21071 17.5514 1.58579 17.9265C1.96086 18.3015 2.46957 18.5122 3 18.5122H14C14.5304 18.5122 15.0391 18.3015 15.4142 17.9265C15.7893 17.5514 16 17.0427 16 16.5122V11.5122M14.586 2.09825C14.7705 1.90723 14.9912 1.75486 15.2352 1.65004C15.4792 1.54523 15.7416 1.49005 16.0072 1.48775C16.2728 1.48544 16.5361 1.53604 16.7819 1.6366C17.0277 1.73717 17.251 1.88567 17.4388 2.07346C17.6266 2.26124 17.7751 2.48455 17.8756 2.73034C17.9762 2.97613 18.0268 3.23949 18.0245 3.50505C18.0222 3.77061 17.967 4.03305 17.8622 4.27706C17.7574 4.52106 17.605 4.74175 17.414 4.92625L8.828 13.5122H6V10.6842L14.586 2.09825Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
  return type === 1 ? primary : secondary;
};

export default Edit;

import React, { FC, MouseEvent } from 'react';

//compoennts
import { Button, ExportSubscribers } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { SubscriberDropdownProps } from '../../../interfaces';

const SubsciberDropdown: FC<SubscriberDropdownProps> = ({
  closeMenu,
  isLoading,
  onExportAllSubscribers,
}) => {
  const { t } = useL10N();

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onExportAllSubscribers();
    closeMenu && closeMenu();
  };
  return (
    <div className="default__dropdown__job__actions">
      <Button
        id="export_subscribers"
        label={t('subscribers.exportAllSub')}
        before={<ExportSubscribers />}
        onClick={handleOnClick}
        className="default__dropdown__job__actions--item"
        loading={isLoading}
      />
    </div>
  );
};

export default SubsciberDropdown;

import React, { FC, useEffect, useState, useContext } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

//components
import { FileFieldButton, FieldInput, ReactSelect } from '../../../../molecules';
import { Button, ErrorMessage as ErrorApi } from '../../../../atoms';
import EmailChangeAlertBox from './EmailChangeAlertBox';
import ChangePasswordDialog from './ChangePassword';
import ChangeEmailDialog from './ChangeEmail';

//contexts
import { toastrContext } from '../../../../../contexts/ToastrContexts';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import { useUpdateAccountSettingsMutation } from '../../../../../gql/gen/graphql';

//types
import {
  MyAccountFormTypes,
  MyAccountProps,
  MyAccountDefaultData,
  PathsTypes,
  MyAccoountProfileValues,
} from './MyAccount.types';
import {
  UserAccountSettingsSchema,
  CompanyAccountSettingsSchema,
} from '../../../../../validations';
import { ChangeEmailDefaultData } from './ChangeEmail.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  UserRoleEnums,
  ToastrTypesEnums,
} from '../../../../../enums';

//static
import { industries } from '../../../../../static';

//config
import { GQL_ACTIONS_URL } from '../../../../../config/gql';

const MyAccountForm: FC<MyAccountProps> = ({ defaultData, user, company_id }) => {
  const { t } = useL10N();
  const toast = useContext(toastrContext);

  const [paths, setPaths] = useState<PathsTypes>({ user: '', company: '' });
  const [isUserFileLoading, setIsUserFileLoading] = useState<boolean>(false);
  const [isCompanyFileLoading, setIsCompanyFileLoading] = useState<boolean>(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState<boolean>(false);
  const [emailUpdateInfo, setEmailUpdateInfo] = useState<ChangeEmailDefaultData | null>(null);

  const validationSchema =
    user?.company_users[0].role === UserRoleEnums.ADMIN
      ? UserAccountSettingsSchema({ t }).concat(CompanyAccountSettingsSchema({ t }))
      : UserAccountSettingsSchema({ t });

  const { register, handleSubmit, watch, setValue, errors, control } =
    useForm<MyAccountDefaultData>({
      defaultValues: { ...defaultData },
      resolver: yupResolver(validationSchema),
    });

  // paths effect
  useEffect(() => {
    setPaths({
      user: defaultData?.user_path || '',
      company: defaultData?.company_path || '',
    });
  }, [defaultData]);

  const [updateProfile, { loading: isUpdateLoading, data }] = useUpdateAccountSettingsMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('settings.userProfile.updateError'));
    },
    onCompleted: ({ update_profile }) => {
      toast?.add(
        update_profile?.statusIsOk ? ToastrTypesEnums.SUCCESS : ToastrTypesEnums.DANGER,
        update_profile?.statusIsOk
          ? t('settings.userProfile.updateSuccessfully')
          : update_profile?.statusMessage || t('settings.userProfile.updateError')
      );
    },
  });

  const onFileDelete = (name: string) => {
    if (name === 'company_file' || name === 'user_file') setValue(name, null);
    if (name === 'company_file') {
      setPaths({ ...paths, company: '' });
    } else {
      setPaths({ ...paths, user: '' });
    }
  };

  const fileUpload = async (file: File) => {
    const data = new FormData();
    data.append('file', file);

    return fetch(`${GQL_ACTIONS_URL}/upload`, {
      method: 'POST',
      body: data,
    }).then((res) => res.json());
  };

  const onSubmit = async (data: MyAccountFormTypes) => {
    let user_image_id;
    let company_image_id;
    if (data?.user_file && data?.user_file[0]) {
      setIsUserFileLoading(true);
      user_image_id = await fileUpload(data?.user_file[0])
        .then((res) => res.id)
        .catch((_) => null)
        .finally(() => setIsUserFileLoading(false));
    }

    if (data?.company_file && data?.company_file[0]) {
      setIsCompanyFileLoading(true);
      company_image_id = await fileUpload(data?.company_file[0])
        .then((res) => res.id)
        .catch((_) => null)
        .finally(() => setIsCompanyFileLoading(false));
    }

    const userValues: MyAccoountProfileValues = {
      name: data.name,
      ...((user_image_id || paths?.user?.length === 0) && {
        file_store_id: user_image_id ? user_image_id : null,
      }),
    };

    updateProfile({
      variables: {
        user_profile_data: userValues,
        company_profile_data: {
          company_id: company_id,
          name: data.company_name,
          industry: data?.industry?.label,
          ...((company_image_id || paths?.company?.length === 0) && {
            file_store_id: company_image_id ? company_image_id : null,
          }),
        },
      },
    });
  };

  // handlers
  const handlePushToast = (type: ToastrTypesEnums, message: string) => {
    toast?.add(type, message);
  };

  const handleTogglePasswordResetModal = (isOpen: boolean) => () =>
    setIsChangePasswordModalOpen(isOpen);

  const handleToggleEmailResetModal = (isOpen: boolean) => () => {
    setIsChangeEmailModalOpen(isOpen);
  };

  const handleCloseEmailUpdateModal = (emailUpdateData?: ChangeEmailDefaultData) => {
    // close modal
    setIsChangeEmailModalOpen(false);

    // email data is retrived on success so it has to pass it over
    if (emailUpdateData) {
      setEmailUpdateInfo(emailUpdateData);
    }
  };

  const handleCloseEmailResetAlert = () => setEmailUpdateInfo(null);

  return (
    <>
      <form className="myAccount__body" id="register-form" onSubmit={handleSubmit(onSubmit)}>
        <ErrorApi
          error={data?.update_profile?.statusMessage}
          className="auth__form__content--error"
        />
        <div className="myAccount__body--content">
          <div className="myAccount__body--content--inner">
            <h1 className="mb-6 text-base font-medium leading-7 myAccount__body--content--title text-primary">
              1. {t('settings.personalInfo')}
            </h1>
            <div className="myAccount__body--content--profilePhoto">
              <FileFieldButton
                name={watch('name') || ''}
                file={watch('user_file') || undefined}
                file_path={paths?.user}
                onDelete={onFileDelete}
                labelProps={{ children: t('settings.profilePic') }}
                inputProps={{
                  name: 'user_file',
                  inputRef: register,
                }}
                error={errors.user_file?.message}
              />
            </div>
            <div className="myAccount__body--content--formGroup ">
              <div className="myAccount__body--content--formGroup--left">
                <FieldInput
                  labelProps={{
                    children: t('settings.fullName'),
                  }}
                  inputProps={{
                    name: 'name',
                    inputRef: register,
                  }}
                  error={errors.name?.message}
                />
              </div>
              <div className="myAccount__body--content--formGroup--right">
                <FieldInput
                  labelProps={{
                    children: t('settings.emailAddress'),
                  }}
                  inputProps={{
                    name: 'email',
                    inputRef: register,
                    disabled: true,
                  }}
                  error={errors.email?.message}
                />
              </div>
            </div>
          </div>
          {emailUpdateInfo !== null && (
            <EmailChangeAlertBox
              masterEmail={defaultData?.email || ''}
              password={emailUpdateInfo.password}
              email={emailUpdateInfo.email}
              onCloseAlertBox={handleCloseEmailResetAlert}
              onPushToastr={handlePushToast}
            />
          )}
          <div className="flex flex-col items-start">
            <Button
              label={t('settings.userProfile.form.changePasswordCTA')}
              className="mb-1 ml-1 text-green-200 font-semiBold"
              onClick={handleTogglePasswordResetModal(true)}
            />
            <Button
              label={t('settings.userProfile.form.changeEmailCTA')}
              className="mb-8 ml-1 text-green-200 md:mb-0 font-semiBold"
              onClick={handleToggleEmailResetModal(true)}
            />
          </div>
        </div>
        {user?.company_users[0].role === UserRoleEnums.ADMIN ? (
          <div className="myAccount__body--content">
            <div className="myAccount__body--content--inner">
              <h1 className="mb-6 text-base font-medium leading-7 myAccount__body--content--title text-primary">
                2. {t('settings.companyInfo')}
              </h1>
              <div className="myAccount__body--content--profilePhoto">
                <FileFieldButton
                  name={watch('company_name') || ''}
                  firstButtonName={t('button.uploadLogo')}
                  file={watch('company_file') || undefined}
                  onDelete={onFileDelete}
                  labelProps={{ children: t('settings.companyLogo') }}
                  inputProps={{
                    name: 'company_file',
                    inputRef: register,
                  }}
                  file_path={paths?.company}
                  error={errors.company_file?.message}
                />
              </div>
              <div className="myAccount__body--content--formGroup ">
                <div className="myAccount__body--content--formGroup--left">
                  <FieldInput
                    labelProps={{ children: t('settings.companyName') }}
                    inputProps={{
                      name: 'company_name',
                      inputRef: register,
                    }}
                  />
                </div>
                <div className="myAccount__body--content--formGroup--right">
                  <Controller
                    as={
                      <ReactSelect
                        defaultValue={{
                          label: defaultData.industry?.label,
                          value: defaultData.industry?.value,
                        }}
                      />
                    }
                    options={industries.map((i, key) => ({
                      ...i,
                      value: key,
                    }))}
                    error={!!errors.industry}
                    labelProps={{ children: t('settings.industry') }}
                    placeholder="Design & Development"
                    name="industry"
                    control={control}
                    defaultValue={defaultData.industry?.value}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="industry"
                    render={({ message }) => {
                      return <p className={'error--input mt-3'}>{message}</p>;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex items-center justify-end mb-8">
          <div className="teamMember__form__buttons">
            <Button
              label="Cancel"
              btnType={ButtonTypesEnums.WHITE_BORDER}
              btnSize={ButtonSizesEnums.XM}
              onClick={() => {}}
            />
            <Button
              className="teamMember__form__buttons--main"
              label="Save Profile"
              type="submit"
              btnType={ButtonTypesEnums.GREEN}
              btnSize={ButtonSizesEnums.LG}
              loading={isUpdateLoading || isUserFileLoading || isCompanyFileLoading}
            />
          </div>
        </div>
      </form>
      {isChangePasswordModalOpen && (
        <ChangePasswordDialog
          onClose={handleTogglePasswordResetModal(false)}
          companyId={company_id}
        />
      )}
      {isChangeEmailModalOpen && <ChangeEmailDialog onClose={handleCloseEmailUpdateModal} />}
    </>
  );
};

export default MyAccountForm;

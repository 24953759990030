import { useState, useLayoutEffect } from 'react';
export interface useWindowSizeProps {
  width?: number;
  height?: number;
  sm: boolean;
  md: boolean;
  xl: boolean;
}
// Hook
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<useWindowSizeProps>({
    width: undefined,
    height: undefined,
    sm: false,
    md: false,
    xl: false,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        sm: window.innerWidth < 375,
        md: window.innerWidth < 768 && window.innerWidth >= 375,
        xl: window.innerWidth >= 768,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;

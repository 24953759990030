import React from 'react';
import cx from 'classnames';

//components
import { Label, Input } from '../../../atoms';

//types
import { CheckboxProps } from './Checkbox.types';

const Checkbox: React.FC<CheckboxProps> = ({ labelProps, inputProps }) => (
  <Label
    {...labelProps}
    htmlFor={
      labelProps?.htmlFor
        ? labelProps.htmlFor
        : inputProps.id || inputProps.name
    }
    className={`checkbox ${cx(labelProps?.className)}`}
  >
    <Input {...inputProps} type="checkbox" />
    <span className="checkmark" />
    {labelProps?.children && labelProps.children}
  </Label>
);

export default Checkbox;

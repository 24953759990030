import React from 'react';

import { Svg } from '../../../../atoms';

const ExportSubscribers = () => (
  <Svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 3H4a2 2 0 00-2 2v10a2 2 0 002 2h3v-2H4V8h12v7h-3v2h3a2 2 0 002-2V5a2 2 0 00-2-2z"
      fill="#ADBAC8"
    />
    <path
      d="M13.955 13.707a.5.5 0 00-.079-.536l-3.5-4a.514.514 0 00-.752 0l-3.5 4a.5.5 0 00.376.83H9v4.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V14h2.5a.5.5 0 00.455-.294z"
      fill="#ADBAC8"
    />
  </Svg>
);

export default ExportSubscribers;

import { useState } from 'react';
import useKeys from '@rooks/use-keys';
import { useTranslation } from 'react-i18next';

export const useL10N = () => {
  const { t } = useTranslation();
  const [displayKey, setDisplayKey] = useState(false);

  // key listener
  useKeys(
    ['l', 't'], // LT
    () => setDisplayKey(!displayKey)
  );

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t: (key: string, options?: any) =>
      // eslint-disable-next-line no-nested-ternary
      displayKey ? key : options ? t(key, options) : t(key),
  };
};

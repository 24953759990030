import React, { FC, cloneElement, isValidElement, ReactElement } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

//types
import { NavSettingsItemProps } from './SettingsNavItems.types';

const SettingsNavItems: FC<NavSettingsItemProps> = ({ item, active }) => {
  return (
    <Link
      to={item.to}
      className={cx('navSettings__item', {
        'navSettings__item--active': active === item.id,
      })}
    >
      {isValidElement(item.before) && cloneElement(item.before as ReactElement)}
      <span className="navSettings__item--title">{item.label}</span>
    </Link>
  );
};

export default SettingsNavItems;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { ThreeDotsProps } from './ThreeDotsProps.types';

//enums
import { ThreeDotsEnums } from '../../../../enums';

const ThreeDots: FC<ThreeDotsProps> = ({ type = ThreeDotsEnums.DARK }) => (
  <div
    tabIndex={0}
    className={cx('three__dots', { [`three__dots--${type}`]: type })}
  >
    <div></div>
  </div>
);

export default ThreeDots;

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// components
import { InfoIcon } from '../../atoms';
import { LayoutContainer } from '../../templates';

import { PaymentBadgeProps } from './PaymentBadge.types';

const PaymentBadge: FC<PaymentBadgeProps> = ({ expiresIn }) => (
  <div className="bg-yellow">
    <LayoutContainer className="">
      <div className="flex items-center justify-center pt-3 pb-3 text-green-400 text-xs">
        <InfoIcon className="mr-1" />
        <p className="">
          You have {expiresIn} days remaning in the trial period!{' '}
        </p>
        <Link to="/settings/payments" className="ml-1 underline">
          Upgrade now.
        </Link>
      </div>
    </LayoutContainer>
  </div>
);

export default PaymentBadge;

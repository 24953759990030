import React, { useState } from 'react';

//components
import { Button, NewTemplate, ContentLoading, MessageTemplateEmpty } from '../../../../atoms';
import {
  MessageTemplateItem,
  MessageTemplateForm,
  CustomModal,
  SettingsTemplateEmpty,
} from '../../../../molecules';

//hooks
import useWindowSize from '../../../../../hooks/useWindowSize';
import useModal from '../../../../../hooks/useModal';
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import useMessageTemplate from '../../../../../gql/hooks/settings/useMessageTemplate';
import useCompanyUser from '../../../../../gql/hooks/useCompanyUser';

//types
import { MessageTemplateModel } from '../../../../../interfaces/models';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ModalTypesEnums,
  ContentLoadingEnums,
} from '../../../../../enums';

const Messages = () => {
  const { company_user } = useCompanyUser();
  const [template, setTemplate] = useState<MessageTemplateModel>();

  const { xl } = useWindowSize();
  const { t } = useL10N();

  const { isOpen, onClick, closeModal } = useModal();

  const { message_templates, loading } = useMessageTemplate({
    company_id: company_user?.company_id,
  });

  const handleOnEditClick = (template: MessageTemplateModel) => {
    setTemplate(template);
    onClick();
  };

  const handleOnNewMessageTemplateClick = () => {
    setTemplate(undefined);
    onClick();
  };

  return (
    <>
      <CustomModal
        open={isOpen}
        closeModal={closeModal}
        title={template ? t('settings.editMessageTemplate') : t('settings.newMessageTemplate')}
        type={ModalTypesEnums.MD}
      >
        <MessageTemplateForm closeModal={closeModal} template={template} />
      </CustomModal>
      <section className="messages">
        <div className="content__header">
          <span className="content__header--title">{t('settings.messagesTemplates')}</span>
          {message_templates.length > 0 && (
            <Button
              className="content__header--btn"
              label={xl ? t('button.newMessageTemplate') : t('button.new')}
              btnSize={!xl ? ButtonSizesEnums.XS : ButtonSizesEnums.MD}
              btnType={ButtonTypesEnums.GREEN}
              before={<NewTemplate />}
              onClick={handleOnNewMessageTemplateClick}
            />
          )}
        </div>
        {!loading ? (
          <div className="flex flex-col justify-center messages__body">
            {message_templates.length ? (
              message_templates.map((message, index) => (
                <MessageTemplateItem
                  message={message}
                  onEditClick={handleOnEditClick}
                  key={message.id}
                  id={index + 1}
                />
              ))
            ) : (
              <SettingsTemplateEmpty
                title={t('settings.templateEmptyTitle')}
                icon={<MessageTemplateEmpty />}
              >
                <Button
                  className="content__header--btn"
                  label={t('button.newMessageTemplate')}
                  btnSize={ButtonSizesEnums.LG}
                  btnType={ButtonTypesEnums.GREEN}
                  before={<NewTemplate />}
                  onClick={handleOnNewMessageTemplateClick}
                />
              </SettingsTemplateEmpty>
            )}
          </div>
        ) : (
          <ContentLoading size={ContentLoadingEnums.LG} className="mt-64" />
        )}
      </section>
    </>
  );
};

export default Messages;

import React, { FC } from 'react';

import TeamMembersList from './TeamMembersList';

//gql
import useCompanyUser from '../../../../../gql/hooks/useCompanyUser';

const TeamMember: FC = () => {
  const { company_user } = useCompanyUser();

  const renderList = () => {
    if (company_user?.company_id)
      return <TeamMembersList id={company_user?.company_id} />;
    else return null;
  };

  return renderList();
};

export default TeamMember;

import React, { FC, useState, useEffect, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';

//components
import {
  Dot,
  Button,
  LetterSvg,
  Clock,
  AtSign,
  Phone,
  Envelope,
  ArrowDown,
} from '../../atoms';
import {
  Title,
  Checkbox,
  CardElements,
  DefaultDropdown,
  CandidateDropdown,
} from '..';

//hooks
import useWindowSize from '../../../hooks/useWindowSize';
import { useL10N } from '../../../hooks/useL10N';

//gql
import useApplicantsStages from '../../../gql/hooks/applicants/useApplicantsStages';
import useCompanyUser from '../../../gql/hooks/useCompanyUser';

//types
import { JobItemProps } from './JobItem.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

//utils
import { findEventName } from '../../../utils/event/findEventName';
import { splitFullName } from '../../../utils/splitFullName';
import { findDotColor } from '../../../utils/findDotColor';
import { generateFormatedDate } from '../../../utils';

const JobItem: FC<JobItemProps> = ({ candidate, select, onSelect }) => {
  const { companyId } = useCompanyUser();
  const { result } = useApplicantsStages({ companyId });

  const { xl } = useWindowSize();
  const { t } = useL10N();

  const { location } = useHistory();

  const [stage, setStage] = useState<string>(
    candidate?.status_id ? findEventName(result, candidate?.status_id) : 0
  );

  const [showButtons, setShowButtons] = useState<boolean>(false);

  useEffect(() => {
    if (result?.length) {
      setStage(
        candidate?.status_id ? findEventName(result, candidate?.status_id) : 0
      );
    }
  }, [result, candidate]);

  const findStage = (stage: string) => {
    const dotColor = result ? findDotColor(result, undefined, stage) : '';
    const label = stage || 'Move to stage';
    return {
      className: 'candidate__item__buttons__trigger',
      btnSize: ButtonSizesEnums.SM,
      btnType: ButtonTypesEnums.WHITE_BORDER,
      after: <ArrowDown />,
      label,
      before: <Dot style={{ backgroundColor: dotColor }} />,
    };
  };

  const onHoverLeave = () => xl && setShowButtons(false);
  const onHoverEnter = () => xl && setShowButtons(true);

  const renderUserInitials = useMemo(
    () => (
      <Link to={`/candidate/${candidate.id}/overview`}>
        <LetterSvg
          first={splitFullName(candidate.name)?.firstName || ''}
          second={splitFullName(candidate.name)?.lastName || ''}
        />
      </Link>
    ),
    [candidate.name, candidate.id]
  );

  return (
    <div
      className="candidate__item"
      onMouseEnter={onHoverEnter}
      onMouseLeave={onHoverLeave}
    >
      <div className="candidate__item__body">
        <div className="candidate__item__body__checkbox">
          <Checkbox
            inputProps={{
              id: candidate.id.toString(),
              name: `name-${candidate.id}`,
              checked: select,
              onChange: onSelect,
            }}
          />
        </div>
        <div className="candidate__item__body__avatar">
          {renderUserInitials}
        </div>
        <div className="candidate__item__body__header">
          <Link
            to={{
              pathname: `/candidate/${candidate.id}/overview`,
              state: { prevLoc: location },
            }}
          >
            <Title value={candidate.name} />
          </Link>
        </div>
        <div className="candidate__item__body__content">
          <CardElements
            elements={[
              {
                ...(candidate.created_at && {
                  value: `${t('candidate.appliedOn')} ${generateFormatedDate(
                    candidate.created_at
                  )}`,
                  Icon: <Clock />,
                }),
              },
              {
                ...(candidate.email && {
                  value: candidate.email,
                  Icon: <AtSign />,
                }),
              },
              {
                ...(candidate.phone && {
                  value: candidate.phone,
                  Icon: <Phone />,
                }),
              },
            ]}
          />
        </div>
        <div className="candidate__item__body__buttons">
          <Link
            to={`/messages/inbox/new?applicant_ids=${candidate.id}`}
            className="mr-4"
          >
            <Button
              className="candidate__item__body__buttons--full "
              label={t('candidate.message')}
              btnSize={xl ? ButtonSizesEnums.SM : ButtonSizesEnums.XL}
              btnType={ButtonTypesEnums.WHITE_BORDER}
              before={<Envelope />}
            />
          </Link>
          <Link
            to={{
              pathname: `/candidate/${candidate.id}/overview`,
              state: { prevLoc: location },
            }}
          >
            <Button
              className="candidate__item__body__buttons--full"
              label={t('candidate.viewProfile')}
              btnSize={xl ? ButtonSizesEnums.SM : ButtonSizesEnums.XL}
              btnType={ButtonTypesEnums.BLUE}
            />
          </Link>
        </div>
      </div>
      {xl && showButtons && (
        <div className="candidate__item__buttons">
          <DefaultDropdown
            className="candidate__item__buttons__dropdown"
            triggerProps={findStage(stage)}
          >
            <CandidateDropdown
              onItemClick={setStage}
              applicant_id={candidate.id}
            />
          </DefaultDropdown>
          <Link to={`/messages/inbox/new?applicant_ids=${candidate.id}`}>
            <Button
              className="mr-2"
              label={t('candidate.message')}
              btnSize={ButtonSizesEnums.SM}
              btnType={ButtonTypesEnums.WHITE_BORDER}
              before={<Envelope />}
            />
          </Link>
          <Link
            to={{
              pathname: `/candidate/${candidate.id}/overview`,
              state: { prevLoc: location },
            }}
          >
            <Button
              label={t('candidate.viewProfile')}
              btnSize={ButtonSizesEnums.SM}
              btnType={ButtonTypesEnums.BLUE}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default JobItem;

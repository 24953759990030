import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Published: FC<IconProps> = ({ active = false }) => (
  <Svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 12.5834V3.41671M7.25 12.5834C7.25 13.0696 7.05685 13.5359 6.71303 13.8797C6.36921 14.2236 5.9029 14.4167 5.41667 14.4167H3.58333C3.0971 14.4167 2.63079 14.2236 2.28697 13.8797C1.94315 13.5359 1.75 13.0696 1.75 12.5834V3.41671C1.75 2.93048 1.94315 2.46416 2.28697 2.12034C2.63079 1.77653 3.0971 1.58337 3.58333 1.58337H5.41667C5.9029 1.58337 6.36921 1.77653 6.71303 2.12034C7.05685 2.46416 7.25 2.93048 7.25 3.41671M7.25 12.5834C7.25 13.0696 7.44315 13.5359 7.78697 13.8797C8.13079 14.2236 8.5971 14.4167 9.08333 14.4167H10.9167C11.4029 14.4167 11.8692 14.2236 12.213 13.8797C12.5568 13.5359 12.75 13.0696 12.75 12.5834M7.25 3.41671C7.25 2.93048 7.44315 2.46416 7.78697 2.12034C8.13079 1.77653 8.5971 1.58337 9.08333 1.58337H10.9167C11.4029 1.58337 11.8692 1.77653 12.213 2.12034C12.5568 2.46416 12.75 2.93048 12.75 3.41671M12.75 12.5834V3.41671M12.75 12.5834C12.75 13.0696 12.9432 13.5359 13.287 13.8797C13.6308 14.2236 14.0971 14.4167 14.5833 14.4167H16.4167C16.9029 14.4167 17.3692 14.2236 17.713 13.8797C18.0568 13.5359 18.25 13.0696 18.25 12.5834V3.41671C18.25 2.93048 18.0568 2.46416 17.713 2.12034C17.3692 1.77653 16.9029 1.58337 16.4167 1.58337H14.5833C14.0971 1.58337 13.6308 1.77653 13.287 2.12034C12.9432 2.46416 12.75 2.93048 12.75 3.41671"
      stroke={active ? '#08D4A5' : '#778CA3'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Published;

import React, { FC, MouseEvent } from 'react';

//compoennts
import { Button, Delete } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { DeleteDropdownsProps } from './DeleteDropdown.types';

const DeleteDropdown: FC<DeleteDropdownsProps> = ({
  closeMenu,
  onDeleteConfirm,
}) => {
  const { t } = useL10N();

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDeleteConfirm && onDeleteConfirm();
    closeMenu && closeMenu();
  };
  return (
    <div className="default__dropdown__jobs">
      <Button
        id="delete"
        label={t('dropdown.delete')}
        before={<Delete />}
        onClick={handleOnClick}
        className="default__dropdown__jobs--item"
      />
    </div>
  );
};

export default DeleteDropdown;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { TextAreaProps } from './Textarea.types';

const Textarea: FC<TextAreaProps> = ({
  className = '',
  id,
  name,
  error,
  textareaRef,
  ...props
}) => (
  <textarea
    {...props}
    id={id ? id : name}
    name={name}
    className={cx(`textarea`, {
      [className]: className,
      'textarea--danger': error,
    })}
    ref={textareaRef}
  />
);

export default Textarea;

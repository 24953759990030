import React, { FC, MouseEvent } from 'react';

//components
import { Candidates, Button } from '../../../atoms';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//types
import { CandidateMailsDropdownProps } from './CandidateMailsDropdown.types';

const CandidateMailsDropdown: FC<CandidateMailsDropdownProps> = ({
  isSeen,
  closeMenu,
  handleOnChange,
}) => {
  const { t } = useL10N();

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    closeMenu && closeMenu();
    handleOnChange(isSeen ? null : new Date(), true);
  };

  return (
    <div className="candidate__mails--dropdown">
      <Button
        id="mark_as_read"
        before={<Candidates />}
        onClick={handleOnClick}
        className="candidate__mails--dropdown--item"
        label={isSeen ? t('messages.markAsUnread') : t('messages.markAsRead')}
      />
    </div>
  );
};

export default CandidateMailsDropdown;

import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

//components
import { LayoutContainer } from '../../templates';
import { Breadcrumbs, Search } from '../../atoms';
import { FieldInput } from '../../molecules';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//enums
import { InputTypesEnums } from '../../../enums';

//utils
import { parseQueryString, serialize } from '../../../utils';

export interface MatchParams {
  id: string;
}

const ApplicantsHeader = () => {
  const {
    push,
    replace,
    location: { search },
  } = useHistory();
  const { t } = useL10N();

  const { query, ...restQuery } = parseQueryString(search);

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.value.length > 1
      ? replace(serialize({ ...restQuery, query: event.target.value }))
      : push(serialize({ ...restQuery, query: event.target.value }));

  return (
    <LayoutContainer>
      <div className="applicants__header">
        <Breadcrumbs
          list={[
            { name: 'Dashboard', to: '/dashboard' },
            { name: 'Applicants' },
          ]}
        />

        <div className="applicants__header__content">
          <h1 className="applicants__header__content--title">
            {t('applicants.title')}
          </h1>
        </div>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          <FieldInput
            before={<Search />}
            inputType={InputTypesEnums.ROUNDED_FULL}
            inputProps={{
              value: query || '',
              onChange: onChange,
              name: 'search',
              placeholder: t('job.searchPlaceholder'),
            }}
          />
        </form>
      </div>
    </LayoutContainer>
  );
};

export default ApplicantsHeader;

import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
  useMemo,
} from 'react';

//gql
import { client } from '../gql/client';
import { UserFieldsFragment } from '../gql/gen/graphql';

//types
import { Nullable } from '../interfaces/types/Nullable.types';

//utils
import { validateAuthToken } from '../utils/auth/validateAuthToken';
import { removeAuthToken } from '../utils/auth/removeAuthToken';
import { storeAuthToken } from '../utils/auth/storeAuthToken';

//enums
import { CreateMessageTypes } from '../enums';

export interface AuthContext {
  logout: () => void;
  setUser: (user: UserFieldsFragment) => void;
  authenticate: (token: string) => void;
  user: Nullable<UserFieldsFragment>;
  isAuthenticated: boolean;
}

export const authContext = createContext<Nullable<AuthContext>>(null);

export const AuthProvider = ({
  children,
}: PropsWithChildren<any>): ReactElement => {
  // keys to remove from local storage
  const keysToRemove = useMemo(() => {
    return [
      CreateMessageTypes.NEW,
      CreateMessageTypes.REPLY,
      CreateMessageTypes.COMPOSE,
    ];
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    validateAuthToken()
  );
  const [authUser, setAuthUser] = useState<Nullable<UserFieldsFragment>>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      keysToRemove.forEach((k) => localStorage.removeItem(k));
      client.resetStore();
    }
  }, [isAuthenticated, keysToRemove]);

  const logout = () => {
    setIsAuthenticated(false);
    removeAuthToken();
  };

  const setUser = (user: UserFieldsFragment) => {
    setAuthUser(user);
  };

  const authenticate = (token: string) => {
    storeAuthToken({ statusIsOk: true, token });
    setIsAuthenticated(true);
  };

  return (
    <authContext.Provider
      value={{
        isAuthenticated,
        setUser,
        logout,
        authenticate,
        user: authUser,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

import React from 'react';

import { Svg } from '../../../atoms';

export const FacebookIcon = () => (
  <Svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8.548a8.001 8.001 0 10-9.25 7.9V10.86H4.719V8.548H6.75V6.785a2.822 2.822 0 013.021-3.112c.6.008 1.199.06 1.791.156V5.8h-1.008a1.155 1.155 0 00-1.3 1.25v1.5h2.219l-.355 2.312H9.25v5.591A8 8 0 0016 8.548z"
      fill="#006AEF"
    />
  </Svg>
);

export default FacebookIcon;

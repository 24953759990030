import React, { FC } from 'react';
import cx from 'classnames';

//components
import { Dashboard, Jobs, Messages, Subscribers } from '../../atoms';
import { NavItem } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { NavItemsProps } from './NavItems.types';

const NavItems: FC<NavItemsProps> = ({ dropdown = false, onClick }) => {
  const { t } = useL10N();

  return (
    <div
      className={cx({
        'dropdown__nav--items': dropdown,
        'header__nav--items': !dropdown,
      })}
    >
      <NavItem
        to="/dashboard"
        Icon={<Dashboard color="#778CA3" />}
        label={t('header.dashboard')}
        onClick={onClick}
      />
      <NavItem
        to="/jobs/all"
        match="/job"
        Icon={<Jobs color="#778CA3" />}
        label={t('header.jobs')}
        onClick={onClick}
      />
      <NavItem
        to="/messages/inbox"
        Icon={<Messages color="#778CA3" />}
        label={t('header.messages')}
        onClick={onClick}
      />
      <NavItem
        to="/applicants/all"
        Icon={<Subscribers color="#778CA3" />}
        label={t('header.applicants')}
        onClick={onClick}
      />
      <NavItem
        to="/subscribers"
        Icon={<Subscribers color="#778CA3" />}
        label={t('header.subscribers')}
        onClick={onClick}
      />
    </div>
  );
};
export default NavItems;

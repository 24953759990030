import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import dayjs from 'dayjs';

//components
import { Dot, NotificationSpan } from '../../../atoms';

//gql
import { useUpdateMessageByPkMutation } from '../../../../gql/gen/graphql';

//types
import { EmailListItemProps } from './EmailListItem.types';

//enums
import { DotSizesEnums, DotTypesEnums } from '../../../../enums';

//utils
import { gqlVar, parseQueryString } from '../../../../utils';

const EmailListItem: FC<EmailListItemProps> = ({ email, active }) => {
  // hooks
  const {
    push,
    location: { pathname, search },
  } = useHistory();

  // gql hook
  const [updateMessageByPk] = useUpdateMessageByPkMutation();

  // const
  const { id, name, seen, subject, date, vacancyTitle, repliesCount } = email;

  // handlers
  const { message_id } = parseQueryString(search);

  const handleOnClick = () => {
    updateMessageByPk(gqlVar({ id: +id, set: { read_at: new Date() } }));
    if (!pathname.includes(id)) {
      push(`/messages/inbox/${id}${search}`);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const returnType =
    pathname.includes('reply') || pathname.includes('compose')
      ? message_id
      : active;

  return (
    <div
      className={cx('email__listItem', {
        'email__listItem--active': returnType === id,
      })}
      role="button"
      onClick={handleOnClick}
    >
      <div
        className={cx('email__listItem--dot', {
          invisible: seen || message_id === id,
        })}
      >
        <Dot size={DotSizesEnums.SMALL} type={DotTypesEnums.GREEN} />
      </div>
      <div
        className={cx('email__listItem--content', {
          'email__listItem--content--active': active === id,
        })}
      >
        <div className="email__listItem--content--header">
          <h1>{name}</h1>
          {active !== id && repliesCount + 1 > 1 && (
            <NotificationSpan value={repliesCount + 1} />
          )}
          <span>{dayjs(date).format('MMM D')}</span>
        </div>
        <p className="mb-3">{repliesCount > 0 ? `Re :${subject}` : subject}</p>
        <div className="job__holderName bg-white px-2 py-1 rounded-medium border-2 border-gray-200 border-opacity-50 text-sm text-secondary">
          {vacancyTitle}
        </div>
      </div>
    </div>
  );
};

export default EmailListItem;

import { MutationUpdaterFn } from '@apollo/client';
import { NotesFragment } from '../../fragments/notes/NotesFragment';

export const insertNotesCache: MutationUpdaterFn = (cache, { data }) => {
  cache.modify({
    fields: {
      notes(existingNoteRefs = [], { readField }) {
        const newNote = cache.writeFragment({
          data: data?.insert_notes_one,
          fragment: NotesFragment,
        });

        // Quick safety check - if the new comment is already
        // present in the cache, we don't need to add it again.
        if (
          existingNoteRefs.some(
            (ref) => readField('id', ref) === data?.insert_notes_one.id
          )
        ) {
          return existingNoteRefs;
        }
        return [newNote, ...existingNoteRefs];
      },
      // notes_aggregate() {},// need to work on this
    },
  });
};

export const removeNotesCache: MutationUpdaterFn = (cache, { data }) => {
  cache.modify({
    fields: {
      notes(existingNotesRefs, { readField }) {
        return existingNotesRefs.filter(
          (noteRef) => data?.delete_notes_by_pk.id !== readField('id', noteRef)
        );
      },
      // notes_aggregate() {},// need to work on this
    },
  });
};

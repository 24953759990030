import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

//components
import { Button, Dropdown, Publish, Share, Delete, Edit, Eye } from '../../atoms';
import JobShareSubDropdown from './JobShareSubDropdown';

//hooks
import { useL10N } from '../../../hooks/useL10N';
import useDropdown from '../../../hooks/useDropdown';

// config
import { DISCOVR_PAGE_URL } from '../../../config';

//types
import { JobsDropdownProps } from '../../../interfaces';

//enums
import { VacaniesStatusTypesEnums } from '../../../enums';

const JobsDropdown: FC<JobsDropdownProps> = ({
  vacancy,
  closeMenu,
  onEditClick,
  toggleDeleteModal,
  togglePublishModal,
  showEditButton = false,
}) => {
  const { t } = useL10N();

  const {
    open: isSubMenuOpen,
    onMenuClick: onSubMenuClick,
    closeMenu: closeSubDropdown,
  } = useDropdown();

  const handleOnSubMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onSubMenuClick();
    closeMenu && closeMenu();
  };

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (event.currentTarget.id === 'publish') {
      togglePublishModal && togglePublishModal(true);
    }
    if (event.currentTarget.id === 'delete') {
      toggleDeleteModal && toggleDeleteModal(true);
    }
    if (event.currentTarget.id === 'edit') {
      onEditClick && onEditClick();
    }
    closeMenu && closeMenu();
  };

  return (
    <div className="default__dropdown__jobs">
      <a
        rel="noopener noreferrer"
        aria-label={vacancy?.title}
        href={`${DISCOVR_PAGE_URL}/vacancy/view/${vacancy?.slug}`}
      >
        <Button
          id="view"
          label={t('jobs.viewJob')}
          before={<Eye type={2} />}
          className="default__dropdown__jobs--item default__dropdown__jobs--view"
        />
      </a>
      {vacancy.status !== VacaniesStatusTypesEnums.PUBLISHED && (
        <Button
          id="publish"
          label={t('jobs.publish')}
          before={<Publish />}
          onClick={onClick}
          className="default__dropdown__jobs--item"
        />
      )}
      {showEditButton && (
        <Link to={`/job/${vacancy.id}/edit`}>
          <Button
            id="edit"
            label={t('jobs.editJob')}
            before={<Edit />}
            className="default__dropdown__jobs--item default__dropdown__jobs--edit"
          />
        </Link>
      )}
      {vacancy.status !== VacaniesStatusTypesEnums.DRAFT && (
        <Button
          id="share"
          label={t('jobs.share')}
          before={<Share type={2} />}
          onClick={onSubMenuClick}
          className={cx('default__dropdown__jobs--item', {
            'default__dropdown__jobs--activeItem': isSubMenuOpen,
          })}
        />
      )}
      <Button
        id="delete"
        label={t('jobs.delete')}
        before={<Delete />}
        onClick={onClick}
        className="default__dropdown__jobs--item"
      />
      <Dropdown
        className="pr-3 default__dropdown default__subDropdown"
        open={isSubMenuOpen}
        closeIcon={false}
        withOpacity={false}
        closeMenu={closeSubDropdown}
        trigger={<Button className="text-primary" onClick={handleOnSubMenuClick} />}
      >
        <JobShareSubDropdown closeMenu={closeSubDropdown} url={`/vacancy/view/${vacancy?.slug}`} />
      </Dropdown>
    </div>
  );
};

export default JobsDropdown;

import React, { FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';

//types
import { LineChartProp, datasetsType } from './LineChart.types';

const LineChart: FC<LineChartProp> = ({ data }) => {
  const [datasets, setDatasets] = useState<Array<datasetsType>>([]);
  const [labels, setLabels] = useState<Array<string>>([]);

  useEffect(() => {
    let datas: datasetsType[] = [];
    let labels: string[] = [];
    if (data.length) {
      data.map((x: any) => {
        let result = x?.result?.map((j: any) => {
          return j.applicants;
        });
        labels = x?.result?.map((j: any) => {
          return dayjs(j.date).format('D MMM');
        });
        datas.push({
          label: 'Job1',
          data: result?.reverse(),
          borderColor: x.color,
          fill: false,
        });
        return x;
      });
    }

    setLabels(labels?.reverse());
    setDatasets(datas);
  }, [data]);

  const options = {
    elements: {
      point: {
        radius: 2,
      },
    },
    showLines: true,
    spanGaps: true,
    layout: {
      padding: {
        top: 55,
      },
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            min: 0,
            display: true,
            color: '#8492A6',
          },
          gridLines: {
            display: true,
            color: '#F5F5F5',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: '#3D4858',
      enabled: true,
      mode: 'single',
      displayColors: false,
      yAlign: 'bottom',
      xAlign: 'center',
      xPadding: 8,
      titleAlign: 'left',
      callbacks: {
        title: function (tooltipItems) {
          return dayjs(tooltipItems[0].label).format('D MMM');
        },
        label: function (tooltipItems) {
          return 'Applicants      ' + tooltipItems.value;
        },
      },
    },
  };

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;

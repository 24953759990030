import decode from 'jwt-decode';
import { JWTModel } from '../../interfaces/models';
import { getAuthToken } from './getAuthToken';

export const validateAuthToken = (token?: string): boolean => {
  const _token = token || getAuthToken();

  if (_token) {
    try {
      const decoded = decode<JWTModel>(_token);
      const now = Date.now() / 1000;

      if (!decoded['https://hasura.io/jwt/claims']) {
        return false;
      }
      // Check if now (in ms) is less then exp time
      return now <= decoded.exp;
    } catch (error) {
      console.error('👾 invalid token format', error);
      return false;
    }
  }
  return false;
};

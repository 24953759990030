import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Location: FC<IconProps> = ({ type = 1 }) => (
  <Svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === 1 ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.05002 2.04999C3.36284 0.73717 5.14341 -0.000366211 7.00002 -0.000366211C8.85663 -0.000366211 10.6372 0.73717 11.95 2.04999C13.2628 3.36281 14.0004 5.14338 14.0004 6.99999C14.0004 8.8566 13.2628 10.6372 11.95 11.95L7.00002 16.9L2.05002 11.95C1.39993 11.3 0.884251 10.5283 0.532423 9.67894C0.180596 8.82961 -0.000488281 7.9193 -0.000488281 6.99999C-0.000488281 6.08068 0.180596 5.17037 0.532423 4.32104C0.884251 3.47172 1.39993 2.70001 2.05002 2.04999ZM7.00002 8.99999C7.53045 8.99999 8.03916 8.78928 8.41423 8.4142C8.7893 8.03913 9.00002 7.53042 9.00002 6.99999C9.00002 6.46956 8.7893 5.96085 8.41423 5.58578C8.03916 5.2107 7.53045 4.99999 7.00002 4.99999C6.46958 4.99999 5.96088 5.2107 5.5858 5.58578C5.21073 5.96085 5.00002 6.46956 5.00002 6.99999C5.00002 7.53042 5.21073 8.03913 5.5858 8.4142C5.96088 8.78928 6.46958 8.99999 7.00002 8.99999Z"
        fill="#CBD5E0"
      />
    ) : (
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.05051 2.05036C3.36333 0.737536 5.14389 0 7.0005 0C8.85711 0 10.6377 0.737536 11.9505 2.05036C13.2633 3.36318 14.0009 5.14375 14.0009 7.00036C14.0009 8.85697 13.2633 10.6375 11.9505 11.9504L7.0005 16.9004L2.05051 11.9504C1.40042 11.3003 0.884739 10.5286 0.532912 9.67931C0.181084 8.82998 0 7.91967 0 7.00036C0 6.08104 0.181084 5.17073 0.532912 4.32141C0.884739 3.47208 1.40042 2.70038 2.05051 2.05036ZM7.0005 9.00036C7.53094 9.00036 8.03965 8.78964 8.41472 8.41457C8.78979 8.0395 9.00051 7.53079 9.00051 7.00036C9.00051 6.46992 8.78979 5.96122 8.41472 5.58614C8.03965 5.21107 7.53094 5.00036 7.0005 5.00036C6.47007 5.00036 5.96136 5.21107 5.58629 5.58614C5.21122 5.96122 5.0005 6.46992 5.0005 7.00036C5.0005 7.53079 5.21122 8.0395 5.58629 8.41457C5.96136 8.78964 6.47007 9.00036 7.0005 9.00036Z"
        fill="#778CA3"
      />
    )}
  </Svg>
);

export default Location;

import React from 'react';

//components
import { JobEmpty } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

const JobsEmptyView = () => {
  const { t } = useL10N();

  return (
    <div className="empty">
      <div className="empty--icon">
        <JobEmpty />
      </div>
      <h1 className="empty__heading text-primary">
        {t('job.noApplicantsTitle')}
      </h1>
      <p className="empty__desc font-medium">{t('job.noApplicantsDesc')}</p>
    </div>
  );
};

export default JobsEmptyView;

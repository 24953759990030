import React, { FC } from 'react';

import { Svg } from '../../../atoms';
import { IconProps } from './Icon.types';

const SelectedIcon: FC<IconProps> = ({ className = '' }) => (
  <Svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.12492 10.0473C6.88844 9.80391 6.57171 9.66924 6.24296 9.67229C5.9142 9.67533 5.59972 9.81585 5.36725 10.0636C5.13477 10.3113 5.0029 10.6464 5.00005 10.9967C4.99719 11.3471 5.12357 11.6846 5.35197 11.9366L7.85968 14.6088C8.09481 14.8593 8.41367 15 8.74615 15C9.07863 15 9.39749 14.8593 9.63262 14.6088L14.648 9.26433C14.8764 9.01234 15.0028 8.67483 15 8.32451C14.9971 7.97418 14.8652 7.63907 14.6328 7.39134C14.4003 7.14361 14.0858 7.00309 13.757 7.00005C13.4283 6.99701 13.1116 7.13168 12.8751 7.37506L8.74615 11.7749L7.12492 10.0473Z"
      fill="#08D4A5"
    />
  </Svg>
);

export default SelectedIcon;

import { object, string, mixed, ref } from 'yup';

const supportedFileFormats = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

const fileSize = 1024 * 1024 * 50;

export const LoginFormSchema = ({ t }) =>
  object().shape({
    email: string()
      .email()
      .required()
      .label(t('shared.forms.validation.email')),
    password: string()
      .required()
      .min(8)
      .label(t('shared.forms.validation.password')),
  });

export const RegisterFormSchema = ({ t }) =>
  object().shape({
    user_file: mixed()
      .test(
        'fileFormat',
        t('shared.forms.validation.fileFormatInvalid'),
        (value) => {
          if (value[0]) {
            if (supportedFileFormats.includes(value[0].type)) {
              return value[0].size <= fileSize;
            } else return false;
          } else return true;
        }
      )
      .label(t('shared.forms.validation.profilePicture')),
    name: string()
      .trim()
      .required()
      .label(t('shared.forms.validation.fullName')),
    email: string()
      .email()
      .required()
      .label(t('shared.forms.validation.email')),
    password: string().required().min(8),
    company_file: mixed()
      .test(
        'fileFormay',
        t('shared.forms.validation.fileFormatInvalid'),
        (value) => {
          if (value[0]) {
            if (supportedFileFormats.includes(value[0].type)) {
              return value[0].size <= fileSize;
            } else return false;
          } else return true;
        }
      )
      .label(t('shared.forms.validation.logo')),
    company_name: string()
      .trim()
      .required()
      .label(t('shared.forms.validation.companyName')),
    industry: object({})
      .shape({
        label: string()
          .required()
          .nullable()
          .label(t('shared.forms.validation.industry')),
      })
      .nullable()
      .required()
      .label(t('shared.forms.validation.industry')),
  });

export const ForgotPasswordSchema = ({ t }) =>
  object().shape({
    email: string()
      .email()
      .required()
      .label(t('shared.forms.validation.email')),
  });

export const ResetPasswordSchema = ({ t }) =>
  object().shape({
    password: string()
      .required()
      .min(8)
      .label(t('shared.forms.validation.password')),
    confirm_password: string()
      .required()
      .min(8)
      .oneOf(
        [ref('password'), null],
        t('shared.forms.validation.passwordMustMatch')
      )
      .label(t('shared.forms.validation.confirmPassword')),
  });

import React from 'react';
import { useHistory } from 'react-router-dom';

//components
import { PaymentConfirmed, CustomModal } from '../../components/molecules';
import {
  LayoutContainer,
  PagesContainer,
  HeaderLayout,
} from '../../components/templates';
import {
  DashboardHeader,
  JobPosts,
  DashboardStatistics,
  JobsHolder,
  MessagesHolder,
} from '../../components/organisms';
import RecentApplicants from '../../components/organisms/dashboard/content/RecentApplicants';

// enums
import { ModalTypesEnums } from '../../enums';

// utils
import { parseQueryString } from '../../utils';
import { useState } from 'react';

const Dashboard = () => {
  const {
    push,
    location: { search },
  } = useHistory();
  const { payment } = parseQueryString(search);
  const [
    showPaymentConfirmedModal,
    setShowPaymentConfirmedModal,
  ] = useState<boolean>(!!payment && payment);

  const handleCloseModal = () => {
    setShowPaymentConfirmedModal(false);
    push('/');
  };

  return (
    <HeaderLayout l10nTitleKey="head.titles.dashboard">
      <CustomModal
        open={showPaymentConfirmedModal}
        closeModal={handleCloseModal}
        type={ModalTypesEnums.XS}
        header={false}
      >
        <PaymentConfirmed closeModal={handleCloseModal} />
      </CustomModal>
      <PagesContainer>
        <div className="dashboard">
          <DashboardHeader />
          <LayoutContainer className="">
            <div className="dashboard__content">
              <div className="dashboard__content--leftSide">
                <JobPosts />
                <RecentApplicants />
                <JobsHolder />
              </div>
              <div className="dashboard__content--rightSide">
                <DashboardStatistics />
                <MessagesHolder />
              </div>
            </div>
          </LayoutContainer>
        </div>
      </PagesContainer>
    </HeaderLayout>
  );
};

export default Dashboard;

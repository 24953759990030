import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//components
import { PaginationArrows } from '../../atoms';
import { PaginationItem } from '..';

//types
import { MessagesPaginationProps } from './MessagesPagination.types';
import { PageItemTypes } from './PaginationItem.types';

//enums
import { MessagesPaginationTypesEnums } from '../../../enums';

//utils
import { serialize, parseQueryString } from '../../../utils';

const MessagesPagination: FC<MessagesPaginationProps> = ({
  countElements,
  limit = MessagesPaginationTypesEnums.LIMIT,
}) => {
  const [pages, setPages] = useState<Array<PageItemTypes>>([]);

  const {
    push,
    replace,
    location: { search, pathname },
  } = useHistory();

  const { page: activePage, ...restQuery } = parseQueryString(search);
  const page = activePage || 1;

  useEffect(() => {
    if (countElements) {
      let tempPages: PageItemTypes[] = [];
      for (let i = 0; i < countElements / limit; i++) {
        tempPages.push({ id: `${i + 1}`, name: `${i + 1}` });
      }
      setPages(tempPages);
    }
  }, [countElements, limit]);

  useEffect(() => {
    if (pages.length > 0 && pages.length < +page)
      replace(serialize({ ...restQuery, page: +pages[pages.length - 1].id }));
  }, [page, pages, replace, restQuery]);

  const onPrev = () => {
    if (!page || parseInt(page) === 1) return;
    const buildQuery = serialize({ ...restQuery, page: page - 1 });
    push(pathname + buildQuery);
  };

  const onNext = () => {
    if (parseInt(page) >= pages.length) return;
    const buildQuery = serialize({
      ...restQuery,
      page: parseInt(page) + 1 || 2,
    });
    push(pathname + buildQuery);
  };

  return (
    <ul className="pagination flex justify-center pt-8 pb-8">
      {page >= 2 && (
        <PaginationItem
          className="previous"
          pageItem={{ id: 'prev', name: 'Previous' }}
          after={<PaginationArrows type={2} />}
          handleItemClick={onPrev}
          disabled={page === 1}
        />
      )}
      <span className="">{page + ' of ' + pages?.length}</span>
      {pages.length > 1 && page < pages?.length && (
        <PaginationItem
          className="next"
          pageItem={{ id: 'next', name: 'Next' }}
          before={<PaginationArrows />}
          handleItemClick={onNext}
          disabled={page === pages.length}
        />
      )}
    </ul>
  );
};

export default MessagesPagination;

import React, { cloneElement, isValidElement, FC } from 'react';
import cx from 'classnames';

//types
import { ToastrItemProps } from './ToastrItem.types';

const ToastrItem: FC<ToastrItemProps> = ({ position, buttonProps, toast }) => {
  const { icon, ...otherButtonProps } = buttonProps;
  return (
    <div className={cx('notification toast', position, toast.classNames)}>
      <div className="notification__image">
        {isValidElement(toast.icon) && cloneElement(toast.icon)}
      </div>
      <p className="notification__message">{toast.description}</p>
      <div className="notification-close">
        <button {...otherButtonProps}>
          {isValidElement(icon) ? cloneElement(icon) : 'X'}
        </button>
      </div>
    </div>
  );
};

export default ToastrItem;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { DotProps } from './Dot.types';

//enums;
import { DotTypesEnums, DotSizesEnums } from '../../../../enums';

const Dot: FC<DotProps> = ({
  type = DotTypesEnums.DEFAULT,
  size = DotSizesEnums.DEFAULT,
  style,
}) => {
  return <div className={cx(`box__${size} ${type}`)} style={style && style} />;
};

export default Dot;

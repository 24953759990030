import React from 'react';

import { Svg } from '../../../../atoms';

const TeamMember = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.6}
      d="M10 2.991a3.667 3.667 0 110 4.851m2.75 10.408h-11v-.917a5.5 5.5 0 0111 0v.917zm0 0h5.5v-.917A5.5 5.5 0 0010 12.57m.917-7.152a3.667 3.667 0 11-7.333 0 3.667 3.667 0 017.333 0z"
      stroke="#778CA3"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default TeamMember;

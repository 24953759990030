import React, { FC, PointerEvent } from 'react';

// atoms
import { ReorderIcon } from '../../assets/icons/index';

//types
import { ReorderButtonProps } from './ReorderButton.types';

const ReorderButton: FC<ReorderButtonProps> = ({ dragControls }) => {
  // handler
  const handlePointer = (event: PointerEvent<HTMLSpanElement>) => dragControls.start(event);

  return (
    <span
      className="z-0 flex items-start justify-center p-1 pl-5 pr-3 mt-6 bg-white cursor-move md:items-center md:pl-0 md:mt-0"
      onPointerDown={handlePointer}
    >
      <ReorderIcon />
    </span>
  );
};

export default ReorderButton;

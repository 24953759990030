import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';

//components
import { LayoutContainer, PagesContainer, HeaderLayout } from '../../components/templates/';
import { Breadcrumbs } from '../../components/atoms';
import { JobForm } from '../../components/organisms';
import { CustomModal, PromoteJob } from '../../components/molecules';

//hooks
import { useL10N } from '../../hooks/useL10N';
import useModal from '../../hooks/useModal';

//gql
import useCompanyUser from '../../gql/hooks/useCompanyUser';
import { useInsertVacanciesMutation } from '../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../contexts/ToastrContexts';

//types
import { JobFormTypes } from '../../components/organisms/job/content/JobForm.types';

//config
import { GQL_ACTIONS_URL } from '../../config/gql';

//enums
import { VacaniesStatusTypesEnums, ToastrTypesEnums, ModalTypesEnums } from '../../enums';

import { gqlData, convertDateFormat } from '../../utils';

const Create = () => {
  const { push } = useHistory();
  const toast = useContext(toastrContext);
  const { t } = useL10N();
  const [vacancyId, setVacancyId] = useState<number>();

  const [btnId, setBtnId] = useState<VacaniesStatusTypesEnums | ''>(VacaniesStatusTypesEnums.DRAFT);

  const { company_user } = useCompanyUser();

  const [insert, { loading }] = useInsertVacanciesMutation();

  const {
    isOpen: isPromotedModalOpen,
    onClick: onPromotedModalClick,
    closeModal: closePromotedModal,
  } = useModal();
  const fileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return fetch(`${GQL_ACTIONS_URL}/upload`, {
      method: 'POST',
      body: formData,
    }).then((res) => res.json());
  };

  const onSubmit = async (
    {
      type,
      file,
      subscriber_notified_at,
      location,
      salary_from,
      salary_to,
      showOnDashboard,
      deadline,
      title,
      description,
      task,
      ...rest
    }: JobFormTypes,
    btnId: VacaniesStatusTypesEnums
  ) => {
    let fileStoreId;
    if (file && file[0])
      fileStoreId = await fileUpload(file[0])
        .then((res) => res.id)
        .catch((err) => undefined);
    setBtnId(btnId);

    insert(
      gqlData({
        ...rest,
        ...(location && { location: location || '' }),
        ...(salary_from ? { salary_from: Number(salary_from) } : { salary_from: 0 }),
        ...(salary_to ? { salary_to: Number(salary_to) } : { salary_to: 0 }),
        ...(fileStoreId && { file_store_id: fileStoreId }),
        ...(title && { title }),
        ...(deadline && { deadline: convertDateFormat(deadline) }),
        ...(description && { description }),
        ...(task && { task }),
        ...(type && { type: type?.value?.toString() || '' }),
        status: typeof btnId === 'string' ? parseInt(btnId) : 0,
        is_NotifyOn:
          typeof btnId === 'string' && parseInt(btnId) === VacaniesStatusTypesEnums.DRAFT
            ? false
            : subscriber_notified_at,
        company_id: company_user?.company_id,
        is_shown: showOnDashboard,
      })
    )
      .then(({ data }) => {
        if (data?.insertVacancy?.statusIsOk) {
          setVacancyId(data?.insertVacancy?.vacancy_id || 0);
          if (
            data?.insertVacancy?.status !== VacaniesStatusTypesEnums.DRAFT &&
            data?.insertVacancy?.is_shown === true
          ) {
            onPromotedModalClick();
          } else {
            toast?.add(ToastrTypesEnums.SUCCESS, t('job.editedSuccessfully'));
            push('/jobs/all');
          }
        } else {
          toast?.add(ToastrTypesEnums.DANGER, t('job.editedError'));
        }
      })
      .catch((err) => {
        toast?.add(ToastrTypesEnums.DANGER, t('job.editedError'));
        setBtnId('');
      });
  };

  const handleClosePromotedModal = () => {
    toast?.add(ToastrTypesEnums.SUCCESS, t('job.editedSuccessfully'));
    closePromotedModal();
    push('/jobs/all');
  };

  return (
    <HeaderLayout l10nTitleKey="head.titles.createJob">
      <PagesContainer>
        <div className="job__form">
          <LayoutContainer className="job__form--header">
            <Breadcrumbs
              list={[
                { name: t('candidate.jobs'), to: '/jobs/all' },
                { name: t('jobs.createNewJob') },
              ]}
            />
          </LayoutContainer>
          <LayoutContainer className="job__form--container">
            <JobForm
              onSubmit={onSubmit}
              headerProps={{
                id: null,
                title: t('jobs.createNewJob'),
                loading: (loading && btnId) || '',
                submitCallback: () => null,
                handleSubmit: () => null,
                trigger: () => null,
                data: null,
                jobTitle: null,
              }}
            />
          </LayoutContainer>
        </div>
      </PagesContainer>
      <CustomModal
        open={isPromotedModalOpen}
        closeModal={handleClosePromotedModal}
        title={t('job.promoteTitle')}
        type={ModalTypesEnums.XL}
      >
        <PromoteJob closeModal={closePromotedModal} vacancyId={vacancyId} />
      </CustomModal>
    </HeaderLayout>
  );
};

export default Create;

import React from 'react';

// component
import { HeaderLayout } from '../../components/templates';

//hooks
import { useL10N } from '../../hooks/useL10N';

const NotFound = () => {
  const { t } = useL10N();

  return (
    <HeaderLayout l10nTitleKey="head.titles.notFound">
      <section className="notFound">
        <h1 className="notFound__error">404</h1>
        <h1 className="notFound__title">{t('page.notFound.title')}</h1>
        <p className="notFound__description">{t('page.notFound.desc')}</p>
      </section>
    </HeaderLayout>
  );
};
export default NotFound;

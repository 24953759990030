import { useAuthUserQuery, UserFieldsFragment } from '../../gen/graphql';

const useAuthUser = (
  withUser: boolean = true,
  fetchPolicy?:
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | 'cache-and-network'
): {
  isAuthenticated: boolean;
  loading: boolean;
  user?: UserFieldsFragment | null;
} => {
  const query = useAuthUserQuery({ ...(fetchPolicy && { fetchPolicy }) });

  const { data: { auth_user = [] } = {}, loading, error } = query;

  if (!loading && !error)
    return {
      isAuthenticated: !!auth_user[0],
      loading,
      ...(withUser && { user: auth_user[0] }),
    };

  return {
    isAuthenticated: false,
    loading,
    ...(withUser && !error && { user: null }),
  };
};

export default useAuthUser;

import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

// hooks
import { useL10N } from '../../../hooks/useL10N';

const HeaderLayout: FC<{ l10nTitleKey: string }> = ({
  children,
  l10nTitleKey,
}) => {
  // l10n
  const { t } = useL10N();

  return (
    <>
      <Helmet>
        <title>{t('head.titles.template', { title: t(l10nTitleKey) })}</title>
      </Helmet>
      {children}
    </>
  );
};

export default HeaderLayout;

import React from 'react';

const PaymentHeader = () => (
  <div className="bg-gray-100 px-4 py-2 paymentHistory__content">
    <span>Date</span>
    <span>Invoice number</span>
    <span>Amount</span>
    <span className="paymentHistory__content--attachment">Attachment</span>
  </div>
);

export default PaymentHeader;

import React, { FC } from 'react';

//types
import { StatisticsHolderProps } from './StatisticsHolder.types';

const StatisticsHolder: FC<StatisticsHolderProps> = ({
  title,
  number,
  icon,
}) => (
  <div className="statistics__holder">
    <div className="statistics__holder--content">
      <h1 className="statistics__holder--content--number">{number}</h1>
      <p className="statistics__holder--content--title">{title}</p>
    </div>
    {icon}
  </div>
);

export default StatisticsHolder;

import React, { useRef, useState, useEffect, FC } from 'react';
import { Editor, EditorState, CompositeDecorator, Modifier } from 'draft-js';
import cx from 'classnames';
import { EditorProps } from './Editor.types';

//components
import { Label, InputErrorMessage, Button, Dropdown } from '../../../atoms';
import MessageVariablesDropdown from '../../dropdowns/MessageVariablesDropdown';

//hooks
import { useL10N } from '../../../../hooks/useL10N';
import useDropdown from '../../../../hooks/useDropdown';

const MyEditor: FC<EditorProps> = ({
  error = false,
  onMessageChange,
  message,
}) => {
  const { t } = useL10N();
  const words = [t('applicant_name'), t('job_title')];
  const { open, onMenuClick, closeMenu } = useDropdown();

  const findWithRegex = (keyWords, contentBlock, callback) => {
    const text = contentBlock.getText();
    keyWords.forEach((word) => {
      const matches = [...text.matchAll(word)];
      matches.forEach((match) =>
        callback(match.index, match.index + match[0].length)
      );
    });
  };

  const createDecorator = () =>
    new CompositeDecorator([
      {
        strategy: (contentBlock, callback) => {
          findWithRegex(words, contentBlock, callback);
        },
        component: ({ children }) => {
          return (
            <span
              style={{
                background: '#778CA3',
                borderRadius: '99px',
                padding: '4px 12px',
                margin: '0 4px',
                color: '#FFFFFF',
              }}
            >
              {children}
            </span>
          );
        },
      },
    ]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(createDecorator())
  );
  const editor = useRef<HTMLElement | null>(null);
  const firstVisit = useRef(false);

  const insertCharacter = (characterToInsert, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );

    return newEditorState;
  };

  useEffect(() => {
    if (!firstVisit.current && message) {
      const newEditorState = insertCharacter(message, editorState);
      setEditorState(newEditorState);
      firstVisit.current = true;
    }
  }, [message, editorState]);

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    onMessageChange(editorState.getCurrentContent().getPlainText('\n'));
  };

  const onAddKeyword = (value) => {
    const newEditorState = insertCharacter(value, editorState);
    setEditorState(newEditorState);
    onMessageChange(newEditorState.getCurrentContent().getPlainText('\n'));
  };

  const handleEditorClicked = () => {
    editor?.current?.focus();
  };

  return (
    <div>
      <Label error={!!error} className="fieldTextarea__label">
        Message
      </Label>
      <div className="w-full flex items-end justify-end mb-4">
        <Dropdown
          className={cx('default__dropdown')}
          open={open}
          closeIcon={false}
          withOpacity={false}
          closeMenu={closeMenu}
          trigger={<Button label="{{ Variables }} " onClick={onMenuClick} />}
        >
          <MessageVariablesDropdown
            closeMenu={closeMenu}
            onAddKeyword={onAddKeyword}
          />
        </Dropdown>
      </div>
      <div
        className={cx(`editor`, {
          'editor--danger': error,
        })}
        onClick={handleEditorClicked}
      >
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={handleEditorChange}
        />
      </div>
      <InputErrorMessage error={error} className="mt-3" />
    </div>
  );
};
export default MyEditor;

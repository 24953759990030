import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Create: FC<IconProps> = ({ className = '' }) => (
  <Svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8H1M8 1V15V1Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Create;

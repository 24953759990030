import React, { FC } from 'react';

//types
import { SelectProps } from './Select.types';

const Select: FC<SelectProps> = ({ data, selectRef, name, id, ...props }) => (
  <select
    className="w-full"
    ref={selectRef}
    id={id ? id : name}
    name={name}
    {...props}
  >
    <option value="" disabled hidden></option>
    {data.map((d, k) => (
      <option key={k} value={d.value} tabIndex={k}>
        {d.label}
      </option>
    ))}
  </select>
);

export default Select;

import React, { FC, useState } from 'react';

// motion
import { useDragControls, motion, BoxDelta } from 'framer-motion';

// hooks
import { useMeasurePosition } from '../../../../hooks/useMeasurePosition';

// atoms
import { ReorderButton } from '../../../atoms/index';

// types
import { StageItemProps } from './StageItem.types';

// component
import { ManageStageItem } from '../index';
import useWindowSize from '../../../../hooks/useWindowSize';

const StageItem: FC<StageItemProps> = ({
  id,
  stage,
  orderId,
  showSwitch,
  updateOrder,
  updatePosition,
  handleEditClick,
}) => {
  // states
  const [isDragging, setIsDragging] = useState<boolean>(false);

  // hooks
  const dragControls = useDragControls();
  const { width } = useWindowSize();

  const itemRef = useMeasurePosition((position) => updatePosition(id, position));

  // handlers
  const handleSetDragged = (value: boolean) => () => setIsDragging(value);

  const handleUpdateOrder = (_: any, delta: BoxDelta) => {
    isDragging && updateOrder(id, delta.y.translate);
  };

  return (
    <motion.li
      id="motion-list"
      layout
      dragPropagation
      drag="y"
      ref={itemRef}
      dragListener={false}
      className="stageItem"
      dragControls={dragControls}
      whileTap={{ position: 'relative' }}
      style={{
        zIndex: isDragging ? 3 : 0,
        borderTopWidth: id === 0 && width && width > 768 ? '0' : '1px',
      }}
      onViewportBoxUpdate={handleUpdateOrder}
      onDragEnd={handleSetDragged(false)}
      onDragStart={handleSetDragged(true)}
      dragTransition={{ bounceStiffness: 100, bounceDamping: 10 }}
    >
      <ReorderButton dragControls={dragControls} />
      <ManageStageItem
        stage={stage}
        orderId={orderId}
        showSwitch={showSwitch}
        onEditClick={handleEditClick}
      />
    </motion.li>
  );
};

export default StageItem;

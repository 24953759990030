import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { generateFormatedDate } from '../../../../utils/generateFormatedDate';
import BigBag from '../../../atoms/assets/icons/BigBag';
import Clock from '../../../atoms/assets/icons/Clock';
import Dot from '../../../atoms/ui/utils/Dot';
import CardElements from '../../../molecules/cards/CardElements';
import Title from '../../../molecules/cards/Title';
import { PreviousApplicationsItemProps } from './PreviousApplicationsItem.types';

const PreviousApplicationsItem: FC<PreviousApplicationsItemProps> = ({
  applicants: applicant_data,
}) => {
  return (
    <>
      <div className="job__item">
        <div className="job__item__body">
          <Link to={`/job/${applicant_data.vacancy.id}/all`} className="job__item__body__avatar">
            <BigBag />
          </Link>
          <div className="job__item__body__header">
            <div className="flex flex-col items-start w-full md:flex-row md:items-center">
              <Link
                to={`/job/${applicant_data.vacancy.id}/all`}
                className="job__item__body__header--content"
              >
                <Title value={applicant_data.vacancy.title} />
              </Link>
            </div>
          </div>
          <div className="job__item__body__content">
            <CardElements
              elements={[
                {
                  value: `Stage: ${applicant_data.stage?.name || ''}`,
                  Icon: (
                    <Dot
                      style={{
                        backgroundColor: applicant_data.stage?.color || '',
                      }}
                    />
                  ),
                },
                {
                  value: `Applied at ${generateFormatedDate(applicant_data.created_at)}`,
                  Icon: <Clock />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousApplicationsItem;

import { FC, useEffect, KeyboardEvent } from 'react';
import { createPortal } from 'react-dom';

//types
import { ModalProps } from './Modal.types';
import { Keys } from '../../../../interfaces/types';

const modalRoot = document.getElementById('modal-root');
const base = document.createElement('div');
base.classList.add('modal');

const Modal: FC<ModalProps> = ({ children, closeModal }) => {
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      const keys: Keys = {
        27: () => {
          e.preventDefault();
          closeModal && closeModal();
          window.removeEventListener('keyup', (event: any) => handleKeyUp(event), false);
        },
      };
      if (keys[e.keyCode]) {
        keys[e.keyCode]();
      }
    };

    modalRoot?.appendChild(base);
    window.addEventListener('keyup', (event: any) => handleKeyUp(event), false);

    return () => {
      modalRoot?.removeChild(base);
      window.removeEventListener('keyup', (event: any) => handleKeyUp(event), false);
    };
  }, [closeModal]);

  return createPortal(children, base);
};

export default Modal;

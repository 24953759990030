import React, { FC, useRef } from 'react';
import cx from 'classnames';

//components
import {
  Label,
  Initials,
  RoundedImage,
  InputErrorMessage,
  Button,
} from '../../../atoms';
import { FieldInput } from '..';

//types
import { FileFieldButtonProps } from './FileFieldButton.types';

//enums
import {
  InputTypesEnums,
  ButtonSizesEnums,
  ButtonTypesEnums,
  InitialsTypesEnums,
} from '../../../../enums';

const FileFieldButton: FC<FileFieldButtonProps> = ({
  inputProps,
  labelProps,
  name,
  file,
  file_path,
  firstButtonName = 'Upload new picture',
  onDelete,
  error,
  uploadLoading = false,
  deleteLoading = false,
}) => {
  const ref = useRef<HTMLInputElement>();

  const { inputRef, ...inputRest } = inputProps;

  const { className, ...labelRest } = labelProps;

  const handleOnUpload = () => ref.current?.click();

  const handleOnDelete = () => onDelete(inputRest?.name ? inputRest.name : '');

  return (
    <>
      <Label {...labelRest} className={cx(className, 'mb-4')} />
      <div className="file__input__custom">
        <div className="file__input__custom--img">
          <FieldInput
            inputType={InputTypesEnums.CIRCLE_FILE}
            inputProps={{
              ...inputRest,
              type: 'file',
              accept: 'image/jpeg, image/pjpeg, image/gif, image/png',
              inputRef: (e: HTMLInputElement) => {
                inputRef && inputRef(e);
                ref.current = e;
              },
            }}
            after={
              <Label
                htmlFor={inputProps.name}
                className="file__input__custom--img--label"
              >
                {(!file || Object.keys(file).length === 0) && file_path ? (
                  <RoundedImage src={file_path} />
                ) : file && Object.values(file).length ? (
                  <RoundedImage
                    src={
                      typeof file === 'string'
                        ? file
                        : URL.createObjectURL(Object.values(file)[0])
                    }
                  />
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <Initials name={name} type={InitialsTypesEnums.LARGE} />
                  </div>
                )}
              </Label>
            }
          />
        </div>
        <div className="file__input__custom--buttons">
          <Button
            label={firstButtonName}
            className="mr-2 min-w-full md:p-0"
            btnType={ButtonTypesEnums.BLUE}
            btnSize={ButtonSizesEnums.LG}
            onClick={handleOnUpload}
            loading={uploadLoading}
            disabled={uploadLoading || deleteLoading}
          />
          <Button
            label="Delete"
            btnType={ButtonTypesEnums.INHERIT}
            btnSize={ButtonSizesEnums.XM}
            onClick={handleOnDelete}
            loading={deleteLoading}
            disabled={uploadLoading || deleteLoading}
          />
        </div>
      </div>
      <InputErrorMessage error={error} />
    </>
  );
};

export default FileFieldButton;

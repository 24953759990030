import React, { ChangeEvent, useState, FC, useContext, useCallback, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

//components
import { Button } from '../../../atoms';
import { FieldInput, FieldTextarea, CustomSelect, ReactSelect } from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useMessageTemplate from '../../../../gql/hooks/settings/useMessageTemplate';
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useGetApplicantsQuery, useSendMessageMutation } from '../../../../gql/gen/graphql';
import useSubscribers from '../../../../gql/hooks/subscribers/useSubscribers';

//types
import { NewMessageSchema } from '../../../../validations';
import { MailFormTypes, MailFormProps, MessageTypes } from './MailForm.types';
import { toastrContext } from '../../../../contexts/ToastrContexts';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ToastrTypesEnums,
  CreateMessageTypes,
} from '../../../../enums';

//utils
import {
  gqlData,
  gqlVar,
  parseQueryString,
  transformTextWithNewLine,
  useGenerateDraftMessage,
} from '../../../../utils';

const MailForm: FC<MailFormProps> = ({ message, type }) => {
  const toast = useContext(toastrContext);
  const { push, goBack } = useHistory();
  const { t } = useL10N();

  const { search } = useLocation();
  const queryParams = parseQueryString(search);

  const [loading, setLoading] = useState<boolean>(false);

  const { companyId } = useCompanyUser();

  const { data: { applicants = [] } = {} } = useGetApplicantsQuery(
    gqlVar({ company_id: companyId })
  );
  const { result } = useSubscribers({ company_id: companyId });

  const { message_templates, loading: messageLoading } = useMessageTemplate({
    company_id: companyId,
  });

  const [sendMessage] = useSendMessageMutation();

  const { localStorageData, returnDefaultValues, toggleLocalStorage, clearLocalStorage } =
    useGenerateDraftMessage(type, message, queryParams);

  const { register, handleSubmit, errors, setValue, control, reset, getValues } =
    useForm<MailFormTypes>({
      defaultValues: returnDefaultValues?.data || message,
      resolver: yupResolver(NewMessageSchema({ t })),
      shouldUnregister: false,
    });

  const returnValuesToStore = useCallback(() => {
    switch (type) {
      case CreateMessageTypes.NEW:
        return {
          values: [{ data: getValues(), messageId: queryParams.message_id }],
        };
      case CreateMessageTypes.COMPOSE:
      case CreateMessageTypes.REPLY:
        if (localStorageData?.values?.length) {
          return {
            values: [
              ...localStorageData?.values?.filter((el) => el.messageId !== queryParams?.message_id),
              { data: getValues(), messageId: queryParams.message_id },
            ],
          };
        } else {
          return {
            values: [{ data: getValues(), messageId: queryParams.message_id }],
          };
        }
      default:
        return null;
    }
  }, [getValues, localStorageData, queryParams, type]);

  useLayoutEffect(() => {
    return () => {
      if (type !== '') {
        if (clearLocalStorage) {
          localStorage.removeItem(type);
        } else {
          localStorage.setItem(
            type,
            JSON.stringify({
              values: returnValuesToStore()?.values,
              clearLocalStorage: clearLocalStorage,
            })
          );
        }
      }
    };
  }, [clearLocalStorage, type, returnValuesToStore]);

  const insertAction = (data: MessageTypes) => {
    sendMessage(gqlData(data))
      .then(({ data }) => {
        setLoading(false);
        if (data?.send_message?.statusIsOk) {
          toast?.add(ToastrTypesEnums.SUCCESS, t('message.sendSuccessfully'));
          reset({ subject: '', message: '', to: [] });
          if (data?.send_message?.message_applicant_id) {
            push(`/messages/inbox/${data?.send_message?.message_applicant_id}`);
          } else push(`/messages/inbox/${queryParams.message_id}`);
        } else {
          toast?.add(ToastrTypesEnums.DANGER, t('message.sendError'));
        }
      })
      .catch((err) => {
        setLoading(false);
        toast?.add(ToastrTypesEnums.DANGER, t('message.sendError'));
      });
  };

  const onSubmit = (values: MailFormTypes) => {
    setLoading(true);
    let applicant_id: number[] = [];
    values?.to?.map((x) => {
      applicant_id.push(x.value);
    });

    let data: MessageTypes = {
      message: values?.message,
      subject: values?.subject,
      company_id: companyId,
      applicant_id,
    };

    if (message?.message_id) {
      data = { ...data, message_id: message.message_id };
    }
    insertAction(data);
    toggleLocalStorage(true);
    reset({ subject: '', message: '', to: [], template: '' });
  };

  const handleOnSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const select_id = event.target.value;
    setValue('template', select_id);
    message_templates.map((template) => {
      if (template.id === parseInt(select_id)) {
        if (type !== CreateMessageTypes.REPLY)
          setValue('subject', template.subject.replace('{{applicant_name}}', '{{applicant.name}}'));

        setValue('message', transformTextWithNewLine(template.message));
      }
      return null;
    });
  };

  const handleOnReset = () => {
    toggleLocalStorage(true);
    goBack();
  };

  return (
    <div className="mail__container">
      <form className="mail__form" id="register-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="mail__form--input">
          <Controller
            as={<ReactSelect />}
            options={(result?.data ? [...applicants, ...result?.data] : applicants).map(
              (applicant) => ({
                ...applicant,
                label: applicant.email,
                value: applicant.id,
              })
            )}
            error={!!errors.to && t('message.selectEmailError')}
            labelProps={{ children: 'To' }}
            placeholder={t('message.selectEmail')}
            name="to"
            control={control}
            defaultValue={message?.to || ''}
            isMulti
            isDisabled={Boolean(message?.to)}
          />
          <ErrorMessage
            errors={errors}
            name="to"
            render={() => {
              return <p className={'error--input mt-3'}>{t('message.selectEmailError')}</p>;
            }}
          />
        </div>
        {!messageLoading && message_templates?.length > 0 && (
          <div className="mail__form--input">
            <CustomSelect
              labelProps={{ children: t('message.template') }}
              selectProps={{
                name: 'template',
                data: message_templates.map((template) => ({
                  label: template.name,
                  value: template.id,
                })),
                selectRef: register,
                onChange: handleOnSelect,
                defaultValue: '',
              }}
            />
          </div>
        )}
        <div className="mail__form--input">
          <FieldInput
            labelProps={{ children: t('message.subject') }}
            inputProps={{
              name: 'subject',
              placeholder: t('message.subjectPlaceholder'),
              inputRef: register,
              readOnly: type === CreateMessageTypes.REPLY,
            }}
            error={errors.subject?.message}
          />
        </div>
        <div className="mail__form--input">
          <FieldTextarea
            textareaProps={{
              name: 'message',
              textareaRef: register,
              className: 'mail__form--input--textarea',
            }}
            labelProps={{ children: t('message.message') }}
            error={errors.message?.message}
          />
        </div>
        <div className="mail__form--buttons">
          <Button
            type="reset"
            label={t('button.cancel')}
            btnSize={ButtonSizesEnums.SM}
            btnType={ButtonTypesEnums.WHITE_BORDER}
            className="mr-2"
            onClick={handleOnReset}
          />
          <Button
            type="submit"
            label={t('button.send')}
            loading={loading}
            btnSize={ButtonSizesEnums.LG}
            btnType={ButtonTypesEnums.GREEN}
          />
        </div>
      </form>
    </div>
  );
};

export default MailForm;

import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// query-string
import queryString from 'query-string';

//components
import { PaginationArrows } from '../../atoms';
import { PaginationItem } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';
import useWindowSize from '../../../hooks/useWindowSize';
import { usePagination } from '../../../hooks/usePagination';

//types
import { PaginationProps } from './Pagination.types';

//enums
import { PaginationTypeEnums } from '../../../enums';
import { useUrlSearchParams } from '../../../hooks/useUrlSearchParams';
import { isNumber } from '../../../gql/utils/isNumber';

const Pagination: FC<PaginationProps> = ({
  loading,
  numberOfItems,
  defaultPage = 1,
  itemsPerPage = PaginationTypeEnums.LIMIT,
  count,
}) => {
  // state
  const [currentPage, setcurrentPage] = useState<number>(defaultPage);

  // hooks
  const { t } = useL10N();
  const { width } = useWindowSize();
  const {
    push,
    location: { search },
  } = useHistory();
  const searchParams = useUrlSearchParams();

  const paginationRange = usePagination({
    currentPage,
    itemsPerPage,
    numberOfItems,
    siblingCount: width && width < 740 ? 0 : 1,
  });

  // query string
  const queryParams = queryString.parse(search);

  const page = Number(searchParams.get('page'));
  const lastPage = Math.ceil((count || 0) / itemsPerPage);

  // effect
  useEffect(() => {
    if (page > lastPage) push({ search: `page=${lastPage}` });
    if (page < 1 || !isNumber(page) || (count || 0) < itemsPerPage) push({ search: 'page=1' });
    setcurrentPage(Number(page));
  }, [itemsPerPage, numberOfItems, page, push, lastPage, count]);

  // handlers
  const handleItemClick = (pageNumber: number) => () => {
    if (
      pageNumber >= paginationRange[0] &&
      pageNumber <= paginationRange[paginationRange.length - 1] &&
      pageNumber !== currentPage
    ) {
      push({
        search: `${queryString.stringify({
          ...queryParams,
          page: pageNumber,
        })}`,
      });
    }
  };

  return (
    <ul className="pagination">
      {currentPage !== 1 && (
        <PaginationItem
          className="previous"
          disabled={page === 1 || currentPage === 1}
          aria-label="Left Arrow"
          after={<PaginationArrows type={2} />}
          pageItem={{ id: 'prev', name: t('paggination.prev') }}
          handleItemClick={handleItemClick(currentPage - 1)}
        />
      )}

      {paginationRange?.map((paginationItem, index) => (
        <PaginationItem
          key={`${index}-${paginationItem}`}
          disabled={paginationItem === '...'}
          isActive={paginationItem === Number(page)}
          handleItemClick={handleItemClick(+paginationItem)}
          pageItem={{
            id: `${paginationItem}`,
            name: `${paginationItem}`,
          }}
        />
      ))}

      {currentPage !== paginationRange[paginationRange.length - 1] && (
        <PaginationItem
          className="next"
          aria-label="Right Arrow"
          before={<PaginationArrows />}
          pageItem={{ id: 'next', name: t('paggination.next') }}
          handleItemClick={handleItemClick(currentPage + 1)}
          disabled={currentPage === paginationRange[paginationRange.length - 1]}
        />
      )}
    </ul>
  );
};

export default Pagination;

import React from 'react';

//components
import { SettingsHeader, SettingsContent } from '../../components/organisms';
import { PagesContainer, HeaderLayout } from '../../components/templates';

const Settings = () => (
  <HeaderLayout l10nTitleKey="head.titles.settings">
    <PagesContainer>
      <SettingsHeader />
      <SettingsContent />
    </PagesContainer>
  </HeaderLayout>
);

export default Settings;

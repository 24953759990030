import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Location } from '../../atoms';
import { FieldInput, Checkbox } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//enums
import { VacaniesTypesEnums } from '../../../enums';

//utils
import { parseQueryString, serialize } from '../../../utils';

const MessagesFilterItems = () => {
  const {
    push,
    replace,
    location: { search },
  } = useHistory();
  const { type, location, ...restParams } = parseQueryString(search);
  const { t } = useL10N();

  const types = (type && type.split(',')) || [];
  const handleonCheck = (event: ChangeEvent<HTMLInputElement>) => {
    let _types = types;
    if (types.includes(event.target.id)) _types = _types.filter((t) => t !== event.target.id);
    else _types.push(event.target.id);

    push(
      serialize({
        ...restParams,
        location,
        type: `${_types.join(',')}`,
      })
    );
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.value.length > 1
      ? replace(serialize({ ...restParams, type, location: event.target.value }))
      : push(serialize({ ...restParams, type, location: event.target.value }));

  return (
    <>
      <FieldInput
        labelProps={{ children: t('filter.location') }}
        after={<Location />}
        inputProps={{
          value: location || '',
          onChange: handleOnChange,
          name: 'location',
          placeholder: 'Prishtine, Kosove',
        }}
      />
      <p className="mt-6 mb-5">{t('filter.type')}</p>
      <div className="flex justify-between">
        <div>
          <Checkbox
            labelProps={{ children: t('filter.fullTime'), className: 'mb-4' }}
            inputProps={{
              id: VacaniesTypesEnums['Full time'].toString(),
              checked: types.includes(VacaniesTypesEnums['Full time'].toString()),
              onChange: handleonCheck,
              name: 'full_time',
            }}
          />
          <Checkbox
            labelProps={{ children: t('filter.partTime') }}
            inputProps={{
              id: VacaniesTypesEnums['Part time'].toString(),
              checked: types.includes(VacaniesTypesEnums['Part time'].toString()),
              onChange: handleonCheck,
              name: 'part_time',
            }}
          />
        </div>
        <div>
          <Checkbox
            labelProps={{ children: t('filter.freelancer'), className: 'mb-4' }}
            inputProps={{
              id: VacaniesTypesEnums['Freelancer'].toString(),
              checked: types.includes(VacaniesTypesEnums['Freelancer'].toString()),
              onChange: handleonCheck,
              name: 'freelancer',
            }}
          />
          <Checkbox
            labelProps={{ children: t('filter.contract') }}
            inputProps={{
              id: VacaniesTypesEnums['Contract'].toString(),
              checked: types.includes(VacaniesTypesEnums['Contract'].toString()),
              onChange: handleonCheck,
              name: 'contract',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MessagesFilterItems;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { ContentLoadingProps } from './ContentLoading.types';

//enums
import { ContentLoadingEnums } from '../../../../enums';

const ContentLoading: FC<ContentLoadingProps> = ({
  size = ContentLoadingEnums.DEFAULT,
  color = '#152E6F',
  className,
}) => (
  <div
    style={{ color: color }}
    className={cx('line-spin-rotating', `${className}`, {
      [`line-${size}`]: size,
    })}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default ContentLoading;

import React, { FC, MouseEvent } from 'react';

//types
import { OpacityProps } from './Opacity.types';

const Opacity: FC<OpacityProps> = ({
  show = false,
  className = '',
  onClick,
}) => {
  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClick && onClick();
  };
  return show ? (
    <div className={`opacity ${className}`} onClick={handleOnClick} />
  ) : null;
};

export default Opacity;

import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

//components
import { Button, Envelope, Delete } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { JobActionsDropdownProps } from '../../../interfaces';

const JobActionsDropdown: FC<JobActionsDropdownProps> = ({
  closeMenu,
  onSendMessage,
  onDeleteCandidate,
  showSendMessageButton = false,
}) => {
  const { t } = useL10N();

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (event.currentTarget.id === 'deleteCandidates') onDeleteCandidate();
    closeMenu && closeMenu();
  };

  const sendMessagePath = onSendMessage ? onSendMessage() : '';

  return (
    <div className="default__dropdown__job__actions">
      {showSendMessageButton && (
        <Link to={sendMessagePath || ''}>
          <Button
            id="sendMessage"
            label={t('candidate.sendMessage')}
            before={<Envelope type={2} />}
            onClick={handleOnClick}
            className="default__dropdown__job__actions--item text-primary"
          />
        </Link>
      )}
      <Button
        id="deleteCandidates"
        label={t('jobs.deleteCandidate')}
        before={<Delete color="#ADBAC8" />}
        onClick={handleOnClick}
        className="default__dropdown__job__actions--item text-primary"
      />
    </div>
  );
};

export default JobActionsDropdown;

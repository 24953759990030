import { array, object, string } from 'yup';

export const NewMessageSchema = ({ t }) => object().shape({
  subject: string().trim().required().label(t('shared.forms.validation.subject')),
  message: string().trim().required().label(t('message.formValidation.message')),
  to: array().min(1, t('message.formValidation.email'))
    .of(
      object().shape({
        email: string(),
      })
    )
    .required(t('message.formValidation.email')),
});

import React, { FC } from 'react';

// components
import { Loading } from '../../atoms';

const EmailResetContainer: FC = ({ children }) => (
  <div className="container h-screen flex flex-col justify-center items-center mx-auto">
    <Loading />
    {children}
  </div>
);

export default EmailResetContainer;

import React from 'react';

import { Svg } from '../../../atoms';

const EuroSign = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM6.736 4.979C7.208 4.193 7.696 4 8 4C8.304 4 8.792 4.193 9.264 4.979C9.40045 5.20642 9.62166 5.37033 9.87896 5.43465C10.1363 5.49898 10.4086 5.45845 10.636 5.322C10.8634 5.18555 11.0273 4.96434 11.0917 4.70704C11.156 4.44974 11.1155 4.17742 10.979 3.95C10.279 2.784 9.232 2 8 2C6.768 2 5.721 2.784 5.021 3.95C4.736 4.425 4.514 4.95 4.351 5.5H4C3.73478 5.5 3.48043 5.60536 3.29289 5.79289C3.10536 5.98043 3 6.23478 3 6.5C3 6.76522 3.10536 7.01957 3.29289 7.20711C3.48043 7.39464 3.73478 7.5 4 7.5H4.013C3.99518 7.8331 3.99518 8.1669 4.013 8.5H4C3.73478 8.5 3.48043 8.60536 3.29289 8.79289C3.10536 8.98043 3 9.23478 3 9.5C3 9.76522 3.10536 10.0196 3.29289 10.2071C3.48043 10.3946 3.73478 10.5 4 10.5H4.351C4.514 11.05 4.736 11.575 5.021 12.05C5.721 13.216 6.768 14 8 14C9.232 14 10.279 13.216 10.979 12.05C11.0466 11.9374 11.0913 11.8126 11.1106 11.6827C11.1299 11.5528 11.1235 11.4204 11.0917 11.293C11.0598 11.1656 11.0032 11.0457 10.925 10.9402C10.8468 10.8346 10.7486 10.7456 10.636 10.678C10.5234 10.6104 10.3986 10.5657 10.2687 10.5464C10.1388 10.5271 10.0064 10.5335 9.87896 10.5653C9.75156 10.5972 9.63168 10.6538 9.52616 10.732C9.42065 10.8102 9.33156 10.9084 9.264 11.021C8.792 11.807 8.304 12 8 12C7.696 12 7.208 11.807 6.736 11.021C6.6362 10.8536 6.54799 10.6795 6.472 10.5H8C8.26522 10.5 8.51957 10.3946 8.70711 10.2071C8.89464 10.0196 9 9.76522 9 9.5C9 9.23478 8.89464 8.98043 8.70711 8.79289C8.51957 8.60536 8.26522 8.5 8 8.5H6.017C5.99433 8.16705 5.99433 7.83295 6.017 7.5H8C8.26522 7.5 8.51957 7.39464 8.70711 7.20711C8.89464 7.01957 9 6.76522 9 6.5C9 6.23478 8.89464 5.98043 8.70711 5.79289C8.51957 5.60536 8.26522 5.5 8 5.5H6.472C6.552 5.315 6.639 5.14 6.736 4.979Z"
      fill="#778CA3"
    />
  </Svg>
);

export default EuroSign;

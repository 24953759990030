// utils
import { gqlVar } from '../../../utils';

// gql
import {
  useApplicantSubscription,
  useGetFileStoreByApplicantIdQuery,
} from '../../gen/graphql';

const useApplicant = ({ id }: { id: string }) => {
  // gql hook
  const query = useApplicantSubscription(gqlVar({ id: +id }));

  const {
    data: fileStoreData,
    loading: loadingFileStore,
  } = useGetFileStoreByApplicantIdQuery(gqlVar({ fileableId: +id }));

  // const
  const {
    data: { applicants_by_pk: result = null } = {},
    loading,
    error,
  } = query;

  return {
    error,
    result,
    loading,
    loadingFileStore,
    resultFileStore: [...(fileStoreData?.file_stores || [])],
  };
};

export default useApplicant;

import { object, string } from 'yup';

export const ChangeEmailSchema = (t: (key: string, options?: any) => string) =>
  object().shape({
    email: string()
      .trim()
      .email()
      .required()
      .label(t('shared.forms.validation.email')),
    password: string()
      .trim()
      .min(8)
      .max(32)
      .required()
      .label(t('settings.password')),
  });

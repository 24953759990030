import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';
import { LinkTypesEnums } from '../../../../enums';

const Links: FC<IconProps> = ({ type = 1 }) => {
  const render = () => {
    switch (type) {
      case LinkTypesEnums.WEBSITE:
        return (
          <path
            d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM17.375 8.75H15C14.875 6.875 14.5 5.125 14 3.625C15.75 4.75 17 6.625 17.375 8.75ZM10 17.5C9.25 17.5 7.75 15.125 7.5 11.25H12.5C12.25 15.125 10.75 17.5 10 17.5ZM7.5 8.75C7.75 4.875 9.125 2.5 10 2.5C10.875 2.5 12.25 4.875 12.5 8.75H7.5ZM6.125 3.625C5.5 5.125 5.125 6.875 5 8.75H2.625C3 6.625 4.25 4.75 6.125 3.625ZM2.625 11.25H5C5.125 13.125 5.5 14.875 6 16.375C4.25 15.25 3 13.375 2.625 11.25ZM13.875 16.375C14.5 14.875 14.75 13.125 14.875 11.25H17.25C17 13.375 15.75 15.25 13.875 16.375Z"
            fill="#778CA3"
          />
        );
      case LinkTypesEnums.GITHUB:
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 -1C4.5 -1 0 3.5 0 9C0 13.375 2.875 17.125 6.875 18.5C7.375 18.625 7.5 18.25 7.5 18C7.5 17.75 7.5 17.125 7.5 16.25C4.75 16.875 4.125 15 4.125 15C3.625 13.875 3 13.5 3 13.5C2.125 12.875 3.125 12.875 3.125 12.875C4.125 13 4.625 13.875 4.625 13.875C5.5 15.5 7 15 7.5 14.75C7.625 14.125 7.875 13.625 8.125 13.375C5.875 13.125 3.625 12.25 3.625 8.375C3.625 7.25 4 6.375 4.625 5.75C4.5 5.5 4.125 4.5 4.75 3.125C4.75 3.125 5.625 2.875 7.5 4.125C8.25 3.875 9.125 3.75 10 3.75C10.875 3.75 11.75 3.875 12.5 4.125C14.375 2.875 15.25 3.125 15.25 3.125C15.75 4.5 15.5 5.5 15.375 5.75C16 6.5 16.375 7.375 16.375 8.375C16.375 12.25 14 13 11.75 13.25C12.125 13.75 12.5 14.375 12.5 15.25C12.5 16.625 12.5 17.625 12.5 18C12.5 18.25 12.625 18.625 13.25 18.5C17.25 17.125 20.125 13.375 20.125 9C20 3.5 15.5 -1 10 -1Z"
            fill="#778CA3"
          />
        );
      case LinkTypesEnums.BEHANCE:
        return (
          <>
            <path
              d="M8.07155 9.32964C8.07155 9.32964 9.96415 9.18904 9.96415 6.96955C9.96415 4.75001 8.41566 3.66687 6.45423 3.66687H2.84387H2.73781H0V16.0705H2.73781H2.84387H6.45423C6.45423 16.0705 10.3943 16.1947 10.3943 12.4095C10.3943 12.4095 10.5663 9.32964 8.07155 9.32964ZM2.84387 5.87133H5.98966H6.45423C6.45423 5.87133 7.33169 5.87133 7.33169 7.16175C7.33169 8.45218 6.81552 8.63929 6.23055 8.63929H2.84387V5.87133ZM6.28783 13.866H2.84387V10.5512H6.45423C6.45423 10.5512 7.76183 10.5341 7.76183 12.2546C7.76183 13.6891 6.8054 13.8516 6.28783 13.866Z"
              fill="#778CA3"
            />
            <path
              d="M15.6496 6.82263C10.8798 6.82263 10.8841 11.5882 10.8841 11.5882C10.8841 11.5882 10.5568 16.3297 15.6498 16.3297C15.6498 16.3297 19.894 16.5721 19.894 13.0313H17.7112C17.7112 13.0313 17.7839 14.365 15.7225 14.365C15.7225 14.365 13.5397 14.511 13.5397 12.2068H19.9667C19.9667 12.2068 20.6699 6.82263 15.6496 6.82263ZM17.5898 10.5513H13.5154C13.5154 10.5513 13.7822 8.63931 15.6982 8.63931C17.6142 8.63931 17.5898 10.5513 17.5898 10.5513Z"
              fill="#778CA3"
            />
            <path
              d="M18.333 4.16669H13.333V5.83335H18.333V4.16669Z"
              fill="#778CA3"
            />
          </>
        );
      case LinkTypesEnums.INSTAGRAM:
        return (
          <path
            d="M11.984 16.815a4.71 4.71 0 004.706-4.707 4.717 4.717 0 00-4.705-4.707c-.692 0-1.349.151-1.941.421a4.752 4.752 0 00-1.158.749 4.695 4.695 0 00-1.608 3.538 4.712 4.712 0 004.706 4.706zM12 8.631a3.479 3.479 0 110 6.957 3.479 3.479 0 010-6.957zM17.223 9H24v10.278A4.722 4.722 0 0119.278 24H4.785C2.177 24 0 21.886 0 19.278V9h6.747a6.048 6.048 0 00-.872 3.109 6.109 6.109 0 1012.218 0A6.047 6.047 0 0017.223 9zm2.055-9H7v5H6V0H5v5H4V.077a4.79 4.79 0 00-1 .27V5H2V.898c-1.202.857-2 2.246-2 3.824V8h7.473a6.087 6.087 0 019.022 0H24V4.722A4.722 4.722 0 0019.278 0zM22 5.265a.74.74 0 01-.745.735h-2.511A.74.74 0 0118 5.265v-2.53A.74.74 0 0118.744 2h2.511a.74.74 0 01.745.735v2.53z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#778CA3"
          />
        );
      case LinkTypesEnums.LINKEDIN:
        return (
          <path
            d="M19.125 0H0.875C0.375 0 0 0.375 0 0.875V19.25C0 19.625 0.375 20 0.875 20H19.25C19.75 20 20.125 19.625 20.125 19.125V0.875C20 0.375 19.625 0 19.125 0ZM5.875 17H3V7.5H6V17H5.875ZM4.5 6.25C3.5 6.25 2.75 5.375 2.75 4.5C2.75 3.5 3.5 2.75 4.5 2.75C5.5 2.75 6.25 3.5 6.25 4.5C6.125 5.375 5.375 6.25 4.5 6.25ZM17 17H14V12.375C14 11.25 14 9.875 12.5 9.875C11 9.875 10.75 11.125 10.75 12.375V17.125H7.75V7.5H10.625V8.75C11 8 12 7.25 13.375 7.25C16.375 7.25 16.875 9.25 16.875 11.75V17H17Z"
            fill="#778CA3"
          />
        );
      case LinkTypesEnums.FACEBOOK:
        return (
          <path
            d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM17.375 8.75H15C14.875 6.875 14.5 5.125 14 3.625C15.75 4.75 17 6.625 17.375 8.75ZM10 17.5C9.25 17.5 7.75 15.125 7.5 11.25H12.5C12.25 15.125 10.75 17.5 10 17.5ZM7.5 8.75C7.75 4.875 9.125 2.5 10 2.5C10.875 2.5 12.25 4.875 12.5 8.75H7.5ZM6.125 3.625C5.5 5.125 5.125 6.875 5 8.75H2.625C3 6.625 4.25 4.75 6.125 3.625ZM2.625 11.25H5C5.125 13.125 5.5 14.875 6 16.375C4.25 15.25 3 13.375 2.625 11.25ZM13.875 16.375C14.5 14.875 14.75 13.125 14.875 11.25H17.25C17 13.375 15.75 15.25 13.875 16.375Z"
            fill="#778CA3"
          />
        );
      case LinkTypesEnums.TWITTER:
        return (
          <path
            d="M20 2.75C19.25 3.125 18.5 3.25 17.625 3.375C18.5 2.875 19.125 2.125 19.375 1.125C18.625 1.625 17.75 1.875 16.75 2.125C16 1.375 14.875 0.875 13.75 0.875C11.625 0.875 9.75 2.75 9.75 5C9.75 5.375 9.75 5.625 9.875 5.875C6.5 5.75 3.375 4.125 1.375 1.625C1 2.25 0.875 2.875 0.875 3.75C0.875 5.125 1.625 6.375 2.75 7.125C2.125 7.125 1.5 6.875 0.875 6.625C0.875 8.625 2.25 10.25 4.125 10.625C3.75 10.75 3.375 10.75 3 10.75C2.75 10.75 2.5 10.75 2.25 10.625C2.75 12.25 4.25 13.5 6.125 13.5C4.75 14.625 3 15.25 1 15.25C0.625 15.25 0.375 15.25 0 15.25C1.875 16.375 4 17.125 6.25 17.125C13.75 17.125 17.875 10.875 17.875 5.5C17.875 5.375 17.875 5.125 17.875 5C18.75 4.375 19.5 3.625 20 2.75Z"
            fill="#778CA3"
          />
        );
      default:
        return (
          <path
            d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM17.375 8.75H15C14.875 6.875 14.5 5.125 14 3.625C15.75 4.75 17 6.625 17.375 8.75ZM10 17.5C9.25 17.5 7.75 15.125 7.5 11.25H12.5C12.25 15.125 10.75 17.5 10 17.5ZM7.5 8.75C7.75 4.875 9.125 2.5 10 2.5C10.875 2.5 12.25 4.875 12.5 8.75H7.5ZM6.125 3.625C5.5 5.125 5.125 6.875 5 8.75H2.625C3 6.625 4.25 4.75 6.125 3.625ZM2.625 11.25H5C5.125 13.125 5.5 14.875 6 16.375C4.25 15.25 3 13.375 2.625 11.25ZM13.875 16.375C14.5 14.875 14.75 13.125 14.875 11.25H17.25C17 13.375 15.75 15.25 13.875 16.375Z"
            fill="#778CA3"
          />
        );
    }
  };

  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {render()}
    </Svg>
  );
};

export default Links;

import React from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Button, Create, JobsEmpty } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const EmptyDraftJobs = () => {
  const { push } = useHistory();
  const { t } = useL10N();

  const handleCreate = () => push('/job/create');
  return (
    <div className="flex flex-col items-center justify-center py-20  px-4">
      <JobsEmpty />
      <p className="mt-4 mb-4 text-secondary text-center text-sm">
        {t('dashboard.draftJobsEmpty')}
      </p>
      <Button
        label={t('dashboard.createNewJob')}
        btnSize={ButtonSizesEnums.MD}
        btnType={ButtonTypesEnums.GREEN}
        before={<Create />}
        onClick={handleCreate}
      />
    </div>
  );
};
export default EmptyDraftJobs;

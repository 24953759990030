import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

import { I18N_API_URL } from '../config/i18n';

// create instance
i18n
    .use(initReactI18next)
    .use(XHR)
    .init(
        {
            lng: 'en',
            fallbackLng: 'en',
            react: {
                useSuspense: true,
            },
            interpolation: {
                escapeValue: false,
            },
            backend: {
                crossDomain: true,
                loadPath: `${I18N_API_URL}localization/{{lng}}?dot=true`,
                parse: (payload) => JSON.parse(payload).data.content,
            },
        },
        (err, t) => {
            if (err) console.error(err);
            const validation = t('form.validation', { returnObjects: true });
            if (typeof validation !== 'string') { }
        }
    );
export default i18n;
import React from 'react';

import { Svg } from '../../../atoms';

const NotesEmpty = () => (
  <Svg
    width="73"
    height="71"
    viewBox="0 0 73 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M56.3906 4.88561C62.1896 7.68019 67.3672 12.3652 70.1839 18.2009C73.0419 24.0367 73.539 31.0231 72.5035 37.763C71.5094 44.5439 69.0241 51.0783 64.8405 56.5031C60.657 61.9689 54.7751 66.3252 48.1477 68.4622C41.4789 70.5992 34.0645 70.476 27.3128 68.2978C20.6026 66.1608 14.5965 62.01 9.70875 56.7086C4.77961 51.4482 0.927428 45.0371 0.140423 38.3383C-0.605161 31.5985 1.71443 24.5709 4.82103 17.9955C7.96905 11.42 11.9041 5.25548 17.5374 2.37871C23.1707 -0.539152 30.4194 -0.21038 37.2953 0.447167C44.1299 1.06362 50.5916 2.04994 56.3906 4.88561Z"
      fill="#EDEFF4"
    />
    <path
      d="M21 28H53"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M21 40H39"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12 13H62C63.3261 13 64.5979 13.5268 65.5355 14.4645C66.4732 15.4021 67 16.6739 67 18V48C67 49.3261 66.4732 50.5979 65.5355 51.5355C64.5979 52.4732 63.3261 53 62 53H39L21 67V53H12C10.6739 53 9.40215 52.4732 8.46447 51.5355C7.52678 50.5979 7 49.3261 7 48V18C7 16.6739 7.52678 15.4021 8.46447 14.4645C9.40215 13.5268 10.6739 13 12 13V13Z"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </Svg>
);

export default NotesEmpty;

import React, { useState, useEffect, FC, memo } from 'react';
import { useParams } from 'react-router-dom';

//components
import {
  EmailListItem,
  EmailListEmpty,
  NoQueryResults,
} from '../../../molecules';

//types
import { EmailListProps } from './EmailList.types';

const EmailList: FC<EmailListProps> = ({ messages, allMessagesCount }) => {
  // local states
  const [active, setActive] = useState<string | null>(null);

  // hooks
  const { tab } = useParams<{ tab: string; id: string }>();

  // effect
  useEffect(() => {
    setActive(tab);
  }, [tab]);

  return (
    <>
      {!messages?.length ? (
        allMessagesCount !== 0 ? (
          <NoQueryResults />
        ) : (
          <EmailListEmpty />
        )
      ) : (
        messages.map((msg) => (
          <EmailListItem
            key={msg?.id}
            active={active}
            email={{
              id: msg?.id.toString(),
              seen: msg?.read_at,
              date: msg?.created_at,
              subject: msg?.subject || '',
              name: msg?.applicant?.name || '',
              applicant_id: msg?.applicant?.id.toString(),
              vacancyTitle: msg?.applicant?.vacancy.title,
              repliesCount: msg?.repliesCount?.aggregate?.count || 0,
            }}
          />
        ))
      )}
    </>
  );
};

export default memo(EmailList);

import React, { FC, useContext } from 'react';

//components
import { Created, Clock, Edit, Button, ThreeDots } from '../../../atoms';
import { Title, CardElements, DeleteDropdown, DefaultDropdown } from '../../';

//hookks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//gql
import { useMessageTemplateDeleteMutation } from '../../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import { MessageTemplateItemProps } from './MessageTemplateItem.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums, ToastrTypesEnums } from '../../../../enums';
import { generateFormatedDate } from '../../../../utils';

const MessageTemplateItem: FC<MessageTemplateItemProps> = ({ id, message, onEditClick }) => {
  const { xl } = useWindowSize();
  const { t } = useL10N();
  const toast = useContext(toastrContext);

  const [deleteMessageTemplate] = useMessageTemplateDeleteMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('template.deletedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('template.deletedSuccessfully'));
    },
  });

  const handleOnDeleteConfirm = () => {
    deleteMessageTemplate({
      variables: { id: message.id },
    });
  };

  const handleOnEditClick = () => {
    onEditClick(message);
  };

  return (
    <div className="pt-4 pb-6 md:flex md:items-center md:justify-between template__item">
      <div className="template__item__body">
        <div className="template__item__body--header">
          <span className="template__item__body--order">{id}</span>
          <Title value={message.name} />
          {!xl && (
            <div className="template__item__body--header__dots">
              <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
                <DeleteDropdown onDeleteConfirm={handleOnDeleteConfirm} />
              </DefaultDropdown>
            </div>
          )}
        </div>
        <div className="template__item__body--content">
          <CardElements
            elements={[
              {
                value: `${t('settings.templateCreatedAt')}  ${generateFormatedDate(
                  message.created_at
                )}`,
                Icon: <Created />,
              },
              {
                value: `${t('settings.templateUpdatedAt')} ${generateFormatedDate(
                  message.updated_at
                )}`,
                Icon: <Clock />,
              },
            ]}
          />
        </div>
        <div className="template__item__body--button">
          <Button
            className="template__item__body--button--view"
            label={t('button.edit')}
            btnType={ButtonTypesEnums.WHITE_BORDER}
            btnSize={ButtonSizesEnums.XL}
            before={<Edit />}
            onClick={handleOnEditClick}
          />
        </div>
      </div>

      {xl && (
        <div className="relative top-0 right-0 template__item__buttons">
          <Button
            className="px-4 -mr-1"
            label={t('button.edit')}
            btnSize={ButtonSizesEnums.SM}
            btnType={ButtonTypesEnums.WHITE_BORDER}
            before={<Edit />}
            onClick={handleOnEditClick}
          />
          <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
            <DeleteDropdown onDeleteConfirm={handleOnDeleteConfirm} />
          </DefaultDropdown>
        </div>
      )}
    </div>
  );
};

export default MessageTemplateItem;

import React, { FC, useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Button } from '../../../atoms';
import { MessageEditor, FieldInput } from '../..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import {
  useMessageTemplateInsertMutation,
  useMessageTemplateUpdateMutation,
} from '../../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import { MessageTemplateFormTypes, MessageTemplateFormProps } from './MessageTemplateForm.types';
import { MessageTemplateSchema } from '../../../../validations';
import { MessageTemplateModel } from '../../../../interfaces/models';

//enums
import { ButtonSizesEnums, ButtonTypesEnums, ToastrTypesEnums } from '../../../../enums';

//utils
import { replaceString, transformTextWithNewLine } from '../../../../utils';

const MessageTemplateForm: FC<MessageTemplateFormProps> = ({ closeModal, template }) => {
  const { company_user } = useCompanyUser();
  const { t } = useL10N();
  const toast = useContext(toastrContext);
  const [messageValue, setMessageValue] = useState<string>('');
  const [messageError, setMessageError] = useState<boolean>(false);

  const { register, handleSubmit, errors } = useForm<MessageTemplateFormTypes>({
    defaultValues: {
      name: template?.name || '',
      subject: template?.subject || '',
    },
    resolver: yupResolver(MessageTemplateSchema({ t })),
  });

  const [insertMessageTemplate, { loading }] = useMessageTemplateInsertMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('messageTemplate.createdError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('messageTemplate.createdSuccessfully'));
    },
  });

  const [updateMessageTemplate, { loading: isUpdateLoading }] = useMessageTemplateUpdateMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('messageTemplate.editedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('messageTemplate.editedSuccessfully'));
    },
  });

  useEffect(() => {
    setMessageValue(template?.message || '');
  }, [template]);

  const onMessageChange = (value) => {
    setMessageValue(value);
  };

  const onSubmit = (data: MessageTemplateModel) => {
    setMessageError(false);
    const messageValues = replaceString(messageValue);
    data.company_id = company_user?.company_id;
    data.message = messageValues;
    if (template) {
      updateMessageTemplate({
        variables: {
          id: template.id,
          set: data,
        },
      }).then(() => {
        closeModal && closeModal();
      });
    } else {
      if (messageValue) {
        insertMessageTemplate({
          variables: { object: data },
        }).then(() => {
          closeModal && closeModal();
        });
      } else {
        setMessageError(true);
      }
    }
  };

  return (
    <form className="messageTemplate__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="messageTemplate__form--field">
        <FieldInput
          labelProps={{ children: 'Name' }}
          inputProps={{
            name: 'name',
            inputRef: register,
          }}
          error={errors.name?.message}
        />
      </div>
      <div className="messageTemplate__form--field">
        <FieldInput
          labelProps={{ children: 'Subject' }}
          inputProps={{
            name: 'subject',
            inputRef: register,
          }}
          error={errors.subject?.message}
        />
      </div>

      <div className="overflow-hidden messageTemplate__form--textarea">
        <MessageEditor
          error={messageError}
          onMessageChange={onMessageChange}
          message={(template && transformTextWithNewLine(template?.message)) || ''}
        />
      </div>
      <div className="messageTemplate__form__buttons">
        <Button
          label="Cancel"
          btnType={ButtonTypesEnums.WHITE_BORDER}
          btnSize={ButtonSizesEnums.XM}
          onClick={closeModal}
        />
        <Button
          type="submit"
          className="messageTemplate__form__buttons--main"
          label={template ? 'Save' : 'Create'}
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.LG}
          loading={template ? isUpdateLoading : loading}
        />
      </div>
    </form>
  );
};

export default MessageTemplateForm;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { InputProps } from './Input.types';

const Input: FC<InputProps> = ({ inputRef, id, name, className, ...props }) => (
  <input
    {...props}
    id={id ? id : name}
    name={name}
    className={cx('input', className)}
    ref={inputRef}
  />
);

export default Input;

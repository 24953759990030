import React, { FC } from 'react';
import cx from 'classnames';

//types
import { ErrorMessageProps } from './ErrorMessage.types';

const ErrorMessage: FC<ErrorMessageProps> = ({ error, className }) => {
  return error ? (
    <div className={cx('error', className)}>
      <p className="error--message">{error}</p>
    </div>
  ) : null;
};

export default ErrorMessage;

import React from 'react';

//components
import { AuthFormFooter } from '../../components/molecules';
import { RegisterForm } from '../../components/organisms';
import { PagesContainer, HeaderLayout } from '../../components/templates';

//hooks
import { useL10N } from '../../hooks/useL10N';

const Register = () => {
  const { t } = useL10N();
  return (
    <HeaderLayout l10nTitleKey="head.titles.register">
      <PagesContainer>
        <h1 className="auth__form--header">{t('auth.signUp')}</h1>
        <RegisterForm />
        <AuthFormFooter
          paragraph={t('auth.alreadyHaveAccount')}
          to="/auth/login"
          name={t('auth.signIn')}
        />
      </PagesContainer>
    </HeaderLayout>
  );
};

export default Register;

import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';

//components
import { Button, Lock } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { TrialExpiredProps } from './TrialExpired.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';
import { DISCOVR_PAGE_URL } from '../../../config';

const TrialExpiredModal: FC<TrialExpiredProps> = ({ closeModal }) => {
  const { t } = useL10N();

  const { push } = useHistory();

  const handleUpgradeNowClicked = () => {
    push('/settings/payments');
    closeModal && closeModal();
  };

  return (
    <div className="tiralExpired__modal">
      <Lock />
      <p className="text-primary text-xl mt-8 leading-8">{t('trialExpired.title')}</p>
      <p className="text-primary text-base opacity-75 mt-4 text-center">
        {t('trialExpired.description')}
      </p>
      <div className="mt-8 flex flex-col items-center">
        <Button
          label={t('button.upgrade')}
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.LG}
          onClick={handleUpgradeNowClicked}
        />

        <a
          aria-label="contact-us"
          href={`${DISCOVR_PAGE_URL}/#contactUs`}
          className="text-primary text-sm mt-4"
        >
          {t('button.contact')}
        </a>
      </div>
    </div>
  );
};

export default TrialExpiredModal;

import React, { FC } from 'react';

//types
import { TeamMembersEmptyProps } from './TeamMembersEmpty.types';

const TeamMembersEmpty: FC<TeamMembersEmptyProps> = ({
  children,
  title,
  icon,
}) => {
  return (
    <div className="template__empty">
      {icon}
      <p className="template__empty--title">{title}</p>
      {children}
    </div>
  );
};

export default TeamMembersEmpty;

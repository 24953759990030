import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

//components
import { Button, ArrowDown } from '../../atoms';
import { NavItems } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import useAuth from '../../../gql/hooks/auth/useAuth';

//types
import { DefaultDropdownsProps } from '../../../interfaces';

const AccountItems: FC<DefaultDropdownsProps> = ({ closeMenu }) => {
  const { t } = useL10N();
  const { push } = useHistory();
  const [active, setActive] = useState<boolean>(false);

  const auth = useAuth();

  const handleSubMenu = () => setActive(!active);

  const _signOut = () => {
    closeMenu && closeMenu();
    auth?.logout();
    push('/auth/login');
  };

  return (
    <div className="default__dropdown__account">
      <NavItems dropdown={true} onClick={closeMenu} />
      <div className="flex flex-col">
        <div className="default__dropdown__account__secondary__menu">
          <p className="default__dropdown__account__sign_in_us">{t('header.menuSignedIn')}</p>
          <p className="default__dropdown__account__email">{auth?.user?.email}</p>
        </div>
        <Button
          className="default__dropdown__account__btn"
          label={auth?.user?.name || ''}
          after={<ArrowDown active={active} />}
          onClick={handleSubMenu}
        />
        <div
          className={`default__dropdown__account__account-items ${
            active ? 'flex' : 'default__dropdown__account__account-items__secondary'
          }`}
        >
          <Link
            to="/settings/account"
            className="default__dropdown__account__account-items__item"
            onClick={closeMenu}
          >
            {t('header.settings')}
          </Link>
          <Button
            className="default__dropdown__account__account-items__item-btn"
            label={t('header.signOut')}
            onClick={_signOut}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountItems;

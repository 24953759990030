import React from 'react';

import { Svg } from '../../../atoms';

const JobEmpty = () => (
  <>
    <Svg
      width="73"
      height="74"
      viewBox="0 0 73 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M56.3906 8.88561C62.1896 11.6802 67.3672 16.3652 70.1839 22.2009C73.0419 28.0367 73.539 35.0231 72.5035 41.763C71.5094 48.5439 69.0241 55.0783 64.8405 60.5031C60.657 65.9689 54.7751 70.3252 48.1477 72.4622C41.4789 74.5992 34.0645 74.476 27.3128 72.2978C20.6026 70.1608 14.5965 66.01 9.70875 60.7086C4.77961 55.4482 0.927428 49.0371 0.140423 42.3383C-0.605161 35.5985 1.71443 28.5709 4.82103 21.9955C7.96905 15.42 11.9041 9.25548 17.5374 6.37871C23.1707 3.46085 30.4194 3.78962 37.2953 4.44717C44.1299 5.06362 50.5916 6.04994 56.3906 8.88561Z"
        fill="#EDEFF4"
      />
      <path
        d="M43.2537 44.6268H29.5522C18.2017 44.6268 9 53.9215 9 65.3867C9 65.3867 19.2761 70 36.403 70C53.5298 70 63.806 65.3867 63.806 65.3867C63.806 53.9215 54.6043 44.6268 43.2537 44.6268Z"
        stroke="#778CA3"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M21.1792 17.6299C21.1792 8.99771 28.2223 2 36.9105 2C45.5987 2 52.6419 8.99771 52.6419 17.6299C52.6419 26.262 45.5987 35.4925 36.9105 35.4925C28.2223 35.4925 21.1792 26.262 21.1792 17.6299Z"
        stroke="#778CA3"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </Svg>
  </>
);

export default JobEmpty;

import React, { FC } from 'react';

//types
import { RoundedImageProps } from './RoundedImage.types';

const RoundedImage: FC<RoundedImageProps> = ({ src }) => {
  return <img className="img__rounded" src={src} alt="test" />;
};

export default RoundedImage;

import React, {
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import cx from 'classnames';

//components
import { Label, Input, InputErrorMessage } from '../../../atoms';

//types
import { FieldInputProps } from './FieldInput.types';

//enums
import { InputTypesEnums } from '../../../../enums';

const FieldInput: FC<FieldInputProps> = ({
  labelProps,
  inputProps,
  inputType = InputTypesEnums.DEFAULT,
  before: Before = null,
  after: After = null,
  error = false,
  className,
  disabledLabel,
}) => {
  const renderIcon = (icon: ReactNode, className: string) =>
    isValidElement(icon) &&
    cloneElement((<div className={className}>{icon}</div>) as ReactElement);

  const renderDisabledLabel = () => (
    <div className="field__input--disabled">{disabledLabel}</div>
  );
  return (
    <div className={cx('field__input', className)}>
      {labelProps && (
        <Label
          {...labelProps}
          htmlFor={labelProps?.htmlFor ? labelProps.htmlFor : inputProps.name}
          error={!!error}
          className={`field__input__label ${labelProps?.className || ''}`}
        />
      )}
      <div
        className={cx(`field__input__content field__input--${inputType}`, {
          'field__input--danger': error,
        })}
      >
        {disabledLabel && renderDisabledLabel()}
        {renderIcon(Before, `field__input--${inputType}__before`)}
        <Input {...inputProps} className={`input--${inputType}`} />
        {renderIcon(After, `field__input--${inputType}__after`)}
      </div>
      <InputErrorMessage error={error} className="mt-3" />
    </div>
  );
};

export default FieldInput;

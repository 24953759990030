import React, { FC } from 'react';
import cx from 'classnames';

//components
import { Close } from '../../atoms';
import { Button, Modal, Opacity } from '../../atoms';

//types
import { CustomModalProps } from './CustomModal.types';
//enums
import { ModalTypesEnums, ButtonSizesEnums } from '../../../enums';

const CustomModal: FC<CustomModalProps> = ({
  children,
  open,
  closeModal,
  header = true,
  title = 'Title',
  type = ModalTypesEnums.SM,
}) => {
  return open ? (
    <>
      <Opacity show={open} onClick={closeModal} />
      <Modal closeModal={closeModal}>
        <div className={cx('modal__content', `modal__content--${type}`)}>
          {header && (
            <header className="modal__content__header">
              <h1 className="modal__content__header--title">{title}</h1>
              <Button before={<Close />} btnSize={ButtonSizesEnums.XS} onClick={closeModal} />
            </header>
          )}

          <div className="modal__content__body">{children}</div>
        </div>
      </Modal>
    </>
  ) : null;
};

export default CustomModal;

import React, { FC, useEffect } from 'react';
import cx from 'classnames';

//components
import { Button, ThreeDots, CreateEdit, Edit, Eye } from '../../../atoms';
import {
  DefaultDropdown,
  JobFormDropdown,
  CustomModal,
  DeleteJob,
  PublishJob,
} from '../../../molecules';

//hooks
import useModal from '../../../../hooks/useModal';
import { useL10N } from '../../../../hooks/useL10N';
import useWindowSize from '../../../../hooks/useWindowSize';

//types
import { JobFormTypes } from '../content/JobForm.types';
import { JobFormHeaderProps } from './JobFormHeader.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ThreeDotsEnums,
  VacaniesStatusTypesEnums,
  ModalTypesEnums,
} from '../../../../enums';
import { useLocation } from 'react-router-dom';

const JobFormHeader: FC<JobFormHeaderProps> = ({
  title,
  id,
  loading = '',
  submitCallback,
  handleSubmit,
  trigger,
  error,
  clearErrors,
  data,
  jobTitle,
  location,
  deadline,
  salaryFrom,
  salaryTo,
  type,
  isDirty,
}) => {
  const { md, sm, width } = useWindowSize();
  const { t } = useL10N();
  const { pathname } = useLocation();
  const titleInput = document.querySelector('[name="title"]');

  const {
    isOpen: isDeleteModalOpen,
    onClick: onDeleteModalClick,
    closeModal: closeDeleteModal,
  } = useModal();

  const {
    isOpen: isPublishModalOpen,
    onClick: onPublishModalClick,
    closeModal: closePublishModal,
  } = useModal();

  const isEditing = pathname.includes('edit');

  const onSubmit = (data: JobFormTypes) =>
    submitCallback(
      {
        ...data,
        title: jobTitle || '',
        deadline,
        location,
        type,
        salary_from: salaryFrom,
        salary_to: salaryTo,
      },
      '1'
    );

  const togglePublishModal = () => {
    trigger();
    onPublishModalClick();
  };

  const toggleDraftModal = () => {
    if (error && width && width < 768) {
      titleInput?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }

    trigger('title');
    clearErrors &&
      clearErrors([
        'description',
        'task',
        'type',
        'location',
        'deadline',
        'subscriber_notified_at',
        'salary_from',
        'salary_to',
        'file',
      ]);

    if (error === false && data && data?.title) {
      submitCallback(data, '10');
    }
  };

  useEffect(() => {
    if (error) {
      closePublishModal();
    }
  }, [error, closePublishModal]);

  return (
    <>
      <div className="job__form__content__header--title">
        <div>
          <CreateEdit />
        </div>
        <h1>{title}</h1>
      </div>
      <div className="job__form__content__header--buttons">
        <Button
          // type="submit"
          className={cx(!isDirty && isEditing && 'job__form__content__header--buttons--disabled')}
          onClick={toggleDraftModal}
          id={VacaniesStatusTypesEnums.DRAFT.toString()}
          loading={VacaniesStatusTypesEnums.DRAFT.toString() === loading}
          label={t('job.saveDraft')}
          before={<Edit type={2} />}
          btnSize={ButtonSizesEnums.MD}
          disabled={!isDirty && isEditing}
          btnType={md || sm ? ButtonTypesEnums.DARK_BLUE : ButtonTypesEnums.GRAY}
        />
        <Button
          onClick={togglePublishModal}
          id={VacaniesStatusTypesEnums.PUBLISHED.toString()}
          loading={VacaniesStatusTypesEnums.PUBLISHED.toString() === loading}
          label={t('job.publish')}
          before={<Eye type={1} />}
          btnSize={ButtonSizesEnums.MD}
          btnType={ButtonTypesEnums.GREEN}
        />
      </div>
      <div
        className={cx(`job__form__content__header--dots`, {
          'md:hidden': !id,
        })}
      >
        <DefaultDropdown
          triggerProps={{
            after: <ThreeDots type={ThreeDotsEnums.WHITE} />,
          }}
        >
          <JobFormDropdown
            id={id}
            onDeleteClick={onDeleteModalClick}
            onPublishClick={onPublishModalClick}
            onDraftClick={toggleDraftModal}
          />
        </DefaultDropdown>
      </div>
      <CustomModal
        open={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        title={t('jobs.delete')}
        type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
      >
        <DeleteJob closeModal={closeDeleteModal} />
      </CustomModal>
      <CustomModal
        open={isPublishModalOpen && !error}
        closeModal={closePublishModal}
        title={t('job.confirmPublication')}
        type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
      >
        <PublishJob
          closeModal={closePublishModal}
          handleSubmit={handleSubmit(onSubmit)}
          showSteps={data?.showOnDashboard}
        />
      </CustomModal>
    </>
  );
};

export default JobFormHeader;

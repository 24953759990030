import React from 'react';

import { Svg } from '../../../../atoms';

const ApiTokensEmpty = () => (
  <Svg
    width={84}
    height={59}
    viewBox="0 0 84 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx={42} cy={49} rx={42} ry={10} fill="#EDEFF4" />
    <path
      d="M61.273 1H22v48h39.273V1z"
      fill="#fff"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M37.273 18.454L30.727 25l6.546 6.545M46 31.545L52.545 25 46 18.454"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);

export default ApiTokensEmpty;

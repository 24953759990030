import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//components
import { ContentLoading } from '../../../atoms';
import { Search, MessagesPagination } from '../../../molecules';
import { EmailList } from '..';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useMessageResult } from '../../../../gql/hooks/messages/useMessageResult';

const Inbox = () => {
  // hooks
  const { xl } = useWindowSize();
  const { push, location } = useHistory();

  // gql hooks
  const { companyId } = useCompanyUser();

  const { loading, messages, allMessagesCount, filterMessagesCount } = useMessageResult({
    companyId,
  });

  useEffect(() => {
    if (location?.pathname === '/messages/inbox' && xl && messages?.length) {
      push(`/messages/inbox/${messages[0].id}`);
    }
  }, [xl, messages, location, push]);

  return (
    <div className="inbox">
      <div className="inbox__search">
        <Search />
      </div>
      <div className="inbox__list">
        {!loading ? (
          <EmailList messages={messages} allMessagesCount={allMessagesCount} />
        ) : (
          <ContentLoading />
        )}
      </div>
      {!loading && messages?.length ? (
        <MessagesPagination countElements={filterMessagesCount} />
      ) : (
        ''
      )}
    </div>
  );
};

export default Inbox;

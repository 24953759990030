import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import {
  Button,
  Check,
  ErrorMessage,
  ShowPassword,
  HidePassword,
} from '../../components/atoms';
import { FieldInput, PasswordMessage } from '../../components/molecules';
import { PagesContainer, HeaderLayout } from '../../components/templates';

//hooks
import { useL10N } from '../../hooks/useL10N';

//types
import { ResetPasswordSchema } from '../../validations';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../enums';

//gql
import { usePasswordResetMutation } from '../../gql/gen/graphql';
import useValidateResetToken from '../../gql/hooks/useValidateResetToken';

//utils
import { gqlVar } from '../../utils';

export type ResetPasswordTypes = {
  password: string;
  confirm_password: string;
};
export interface RouteMatchProps {
  token: string;
}

const ResetPassword = () => {
  const { t } = useL10N();

  const {
    params: { token },
  } = useRouteMatch<RouteMatchProps>();

  const { register, errors, handleSubmit } = useForm<ResetPasswordTypes>({
    resolver: yupResolver(ResetPasswordSchema({ t })),
  });

  const { isValidToken, loading: tloading } = useValidateResetToken({ token });

  const [resetMutation, { loading, data }] = usePasswordResetMutation({
    onError: (err) => console.error(err),
  });

  // state
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
    false
  );

  // submit handler
  const onSubmit = (data: ResetPasswordTypes) => {
    resetMutation(gqlVar({ ...data, token }));
  };

  // show hide password handler
  const handleTogglePassword = () => setShowPassword((prev) => !prev);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  if (tloading || !isValidToken) return null;

  return (
    <HeaderLayout l10nTitleKey="head.titles.resetPassword">
      <PagesContainer>
        <h1 className="font-medium text-3xl leading-10 text-blue-100 not-italic mb-12">
          {t('auth.resetPassword.title')}
        </h1>
        {data?.password_reset?.statusIsOk ? (
          <PasswordMessage
            icon={<Check />}
            status={t('auth.resetPassword.status')}
            message={t('auth.resetPassword.message')}
          />
        ) : (
          <>
            <ErrorMessage
              error={data?.password_reset?.statusMessage}
              className="auth__form__content--error"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldInput
                labelProps={{ children: t('auth.resetPassword.password') }}
                after={
                  <button
                    type="button"
                    className="btn"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <HidePassword /> : <ShowPassword />}
                  </button>
                }
                inputProps={{
                  inputRef: register,
                  type: showPassword ? 'text' : 'password',
                  name: 'password',
                }}
                error={errors.password?.message}
              />
              <FieldInput
                className="mt-6"
                labelProps={{
                  children: t('auth.resetPassword.confirmPassword'),
                }}
                after={
                  <button
                    type="button"
                    className="btn"
                    onClick={handleToggleConfirmPassword}
                  >
                    {showConfirmPassword ? <HidePassword /> : <ShowPassword />}
                  </button>
                }
                inputProps={{
                  inputRef: register,
                  type: showConfirmPassword ? 'text' : 'password',
                  name: 'confirm_password',
                }}
                error={errors.confirm_password?.message}
              />
              <Button
                type="submit"
                label={t('auth.resetPassword.changePassword')}
                loading={loading}
                disabled={loading}
                btnType={ButtonTypesEnums.GREEN}
                className="w-full mt-6"
                btnSize={ButtonSizesEnums.XS}
              />
            </form>
          </>
        )}
      </PagesContainer>
    </HeaderLayout>
  );
};

export default ResetPassword;

import React from 'react';

import { Svg } from '../../../atoms';

const Bag = () => (
  <Svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M0 0.875C0 0.642936 0.0921872 0.420376 0.256282 0.256282C0.420376 0.0921872 0.642936 0 0.875 0H2.75887C2.96599 9.5348e-05 3.16636 0.0736581 3.32434 0.207602C3.48232 0.341546 3.58766 0.527186 3.62162 0.7315L4.26912 4.61212C4.30006 4.79694 4.27085 4.98681 4.18579 5.15378C4.10073 5.32075 3.96432 5.456 3.79663 5.53963L2.44212 6.216C2.92784 7.41969 3.65123 8.51312 4.56905 9.43095C5.48688 10.3488 6.58031 11.0722 7.784 11.5579L8.46125 10.2034C8.54483 10.0358 8.67994 9.89954 8.84673 9.81449C9.01352 9.72945 9.20319 9.70014 9.38787 9.73088L13.2685 10.3784C13.4728 10.4123 13.6585 10.5177 13.7924 10.6757C13.9263 10.8336 13.9999 11.034 14 11.2411V13.125C14 13.3571 13.9078 13.5796 13.7437 13.7437C13.5796 13.9078 13.3571 14 13.125 14H11.375C5.0925 14 0 8.9075 0 2.625V0.875Z"
      fill="#778CA3"
    />
  </Svg>
);

export default Bag;

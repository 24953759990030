import React from 'react';

//components
import { MessagesHeader, MessagesContent } from '../../components/organisms';
import { PagesContainer, HeaderLayout } from '../../components/templates';

const Messages = () => (
  <HeaderLayout l10nTitleKey="head.titles.messages">
    <PagesContainer>
      <MessagesHeader />
      <MessagesContent />
    </PagesContainer>
  </HeaderLayout>
);

export default Messages;

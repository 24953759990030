import { gqlVar } from '../../../utils';

import { useFindPreviousApplicationsQuery } from '../../gen/graphql';

interface FindPreviousApplicationsProps {
  applicantId: number;
  applicantEmail: string;
}

const useFindPreviousApplications = ({
  applicantId,
  applicantEmail,
}: FindPreviousApplicationsProps) => {
  const query = useFindPreviousApplicationsQuery(gqlVar({ applicantId, applicantEmail }));

  const { data: { applicants = [] } = {}, loading, error } = query;

  return {
    applicants: applicants,
    loading,
    error,
  };
};

export default useFindPreviousApplications;

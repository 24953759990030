import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Button } from '../../../atoms';
import { FieldInput } from '../..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useApiTokenInsertMutation } from '../../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import {
  TokenTemplateFormProps,
  TokenTemplateFormTypes,
} from './TokenTemplateForm.types';
import { ApiTokensSchema } from '../../../../validations';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ToastrTypesEnums,
} from '../../../../enums';

const TokenTemplateForm: FC<TokenTemplateFormProps> = ({ closeModal }) => {
  const { company_user } = useCompanyUser();
  const { t } = useL10N();
  const toast = useContext(toastrContext);

  const { register, handleSubmit, errors } = useForm<TokenTemplateFormTypes>({
    resolver: yupResolver(ApiTokensSchema({ t })),
  });

  const [insertApiToken, { loading }] = useApiTokenInsertMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('apiTokens.createdError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('apiTokens.createdSuccessfully'));
    },
  });

  const onSubmit = (data: TokenTemplateFormTypes) => {
    insertApiToken({
      variables: {
        companyId: company_user?.company_id,
        name: data?.name,
      },
    }).then(() => {
      closeModal && closeModal();
    });
  };

  return (
    <form className="tokenTemplate__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="tokenTemplate__form--field">
        <FieldInput
          labelProps={{ children: 'Full name' }}
          inputProps={{
            name: 'name',
            inputRef: register,
          }}
          error={errors.name?.message}
        />
      </div>
      <div className="tokenTemplate__form__buttons">
        <Button
          label="Cancel"
          btnType={ButtonTypesEnums.WHITE_BORDER}
          btnSize={ButtonSizesEnums.XM}
          onClick={closeModal}
        />
        <Button
          type="submit"
          className="tokenTemplate__form__buttons--main"
          label="Create"
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.XM}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default TokenTemplateForm;

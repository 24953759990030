import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Draft: FC<IconProps> = ({ active = false }) => (
  <Svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M8.08335 3.58328H3.50002C3.01379 3.58328 2.54747 3.77643 2.20366 4.12025C1.85984 4.46407 1.66669 4.93038 1.66669 5.41661V15.4999C1.66669 15.9862 1.85984 16.4525 2.20366 16.7963C2.54747 17.1401 3.01379 17.3333 3.50002 17.3333H13.5834C14.0696 17.3333 14.5359 17.1401 14.8797 16.7963C15.2235 16.4525 15.4167 15.9862 15.4167 15.4999V10.9166M14.1205 2.28711C14.2896 2.11201 14.4919 1.97234 14.7156 1.87626C14.9393 1.78018 15.1799 1.7296 15.4233 1.72749C15.6667 1.72537 15.9081 1.77176 16.1334 1.86394C16.3587 1.95612 16.5634 2.09225 16.7356 2.26439C16.9077 2.43652 17.0438 2.64122 17.136 2.86653C17.2282 3.09184 17.2746 3.33325 17.2725 3.57668C17.2704 3.82011 17.2198 4.06068 17.1237 4.28435C17.0276 4.50803 16.888 4.71033 16.7129 4.87945L8.84235 12.7499H6.25002V10.1576L14.1205 2.28711Z"
      stroke={active ? '#08D4A5' : '#778CA3'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Draft;

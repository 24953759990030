import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Messages: FC<IconProps> = ({ active = false, color = 'white' }) => (
  <Svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={active ? '1' : '0.4'}
      d="M1.5 3.66663L8.075 8.04996C8.34888 8.23269 8.67075 8.33021 9 8.33021C9.32925 8.33021 9.65112 8.23269 9.925 8.04996L16.5 3.66663M3.16667 12.8333H14.8333C15.2754 12.8333 15.6993 12.6577 16.0118 12.3451C16.3244 12.0326 16.5 11.6087 16.5 11.1666V2.83329C16.5 2.39127 16.3244 1.96734 16.0118 1.65478C15.6993 1.34222 15.2754 1.16663 14.8333 1.16663H3.16667C2.72464 1.16663 2.30072 1.34222 1.98816 1.65478C1.67559 1.96734 1.5 2.39127 1.5 2.83329V11.1666C1.5 11.6087 1.67559 12.0326 1.98816 12.3451C2.30072 12.6577 2.72464 12.8333 3.16667 12.8333Z"
      stroke={active ? '#08D4A5' : color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Messages;

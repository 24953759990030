import React, { FC, useContext, useEffect, useState } from 'react';

//components
import {
  Roles,
  Email,
  Clock,
  Edit,
  LetterSvg,
  ThreeDots,
  Button,
  RoundedImage,
} from '../../../atoms';
import { Title, CardElements, DeleteDropdown, DefaultDropdown } from '../../index';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

// utils
import { splitFullName } from '../../../../utils/splitFullName';

//gql
import { useTeamMemberDeleteMutation } from '../../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import { CardElementProps } from '../../cards/CardElement.types';
import { TeamMemberItemItemProps } from './TeamMemberItem.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  UserRoleEnums,
  ToastrTypesEnums,
} from '../../../../enums';
import { generateFormatedDate } from '../../../../utils';

const TeamMemberItem: FC<TeamMemberItemItemProps> = ({
  item,
  position,
  onEditClick,
  membersCount,
  isAdmin,
}) => {
  const toast = useContext(toastrContext);
  const { xl } = useWindowSize();
  const { t } = useL10N();
  const [cardElements, setCardElements] = useState<CardElementProps[]>([]);

  useEffect(() => {
    if (item) {
      const elements = [
        { value: item?.user?.email, Icon: <Email /> },
        { value: UserRoleEnums[item?.role || 10], Icon: <Roles /> },
        {
          value: `${generateFormatedDate(item?.user?.created_at)}`,
          Icon: <Clock />,
        },
      ];
      setCardElements(elements);
    }
  }, [item]);

  const [deleteMessageTemplate] = useTeamMemberDeleteMutation({
    update: () => {},
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('settings.teamMemberDeletedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('settings.teamMemberDeletedSuccessfully'));
    },
  });

  const handleDeleteConfirm = () => {
    deleteMessageTemplate({
      variables: { id: +item.user_id || 0 },
    });
  };

  const handleEditClick = () => {
    onEditClick(item);
  };

  return (
    <div className="teamMember__item">
      <div className="teamMember__item__body">
        {xl && <span className="teamMember__item__body__order">{position}</span>}
        <div className="teamMember__item__body__avatar">
          <div className="teamMember__item__body__avatar--item">
            {item?.user?.file_store?.file_path ? (
              <RoundedImage src={item?.user.file_store.file_path} />
            ) : (
              <LetterSvg
                first={splitFullName(item?.user?.name || '')?.firstName || ''}
                second={splitFullName(item?.user?.name || '')?.lastName || ''}
              />
            )}
          </div>
          {!xl && <Title value={item?.user?.name || ''} />}
          {!xl && membersCount > 1 && isAdmin && (
            <div className="teamMember__item__body__avatar__dots">
              <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
                <DeleteDropdown onDeleteConfirm={handleDeleteConfirm} />
              </DefaultDropdown>
            </div>
          )}
        </div>
        <div className="teamMember__item__body__header">
          {xl && <Title value={item?.user?.name || ''} />}
          <div className="job__item__body__content">
            <CardElements elements={cardElements} />
          </div>
        </div>
        {isAdmin && (
          <div className="teamMember__item__body__button">
            <Button
              className="teamMember__item__body__button--view"
              label={t('button.edit')}
              btnType={ButtonTypesEnums.WHITE_BORDER}
              btnSize={ButtonSizesEnums.XL}
              before={<Edit />}
              onClick={handleEditClick}
            />
          </div>
        )}
      </div>

      {xl && isAdmin && (
        <div className="teamMember__item__buttons">
          <Button
            className="mr-2"
            label={t('button.edit')}
            btnSize={ButtonSizesEnums.SM}
            btnType={ButtonTypesEnums.WHITE_BORDER}
            before={<Edit />}
            onClick={handleEditClick}
          />
          {membersCount > 1 && (
            <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
              <DeleteDropdown onDeleteConfirm={handleDeleteConfirm} />
            </DefaultDropdown>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamMemberItem;

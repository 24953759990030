import { object, string } from 'yup';

export const ChangePasswordSchema = (
  t: (key: string, options?: any) => string
) =>
  object().shape({
    old_password: string()
      .trim()
      .min(8)
      .max(32)
      .required()
      .label(t('shared.forms.validation.oldPassword')),
    new_password: string()
      .trim()
      .min(8)
      .max(32)
      .test(
        'matches-old-password',
        t('shared.forms.validation.newPwMatchesOld'),
        (new_password, context) => new_password !== context.parent.old_password
      )
      .label(t('shared.forms.validation.newPassword')),
  });

import { InvoicesQuery, useInvoicesQuery } from '../../gen/graphql';

const useInvoices = ({ companyId }: { companyId: number }) => {
  const query = useInvoicesQuery({
    variables: {
      company_id: companyId,
      offset: 0,
      limit: 5,
    },
  });

  const {
    data: { invoices: data = null, invoicesAggregate } = {},
    loading,
    error,
    fetchMore,
  } = query;

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data?.length,
      },
      updateQuery: (prev: InvoicesQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!!prev.invoices && fetchMoreResult.invoices) {
          return Object.assign({}, prev, {
            invoices: [...prev?.invoices, ...fetchMoreResult.invoices],
          });
        } else return prev;
      },
    });
  };

  return {
    result: { data, invoicesAggregate },
    loading,
    error,
    onLoadMore,
  };
};

export default useInvoices;

import React from 'react';

import { Svg } from '../../../atoms';

const Search = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M17 17L11.6667 11.6667L17 17ZM13.4444 7.22222C13.4444 8.03934 13.2835 8.84845 12.9708 9.60336C12.6581 10.3583 12.1998 11.0442 11.622 11.622C11.0442 12.1998 10.3583 12.6581 9.60336 12.9708C8.84845 13.2835 8.03934 13.4444 7.22222 13.4444C6.40511 13.4444 5.596 13.2835 4.84108 12.9708C4.08617 12.6581 3.40023 12.1998 2.82245 11.622C2.24466 11.0442 1.78633 10.3583 1.47364 9.60336C1.16094 8.84845 1 8.03934 1 7.22222C1 5.57199 1.65555 3.98934 2.82245 2.82245C3.98934 1.65555 5.57199 1 7.22222 1C8.87246 1 10.4551 1.65555 11.622 2.82245C12.7889 3.98934 13.4444 5.57199 13.4444 7.22222Z"
      stroke="#778CA3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Search;

import React from 'react';

import { Svg } from '../../../atoms';

const ShowPassword = () => (
  <Svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.625 8C0.625 8 4.375 1.125 10 1.125C15.625 1.125 19.375 8 19.375 8C19.375 8 15.625 14.875 10 14.875C4.375 14.875 0.625 8 0.625 8Z"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11.125C11.7259 11.125 13.125 9.72589 13.125 8C13.125 6.27411 11.7259 4.875 10 4.875C8.27411 4.875 6.875 6.27411 6.875 8C6.875 9.72589 8.27411 11.125 10 11.125Z"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ShowPassword;

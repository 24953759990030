import React, { FC } from 'react';

// libs
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

//components
import { Button, RoundedImage, LetterSvg, FilledEye } from '../../../atoms';
import { Reply } from '../../../atoms/assets/icons';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//types
import { CandidateMailsItemProps } from './CandidateMailsItem.types';

//utils
import {
  splitFullName,
  generateFormatedDateTime,
  transformTextWithNewLine,
} from '../../../../utils';

const CandidateMailsItem: FC<CandidateMailsItemProps> = ({
  user,
  title,
  message,
  applicant,
  opened_at,
  created_at,
  message_id,
}) => {
  const { t } = useL10N();

  const renderAvatar = (name?: string | null) => (
    <LetterSvg
      first={splitFullName(name || '')?.firstName || ''}
      second={splitFullName(name || '')?.lastName || ''}
    />
  );

  return (
    <div className="mail">
      <div className="mail__header">
        <h1>{title}</h1>
        <Link to={`/messages/inbox/reply?message_id=${message_id}`}>
          <Button label={t('messages.reply')} before={<Reply />} className="text-tertiary" />
        </Link>
      </div>
      <div className="mail__content">
        <div className="mr-3">
          {user && user?.file_store?.file_path ? (
            <RoundedImage src={user.file_store.file_path} />
          ) : (
            renderAvatar(user?.name || applicant?.name)
          )}
        </div>
        <div className="mail__content--user">
          <div className="flex flex-row items-center">
            <p className="mr-2">{user?.name || applicant?.name}</p>
            {opened_at && (
              <div className="eye__tooltip" data-tip>
                <ReactTooltip place="right" type="dark" effect="solid">
                  <div className="flex flex-col">
                    <span className="eye__tooltip__heading">
                      {t('mails.candidate.reply.lastSeen')}
                    </span>
                    <span className="eye__tooltip__date">
                      {dayjs(opened_at).format('D.MM.YYYY HH:mm')}
                    </span>
                  </div>
                </ReactTooltip>
                <FilledEye />
              </div>
            )}
          </div>
          <span>{generateFormatedDateTime(created_at)}</span>
        </div>
      </div>
      <p
        className="mail--message"
        dangerouslySetInnerHTML={{
          __html: transformTextWithNewLine(message),
        }}
      />
    </div>
  );
};

export default CandidateMailsItem;

import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import Footer from '../components/organisms/footer/Footer';
import Header from '../components/organisms/header/Header';
import withAuth from '../hoc/withAuth';
import { PaymentBadge } from '../components/molecules';

// contenxts
import { PaymentContext } from '../contexts/PaymentContext';
import {
  Dashboard,
  Jobs,
  Job,
  CandidateProfile,
  Create,
  Edit,
  Messages,
  Settings,
  NotFound,
  Subscribers,
  StripeSubscription,
  Applicants,
} from '../pages';

//gql
import { usePaymentsSubscription } from '../gql/gen/graphql';
import useCompanyUser from '../gql/hooks/useCompanyUser';

//enums
import { PaymentPlanTypeEnums } from '../enums';

// utils
import { generateDatesDiff, parseQueryString } from '../utils';

const Routes = () => {
  // hooks
  const {
    location: { pathname, search },
  } = useHistory();
  const paymentContext = useContext(PaymentContext);

  // const
  const { payment } = parseQueryString(search);

  // local state
  const [freeTrialExpiresIn, setFreeTrialExpiresIn] = useState<number | null>(null);

  const { company_user } = useCompanyUser();
  const { data: { payments = [] } = {} } = usePaymentsSubscription({
    variables: { company_id: company_user?.company_id },
  });

  useEffect(() => {
    if (payments[0]?.status === PaymentPlanTypeEnums.FREE_TRIAL && !payment) {
      const expiresIn = generateDatesDiff(payments[0]?.expiredAt);
      setFreeTrialExpiresIn(expiresIn);
      if (expiresIn > 0) {
        paymentContext?.showBadge();
      } else {
        if (pathname !== '/settings/payments') {
          paymentContext?.showModal();
        } else {
          paymentContext?.closeModal();
        }
      }
    }
  }, [payments, paymentContext, pathname, payment]);

  return (
    <>
      {paymentContext?.showPaymentBadge && (
        <PaymentBadge expiresIn={freeTrialExpiresIn?.toString() || '1'} />
      )}
      <Header />
      <Switch>
        <Route exact path={['/dashboard', '/']} component={Dashboard} />
        <Route exact path={'/jobs/:tab(all|published|draft)'} component={Jobs} />
        <Route exact path={'/messages/inbox/:tab(compose|reply|new|\\d+)?'} component={Messages} />
        <Route exact path={'/applicants/:tab(all)'} component={Applicants} />
        <Route exact path={'/applicants/:id/:tab/:tabId(\\d+)?'} component={Applicants} />

        <Route exact path={'/subscribers'} component={Subscribers} />

        <Route exact path={'/job/create'} component={Create} />
        <Route exact path={'/job/:id/edit'} component={Edit} />
        <Route exact path={'/job/:id/:tab(all)'} component={Job} />
        <Route exact path={'/job/:id/:tab/:tabId'} component={Job} />
        <Route exact path={'/candidate/:id/:tab(overview|notes)'} component={CandidateProfile} />
        <Route exact path={'/settings/:tab'} component={Settings} />
        <Route exact path={'/stripe/subscription'} component={StripeSubscription} />
        <Route path="/error/404" component={NotFound} />
        <Redirect to="/error/404" />
      </Switch>
      <Footer />
    </>
  );
};
export default withAuth(Routes, true);

import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const LinkEdit: FC<IconProps> = ({ active = false }) => (
  <Svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M8.08366 3.58328H3.50033C3.0141 3.58328 2.54778 3.77643 2.20396 4.12025C1.86015 4.46407 1.66699 4.93038 1.66699 5.41661V15.4999C1.66699 15.9862 1.86015 16.4525 2.20396 16.7963C2.54778 17.1401 3.0141 17.3333 3.50033 17.3333H13.5837C14.0699 17.3333 14.5362 17.1401 14.88 16.7963C15.2238 16.4525 15.417 15.9862 15.417 15.4999V10.9166M14.1208 2.28711C14.2899 2.11201 14.4922 1.97234 14.7159 1.87626C14.9396 1.78018 15.1802 1.7296 15.4236 1.72749C15.667 1.72537 15.9084 1.77176 16.1337 1.86394C16.3591 1.95612 16.5637 2.09225 16.7359 2.26439C16.908 2.43652 17.0441 2.64122 17.1363 2.86653C17.2285 3.09184 17.2749 3.33325 17.2728 3.57668C17.2707 3.82011 17.2201 4.06068 17.124 4.28435C17.0279 4.50803 16.8883 4.71033 16.7132 4.87945L8.84266 12.7499H6.25033V10.1576L14.1208 2.28711Z"
      stroke={active ? '#08D4A5' : '#778CA3'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LinkEdit;

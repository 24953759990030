import React from 'react';

import { Svg } from '../../../../atoms';

const Created = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.6}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0a1 1 0 00-1 1v1H2a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2h-1V1a1 1 0 00-2 0v1H5V1a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H4z"
      fill="#778CA3"
    />
  </Svg>
);

export default Created;

import React, { FC } from 'react';
import cx from 'classnames';

//components
import { ArrowDown, Label, Select } from '../../../atoms';

//types
import { CustomSelectProps } from './CustomSelect.types';

const CustomSelect: FC<CustomSelectProps> = ({
  error,
  labelProps,
  selectProps,
}) => (
  <>
    <Label {...labelProps} className="mb-2" />
    <div
      className={cx(
        'flex items-center justify-center pl-4 py-4 bg-white w-full h-12 rounded-md border-gray-200 border',
        { 'border-danger': !!error }
      )}
    >
      <Select {...selectProps} />
      <ArrowDown className="mr-4" />
    </div>
  </>
);

export default CustomSelect;

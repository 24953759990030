import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Eye: FC<IconProps> = ({ type = 1 }) => {
  const primary = (
    <Svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.542 8C13.542 8.79565 13.2259 9.55871 12.6633 10.1213C12.1007 10.6839 11.3376 11 10.542 11C9.74634 11 8.98328 10.6839 8.42067 10.1213C7.85806 9.55871 7.54199 8.79565 7.54199 8C7.54199 7.20435 7.85806 6.44129 8.42067 5.87868C8.98328 5.31607 9.74634 5 10.542 5C11.3376 5 12.1007 5.31607 12.6633 5.87868C13.2259 6.44129 13.542 7.20435 13.542 8V8Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8C2.274 3.943 6.065 1 10.542 1C15.02 1 18.81 3.943 20.084 8C18.81 12.057 15.02 15 10.542 15C6.065 15 2.274 12.057 1 8Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
  const secondary = (
    <Svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.54199 9C10.0724 9 10.5811 8.78929 10.9562 8.41421C11.3313 8.03914 11.542 7.53043 11.542 7C11.542 6.46957 11.3313 5.96086 10.9562 5.58579C10.5811 5.21071 10.0724 5 9.54199 5C9.01156 5 8.50285 5.21071 8.12778 5.58579C7.75271 5.96086 7.54199 6.46957 7.54199 7C7.54199 7.53043 7.75271 8.03914 8.12778 8.41421C8.50285 8.78929 9.01156 9 9.54199 9Z"
        fill="#ADBAC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C1.274 2.943 5.064 0 9.542 0C14.02 0 17.81 2.943 19.084 7C17.81 11.057 14.02 14 9.542 14C5.064 14 1.274 11.057 0 7ZM13.542 7C13.542 8.06087 13.1206 9.07828 12.3704 9.82843C11.6203 10.5786 10.6029 11 9.542 11C8.48113 11 7.46372 10.5786 6.71357 9.82843C5.96343 9.07828 5.542 8.06087 5.542 7C5.542 5.93913 5.96343 4.92172 6.71357 4.17157C7.46372 3.42143 8.48113 3 9.542 3C10.6029 3 11.6203 3.42143 12.3704 4.17157C13.1206 4.92172 13.542 5.93913 13.542 7Z"
        fill="#ADBAC8"
      />
    </Svg>
  );
  return type === 1 ? primary : secondary;
};

export default Eye;

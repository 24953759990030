// react
import React, { ChangeEvent, FC, ReactElement } from 'react';

// classNames
import cx from 'classnames';

// hooks
import { useL10N } from '../../../../hooks/useL10N';
import useWindowSize from '../../../../hooks/useWindowSize';

// components
import { Edit, Button } from '../../../atoms';
import { ToggleStage } from '../../../molecules';

// gql
import { useStageUpdateMutation } from '../../../../gql/gen/graphql';

// utils
import { gqlVar } from '../../../../utils';

// enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../../enums';

// types
import { ManageStageActionsProps } from './ManageStageActions.types';

const ManageStageActions: FC<ManageStageActionsProps> = ({
  stage,
  children,
  showSwitch,
  handleEditClick,
}): ReactElement => {
  // hooks
  const { t } = useL10N();
  const { xl } = useWindowSize();

  // graphql hooks
  const [updateStage] = useStageUpdateMutation();

  // handler
  const handleStageChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateStage(gqlVar({ id: stage.id, set: { is_shown: event.target.checked ? 1 : 0 } }));
  };

  return (
    <div
      className={cx(xl ? 'flex py-6' : 'template__item__body--button flex flex-col items-start')}
    >
      <ToggleStage
        className="mb-4 mr-2 md:mb-0"
        name={t('stage.showOnDashboard')}
        inputProps={{
          disabled: showSwitch ? !showSwitch && !!stage?.is_shown : !stage?.is_shown && !showSwitch,
          id: `${stage?.name?.toLowerCase()}`,
          name: `${stage?.name?.toLowerCase()}`,
          defaultChecked: stage.is_shown === 1,
          onChange: handleStageChange,
        }}
      />
      <Button
        before={<Edit />}
        label={t('button.edit')}
        onClick={handleEditClick}
        btnType={ButtonTypesEnums.WHITE_BORDER}
        btnSize={!xl ? ButtonSizesEnums.XL : ButtonSizesEnums.SM}
        className={cx(!xl && 'template__item__body--button--view')}
      />
      {children}
    </div>
  );
};

export default ManageStageActions;

import { gql } from '@apollo/client';

export const NotesFragment = gql`
  fragment NotesFragment on notes {
    id
    created_at
    applicant_id
    description
    user {
      id
      name
    }
  }
`;

import React, { FC, MouseEvent } from 'react';
import cx from 'classnames';

//types
import { CarouselTriggerProps } from './CarouselTrigger.types';

const CarouselTrigger: FC<CarouselTriggerProps> = ({
  className = '',
  id,
  active,
  onClick,
}) => {
  const handleOnClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onClick(parseInt(event.currentTarget.id));
  };

  return (
    <span
      id={id}
      role="button"
      aria-label="Carousel element"
      className={cx('carousel--trigger', {
        'opacity-75': active === parseInt(id),
        [className]: className,
      })}
      onClick={handleOnClick}
    />
  );
};

export default CarouselTrigger;

import React, { ChangeEvent, FC } from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Search } from '../../atoms';
import { FilterDropdown, FieldInput, Pills } from '..';

//types
import { FilterProps } from './Filter.types';

//enums
import { InputTypesEnums, VacaniesTypesEnums } from '../../../enums';

//utils
import { parseQueryString, serialize } from '../../../utils';

const Filter: FC<FilterProps> = ({ placeholder, showFilterDropdown }) => {
  const {
    push,
    replace,
    location: { search },
  } = useHistory();

  const { query, type, page, ...restQuery } = parseQueryString(search);

  const handleOnPillClick = (id: string) => {
    let _types = type.split(',') || [];
    push(
      serialize({
        ...restQuery,
        query,
        page,
        type: _types.filter((t) => t !== id).join(','),
      })
    );
  };

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.value.length > 1
      ? replace(serialize({ ...restQuery, type, query: event.target.value }))
      : push(serialize({ ...restQuery, type, query: event.target.value }));

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
        }}
      >
        <FieldInput
          before={<Search />}
          after={!!showFilterDropdown && <FilterDropdown />}
          inputType={InputTypesEnums.ROUNDED_FULL}
          inputProps={{
            value: query || '',
            onChange: handleOnInputChange,
            name: 'search',
            placeholder,
          }}
        />
      </form>
      <div className="mt-4 ml-1 text-orange-100">
        <Pills
          pills={
            type
              ? type.split(',').map((t) => ({
                  name: VacaniesTypesEnums[t],
                  id: t,
                }))
              : []
          }
          onPillClick={handleOnPillClick}
        />
      </div>
    </>
  );
};

export default Filter;

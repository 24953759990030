// react
import React, { useEffect, useMemo, useState } from 'react';

// router
import { useHistory } from 'react-router-dom';

// libs
import clsx from 'clsx';

//components
import {
  LineChart,
  JobPostItem,
  EmptyActiveJobs,
  DashboardSortDropdown,
} from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import { useVacanciesStatsQuery } from '../../../../gql/gen/graphql';

//types
import { JobType, DetailedJobsType } from './JobPosts.types';

//utils
import {
  gqlVar,
  parseQueryString,
  generateDashboardJobColor,
} from '../../../../utils';

const JobPosts = () => {
  // hooks
  const { t } = useL10N();
  const { location } = useHistory();
  const { sortJobs } = parseQueryString(location?.search);
  const { data } = useVacanciesStatsQuery(gqlVar({ period: sortJobs }));

  // states
  const [jobs, setJobs] = useState<Array<JobType>>([]);
  const [detailedJobs, setDetailedJobs] = useState<Array<DetailedJobsType>>([]);

  useEffect(() => {
    let datas: JobType[] = [];
    let detailedDatas: DetailedJobsType[] = [];
    const jobs = data?.job_posting_data?.jobs_data || {};
    const detailed = data?.job_posting_data?.detailed_jobs_data || {};

    if (Object.keys(jobs).length) {
      datas = Object.entries(jobs).map(([key, value]: any, index: number) => {
        return {
          id: parseInt(key),
          result: value,
          color: generateDashboardJobColor(index),
        };
      });
      setJobs(datas);
    }

    if (Object.keys(detailed).length) {
      detailedDatas = Object.entries(detailed).map(([key, value]: any) => {
        return {
          id: parseInt(key),
          ...value[0],
          color: datas?.find((el) => el.id === parseInt(key))?.color,
        };
      });
      setDetailedJobs(detailedDatas);
    }
  }, [data]);

  // memoized render
  const memoizedLineChart = useMemo(
    () => (
      <div className="relative">
        <LineChart data={jobs} />
        {jobs.length === 0 && <EmptyActiveJobs />}
      </div>
    ),
    [jobs]
  );

  return (
    <div className="job__content jobs__posted">
      <div className="pr-5 job__content--header md:pr-0">
        <h1 className="job__content--header--title">
          {t('dashboard.jobPosting')}
        </h1>
        <DashboardSortDropdown />
      </div>
      <div className="table__holder">
        <div className="job__content--graph">{memoizedLineChart}</div>
        <div className="job__content--content">
          <div
            className={clsx('job__content--content--title', {
              'border-0 pb-0': !detailedJobs.length,
            })}
          >
            <p>{t('dashboard.jobTitle')}</p>
            <p className="text-right ">{t('dashboard.applicants')}</p>
            <p className="text-right ">{t('dashboard.deadline')}</p>
          </div>
          {detailedJobs.length > 0 && (
            <div className="job__content--content--items">
              {detailedJobs.map((job) => (
                <JobPostItem
                  key={job.id}
                  item={{
                    color: job.color,
                    applicants: job.applicants,
                    name: job.job_title,
                    deadline: job.deadline,
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPosts;

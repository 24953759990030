import React, { FC } from 'react';
import cx from 'classnames';

//components
import { Label, Input } from '../../../atoms';

//types
import { ToggleProps } from './Toggle.types';

const Toggle: FC<ToggleProps> = ({ name, inputProps, className }) => (
  <Label
    htmlFor={inputProps.id || 'toogleA'}
    className={cx('toggle', {
      [`${className}`]: className,
    })}
  >
    <div className="relative">
      <Input type="checkbox" className="toggle__input" {...inputProps} />
      <div className="toggle__line"></div>
      <div className="toggle__dot"></div>
    </div>
    {name && <div className="toggle__text">{name}</div>}
  </Label>
);

export default Toggle;

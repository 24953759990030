import React from 'react';

//components
import { NotesEmpty as NotesEmptyIcon } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

const NotesEmpty = () => {
  const { t } = useL10N();

  return (
    <div className="empty px-4">
      <div className="empty--icon">
        <NotesEmptyIcon />
      </div>
      <h1 className="empty__heading text-primary">{t('notes.emptyTitle')}</h1>
      <p className="empty__desc">{t('notes.emptyDesc')}</p>
    </div>
  );
};

export default NotesEmpty;

import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Button, ErrorMessage, ShowPassword, HidePassword } from '../../atoms';
import { FieldInput } from '../../molecules';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import { useLoginMutation } from '../../../gql/gen/graphql';
import useAuth from '../../../gql/hooks/auth/useAuth';

//types
import { LoginFormTypes } from './LoginForm.types';
import { LoginFormSchema } from '../../../validations';

//enums
import { ButtonTypesEnums, ButtonSizesEnums } from '../../../enums';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { push } = useHistory();
  const { t } = useL10N();

  const auth = useAuth();

  const { register, errors, handleSubmit, setValue } = useForm<LoginFormTypes>({
    resolver: yupResolver(LoginFormSchema({ t })),
  });

  const [loginMutation, { loading, data }] = useLoginMutation({
    onCompleted: ({ login }) =>
      login?.token && auth?.authenticate(login?.token),
    onError: (err) => console.error(err),
  });

  const handleTogglePassword = () => setShowPassword((prev) => !prev);

  const onSubmit = (data: LoginFormTypes) => {
    loginMutation({ variables: { ...data } })
      .then(({ data }) => {
        if (data?.login?.statusCode === 'INVALID_CREDENTIALS') {
          setValue('password', '');
        }
        if (data?.login?.statusIsOk) push('/dashboard');
      })
      .catch((err) => console.log(err));
  };

  return (
    <form className="auth__form__content" onSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage
        error={data?.login?.statusMessage}
        className="auth__form__content--error"
      />
      <div className="auth__form__content--input">
        <FieldInput
          error={errors.email?.message}
          labelProps={{ children: t('login.email') }}
          inputProps={{
            name: 'email',
            inputRef: register,
            placeholder: 'flamur.mavraj@ornio.no',
            type: 'email',
          }}
        />
      </div>
      <div className="auth__form__content--input">
        <FieldInput
          error={errors.password?.message}
          labelProps={{ children: t('auth.password') }}
          after={
            <button
              type="button"
              className="btn"
              onClick={handleTogglePassword}
            >
              {showPassword ? <HidePassword /> : <ShowPassword />}
            </button>
          }
          inputProps={{
            name: 'password',
            inputRef: register,
            placeholder: t('auth.passwordPlaceholder'),
            type: showPassword ? 'text' : 'password',
          }}
        />
      </div>
      <div className="auth__form__content--inputs">
        <Link className="auth__form--link" to="/auth/password/forgot">
          {t('login.forgotPassword')}
        </Link>
      </div>
      <div className="auth__form__content--input">
        <Button
          type="submit"
          label={t('button.login')}
          loading={loading}
          disabled={loading}
          btnType={ButtonTypesEnums.GREEN}
          className="w-full"
          btnSize={ButtonSizesEnums.XS}
        />
      </div>
    </form>
  );
};

export default LoginForm;

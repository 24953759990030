const dashboardJobColors = [
  '#367E7F',
  '#87A96B',
  '#E48A32',
  '#21ABCD',
  '#A2A2D0',
  '#C2B280',
  '#708090',
  '#F8CC55',
  '#C05D24 ',
];

/*
  9 colors
  0-8 dashboardJobColors indexes
  if jobindex > 8 -> jobindex - dashboardJobColors.length
  else jobindex = dashboardJobColors[jobIndex]
*/
export const generateDashboardJobColor = (index: number): string => {
  return index >= dashboardJobColors.length
    ? dashboardJobColors[index - dashboardJobColors.length]
    : dashboardJobColors[index];
};

import React from 'react';
import { useLocation } from 'react-router-dom';

//components
import { ContentLoading } from '../../../atoms';
import { MailFormHeader } from '../../../molecules';
import { MailForm } from '..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

// graphql
import { useMessageByPk } from '../../../../gql/hooks/messages/useMessageByPk';

//utils
import { parseQueryString } from '../../../../utils';

// enums
import { CreateMessageTypes } from '../../../../enums';

const Compose = () => {
  const { t } = useL10N();

  const { search } = useLocation();
  const queryParams = parseQueryString(search);

  const { result, loading } = useMessageByPk({
    messageId: queryParams.message_id,
  });

  if (loading) return <ContentLoading />;

  return (
    <>
      <MailFormHeader title={t('message.newMessage')} />
      {result?.subject && result?.applicant ? (
        <MailForm
          message={{
            to: [
              {
                value: result?.applicant?.id,
                label: result.applicant.email,
              },
            ],
          }}
          type={CreateMessageTypes.COMPOSE}
        />
      ) : (
        <MailForm type={CreateMessageTypes.COMPOSE} />
      )}
    </>
  );
};

export default Compose;

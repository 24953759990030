import React from 'react';

//components
import { LayoutContainer } from '../../../templates';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

const DashboardHeader = () => {
  const { t } = useL10N();
  return (
    <LayoutContainer>
      <div className="dashboard__header">
        <div className="dashboard__header__content">
          <h1 className="dashboard__header__content--title">
            {t('dashboard.title')}
          </h1>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default DashboardHeader;

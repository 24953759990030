import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Jobs: FC<IconProps> = ({ active = false, color = 'white' }) => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={active ? '1' : '0.4'}
      d="M16.5 11.0458C14.1171 12.0093 11.5704 12.5031 9 12.5C6.3475 12.5 3.81667 11.9833 1.5 11.0458M9 9.99996H9.00833M12.3333 4.99996V3.33329C12.3333 2.89126 12.1577 2.46734 11.8452 2.15478C11.5326 1.84222 11.1087 1.66663 10.6667 1.66663H7.33333C6.89131 1.66663 6.46738 1.84222 6.15482 2.15478C5.84226 2.46734 5.66667 2.89126 5.66667 3.33329V4.99996H12.3333ZM3.16667 16.6666H14.8333C15.2754 16.6666 15.6993 16.491 16.0118 16.1785C16.3244 15.8659 16.5 15.442 16.5 15V6.66663C16.5 6.2246 16.3244 5.80067 16.0118 5.48811C15.6993 5.17555 15.2754 4.99996 14.8333 4.99996H3.16667C2.72464 4.99996 2.30072 5.17555 1.98816 5.48811C1.67559 5.80067 1.5 6.2246 1.5 6.66663V15C1.5 15.442 1.67559 15.8659 1.98816 16.1785C2.30072 16.491 2.72464 16.6666 3.16667 16.6666Z"
      stroke={active ? '#08D4A5' : color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Jobs;

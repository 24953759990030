// router
import { useLocation } from 'react-router-dom';

// gql
import { useMessagesQuery, useNewMessagesCountSubscription } from '../../gen/graphql';

// utils
import { gqlVar, parseQueryString } from '../../../utils';

// types
import { MessagesPaginationTypesEnums, MessageTypes } from '../../../enums';

export const useMessageResult = ({ limit, companyId }: { limit?: number; companyId: number }) => {
  // hooks
  const { search } = useLocation();
  const { query, type, page } = parseQueryString(search);

  const hasRead = () => {
    if (type === MessageTypes.READ) return false;
    if (type === MessageTypes.UNREAD) return true;
  };

  // const
  const where = {
    parent_id: { _is_null: true },
    company_id: { _eq: companyId },
    ...(!!type &&
      type !== MessageTypes.ALL && {
        read_at: { _is_null: hasRead() },
      }),
    ...(!!query && {
      _or: [
        { subject: { _ilike: `%${query}%` } },
        {
          applicant: {
            _or: [{ email: { _ilike: `%${query}%` } }, { name: { _ilike: `%${query}%` } }],
          },
        },
      ],
    }),
  };

  /// gql hooks
  const { data, loading, error } = useMessagesQuery(
    gqlVar({
      where,
      limit: limit ? limit : MessagesPaginationTypesEnums.LIMIT,
      offset: ((page || 1) - 1) * MessagesPaginationTypesEnums.LIMIT,
    })
  );

  // consts
  const { data: newMessages } = useNewMessagesCountSubscription(gqlVar({ where }));

  const { data: allNewMessags } = useNewMessagesCountSubscription(
    gqlVar({ where: { company_id: { _eq: companyId } } })
  );

  return {
    messages: data?.messages || [],
    filterMessagesCount: newMessages?.messages_aggregate.aggregate?.count || 0,
    allMessagesCount: allNewMessags?.messages_aggregate?.aggregate?.count || 0,
    loading,
    error,
  };
};

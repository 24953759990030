import React from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Button, MessageEmpty, Create } from '../../../atoms';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../../enums';

const EmailListEmpty = () => {
  const { push } = useHistory();
  const { t } = useL10N();

  const handleCreate = () => push('/messages/inbox/new');
  return (
    <div className="flex flex-col items-center justify-center mt-20">
      <MessageEmpty />
      <p className="mt-4 mb-4">{t('message.emptyTitle')}</p>
      <Button
        label={t('button.newMessage')}
        btnSize={ButtonSizesEnums.XS}
        btnType={ButtonTypesEnums.GREEN}
        before={<Create />}
        onClick={handleCreate}
      />
    </div>
  );
};
export default EmailListEmpty;

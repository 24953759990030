import { JWT_STORAGE_KEY } from '../../config/jwt';

export const storeAuthToken = ({
  statusIsOk,
  token,
}: {
  statusIsOk: boolean;
  token: string | null;
}) => {
  if (statusIsOk && token) {
    localStorage.setItem(JWT_STORAGE_KEY, token);
  }
};

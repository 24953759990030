import React, { useState } from 'react';

//components
import { Button, Export, ThreeDots } from '../../../atoms';
import {
  DefaultDropdown,
  Filter,
  SubscriberDropdown,
} from '../../../molecules';
import { LayoutContainer } from '../../../templates';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import useSubscribers from '../../../../gql/hooks/subscribers/useSubscribers';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../../enums';

//config
import { GQL_ACTIONS_URL } from '../../../../config/gql';

const SubscribersHeader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { xl } = useWindowSize();
  const { t } = useL10N();
  const { company_user } = useCompanyUser();

  const { result } = useSubscribers({
    company_id: company_user?.company_id,
  });

  const handleExportAllSubscribers = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${GQL_ACTIONS_URL}/export-subscribers/${company_user?.company_id}/true`,
      {
        method: 'GET',
      }
    );

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', response?.url);
    document.body.appendChild(a);
    a.click();
    setIsLoading(false);
  };

  return (
    <LayoutContainer>
      <div className="subscribers__header">
        <div className="subscribers__header__content">
          <h1 className="subscribers__header__content--title">
            {t('subscribers.title')}
          </h1>
          {!!result.data &&
            result.data.length > 0 &&
            (xl ? (
              <Button
                label={t('subscribers.exportAll')}
                before={<Export />}
                btnSize={ButtonSizesEnums.MD}
                btnType={ButtonTypesEnums.GRAY}
                onClick={handleExportAllSubscribers}
                loading={isLoading}
              />
            ) : (
              <DefaultDropdown
                triggerProps={{
                  after: <ThreeDots />,
                }}
              >
                <SubscriberDropdown
                  onExportAllSubscribers={handleExportAllSubscribers}
                  isLoading={isLoading}
                />
              </DefaultDropdown>
            ))}
        </div>
        <div className="subscribers__header__filter">
          <Filter placeholder="Search subscribers" />
        </div>
      </div>
    </LayoutContainer>
  );
};

export default SubscribersHeader;

import React, { FC } from 'react';

//components
import { Label, Upload } from '../../../atoms';
import { FieldInput } from '..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//types
import { FileFieldProps } from './FileField.types';

//enums
import { InputTypesEnums } from '../../../../enums';

const FileField: FC<FileFieldProps> = ({
  inputProps,
  labelProps,
  file_path,
  error,
  file,
}) => {
  const { t } = useL10N();
  return (
    <FieldInput
      inputType={InputTypesEnums.DEFAULT_FILE}
      labelProps={labelProps}
      inputProps={{
        ...inputProps,
        type: 'file',
      }}
      error={error}
      after={
        <Label
          htmlFor={inputProps.name}
          className="file__input file__input--placeholder"
        >
          {(!file || Object.keys(file).length === 0) && file_path ? (
            <span>{file_path}</span>
          ) : (
            <>
              {!(file && file[0]) && <Upload />}
              <span className="ml-2">
                {(file && file[0]?.name) || t('file.chooseFile')}
              </span>
            </>
          )}
        </Label>
      }
    />
  );
};

export default FileField;

import { useLocation, useRouteMatch } from 'react-router-dom';

import { gqlVar, parseQueryString } from '../../../utils';
import { PaginationTypeEnums } from '../../../enums';

import { useApplicantsSubscription, useAllApplicantsSubscription } from '../../gen/graphql';

export interface RouteMatchProps {
  tab: string;
  tabId: string;
}

const useAllCompanyApplicants = ({ id }: { id: number }) => {
  const { search } = useLocation();
  const {
    params: { tab, tabId },
  } = useRouteMatch<RouteMatchProps>();

  const { query: q, page } = parseQueryString(search);

  const where = {
    vacancy: { company_id: { _eq: id ? id : 0 } },
    ...(q && { name: { _ilike: `%${q}%` } }),
  };

  // Applicants query
  const query = useApplicantsSubscription(
    gqlVar({
      limit: PaginationTypeEnums.LIMIT,
      offset: ((page || 1) - 1) * PaginationTypeEnums.LIMIT,
      where: {
        ...where,
        ...(tab &&
          tab !== 'all' && {
            status_id: { _eq: +tabId },
          }),
      },
    })
  );

  // All applicants count query
  const queryAll = useAllApplicantsSubscription(
    gqlVar({
      where_count: {
        ...where,
        ...(tab &&
          tab !== 'all' && {
            status_id: { _eq: +tabId },
          }),
      },
    })
  );

  // Applicants data
  const { data: { applicants: data = null } = {}, loading, error } = query;

  // All applicants count data
  const { data: { count_all } = {} } = queryAll;

  return {
    result: {
      data,
      meta: {
        all: count_all?.aggregate?.count || 0,
      },
    },
    loading,
    error,
  };
};

export default useAllCompanyApplicants;

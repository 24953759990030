import React, { useState, ChangeEvent, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

//components
import { LayoutContainer } from '../../../templates';
import { ContentLoading } from '../../../atoms';
import { JobItem, JobEmptyView, ReactPagination } from '../../../molecules';
import { JobTabs, JobActions } from '../../../organisms';

//gql
import useApplicants from '../../../../gql/hooks/applicants/useApplicants';

export type SelectTypeId = string | number;
export interface MatchParams {
  id: string;
  tab: string;
}

const JobContent = () => {
  const [selected, setSelected] = useState<Array<SelectTypeId>>([]);
  const { params } = useRouteMatch<MatchParams>();
  const {
    location: { pathname, search },
  } = useHistory();

  const { result, loading } = useApplicants({ id: params?.id });

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
    setSelected([]);
  }, [pathname, search]);

  useEffect(() => {
    if (result.data && result.data.length) {
      setSelectAll(result.data.every((j) => selected.includes(j.id.toString())));
    } else setSelectAll(false);
  }, [result, selected]);

  const findId = (id: string) => (selected.indexOf(id) >= 0 ? true : false);

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && result.data)
      setSelected(result.data.map(({ id }) => id.toString()));
    else setSelected([]);
    setSelectAll(!selectAll);
  };

  const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const temp = findId(event.target.id);
    if (temp) setSelected(selected.filter((id) => id !== event.target.id));
    else setSelected([...selected, event.target.id]);
  };

  const handleOnSendMessage = () => {
    let selected_applicants: number[] = [];
    result?.data
      ?.filter((j) => selected.includes(j.id.toString()))
      .map((applicant) => selected_applicants.push(applicant.id));
    if (selected_applicants.length) {
      return `/messages/inbox/new?applicant_ids=${selected_applicants.join(',')}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <JobTabs meta={result.meta} />
      <LayoutContainer className="">
        <div className="job--actions">
          {result.data && result.data.length > 0 && (
            <JobActions
              all={selectAll}
              onSelect={onSelectAll}
              onSendMessage={handleOnSendMessage}
              selected={result.data
                .filter((j) => selected.includes(j.id.toString()))
                .map((job) => job.id)}
            />
          )}
        </div>

        {loading ? (
          <div className="flex items-center justify-center job--content">
            <ContentLoading />
          </div>
        ) : (
          <div className="job--content">
            {result.data?.length ? (
              result?.data.map((el) => (
                <JobItem
                  key={el.id}
                  candidate={el}
                  onSelect={onSelect}
                  select={findId(el.id.toString())}
                />
              ))
            ) : (
              <JobEmptyView />
            )}
          </div>
        )}
        {!!result.meta?.all && !loading && (
          <ReactPagination
            className="job--pagination"
            loading={loading}
            pagination={{ numberOfItems: result.meta?.all }}
          />
        )}
      </LayoutContainer>
    </>
  );
};

export default JobContent;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { NotificationSpanProps } from './NotificationSpan.types';

const NotificationSpan: FC<NotificationSpanProps> = ({
  value = 0,
  showWithValue = false,
}) => (
  <span
    className={cx(`notification--number`, {
      hidden: !showWithValue && !value,
    })}
  >
    {value}
  </span>
);

export default NotificationSpan;

export const serialize = (obj: any) => {
  const str: Array<any> = [];
  const objKeys = Object.keys(obj);
  objKeys
    .filter((o) => obj[o])
    .forEach((key) => {
      str.push(`${key}=${obj[key]}`);
    });
  return '?' + str.join('&');
};


import React from 'react';

import { Svg } from '../../../atoms';

const FilledEye = () => (
  <Svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 7.54695C8.44463 7.54695 8.87113 7.37029 9.18559 7.05583C9.50005 6.74137 9.67671 6.31487 9.67671 5.87016C9.67671 5.42544 9.50005 4.99894 9.18559 4.68448C8.87113 4.37002 8.44463 4.19336 7.99992 4.19336C7.5552 4.19336 7.1287 4.37002 6.81424 4.68448C6.49978 4.99894 6.32312 5.42544 6.32312 5.87016C6.32312 6.31487 6.49978 6.74137 6.81424 7.05583C7.1287 7.37029 7.5552 7.54695 7.99992 7.54695Z"
      fill="#778CA3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.86879C1.06812 2.46741 4.24565 0 8 0C11.7543 0 14.9319 2.46741 16 5.86879C14.9319 9.27017 11.7543 11.7376 8 11.7376C4.24565 11.7376 1.06812 9.27017 0 5.86879ZM11.3536 5.86879C11.3536 6.75822 11.0003 7.61122 10.3714 8.24014C9.74243 8.86906 8.88943 9.22239 8 9.22239C7.11057 9.22239 6.25757 8.86906 5.62865 8.24014C4.99973 7.61122 4.64641 6.75822 4.64641 5.86879C4.64641 4.97936 4.99973 4.12636 5.62865 3.49744C6.25757 2.86852 7.11057 2.5152 8 2.5152C8.88943 2.5152 9.74243 2.86852 10.3714 3.49744C11.0003 4.12636 11.3536 4.97936 11.3536 5.86879Z"
      fill="#778CA3"
    />
  </Svg>
);

export default FilledEye;

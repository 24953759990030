import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

//components
import PromoteJobCard from './promoteJobCard/PromoteJobCard';
import { Button } from '../../atoms';

//types
import { PromoteJobProps } from './PromoteJob.types';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//enums
import {
  ButtonTypesEnums,
  PromoteJobTypesEnums,
  ButtonSizesEnums,
} from '../../../enums';

const PromoteJob: FC<PromoteJobProps> = ({ closeModal, vacancyId }) => {
  const [plan, setPlan] = useState<PromoteJobTypesEnums>(PromoteJobTypesEnums.EMPTY);
  const { t } = useL10N();
  const { push } = useHistory();

  const onJobPromoteChange = (type: PromoteJobTypesEnums) => {
    setPlan(type);
  };

  const handleSelectPromotedPlan = () => {
    if (plan && plan !== PromoteJobTypesEnums.EMPTY)
      push(`/stripe/subscription?type=${plan}&vacancy_id=${vacancyId}&is_vacancy=${true}`);
    else push('/jobs/published');
    closeModal && closeModal();
  };

  return (
    <div>
      <PromoteJobCard
        title={t('promoteJob.litePlan')}
        type={PromoteJobTypesEnums.LITE}
        price="4.99"
        checked={plan === PromoteJobTypesEnums.LITE}
        onPromoteJobChange={onJobPromoteChange}
        remainingDays="5"
      />
      <PromoteJobCard
        title={t('promoteJob.plusPlan')}
        type={PromoteJobTypesEnums.PLUS}
        price="9.99"
        checked={plan === PromoteJobTypesEnums.PLUS}
        onPromoteJobChange={onJobPromoteChange}
        remainingDays="15"
      />
      <PromoteJobCard
        title={t('promoteJob.proPlan')}
        type={PromoteJobTypesEnums.PRO}
        price="14.99"
        checked={plan === PromoteJobTypesEnums.PRO}
        onPromoteJobChange={onJobPromoteChange}
        remainingDays="30"
      />

      <PromoteJobCard
        title={t('promoteJob.noThanks')}
        type={PromoteJobTypesEnums.EMPTY}
        checked={plan === PromoteJobTypesEnums.EMPTY}
        onPromoteJobChange={onJobPromoteChange}
        remainingDays="30"
      />
      <div className="flex justify-between items-center mt-4">
        <span className="text-tertiary text-xs">2/2</span>
        <Button
          label="Continue"
          disabled={!plan}
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.XL}
          onClick={handleSelectPromotedPlan}
        />
      </div>
    </div>
  );
};

export default PromoteJob;

import { useTokensSubscription } from '../../gen/graphql'

const useApiTokens = ({ company_id }) => {
    const query = useTokensSubscription({ variables: { id: company_id } });

    const { data: { api_tokens = [] } = {}, loading, error } = query;

    return {
        api_tokens,
        loading,
        error
    };
};

export default useApiTokens;
import React, { useState } from 'react';
import { useHistory, useRouteMatch, useLocation, Link } from 'react-router-dom';

//components
import LayoutContainer from '../../../templates/main/LayoutContainer';
import {
  Dot,
  Button,
  Breadcrumbs,
  ThreeDots,
  LetterSvg,
  Skeleton,
  ArrowDown,
  Envelope,
} from '../../../atoms';
import {
  DefaultDropdown,
  JobActionsDropdown,
  CandidateDropdown,
  CustomModal,
  DeleteCandidate,
} from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useApplicant from '../../../../gql/hooks/applicants/useApplicant';
import useApplicantsStages from '../../../../gql/hooks/applicants/useApplicantsStages';
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';

//types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ThreeDotsEnums,
  ModalTypesEnums,
} from '../../../../enums';

//utils
import { findEventName } from '../../../../utils/event/findEventName';
import { findDotColor } from '../../../../utils/findDotColor';
import { splitFullName } from '../../../../utils/splitFullName';

export interface LocationProps {
  prevLoc: { pathname: string; search: string };
}

const CandidateHeader = () => {
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const { replace } = useHistory();
  const { t } = useL10N();

  const { state } = useLocation<LocationProps>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  // graphql
  const { companyId } = useCompanyUser();
  const { result: applicant, loading } = useApplicant({ id });
  const { result: stages } = useApplicantsStages({ companyId });

  const findStage = (stage_id: number) => {
    const dotColor = findDotColor(stages, stage_id);
    const label = findEventName(stages, stage_id) || t('candidate.moveToStage');

    return {
      after: <ArrowDown />,
      label,
      btnSize: ButtonSizesEnums.MD,
      btnType: ButtonTypesEnums.WHITE,
      className: 'candidate__header__buttons--stage',
      before: <Dot style={{ backgroundColor: dotColor }} />,
    };
  };

  const onDeleteCompleted = () => {
    if (state?.prevLoc?.pathname)
      replace(`${state?.prevLoc.pathname}${state?.prevLoc.search}`);
    else if (applicant?.vacancy.id) replace(`/job/${applicant.vacancy.id}/all`);
    else replace('/jobs/all');
  };

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  return (
    <>
      <CustomModal
        open={showDeleteModal}
        closeModal={closeDeleteModal}
        title="Delete"
        type={ModalTypesEnums.XS}
      >
        <DeleteCandidate
          closeModal={closeDeleteModal}
          applicant_ids={[parseInt(id)]}
          onDeleteCompleted={onDeleteCompleted}
        />
      </CustomModal>

      <LayoutContainer>
        <div className="candidate__header">
          <div className="candidate__header__nav">
            {loading ? (
              <Skeleton className="candidate__header--breadcrumbs-loading" />
            ) : (
              <Breadcrumbs
                list={[
                  {
                    name: `${t('candidate.backTo')} ${
                      applicant?.vacancy.title || t('candidate.jobs')
                    }`,
                    to: state?.prevLoc?.pathname
                      ? `${state?.prevLoc.pathname}${state?.prevLoc.search}`
                      : applicant?.vacancy.id
                      ? `/job/${applicant?.vacancy.id}/all`
                      : '/jobs/all',
                  },
                ]}
              />
            )}
          </div>
          <div className="candidate__header__image">
            {loading ? (
              <Skeleton className="w-16 h-16 rounded-full" />
            ) : (
              <LetterSvg
                first={splitFullName(applicant?.name || '')?.firstName || ''}
                second={splitFullName(applicant?.name || '')?.lastName || ''}
              />
            )}
          </div>
          <div className="candidate__header__title">
            {loading ? (
              <Skeleton className="candidate__header__title-loading" />
            ) : (
              <h1>{applicant?.name}</h1>
            )}
          </div>
          {applicant?.id && (
            <>
              <div className="candidate__header__buttons">
                <Link to={`/messages/inbox/new?applicant_ids=${applicant?.id}`}>
                  <Button
                    className="candidate__header__buttons--msg"
                    label={t('candidate.sendMessage')}
                    before={<Envelope />}
                    btnSize={ButtonSizesEnums.MD}
                    btnType={ButtonTypesEnums.GRAY}
                  />
                </Link>
                <DefaultDropdown
                  className="candidate__header__buttons__dropdown"
                  triggerProps={findStage(applicant?.status_id || 1)}
                >
                  <CandidateDropdown applicant_id={applicant.id} />
                </DefaultDropdown>
              </div>
              <div className="candidate__header__dots">
                <DefaultDropdown
                  triggerProps={{
                    after: <ThreeDots type={ThreeDotsEnums.WHITE} />,
                  }}
                >
                  <JobActionsDropdown onDeleteCandidate={openDeleteModal} />
                </DefaultDropdown>
              </div>
            </>
          )}
        </div>
      </LayoutContainer>
    </>
  );
};

export default CandidateHeader;

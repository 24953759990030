import React from 'react';

import { Svg } from '../../..';

const StagesEmptyIcon = () => (
  <Svg
    width="84"
    height="65"
    viewBox="0 0 84 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="42" cy="55" rx="42" ry="10" fill="#EDEFF4" />
    <path
      d="M46 6H69"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M46 18H69"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M46 38H69"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M46 50H69"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M37 1H15V23H37V1Z"
      fill="white"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M37 33H15V55H37V33Z"
      fill="white"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </Svg>
);

export default StagesEmptyIcon;

import React from 'react';

import { Svg } from '../../../atoms';

const HidePassword = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.625 10C0.625 10 4.375 3.125 10 3.125C15.625 3.125 19.375 10 19.375 10C19.375 10 15.625 16.875 10 16.875C4.375 16.875 0.625 10 0.625 10Z"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 10C6.25 7.92875 7.92875 6.25 10 6.25"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 10C13.75 12.0712 12.0712 13.75 10 13.75"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 18.75L18.75 1.25"
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default HidePassword;

import React, { FC } from 'react';

import { DocumentDownload } from '../../atoms';

//types
import { PaymentHistoryItemProps } from './PaymentHistoryItem.types';

//utils
import { generateFormatedDate } from '../../../utils';

const PaymentHistoryItem: FC<PaymentHistoryItemProps> = ({ item }) => (
  <div className="paymentHistory__content paymentHistory__contentItem ">
    <span>{generateFormatedDate(item?.date || '')}</span>
    <span className="paymentHistory__content--invoiceNumber">
      {item?.invoiceNumber}
    </span>
    <span>{item?.ammount}€</span>
    {item?.invoiceUrl ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={item?.invoiceUrl || ''}
        className="paymentHistory__content--attachment"
        download="PDF"
      >
        <DocumentDownload /> PDF
      </a>
    ) : (
      <span className="text-right pr-6">-</span>
    )}
  </div>
);

export default PaymentHistoryItem;

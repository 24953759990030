import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Close: FC<IconProps> = ({ type = 1 }) => {
  const primary = (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L15 15M1 15L15 1L1 15Z"
        stroke="#778CA3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
  const secondary = (
    <Svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.243987 0.754405C0.400259 0.59818 0.612181 0.510418 0.83315 0.510418C1.05412 0.510418 1.26604 0.59818 1.42231 0.754405L4.99979 4.33188L8.57727 0.754405C8.65414 0.674814 8.74609 0.611329 8.84776 0.567655C8.94943 0.523981 9.05878 0.500993 9.16943 0.500031C9.28008 0.49907 9.38981 0.520154 9.49222 0.562055C9.59464 0.603955 9.68768 0.665833 9.76592 0.744076C9.84417 0.822319 9.90604 0.915362 9.94794 1.01778C9.98985 1.12019 10.0109 1.22992 10.01 1.34057C10.009 1.45122 9.98602 1.56057 9.94234 1.66224C9.89867 1.76391 9.83519 1.85586 9.75559 1.93273L6.17812 5.51021L9.75559 9.08769C9.90739 9.24485 9.99139 9.45535 9.98949 9.67385C9.98759 9.89235 9.89995 10.1014 9.74544 10.2559C9.59094 10.4104 9.38193 10.498 9.16343 10.4999C8.94494 10.5018 8.73444 10.4178 8.57727 10.266L4.99979 6.68853L1.42231 10.266C1.26515 10.4178 1.05464 10.5018 0.836149 10.4999C0.617653 10.498 0.408644 10.4104 0.254138 10.2559C0.0996325 10.1014 0.0119921 9.89235 0.0100934 9.67385C0.00819472 9.45535 0.0921899 9.24485 0.243987 9.08769L3.82147 5.51021L0.243987 1.93273C0.0877624 1.77646 0 1.56454 0 1.34357C0 1.1226 0.0877624 0.910677 0.243987 0.754405Z"
        fill="white"
      />
    </Svg>
  );
  return type === 1 ? primary : secondary;
};

export default Close;

import React, { useState, FC } from 'react';
import { SketchPicker } from 'react-color';

//components
import { Button, Label } from '../../../atoms';

//types
import { ColorPickerProps } from './ColorPicker.types';

//enums
import { ButtonSizesEnums } from '../../../../enums';

const ColorPickerSelect: FC<ColorPickerProps> = ({ labelProps, color, handleChangeColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChangeComplete = (color) => {
    handleChangeColor(color.hex);
    setDisplayColorPicker(false);
  };

  return (
    <>
      <div className="colorPicker">
        <Label {...labelProps} className="mb-2" />
        <Button
          label={color}
          className="colorPicker__holder"
          btnSize={ButtonSizesEnums.SM}
          onClick={() => {
            setDisplayColorPicker(!displayColorPicker);
          }}
          before={<div className={`box__default`} style={{ backgroundColor: color }} />}
        />
        {displayColorPicker && (
          <div className="colorPicker__colors">
            <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
          </div>
        )}
      </div>
    </>
  );
};

export default ColorPickerSelect;

import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from '../../../utils/auth/getAuthToken';
import { validateAuthToken } from '../../../utils/auth/validateAuthToken';

export const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  if (token && validateAuthToken(token)) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return headers;
});

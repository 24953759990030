import React from 'react';

import { Svg } from '../../../atoms';

const Candidates = () => (
  <Svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M8 3C8 3.79565 7.68393 4.55871 7.12132 5.12132C6.55871 5.68393 5.79565 6 5 6C4.20435 6 3.44129 5.68393 2.87868 5.12132C2.31607 4.55871 2 3.79565 2 3C2 2.20435 2.31607 1.44129 2.87868 0.87868C3.44129 0.316071 4.20435 0 5 0C5.79565 0 6.55871 0.316071 7.12132 0.87868C7.68393 1.44129 8 2.20435 8 3ZM16 3C16 3.39397 15.9224 3.78407 15.7716 4.14805C15.6209 4.51203 15.3999 4.84274 15.1213 5.12132C14.8427 5.3999 14.512 5.62087 14.1481 5.77164C13.7841 5.9224 13.394 6 13 6C12.606 6 12.2159 5.9224 11.8519 5.77164C11.488 5.62087 11.1573 5.3999 10.8787 5.12132C10.6001 4.84274 10.3791 4.51203 10.2284 4.14805C10.0776 3.78407 10 3.39397 10 3C10 2.20435 10.3161 1.44129 10.8787 0.87868C11.4413 0.316071 12.2044 0 13 0C13.7956 0 14.5587 0.316071 15.1213 0.87868C15.6839 1.44129 16 2.20435 16 3ZM11.93 14C11.976 13.673 12 13.34 12 13C12.0023 11.4289 11.4737 9.90302 10.5 8.67C11.2601 8.23116 12.1223 8.00013 13 8.00013C13.8776 8.00012 14.7399 8.23114 15.4999 8.66997C16.26 9.1088 16.8912 9.73997 17.3301 10.5C17.7689 11.2601 18 12.1223 18 13V14H11.93ZM5 8C6.32608 8 7.59785 8.52678 8.53553 9.46447C9.47322 10.4021 10 11.6739 10 13V14H0V13C0 11.6739 0.526784 10.4021 1.46447 9.46447C2.40215 8.52678 3.67392 8 5 8Z"
      fill="#778CA3"
    />
  </Svg>
);

export default Candidates;

import { LinkTypesEnums, ApplicantLinkTypesEnums } from '../enums';

const fbRegExp = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/;
const twRegExp = /^(https?:\/\/){0,1}(www\.){0,1}twitter\.com/;
const inRegExp = /^(https?:\/\/){0,1}(www\.){0,1}linkedin\.com/;
const instaRegExp = /^(https?:\/\/){0,1}(www\.){0,1}instagram\.com/;
const gitRegExp = /^(https?:\/\/){0,1}(www\.){0,1}github\.com/;


export const validateLinks = (link: string) => {
    if (link?.match(fbRegExp)) {
        return { linkType: LinkTypesEnums.FACEBOOK, name: 'Facebook', type: ApplicantLinkTypesEnums.SOCIAL_MEDIA_LINKS, link }
    }
    if (link?.match(twRegExp)) {
        return { linkType: LinkTypesEnums.TWITTER, name: 'Twitter', type: ApplicantLinkTypesEnums.SOCIAL_MEDIA_LINKS, link }
    }
    if (link?.match(inRegExp)) {
        return { linkType: LinkTypesEnums.LINKEDIN, name: 'LinkedIn', type: ApplicantLinkTypesEnums.SOCIAL_MEDIA_LINKS, link }
    }
    if (link?.match(instaRegExp)) {
        return { linkType: LinkTypesEnums.INSTAGRAM, name: 'Instagram', type: ApplicantLinkTypesEnums.SOCIAL_MEDIA_LINKS, link }
    }
    if (link?.match(gitRegExp)) {
        return { linkType: LinkTypesEnums.GITHUB, name: 'GitHub', type: ApplicantLinkTypesEnums.PERSONAL_LINKS, link }
    }
    else return { linkType: LinkTypesEnums.WEBSITE, name: 'Website', type: ApplicantLinkTypesEnums.PERSONAL_LINKS, link }
};

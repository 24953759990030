import React, { FC, useState } from 'react';

//components
import { CarouselTrigger } from '../../atoms';
import { CarouselItem } from '..';

//types
import { CarouselProps } from './Carousel.types';

const Carousel: FC<CarouselProps> = ({ items }) => {
  const [active, setActive] = useState<number>(0);

  const handleOnTrigger = (id: number) => setActive(id);

  return (
    <div className="carousel">
      <CarouselItem item={items[active]} />
      <div className="carousel__triggers">
        <CarouselTrigger id="0" onClick={handleOnTrigger} active={active} />
        <CarouselTrigger id="1" onClick={handleOnTrigger} active={active} />
        <CarouselTrigger id="2" onClick={handleOnTrigger} active={active} />
        <CarouselTrigger id="3" onClick={handleOnTrigger} active={active} />
      </div>
    </div>
  );
};

export default Carousel;

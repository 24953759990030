// react
import { useMemo } from 'react';

// hooks
import useWindowSize from './useWindowSize';

// utils
import { range } from '../utils/index';

// types
interface PaginationProps {
  // Represents the maximum data that is visible in a single page.
  itemsPerPage: number;
  // Represents the total count of data available from the source.
  numberOfItems: number;
  // Represents the current active page. We'll use a 1-based index instead of a traditional 0-based index for our currentPage value.
  currentPage: number;
  // (optional) Represents the min number of page buttons to be shown on each side of the current page button. Defaults to 1.
  siblingCount?: number;
}

export const usePagination = ({
  itemsPerPage,
  numberOfItems,
  currentPage,
  siblingCount = 1,
}: PaginationProps): (string | number)[] => {
  // hooks
  const { width } = useWindowSize();

  // constants
  const DOTS = '...';
  const isMobile = width && width < 740;

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(numberOfItems / itemsPerPage);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 
      1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = isMobile ? leftSiblingIndex > 3 : leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  }, [itemsPerPage, numberOfItems, currentPage, siblingCount, isMobile]);

  return paginationRange;
};

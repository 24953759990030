import React, { ChangeEvent } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

//components
import { LayoutContainer } from '../../../templates';
import {
  Breadcrumbs,
  Search,
  Skeleton,
  Bag,
  Candidates,
  Clock,
  Location,
} from '../../../atoms';
import { CardElements, FieldInput, JobHeaderActions } from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//enums
import { InputTypesEnums, VacaniesTypesEnums } from '../../../../enums';

//gql
import useVacancy from '../../../../gql/hooks/vacancies/useVacancy';

//utils
import {
  parseQueryString,
  serialize,
  generateFormatedDate,
} from '../../../../utils';

export interface MatchParams {
  id: string;
}

const JobHeader = () => {
  const {
    push,
    replace,
    location: { search },
  } = useHistory();
  const { t } = useL10N();

  const { params } = useRouteMatch<MatchParams>();
  const { query, ...restQuery } = parseQueryString(search);

  const { result, loading } = useVacancy({ id: params.id });

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.value.length > 1
      ? replace(serialize({ ...restQuery, query: event.target.value }))
      : push(serialize({ ...restQuery, query: event.target.value }));

  return (
    <LayoutContainer>
      <div className="job__header">
        {loading ? (
          <Skeleton className="job__header-breadcrumbsLoading" />
        ) : (
          <Breadcrumbs
            list={[
              { name: 'Jobs', to: '/jobs/all' },
              { ...(result?.title && { name: result.title }) },
            ]}
          />
        )}
        <div className="items-start job__header__content md:items-center">
          {loading ? (
            <Skeleton className="job__header__content--title-loading" />
          ) : (
            <h1 className="job__header__content--title">{result?.title}</h1>
          )}
          <JobHeaderActions
            vacancySlug={result?.slug || ''}
            vacancyId={params.id}
          />
        </div>
        <div className="job__header__elements">
          {loading ? (
            <Skeleton className="job__header__elements-loading" />
          ) : (
            <CardElements
              elements={[
                {
                  value: `${
                    result?.applicants_count?.aggregate?.count || 0
                  } ${t('jobs.candidates')}`,
                  Icon: <Candidates />,
                },
                {
                  ...(result?.type && {
                    value: VacaniesTypesEnums[result.type],
                    Icon: <Bag />,
                  }),
                },
                {
                  ...(result?.deadline && {
                    value: `${t('jobs.closingOn')} ${generateFormatedDate(
                      result.deadline
                    )}`,
                    Icon: <Clock />,
                  }),
                },
                // { value: '800€ - 1200€', Icon: <EuroSign /> },
                {
                  ...(result?.location && {
                    value: result.location,
                    Icon: <Location type={2} />,
                  }),
                },
              ]}
            />
          )}
        </div>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          <FieldInput
            before={<Search />}
            inputType={InputTypesEnums.ROUNDED_FULL}
            inputProps={{
              value: query || '',
              onChange: onChange,
              name: 'search',
              placeholder: t('job.searchPlaceholder'),
            }}
          />
        </form>
      </div>
    </LayoutContainer>
  );
};

export default JobHeader;

import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Share: FC<IconProps> = ({ type = 1 }) => {
  const primary = (
    <Svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 5L9 1M9 1L5 5M9 1V13"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
  const secondary = (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9999 6C13.6091 6.00002 14.2038 5.8146 14.705 5.4684C15.2062 5.1222 15.5902 4.63162 15.8058 4.06191C16.0214 3.49219 16.0585 2.87034 15.9121 2.27904C15.7657 1.68774 15.4428 1.15501 14.9863 0.751702C14.5297 0.348394 13.9613 0.0936156 13.3564 0.0212481C12.7516 -0.0511194 12.139 0.0623529 11.6003 0.346575C11.0615 0.630798 10.622 1.0723 10.3402 1.61238C10.0585 2.15245 9.9478 2.7655 10.0229 3.37L5.08293 5.84C4.65916 5.43136 4.12453 5.15642 3.54562 5.04945C2.96672 4.94247 2.36914 5.00819 1.82731 5.2384C1.28549 5.46862 0.823377 5.85317 0.498537 6.34414C0.173697 6.83511 0.000488281 7.4108 0.000488281 7.9995C0.000488281 8.58821 0.173697 9.1639 0.498537 9.65487C0.823377 10.1458 1.28549 10.5304 1.82731 10.7606C2.36914 10.9908 2.96672 11.0565 3.54562 10.9496C4.12453 10.8426 4.65916 10.5676 5.08293 10.159L10.0229 12.629C9.93543 13.3312 10.099 14.0418 10.4847 14.6351C10.8704 15.2284 11.4535 15.6663 12.1308 15.8713C12.8081 16.0763 13.5361 16.0353 14.1861 15.7555C14.8361 15.4757 15.3663 14.9751 15.6829 14.3422C15.9995 13.7093 16.0822 12.9849 15.9164 12.2969C15.7505 11.6089 15.3468 11.0017 14.7766 10.5826C14.2064 10.1635 13.5064 9.95951 12.8003 10.0066C12.0942 10.0537 11.4274 10.3489 10.9179 10.84L5.97793 8.37C6.00819 8.12426 6.00819 7.87574 5.97793 7.63L10.9179 5.16C11.4559 5.68 12.1899 6 12.9999 6Z"
        fill="#778CA3"
      />
    </Svg>
  );
  const tertiary = (
    <Svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.125C0 12.8929 0.0921884 12.6704 0.256285 12.5063C0.420381 12.3422 0.642944 12.25 0.875012 12.25H11.3752C11.6072 12.25 11.8298 12.3422 11.9939 12.5063C12.158 12.6704 12.2502 12.8929 12.2502 13.125C12.2502 13.3571 12.158 13.5796 11.9939 13.7437C11.8298 13.9078 11.6072 14 11.3752 14H0.875012C0.642944 14 0.420381 13.9078 0.256285 13.7437C0.0921884 13.5796 0 13.3571 0 13.125ZM2.88141 4.11849C2.71737 3.9544 2.62522 3.73188 2.62522 3.49986C2.62522 3.26784 2.71737 3.04532 2.88141 2.88123L5.50645 0.256191C5.67054 0.0921521 5.89306 0 6.12508 0C6.3571 0 6.57963 0.0921521 6.74372 0.256191L9.36875 2.88123C9.52814 3.04626 9.61634 3.26729 9.61434 3.49671C9.61235 3.72614 9.52033 3.9456 9.35809 4.10783C9.19586 4.27007 8.97639 4.36209 8.74697 4.36409C8.51754 4.36608 8.29651 4.27788 8.13148 4.11849L7.00009 2.9871V9.62494C7.00009 9.85701 6.90791 10.0796 6.74381 10.2437C6.57971 10.4078 6.35715 10.5 6.12508 10.5C5.89301 10.5 5.67045 10.4078 5.50636 10.2437C5.34226 10.0796 5.25007 9.85701 5.25007 9.62494V2.9871L4.11868 4.11849C3.95459 4.28253 3.73207 4.37468 3.50005 4.37468C3.26803 4.37468 3.0455 4.28253 2.88141 4.11849Z"
        fill="#ADBAC8"
      />
    </Svg>
  );
  return type === 1 ? primary : type === 2 ? secondary : tertiary;
};

export default Share;

import React, { FC } from 'react';
import cx from 'classnames';

//components
import { Button, Dropdown, ArrowDown } from '../../atoms';
import MessagesFormDropdown from './MessagesFormDropdown';

//hooks
import { useL10N } from '../../../hooks/useL10N';
import useDropdown from '../../../hooks/useDropdown';

import { MessagesFilterDropdownProps } from './MessagesFilterDropdown.types';
import { MessageTypes } from '../../../enums/MessaageTypes.enum';

const MessagesFilterDropdown: FC<MessagesFilterDropdownProps> = ({ label }) => {
  const { open, onMenuClick, closeMenu } = useDropdown();
  const { t } = useL10N();

  const readType = Object.values(MessageTypes).find((type) => type === label);

  return (
    <Dropdown
      className="filter__dropdown z-30 w-40"
      open={open}
      closeMenu={closeMenu}
      trigger={
        <Button
          label={readType ? t(`messages.${readType}`) : t('messages.all')}
          className={cx('filter__dropdown__btn', {
            'filter__dropdown__btn--close': !open,
          })}
          onClick={onMenuClick}
          after={<ArrowDown active={open} />}
        />
      }
    >
      <div className="default__dropdown__messages-filter">
        <MessagesFormDropdown handleCloseMenu={closeMenu} />
      </div>
    </Dropdown>
  );
};

export default MessagesFilterDropdown;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthRightSide from '../components/organisms/auth/AuthRightSide';
import { AuthFormContainer } from '../components/templates';
import withAuth from '../hoc/withAuth';
import { Login, Register, ForgotPassword, ResetPassword } from '../pages';

const AuthRoutes = () => (
  <div className="flex">
    <AuthFormContainer>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/auth/register" component={Register} />
        <Route exact path="/auth/password/forgot" component={ForgotPassword} />
        <Route
          exact
          path="/auth/password/reset/:token"
          component={ResetPassword}
        />
      </Switch>
    </AuthFormContainer>
    <AuthRightSide />
  </div>
);

export default withAuth(AuthRoutes, false);

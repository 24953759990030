import React from 'react';

//icons
import { MessageEmpty } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

const EmptyMessage = () => {
  const { t } = useL10N();

  return (
    <div className="flex flex-col items-center justify-center mt-20 mb-20">
      <MessageEmpty />
      <p className="mt-4 mb-4 text-secondary text-sm">
        {t('dashboard.messagesEmpty')}
      </p>
    </div>
  );
};

export default EmptyMessage;

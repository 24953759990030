import React, { FC } from 'react';

//types
import { SettingsTemplateEmptyProps } from './SettingsTemplateEmpty.types';

const SettingsTemplateEmpty: FC<SettingsTemplateEmptyProps> = ({
  children,
  title,
  icon,
}) => (
  <div className="template__empty">
    {icon}
    <p className="template__empty--title">{title}</p>
    {children}
  </div>
);

export default SettingsTemplateEmpty;

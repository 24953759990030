import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  float8: any;
  json: any;
  jsonb: any;
  time: any;
  timestamptz: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CheckoutSessionInput = {
  company_id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Scalars['String']>;
  price_id?: Maybe<Scalars['String']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

export type CompanyProfileInput = {
  company_id: Scalars['Int'];
  file_store_id?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type DeletefileResponse = {
  __typename?: 'DeletefileResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type EmailResetResponse = {
  __typename?: 'EmailResetResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type ForgotPasswordRequestResponse = {
  __typename?: 'ForgotPasswordRequestResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type ForgotPasswordResetResponse = {
  __typename?: 'ForgotPasswordResetResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type GenerateApiTokenResponse = {
  __typename?: 'GenerateApiTokenResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type InviteTeammateResponse = {
  __typename?: 'InviteTeammateResponse';
  id?: Maybe<Scalars['Int']>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type JobPostingDataResponse = {
  __typename?: 'JobPostingDataResponse';
  detailed_jobs_data?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobs_data?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
  total_applicants: Scalars['Int'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type RevertEmailResetResponse = {
  __typename?: 'RevertEmailResetResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type SendMessageInput = {
  applicant_id?: Maybe<Array<Scalars['Int']>>;
  company_id: Scalars['Int'];
  message: Scalars['String'];
  message_id?: Maybe<Scalars['Int']>;
  subject: Scalars['String'];
};

export type SendMessageResponse = {
  __typename?: 'SendMessageResponse';
  applicant_id?: Maybe<Scalars['Int']>;
  message_applicant_id?: Maybe<Scalars['Int']>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type StripeCancelSubscriptionResponse = {
  __typename?: 'StripeCancelSubscriptionResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type StripeCheckoutSessionResponse = {
  __typename?: 'StripeCheckoutSessionResponse';
  sessionId?: Maybe<Scalars['String']>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type StripeCustomerPortalResponse = {
  __typename?: 'StripeCustomerPortalResponse';
  customer?: Maybe<Scalars['String']>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type UnsubscribeResponse = {
  __typename?: 'UnsubscribeResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type UpdateEmailResponse = {
  __typename?: 'UpdateEmailResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type UpdateProfileResponse = {
  __typename?: 'UpdateProfileResponse';
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
};

export type UserInput = {
  company_image_id?: Maybe<Scalars['Int']>;
  company_name: Scalars['String'];
  email: Scalars['String'];
  industry: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  user_image_id?: Maybe<Scalars['Int']>;
};

export type UserProfileInput = {
  file_store_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  new_password?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type VacanciesInput = {
  company_id: Scalars['Int'];
  deadline?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  is_NotifyOn: Scalars['Boolean'];
  is_shown?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subscriber_notified_at?: Maybe<Scalars['Date']>;
  task?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

export type VacanciesResponse = {
  __typename?: 'VacanciesResponse';
  is_shown?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
  statusCode: Scalars['String'];
  statusIsOk: Scalars['Boolean'];
  statusMessage: Scalars['String'];
  statusPath: Scalars['String'];
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "activity_log_applicants" */
export type Activity_Log_Applicants = {
  __typename?: 'activity_log_applicants';
  /** An object relationship */
  activity_log_event: Activity_Log_Events;
  /** An object relationship */
  applicant?: Maybe<Applicants>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['json']>;
  event_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "activity_log_applicants" */
export type Activity_Log_ApplicantsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "activity_log_applicants" */
export type Activity_Log_Applicants_Aggregate = {
  __typename?: 'activity_log_applicants_aggregate';
  aggregate?: Maybe<Activity_Log_Applicants_Aggregate_Fields>;
  nodes: Array<Activity_Log_Applicants>;
};

/** aggregate fields of "activity_log_applicants" */
export type Activity_Log_Applicants_Aggregate_Fields = {
  __typename?: 'activity_log_applicants_aggregate_fields';
  avg?: Maybe<Activity_Log_Applicants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Activity_Log_Applicants_Max_Fields>;
  min?: Maybe<Activity_Log_Applicants_Min_Fields>;
  stddev?: Maybe<Activity_Log_Applicants_Stddev_Fields>;
  stddev_pop?: Maybe<Activity_Log_Applicants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Activity_Log_Applicants_Stddev_Samp_Fields>;
  sum?: Maybe<Activity_Log_Applicants_Sum_Fields>;
  var_pop?: Maybe<Activity_Log_Applicants_Var_Pop_Fields>;
  var_samp?: Maybe<Activity_Log_Applicants_Var_Samp_Fields>;
  variance?: Maybe<Activity_Log_Applicants_Variance_Fields>;
};


/** aggregate fields of "activity_log_applicants" */
export type Activity_Log_Applicants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "activity_log_applicants" */
export type Activity_Log_Applicants_Aggregate_Order_By = {
  avg?: Maybe<Activity_Log_Applicants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Activity_Log_Applicants_Max_Order_By>;
  min?: Maybe<Activity_Log_Applicants_Min_Order_By>;
  stddev?: Maybe<Activity_Log_Applicants_Stddev_Order_By>;
  stddev_pop?: Maybe<Activity_Log_Applicants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Activity_Log_Applicants_Stddev_Samp_Order_By>;
  sum?: Maybe<Activity_Log_Applicants_Sum_Order_By>;
  var_pop?: Maybe<Activity_Log_Applicants_Var_Pop_Order_By>;
  var_samp?: Maybe<Activity_Log_Applicants_Var_Samp_Order_By>;
  variance?: Maybe<Activity_Log_Applicants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "activity_log_applicants" */
export type Activity_Log_Applicants_Arr_Rel_Insert_Input = {
  data: Array<Activity_Log_Applicants_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Activity_Log_Applicants_On_Conflict>;
};

/** aggregate avg on columns */
export type Activity_Log_Applicants_Avg_Fields = {
  __typename?: 'activity_log_applicants_avg_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Avg_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "activity_log_applicants". All fields are combined with a logical 'AND'. */
export type Activity_Log_Applicants_Bool_Exp = {
  _and?: Maybe<Array<Activity_Log_Applicants_Bool_Exp>>;
  _not?: Maybe<Activity_Log_Applicants_Bool_Exp>;
  _or?: Maybe<Array<Activity_Log_Applicants_Bool_Exp>>;
  activity_log_event?: Maybe<Activity_Log_Events_Bool_Exp>;
  applicant?: Maybe<Applicants_Bool_Exp>;
  applicant_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Json_Comparison_Exp>;
  event_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "activity_log_applicants" */
export enum Activity_Log_Applicants_Constraint {
  /** unique or primary key constraint */
  ActivitiesPkey = 'activities_pkey'
}

/** input type for incrementing numeric columns in table "activity_log_applicants" */
export type Activity_Log_Applicants_Inc_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "activity_log_applicants" */
export type Activity_Log_Applicants_Insert_Input = {
  activity_log_event?: Maybe<Activity_Log_Events_Obj_Rel_Insert_Input>;
  applicant?: Maybe<Applicants_Obj_Rel_Insert_Input>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['json']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Activity_Log_Applicants_Max_Fields = {
  __typename?: 'activity_log_applicants_max_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Max_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Activity_Log_Applicants_Min_Fields = {
  __typename?: 'activity_log_applicants_min_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Min_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "activity_log_applicants" */
export type Activity_Log_Applicants_Mutation_Response = {
  __typename?: 'activity_log_applicants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Activity_Log_Applicants>;
};

/** on conflict condition type for table "activity_log_applicants" */
export type Activity_Log_Applicants_On_Conflict = {
  constraint: Activity_Log_Applicants_Constraint;
  update_columns?: Array<Activity_Log_Applicants_Update_Column>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};

/** Ordering options when selecting data from "activity_log_applicants". */
export type Activity_Log_Applicants_Order_By = {
  activity_log_event?: Maybe<Activity_Log_Events_Order_By>;
  applicant?: Maybe<Applicants_Order_By>;
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: activity_log_applicants */
export type Activity_Log_Applicants_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "activity_log_applicants" */
export enum Activity_Log_Applicants_Select_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "activity_log_applicants" */
export type Activity_Log_Applicants_Set_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['json']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Activity_Log_Applicants_Stddev_Fields = {
  __typename?: 'activity_log_applicants_stddev_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Stddev_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Activity_Log_Applicants_Stddev_Pop_Fields = {
  __typename?: 'activity_log_applicants_stddev_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Stddev_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Activity_Log_Applicants_Stddev_Samp_Fields = {
  __typename?: 'activity_log_applicants_stddev_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Stddev_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Activity_Log_Applicants_Sum_Fields = {
  __typename?: 'activity_log_applicants_sum_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Sum_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "activity_log_applicants" */
export enum Activity_Log_Applicants_Update_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Activity_Log_Applicants_Var_Pop_Fields = {
  __typename?: 'activity_log_applicants_var_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Var_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Activity_Log_Applicants_Var_Samp_Fields = {
  __typename?: 'activity_log_applicants_var_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Var_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Activity_Log_Applicants_Variance_Fields = {
  __typename?: 'activity_log_applicants_variance_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "activity_log_applicants" */
export type Activity_Log_Applicants_Variance_Order_By = {
  applicant_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "activity_log_events" */
export type Activity_Log_Events = {
  __typename?: 'activity_log_events';
  /** An array relationship */
  activity_log_applicants: Array<Activity_Log_Applicants>;
  /** An aggregate relationship */
  activity_log_applicants_aggregate: Activity_Log_Applicants_Aggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  event: Scalars['String'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "activity_log_events" */
export type Activity_Log_EventsActivity_Log_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


/** columns and relationships of "activity_log_events" */
export type Activity_Log_EventsActivity_Log_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};

/** aggregated selection of "activity_log_events" */
export type Activity_Log_Events_Aggregate = {
  __typename?: 'activity_log_events_aggregate';
  aggregate?: Maybe<Activity_Log_Events_Aggregate_Fields>;
  nodes: Array<Activity_Log_Events>;
};

/** aggregate fields of "activity_log_events" */
export type Activity_Log_Events_Aggregate_Fields = {
  __typename?: 'activity_log_events_aggregate_fields';
  avg?: Maybe<Activity_Log_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Activity_Log_Events_Max_Fields>;
  min?: Maybe<Activity_Log_Events_Min_Fields>;
  stddev?: Maybe<Activity_Log_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Activity_Log_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Activity_Log_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Activity_Log_Events_Sum_Fields>;
  var_pop?: Maybe<Activity_Log_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Activity_Log_Events_Var_Samp_Fields>;
  variance?: Maybe<Activity_Log_Events_Variance_Fields>;
};


/** aggregate fields of "activity_log_events" */
export type Activity_Log_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Activity_Log_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Activity_Log_Events_Avg_Fields = {
  __typename?: 'activity_log_events_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "activity_log_events". All fields are combined with a logical 'AND'. */
export type Activity_Log_Events_Bool_Exp = {
  _and?: Maybe<Array<Activity_Log_Events_Bool_Exp>>;
  _not?: Maybe<Activity_Log_Events_Bool_Exp>;
  _or?: Maybe<Array<Activity_Log_Events_Bool_Exp>>;
  activity_log_applicants?: Maybe<Activity_Log_Applicants_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  event?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "activity_log_events" */
export enum Activity_Log_Events_Constraint {
  /** unique or primary key constraint */
  ActivityLogEventsPkey = 'activity_log_events_pkey'
}

/** input type for incrementing numeric columns in table "activity_log_events" */
export type Activity_Log_Events_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "activity_log_events" */
export type Activity_Log_Events_Insert_Input = {
  activity_log_applicants?: Maybe<Activity_Log_Applicants_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Activity_Log_Events_Max_Fields = {
  __typename?: 'activity_log_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Activity_Log_Events_Min_Fields = {
  __typename?: 'activity_log_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "activity_log_events" */
export type Activity_Log_Events_Mutation_Response = {
  __typename?: 'activity_log_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Activity_Log_Events>;
};

/** input type for inserting object relation for remote table "activity_log_events" */
export type Activity_Log_Events_Obj_Rel_Insert_Input = {
  data: Activity_Log_Events_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Activity_Log_Events_On_Conflict>;
};

/** on conflict condition type for table "activity_log_events" */
export type Activity_Log_Events_On_Conflict = {
  constraint: Activity_Log_Events_Constraint;
  update_columns?: Array<Activity_Log_Events_Update_Column>;
  where?: Maybe<Activity_Log_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "activity_log_events". */
export type Activity_Log_Events_Order_By = {
  activity_log_applicants_aggregate?: Maybe<Activity_Log_Applicants_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: activity_log_events */
export type Activity_Log_Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "activity_log_events" */
export enum Activity_Log_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "activity_log_events" */
export type Activity_Log_Events_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Activity_Log_Events_Stddev_Fields = {
  __typename?: 'activity_log_events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Activity_Log_Events_Stddev_Pop_Fields = {
  __typename?: 'activity_log_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Activity_Log_Events_Stddev_Samp_Fields = {
  __typename?: 'activity_log_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Activity_Log_Events_Sum_Fields = {
  __typename?: 'activity_log_events_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "activity_log_events" */
export enum Activity_Log_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Activity_Log_Events_Var_Pop_Fields = {
  __typename?: 'activity_log_events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Activity_Log_Events_Var_Samp_Fields = {
  __typename?: 'activity_log_events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Activity_Log_Events_Variance_Fields = {
  __typename?: 'activity_log_events_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "api_tokens" */
export type Api_Tokens = {
  __typename?: 'api_tokens';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "api_tokens" */
export type Api_Tokens_Aggregate = {
  __typename?: 'api_tokens_aggregate';
  aggregate?: Maybe<Api_Tokens_Aggregate_Fields>;
  nodes: Array<Api_Tokens>;
};

/** aggregate fields of "api_tokens" */
export type Api_Tokens_Aggregate_Fields = {
  __typename?: 'api_tokens_aggregate_fields';
  avg?: Maybe<Api_Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Api_Tokens_Max_Fields>;
  min?: Maybe<Api_Tokens_Min_Fields>;
  stddev?: Maybe<Api_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Api_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Api_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Api_Tokens_Sum_Fields>;
  var_pop?: Maybe<Api_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Api_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Api_Tokens_Variance_Fields>;
};


/** aggregate fields of "api_tokens" */
export type Api_Tokens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Api_Tokens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "api_tokens" */
export type Api_Tokens_Aggregate_Order_By = {
  avg?: Maybe<Api_Tokens_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Api_Tokens_Max_Order_By>;
  min?: Maybe<Api_Tokens_Min_Order_By>;
  stddev?: Maybe<Api_Tokens_Stddev_Order_By>;
  stddev_pop?: Maybe<Api_Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Api_Tokens_Stddev_Samp_Order_By>;
  sum?: Maybe<Api_Tokens_Sum_Order_By>;
  var_pop?: Maybe<Api_Tokens_Var_Pop_Order_By>;
  var_samp?: Maybe<Api_Tokens_Var_Samp_Order_By>;
  variance?: Maybe<Api_Tokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "api_tokens" */
export type Api_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Api_Tokens_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Api_Tokens_On_Conflict>;
};

/** aggregate avg on columns */
export type Api_Tokens_Avg_Fields = {
  __typename?: 'api_tokens_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "api_tokens" */
export type Api_Tokens_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "api_tokens". All fields are combined with a logical 'AND'. */
export type Api_Tokens_Bool_Exp = {
  _and?: Maybe<Array<Api_Tokens_Bool_Exp>>;
  _not?: Maybe<Api_Tokens_Bool_Exp>;
  _or?: Maybe<Array<Api_Tokens_Bool_Exp>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "api_tokens" */
export enum Api_Tokens_Constraint {
  /** unique or primary key constraint */
  ApiTokensPkey = 'api_tokens_pkey'
}

/** input type for incrementing numeric columns in table "api_tokens" */
export type Api_Tokens_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "api_tokens" */
export type Api_Tokens_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Api_Tokens_Max_Fields = {
  __typename?: 'api_tokens_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "api_tokens" */
export type Api_Tokens_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Api_Tokens_Min_Fields = {
  __typename?: 'api_tokens_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "api_tokens" */
export type Api_Tokens_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "api_tokens" */
export type Api_Tokens_Mutation_Response = {
  __typename?: 'api_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Api_Tokens>;
};

/** on conflict condition type for table "api_tokens" */
export type Api_Tokens_On_Conflict = {
  constraint: Api_Tokens_Constraint;
  update_columns?: Array<Api_Tokens_Update_Column>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "api_tokens". */
export type Api_Tokens_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: api_tokens */
export type Api_Tokens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "api_tokens" */
export enum Api_Tokens_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "api_tokens" */
export type Api_Tokens_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Api_Tokens_Stddev_Fields = {
  __typename?: 'api_tokens_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "api_tokens" */
export type Api_Tokens_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Api_Tokens_Stddev_Pop_Fields = {
  __typename?: 'api_tokens_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "api_tokens" */
export type Api_Tokens_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Api_Tokens_Stddev_Samp_Fields = {
  __typename?: 'api_tokens_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "api_tokens" */
export type Api_Tokens_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Api_Tokens_Sum_Fields = {
  __typename?: 'api_tokens_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "api_tokens" */
export type Api_Tokens_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "api_tokens" */
export enum Api_Tokens_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Api_Tokens_Var_Pop_Fields = {
  __typename?: 'api_tokens_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "api_tokens" */
export type Api_Tokens_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Api_Tokens_Var_Samp_Fields = {
  __typename?: 'api_tokens_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "api_tokens" */
export type Api_Tokens_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Api_Tokens_Variance_Fields = {
  __typename?: 'api_tokens_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "api_tokens" */
export type Api_Tokens_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "applicants" */
export type Applicants = {
  __typename?: 'applicants';
  /** An array relationship */
  activities: Array<Activity_Log_Applicants>;
  /** An aggregate relationship */
  activities_aggregate: Activity_Log_Applicants_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** An object relationship */
  file_store?: Maybe<File_Stores>;
  file_store_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  inbound_identifier?: Maybe<Scalars['String']>;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  /** An array relationship */
  message_applicants: Array<Message_Applicants>;
  /** An aggregate relationship */
  message_applicants_aggregate: Message_Applicants_Aggregate;
  /** An array relationship */
  message_replies: Array<Message_Replies>;
  /** An aggregate relationship */
  message_replies_aggregate: Message_Replies_Aggregate;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  phone: Scalars['String'];
  /** An object relationship */
  stage?: Maybe<Stages>;
  status_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vacancy: Vacancies;
  vacancy_id: Scalars['Int'];
};


/** columns and relationships of "applicants" */
export type ApplicantsActivitiesArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsLinksArgs = {
  distinct_on?: Maybe<Array<Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Links_Order_By>>;
  where?: Maybe<Links_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsLinks_AggregateArgs = {
  distinct_on?: Maybe<Array<Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Links_Order_By>>;
  where?: Maybe<Links_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsMessage_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsMessage_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsMessage_RepliesArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsMessage_Replies_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "applicants" */
export type ApplicantsNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};

/** aggregated selection of "applicants" */
export type Applicants_Aggregate = {
  __typename?: 'applicants_aggregate';
  aggregate?: Maybe<Applicants_Aggregate_Fields>;
  nodes: Array<Applicants>;
};

/** aggregate fields of "applicants" */
export type Applicants_Aggregate_Fields = {
  __typename?: 'applicants_aggregate_fields';
  avg?: Maybe<Applicants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Applicants_Max_Fields>;
  min?: Maybe<Applicants_Min_Fields>;
  stddev?: Maybe<Applicants_Stddev_Fields>;
  stddev_pop?: Maybe<Applicants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Applicants_Stddev_Samp_Fields>;
  sum?: Maybe<Applicants_Sum_Fields>;
  var_pop?: Maybe<Applicants_Var_Pop_Fields>;
  var_samp?: Maybe<Applicants_Var_Samp_Fields>;
  variance?: Maybe<Applicants_Variance_Fields>;
};


/** aggregate fields of "applicants" */
export type Applicants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Applicants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "applicants" */
export type Applicants_Aggregate_Order_By = {
  avg?: Maybe<Applicants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Applicants_Max_Order_By>;
  min?: Maybe<Applicants_Min_Order_By>;
  stddev?: Maybe<Applicants_Stddev_Order_By>;
  stddev_pop?: Maybe<Applicants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Applicants_Stddev_Samp_Order_By>;
  sum?: Maybe<Applicants_Sum_Order_By>;
  var_pop?: Maybe<Applicants_Var_Pop_Order_By>;
  var_samp?: Maybe<Applicants_Var_Samp_Order_By>;
  variance?: Maybe<Applicants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "applicants" */
export type Applicants_Arr_Rel_Insert_Input = {
  data: Array<Applicants_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Applicants_On_Conflict>;
};

/** aggregate avg on columns */
export type Applicants_Avg_Fields = {
  __typename?: 'applicants_avg_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "applicants" */
export type Applicants_Avg_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "applicants". All fields are combined with a logical 'AND'. */
export type Applicants_Bool_Exp = {
  _and?: Maybe<Array<Applicants_Bool_Exp>>;
  _not?: Maybe<Applicants_Bool_Exp>;
  _or?: Maybe<Array<Applicants_Bool_Exp>>;
  activities?: Maybe<Activity_Log_Applicants_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  file_store?: Maybe<File_Stores_Bool_Exp>;
  file_store_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_identifier?: Maybe<String_Comparison_Exp>;
  links?: Maybe<Links_Bool_Exp>;
  message_applicants?: Maybe<Message_Applicants_Bool_Exp>;
  message_replies?: Maybe<Message_Replies_Bool_Exp>;
  messages?: Maybe<Messages_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  stage?: Maybe<Stages_Bool_Exp>;
  status_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vacancy?: Maybe<Vacancies_Bool_Exp>;
  vacancy_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "applicants" */
export enum Applicants_Constraint {
  /** unique or primary key constraint */
  ApplicantsPkey = 'applicants_pkey'
}

/** input type for incrementing numeric columns in table "applicants" */
export type Applicants_Inc_Input = {
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status_id?: Maybe<Scalars['Int']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "applicants" */
export type Applicants_Insert_Input = {
  activities?: Maybe<Activity_Log_Applicants_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_store?: Maybe<File_Stores_Obj_Rel_Insert_Input>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_identifier?: Maybe<Scalars['String']>;
  links?: Maybe<Links_Arr_Rel_Insert_Input>;
  message_applicants?: Maybe<Message_Applicants_Arr_Rel_Insert_Input>;
  message_replies?: Maybe<Message_Replies_Arr_Rel_Insert_Input>;
  messages?: Maybe<Messages_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  stage?: Maybe<Stages_Obj_Rel_Insert_Input>;
  status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy?: Maybe<Vacancies_Obj_Rel_Insert_Input>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Applicants_Max_Fields = {
  __typename?: 'applicants_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "applicants" */
export type Applicants_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_identifier?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Applicants_Min_Fields = {
  __typename?: 'applicants_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "applicants" */
export type Applicants_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_identifier?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "applicants" */
export type Applicants_Mutation_Response = {
  __typename?: 'applicants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Applicants>;
};

/** input type for inserting object relation for remote table "applicants" */
export type Applicants_Obj_Rel_Insert_Input = {
  data: Applicants_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Applicants_On_Conflict>;
};

/** on conflict condition type for table "applicants" */
export type Applicants_On_Conflict = {
  constraint: Applicants_Constraint;
  update_columns?: Array<Applicants_Update_Column>;
  where?: Maybe<Applicants_Bool_Exp>;
};

/** Ordering options when selecting data from "applicants". */
export type Applicants_Order_By = {
  activities_aggregate?: Maybe<Activity_Log_Applicants_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file_store?: Maybe<File_Stores_Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_identifier?: Maybe<Order_By>;
  links_aggregate?: Maybe<Links_Aggregate_Order_By>;
  message_applicants_aggregate?: Maybe<Message_Applicants_Aggregate_Order_By>;
  message_replies_aggregate?: Maybe<Message_Replies_Aggregate_Order_By>;
  messages_aggregate?: Maybe<Messages_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  stage?: Maybe<Stages_Order_By>;
  status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancy?: Maybe<Vacancies_Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** primary key columns input for table: applicants */
export type Applicants_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "applicants" */
export enum Applicants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  InboundIdentifier = 'inbound_identifier',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VacancyId = 'vacancy_id'
}

/** input type for updating data in table "applicants" */
export type Applicants_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Applicants_Stddev_Fields = {
  __typename?: 'applicants_stddev_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "applicants" */
export type Applicants_Stddev_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Applicants_Stddev_Pop_Fields = {
  __typename?: 'applicants_stddev_pop_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "applicants" */
export type Applicants_Stddev_Pop_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Applicants_Stddev_Samp_Fields = {
  __typename?: 'applicants_stddev_samp_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "applicants" */
export type Applicants_Stddev_Samp_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Applicants_Sum_Fields = {
  __typename?: 'applicants_sum_fields';
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status_id?: Maybe<Scalars['Int']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "applicants" */
export type Applicants_Sum_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** update columns of table "applicants" */
export enum Applicants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  InboundIdentifier = 'inbound_identifier',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VacancyId = 'vacancy_id'
}

/** aggregate var_pop on columns */
export type Applicants_Var_Pop_Fields = {
  __typename?: 'applicants_var_pop_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "applicants" */
export type Applicants_Var_Pop_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Applicants_Var_Samp_Fields = {
  __typename?: 'applicants_var_samp_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "applicants" */
export type Applicants_Var_Samp_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Applicants_Variance_Fields = {
  __typename?: 'applicants_variance_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "applicants" */
export type Applicants_Variance_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** columns and relationships of "companies" */
export type Companies = {
  __typename?: 'companies';
  /** An array relationship */
  api_tokens: Array<Api_Tokens>;
  /** An aggregate relationship */
  api_tokens_aggregate: Api_Tokens_Aggregate;
  /** An array relationship */
  company_users: Array<Company_Users>;
  /** An aggregate relationship */
  company_users_aggregate: Company_Users_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  file_store?: Maybe<File_Stores>;
  file_store_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** An array relationship */
  message_applicants: Array<Message_Applicants>;
  /** An aggregate relationship */
  message_applicants_aggregate: Message_Applicants_Aggregate;
  /** An array relationship */
  message_templates: Array<Message_Templates>;
  /** An aggregate relationship */
  message_templates_aggregate: Message_Templates_Aggregate;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** An array relationship */
  stages: Array<Stages>;
  /** An aggregate relationship */
  stages_aggregate: Stages_Aggregate;
  /** An array relationship */
  subscribers: Array<Subscribers>;
  /** An aggregate relationship */
  subscribers_aggregate: Subscribers_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  vacancies: Array<Vacancies>;
  /** An aggregate relationship */
  vacancies_aggregate: Vacancies_Aggregate;
};


/** columns and relationships of "companies" */
export type CompaniesApi_TokensArgs = {
  distinct_on?: Maybe<Array<Api_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Tokens_Order_By>>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesApi_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Tokens_Order_By>>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesCompany_UsersArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesCompany_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesMessage_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesMessage_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesMessage_TemplatesArgs = {
  distinct_on?: Maybe<Array<Message_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Templates_Order_By>>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesMessage_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Templates_Order_By>>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesStagesArgs = {
  distinct_on?: Maybe<Array<Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stages_Order_By>>;
  where?: Maybe<Stages_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesStages_AggregateArgs = {
  distinct_on?: Maybe<Array<Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stages_Order_By>>;
  where?: Maybe<Stages_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesSubscribersArgs = {
  distinct_on?: Maybe<Array<Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscribers_Order_By>>;
  where?: Maybe<Subscribers_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesSubscribers_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscribers_Order_By>>;
  where?: Maybe<Subscribers_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesVacanciesArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesVacancies_AggregateArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};

/** aggregated selection of "companies" */
export type Companies_Aggregate = {
  __typename?: 'companies_aggregate';
  aggregate?: Maybe<Companies_Aggregate_Fields>;
  nodes: Array<Companies>;
};

/** aggregate fields of "companies" */
export type Companies_Aggregate_Fields = {
  __typename?: 'companies_aggregate_fields';
  avg?: Maybe<Companies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Companies_Max_Fields>;
  min?: Maybe<Companies_Min_Fields>;
  stddev?: Maybe<Companies_Stddev_Fields>;
  stddev_pop?: Maybe<Companies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Companies_Stddev_Samp_Fields>;
  sum?: Maybe<Companies_Sum_Fields>;
  var_pop?: Maybe<Companies_Var_Pop_Fields>;
  var_samp?: Maybe<Companies_Var_Samp_Fields>;
  variance?: Maybe<Companies_Variance_Fields>;
};


/** aggregate fields of "companies" */
export type Companies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Companies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companies" */
export type Companies_Aggregate_Order_By = {
  avg?: Maybe<Companies_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Companies_Max_Order_By>;
  min?: Maybe<Companies_Min_Order_By>;
  stddev?: Maybe<Companies_Stddev_Order_By>;
  stddev_pop?: Maybe<Companies_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Companies_Stddev_Samp_Order_By>;
  sum?: Maybe<Companies_Sum_Order_By>;
  var_pop?: Maybe<Companies_Var_Pop_Order_By>;
  var_samp?: Maybe<Companies_Var_Samp_Order_By>;
  variance?: Maybe<Companies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "companies" */
export type Companies_Arr_Rel_Insert_Input = {
  data: Array<Companies_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Companies_On_Conflict>;
};

/** aggregate avg on columns */
export type Companies_Avg_Fields = {
  __typename?: 'companies_avg_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "companies" */
export type Companies_Avg_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "companies". All fields are combined with a logical 'AND'. */
export type Companies_Bool_Exp = {
  _and?: Maybe<Array<Companies_Bool_Exp>>;
  _not?: Maybe<Companies_Bool_Exp>;
  _or?: Maybe<Array<Companies_Bool_Exp>>;
  api_tokens?: Maybe<Api_Tokens_Bool_Exp>;
  company_users?: Maybe<Company_Users_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_store?: Maybe<File_Stores_Bool_Exp>;
  file_store_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  industry?: Maybe<String_Comparison_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  message_applicants?: Maybe<Message_Applicants_Bool_Exp>;
  message_templates?: Maybe<Message_Templates_Bool_Exp>;
  messages?: Maybe<Messages_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  stages?: Maybe<Stages_Bool_Exp>;
  subscribers?: Maybe<Subscribers_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vacancies?: Maybe<Vacancies_Bool_Exp>;
};

/** unique or primary key constraints on table "companies" */
export enum Companies_Constraint {
  /** unique or primary key constraint */
  CompaniesPkey = 'companies_pkey'
}

/** input type for incrementing numeric columns in table "companies" */
export type Companies_Inc_Input = {
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "companies" */
export type Companies_Insert_Input = {
  api_tokens?: Maybe<Api_Tokens_Arr_Rel_Insert_Input>;
  company_users?: Maybe<Company_Users_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_store?: Maybe<File_Stores_Obj_Rel_Insert_Input>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  invoices?: Maybe<Invoices_Arr_Rel_Insert_Input>;
  message_applicants?: Maybe<Message_Applicants_Arr_Rel_Insert_Input>;
  message_templates?: Maybe<Message_Templates_Arr_Rel_Insert_Input>;
  messages?: Maybe<Messages_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<Payments_Arr_Rel_Insert_Input>;
  stages?: Maybe<Stages_Arr_Rel_Insert_Input>;
  subscribers?: Maybe<Subscribers_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancies?: Maybe<Vacancies_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Companies_Max_Fields = {
  __typename?: 'companies_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "companies" */
export type Companies_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Companies_Min_Fields = {
  __typename?: 'companies_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "companies" */
export type Companies_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "companies" */
export type Companies_Mutation_Response = {
  __typename?: 'companies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "companies" */
export type Companies_Obj_Rel_Insert_Input = {
  data: Companies_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Companies_On_Conflict>;
};

/** on conflict condition type for table "companies" */
export type Companies_On_Conflict = {
  constraint: Companies_Constraint;
  update_columns?: Array<Companies_Update_Column>;
  where?: Maybe<Companies_Bool_Exp>;
};

/** Ordering options when selecting data from "companies". */
export type Companies_Order_By = {
  api_tokens_aggregate?: Maybe<Api_Tokens_Aggregate_Order_By>;
  company_users_aggregate?: Maybe<Company_Users_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  file_store?: Maybe<File_Stores_Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  message_applicants_aggregate?: Maybe<Message_Applicants_Aggregate_Order_By>;
  message_templates_aggregate?: Maybe<Message_Templates_Aggregate_Order_By>;
  messages_aggregate?: Maybe<Messages_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  stages_aggregate?: Maybe<Stages_Aggregate_Order_By>;
  subscribers_aggregate?: Maybe<Subscribers_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancies_aggregate?: Maybe<Vacancies_Aggregate_Order_By>;
};

/** primary key columns input for table: companies */
export type Companies_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "companies" */
export enum Companies_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "companies" */
export type Companies_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Companies_Stddev_Fields = {
  __typename?: 'companies_stddev_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "companies" */
export type Companies_Stddev_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Companies_Stddev_Pop_Fields = {
  __typename?: 'companies_stddev_pop_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "companies" */
export type Companies_Stddev_Pop_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Companies_Stddev_Samp_Fields = {
  __typename?: 'companies_stddev_samp_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "companies" */
export type Companies_Stddev_Samp_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Companies_Sum_Fields = {
  __typename?: 'companies_sum_fields';
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "companies" */
export type Companies_Sum_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "companies" */
export enum Companies_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Companies_Var_Pop_Fields = {
  __typename?: 'companies_var_pop_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "companies" */
export type Companies_Var_Pop_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Companies_Var_Samp_Fields = {
  __typename?: 'companies_var_samp_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "companies" */
export type Companies_Var_Samp_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Companies_Variance_Fields = {
  __typename?: 'companies_variance_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "companies" */
export type Companies_Variance_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "company_users" */
export type Company_Users = {
  __typename?: 'company_users';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  role?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "company_users" */
export type Company_Users_Aggregate = {
  __typename?: 'company_users_aggregate';
  aggregate?: Maybe<Company_Users_Aggregate_Fields>;
  nodes: Array<Company_Users>;
};

/** aggregate fields of "company_users" */
export type Company_Users_Aggregate_Fields = {
  __typename?: 'company_users_aggregate_fields';
  avg?: Maybe<Company_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Users_Max_Fields>;
  min?: Maybe<Company_Users_Min_Fields>;
  stddev?: Maybe<Company_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Users_Sum_Fields>;
  var_pop?: Maybe<Company_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Users_Var_Samp_Fields>;
  variance?: Maybe<Company_Users_Variance_Fields>;
};


/** aggregate fields of "company_users" */
export type Company_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_users" */
export type Company_Users_Aggregate_Order_By = {
  avg?: Maybe<Company_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Company_Users_Max_Order_By>;
  min?: Maybe<Company_Users_Min_Order_By>;
  stddev?: Maybe<Company_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Company_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Company_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Company_Users_Sum_Order_By>;
  var_pop?: Maybe<Company_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Company_Users_Var_Samp_Order_By>;
  variance?: Maybe<Company_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_users" */
export type Company_Users_Arr_Rel_Insert_Input = {
  data: Array<Company_Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Company_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Users_Avg_Fields = {
  __typename?: 'company_users_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_users" */
export type Company_Users_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_users". All fields are combined with a logical 'AND'. */
export type Company_Users_Bool_Exp = {
  _and?: Maybe<Array<Company_Users_Bool_Exp>>;
  _not?: Maybe<Company_Users_Bool_Exp>;
  _or?: Maybe<Array<Company_Users_Bool_Exp>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  role?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_users" */
export enum Company_Users_Constraint {
  /** unique or primary key constraint */
  CompanyUsersPkey = 'company_users_pkey'
}

/** input type for incrementing numeric columns in table "company_users" */
export type Company_Users_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_users" */
export type Company_Users_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Users_Max_Fields = {
  __typename?: 'company_users_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company_users" */
export type Company_Users_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Users_Min_Fields = {
  __typename?: 'company_users_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company_users" */
export type Company_Users_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "company_users" */
export type Company_Users_Mutation_Response = {
  __typename?: 'company_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Users>;
};

/** on conflict condition type for table "company_users" */
export type Company_Users_On_Conflict = {
  constraint: Company_Users_Constraint;
  update_columns?: Array<Company_Users_Update_Column>;
  where?: Maybe<Company_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "company_users". */
export type Company_Users_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: company_users */
export type Company_Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_users" */
export enum Company_Users_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "company_users" */
export type Company_Users_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Users_Stddev_Fields = {
  __typename?: 'company_users_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_users" */
export type Company_Users_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Users_Stddev_Pop_Fields = {
  __typename?: 'company_users_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_users" */
export type Company_Users_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Users_Stddev_Samp_Fields = {
  __typename?: 'company_users_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_users" */
export type Company_Users_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Company_Users_Sum_Fields = {
  __typename?: 'company_users_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_users" */
export type Company_Users_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "company_users" */
export enum Company_Users_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Company_Users_Var_Pop_Fields = {
  __typename?: 'company_users_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_users" */
export type Company_Users_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Users_Var_Samp_Fields = {
  __typename?: 'company_users_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_users" */
export type Company_Users_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Users_Variance_Fields = {
  __typename?: 'company_users_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_users" */
export type Company_Users_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "email_reset" */
export type Email_Reset = {
  __typename?: 'email_reset';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  expire_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_confirmed?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "email_reset" */
export type Email_Reset_Aggregate = {
  __typename?: 'email_reset_aggregate';
  aggregate?: Maybe<Email_Reset_Aggregate_Fields>;
  nodes: Array<Email_Reset>;
};

/** aggregate fields of "email_reset" */
export type Email_Reset_Aggregate_Fields = {
  __typename?: 'email_reset_aggregate_fields';
  avg?: Maybe<Email_Reset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Reset_Max_Fields>;
  min?: Maybe<Email_Reset_Min_Fields>;
  stddev?: Maybe<Email_Reset_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Reset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Reset_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Reset_Sum_Fields>;
  var_pop?: Maybe<Email_Reset_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Reset_Var_Samp_Fields>;
  variance?: Maybe<Email_Reset_Variance_Fields>;
};


/** aggregate fields of "email_reset" */
export type Email_Reset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Reset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Email_Reset_Avg_Fields = {
  __typename?: 'email_reset_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "email_reset". All fields are combined with a logical 'AND'. */
export type Email_Reset_Bool_Exp = {
  _and?: Maybe<Array<Email_Reset_Bool_Exp>>;
  _not?: Maybe<Email_Reset_Bool_Exp>;
  _or?: Maybe<Array<Email_Reset_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  expire_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_confirmed?: Maybe<Boolean_Comparison_Exp>;
  new_email?: Maybe<String_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_reset" */
export enum Email_Reset_Constraint {
  /** unique or primary key constraint */
  EmailResetPkey = 'email_reset_pkey'
}

/** input type for incrementing numeric columns in table "email_reset" */
export type Email_Reset_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "email_reset" */
export type Email_Reset_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_confirmed?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Reset_Max_Fields = {
  __typename?: 'email_reset_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  new_email?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Reset_Min_Fields = {
  __typename?: 'email_reset_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  new_email?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_reset" */
export type Email_Reset_Mutation_Response = {
  __typename?: 'email_reset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Reset>;
};

/** on conflict condition type for table "email_reset" */
export type Email_Reset_On_Conflict = {
  constraint: Email_Reset_Constraint;
  update_columns?: Array<Email_Reset_Update_Column>;
  where?: Maybe<Email_Reset_Bool_Exp>;
};

/** Ordering options when selecting data from "email_reset". */
export type Email_Reset_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_confirmed?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: email_reset */
export type Email_Reset_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "email_reset" */
export enum Email_Reset_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsConfirmed = 'is_confirmed',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "email_reset" */
export type Email_Reset_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_confirmed?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Email_Reset_Stddev_Fields = {
  __typename?: 'email_reset_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Reset_Stddev_Pop_Fields = {
  __typename?: 'email_reset_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Reset_Stddev_Samp_Fields = {
  __typename?: 'email_reset_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Email_Reset_Sum_Fields = {
  __typename?: 'email_reset_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "email_reset" */
export enum Email_Reset_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsConfirmed = 'is_confirmed',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Email_Reset_Var_Pop_Fields = {
  __typename?: 'email_reset_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Reset_Var_Samp_Fields = {
  __typename?: 'email_reset_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Reset_Variance_Fields = {
  __typename?: 'email_reset_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "failed_jobs" */
export type Failed_Jobs = {
  __typename?: 'failed_jobs';
  connection: Scalars['String'];
  created_at: Scalars['timestamptz'];
  exception: Scalars['String'];
  failed_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  payload: Scalars['String'];
  queue: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "failed_jobs" */
export type Failed_Jobs_Aggregate = {
  __typename?: 'failed_jobs_aggregate';
  aggregate?: Maybe<Failed_Jobs_Aggregate_Fields>;
  nodes: Array<Failed_Jobs>;
};

/** aggregate fields of "failed_jobs" */
export type Failed_Jobs_Aggregate_Fields = {
  __typename?: 'failed_jobs_aggregate_fields';
  avg?: Maybe<Failed_Jobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Failed_Jobs_Max_Fields>;
  min?: Maybe<Failed_Jobs_Min_Fields>;
  stddev?: Maybe<Failed_Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Failed_Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Failed_Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Failed_Jobs_Sum_Fields>;
  var_pop?: Maybe<Failed_Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Failed_Jobs_Var_Samp_Fields>;
  variance?: Maybe<Failed_Jobs_Variance_Fields>;
};


/** aggregate fields of "failed_jobs" */
export type Failed_Jobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Failed_Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Failed_Jobs_Avg_Fields = {
  __typename?: 'failed_jobs_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "failed_jobs". All fields are combined with a logical 'AND'. */
export type Failed_Jobs_Bool_Exp = {
  _and?: Maybe<Array<Failed_Jobs_Bool_Exp>>;
  _not?: Maybe<Failed_Jobs_Bool_Exp>;
  _or?: Maybe<Array<Failed_Jobs_Bool_Exp>>;
  connection?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  exception?: Maybe<String_Comparison_Exp>;
  failed_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  payload?: Maybe<String_Comparison_Exp>;
  queue?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "failed_jobs" */
export enum Failed_Jobs_Constraint {
  /** unique or primary key constraint */
  FailedJobsPkey = 'failed_jobs_pkey'
}

/** input type for incrementing numeric columns in table "failed_jobs" */
export type Failed_Jobs_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "failed_jobs" */
export type Failed_Jobs_Insert_Input = {
  connection?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exception?: Maybe<Scalars['String']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  queue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Failed_Jobs_Max_Fields = {
  __typename?: 'failed_jobs_max_fields';
  connection?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exception?: Maybe<Scalars['String']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  queue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Failed_Jobs_Min_Fields = {
  __typename?: 'failed_jobs_min_fields';
  connection?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exception?: Maybe<Scalars['String']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  queue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "failed_jobs" */
export type Failed_Jobs_Mutation_Response = {
  __typename?: 'failed_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Failed_Jobs>;
};

/** on conflict condition type for table "failed_jobs" */
export type Failed_Jobs_On_Conflict = {
  constraint: Failed_Jobs_Constraint;
  update_columns?: Array<Failed_Jobs_Update_Column>;
  where?: Maybe<Failed_Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "failed_jobs". */
export type Failed_Jobs_Order_By = {
  connection?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  exception?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  queue?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: failed_jobs */
export type Failed_Jobs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "failed_jobs" */
export enum Failed_Jobs_Select_Column {
  /** column name */
  Connection = 'connection',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Exception = 'exception',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Queue = 'queue',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "failed_jobs" */
export type Failed_Jobs_Set_Input = {
  connection?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exception?: Maybe<Scalars['String']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  queue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Failed_Jobs_Stddev_Fields = {
  __typename?: 'failed_jobs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Failed_Jobs_Stddev_Pop_Fields = {
  __typename?: 'failed_jobs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Failed_Jobs_Stddev_Samp_Fields = {
  __typename?: 'failed_jobs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Failed_Jobs_Sum_Fields = {
  __typename?: 'failed_jobs_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "failed_jobs" */
export enum Failed_Jobs_Update_Column {
  /** column name */
  Connection = 'connection',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Exception = 'exception',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Queue = 'queue',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Failed_Jobs_Var_Pop_Fields = {
  __typename?: 'failed_jobs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Failed_Jobs_Var_Samp_Fields = {
  __typename?: 'failed_jobs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Failed_Jobs_Variance_Fields = {
  __typename?: 'failed_jobs_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "file_stores" */
export type File_Stores = {
  __typename?: 'file_stores';
  /** An array relationship */
  applicants: Array<Applicants>;
  /** An aggregate relationship */
  applicants_aggregate: Applicants_Aggregate;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: Companies_Aggregate;
  created_at: Scalars['timestamptz'];
  file_name: Scalars['String'];
  file_path: Scalars['String'];
  file_size: Scalars['String'];
  fileable_id?: Maybe<Scalars['Int']>;
  fileable_type?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mime_type: Scalars['String'];
  name: Scalars['String'];
  original_ext: Scalars['String'];
  original_name: Scalars['String'];
  status: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An array relationship */
  vacancies: Array<Vacancies>;
  /** An aggregate relationship */
  vacancies_aggregate: Vacancies_Aggregate;
};


/** columns and relationships of "file_stores" */
export type File_StoresApplicantsArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresApplicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresVacanciesArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};


/** columns and relationships of "file_stores" */
export type File_StoresVacancies_AggregateArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};

/** aggregated selection of "file_stores" */
export type File_Stores_Aggregate = {
  __typename?: 'file_stores_aggregate';
  aggregate?: Maybe<File_Stores_Aggregate_Fields>;
  nodes: Array<File_Stores>;
};

/** aggregate fields of "file_stores" */
export type File_Stores_Aggregate_Fields = {
  __typename?: 'file_stores_aggregate_fields';
  avg?: Maybe<File_Stores_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<File_Stores_Max_Fields>;
  min?: Maybe<File_Stores_Min_Fields>;
  stddev?: Maybe<File_Stores_Stddev_Fields>;
  stddev_pop?: Maybe<File_Stores_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Stores_Stddev_Samp_Fields>;
  sum?: Maybe<File_Stores_Sum_Fields>;
  var_pop?: Maybe<File_Stores_Var_Pop_Fields>;
  var_samp?: Maybe<File_Stores_Var_Samp_Fields>;
  variance?: Maybe<File_Stores_Variance_Fields>;
};


/** aggregate fields of "file_stores" */
export type File_Stores_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<File_Stores_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type File_Stores_Avg_Fields = {
  __typename?: 'file_stores_avg_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "file_stores". All fields are combined with a logical 'AND'. */
export type File_Stores_Bool_Exp = {
  _and?: Maybe<Array<File_Stores_Bool_Exp>>;
  _not?: Maybe<File_Stores_Bool_Exp>;
  _or?: Maybe<Array<File_Stores_Bool_Exp>>;
  applicants?: Maybe<Applicants_Bool_Exp>;
  companies?: Maybe<Companies_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_name?: Maybe<String_Comparison_Exp>;
  file_path?: Maybe<String_Comparison_Exp>;
  file_size?: Maybe<String_Comparison_Exp>;
  fileable_id?: Maybe<Int_Comparison_Exp>;
  fileable_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  mime_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  original_ext?: Maybe<String_Comparison_Exp>;
  original_name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  vacancies?: Maybe<Vacancies_Bool_Exp>;
};

/** unique or primary key constraints on table "file_stores" */
export enum File_Stores_Constraint {
  /** unique or primary key constraint */
  FileStoresPkey = 'file_stores_pkey'
}

/** input type for incrementing numeric columns in table "file_stores" */
export type File_Stores_Inc_Input = {
  fileable_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "file_stores" */
export type File_Stores_Insert_Input = {
  applicants?: Maybe<Applicants_Arr_Rel_Insert_Input>;
  companies?: Maybe<Companies_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['String']>;
  fileable_id?: Maybe<Scalars['Int']>;
  fileable_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_ext?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  vacancies?: Maybe<Vacancies_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type File_Stores_Max_Fields = {
  __typename?: 'file_stores_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['String']>;
  fileable_id?: Maybe<Scalars['Int']>;
  fileable_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_ext?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type File_Stores_Min_Fields = {
  __typename?: 'file_stores_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['String']>;
  fileable_id?: Maybe<Scalars['Int']>;
  fileable_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_ext?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "file_stores" */
export type File_Stores_Mutation_Response = {
  __typename?: 'file_stores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Stores>;
};

/** input type for inserting object relation for remote table "file_stores" */
export type File_Stores_Obj_Rel_Insert_Input = {
  data: File_Stores_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<File_Stores_On_Conflict>;
};

/** on conflict condition type for table "file_stores" */
export type File_Stores_On_Conflict = {
  constraint: File_Stores_Constraint;
  update_columns?: Array<File_Stores_Update_Column>;
  where?: Maybe<File_Stores_Bool_Exp>;
};

/** Ordering options when selecting data from "file_stores". */
export type File_Stores_Order_By = {
  applicants_aggregate?: Maybe<Applicants_Aggregate_Order_By>;
  companies_aggregate?: Maybe<Companies_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  file_path?: Maybe<Order_By>;
  file_size?: Maybe<Order_By>;
  fileable_id?: Maybe<Order_By>;
  fileable_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mime_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  original_ext?: Maybe<Order_By>;
  original_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  vacancies_aggregate?: Maybe<Vacancies_Aggregate_Order_By>;
};

/** primary key columns input for table: file_stores */
export type File_Stores_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "file_stores" */
export enum File_Stores_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  FileableId = 'fileable_id',
  /** column name */
  FileableType = 'fileable_type',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Name = 'name',
  /** column name */
  OriginalExt = 'original_ext',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "file_stores" */
export type File_Stores_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['String']>;
  fileable_id?: Maybe<Scalars['Int']>;
  fileable_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_ext?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type File_Stores_Stddev_Fields = {
  __typename?: 'file_stores_stddev_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type File_Stores_Stddev_Pop_Fields = {
  __typename?: 'file_stores_stddev_pop_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type File_Stores_Stddev_Samp_Fields = {
  __typename?: 'file_stores_stddev_samp_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type File_Stores_Sum_Fields = {
  __typename?: 'file_stores_sum_fields';
  fileable_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** update columns of table "file_stores" */
export enum File_Stores_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  FileableId = 'fileable_id',
  /** column name */
  FileableType = 'fileable_type',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Name = 'name',
  /** column name */
  OriginalExt = 'original_ext',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type File_Stores_Var_Pop_Fields = {
  __typename?: 'file_stores_var_pop_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type File_Stores_Var_Samp_Fields = {
  __typename?: 'file_stores_var_samp_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type File_Stores_Variance_Fields = {
  __typename?: 'file_stores_variance_fields';
  fileable_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "inbound_mails" */
export type Inbound_Mails = {
  __typename?: 'inbound_mails';
  created_at: Scalars['timestamptz'];
  from: Scalars['String'];
  from_array: Scalars['json'];
  headers_raw: Scalars['String'];
  html: Scalars['String'];
  id: Scalars['Int'];
  subject: Scalars['String'];
  text: Scalars['String'];
  to: Scalars['String'];
  to_array: Scalars['json'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "inbound_mails" */
export type Inbound_MailsFrom_ArrayArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "inbound_mails" */
export type Inbound_MailsTo_ArrayArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "inbound_mails" */
export type Inbound_Mails_Aggregate = {
  __typename?: 'inbound_mails_aggregate';
  aggregate?: Maybe<Inbound_Mails_Aggregate_Fields>;
  nodes: Array<Inbound_Mails>;
};

/** aggregate fields of "inbound_mails" */
export type Inbound_Mails_Aggregate_Fields = {
  __typename?: 'inbound_mails_aggregate_fields';
  avg?: Maybe<Inbound_Mails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inbound_Mails_Max_Fields>;
  min?: Maybe<Inbound_Mails_Min_Fields>;
  stddev?: Maybe<Inbound_Mails_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Mails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Mails_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Mails_Sum_Fields>;
  var_pop?: Maybe<Inbound_Mails_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Mails_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Mails_Variance_Fields>;
};


/** aggregate fields of "inbound_mails" */
export type Inbound_Mails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Mails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inbound_Mails_Avg_Fields = {
  __typename?: 'inbound_mails_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inbound_mails". All fields are combined with a logical 'AND'. */
export type Inbound_Mails_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Mails_Bool_Exp>>;
  _not?: Maybe<Inbound_Mails_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Mails_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from?: Maybe<String_Comparison_Exp>;
  from_array?: Maybe<Json_Comparison_Exp>;
  headers_raw?: Maybe<String_Comparison_Exp>;
  html?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  to?: Maybe<String_Comparison_Exp>;
  to_array?: Maybe<Json_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_mails" */
export enum Inbound_Mails_Constraint {
  /** unique or primary key constraint */
  InboundMailsPkey = 'inbound_mails_pkey'
}

/** input type for incrementing numeric columns in table "inbound_mails" */
export type Inbound_Mails_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "inbound_mails" */
export type Inbound_Mails_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  from_array?: Maybe<Scalars['json']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  to_array?: Maybe<Scalars['json']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Inbound_Mails_Max_Fields = {
  __typename?: 'inbound_mails_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Inbound_Mails_Min_Fields = {
  __typename?: 'inbound_mails_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "inbound_mails" */
export type Inbound_Mails_Mutation_Response = {
  __typename?: 'inbound_mails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Mails>;
};

/** on conflict condition type for table "inbound_mails" */
export type Inbound_Mails_On_Conflict = {
  constraint: Inbound_Mails_Constraint;
  update_columns?: Array<Inbound_Mails_Update_Column>;
  where?: Maybe<Inbound_Mails_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_mails". */
export type Inbound_Mails_Order_By = {
  created_at?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  from_array?: Maybe<Order_By>;
  headers_raw?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  to_array?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_mails */
export type Inbound_Mails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inbound_mails" */
export enum Inbound_Mails_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  FromArray = 'from_array',
  /** column name */
  HeadersRaw = 'headers_raw',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Subject = 'subject',
  /** column name */
  Text = 'text',
  /** column name */
  To = 'to',
  /** column name */
  ToArray = 'to_array',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "inbound_mails" */
export type Inbound_Mails_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  from_array?: Maybe<Scalars['json']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  to_array?: Maybe<Scalars['json']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Inbound_Mails_Stddev_Fields = {
  __typename?: 'inbound_mails_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Mails_Stddev_Pop_Fields = {
  __typename?: 'inbound_mails_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Mails_Stddev_Samp_Fields = {
  __typename?: 'inbound_mails_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Inbound_Mails_Sum_Fields = {
  __typename?: 'inbound_mails_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "inbound_mails" */
export enum Inbound_Mails_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  FromArray = 'from_array',
  /** column name */
  HeadersRaw = 'headers_raw',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Subject = 'subject',
  /** column name */
  Text = 'text',
  /** column name */
  To = 'to',
  /** column name */
  ToArray = 'to_array',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Inbound_Mails_Var_Pop_Fields = {
  __typename?: 'inbound_mails_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inbound_Mails_Var_Samp_Fields = {
  __typename?: 'inbound_mails_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inbound_Mails_Variance_Fields = {
  __typename?: 'inbound_mails_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "inbound_messages" */
export type Inbound_Messages = {
  __typename?: 'inbound_messages';
  created_at: Scalars['timestamptz'];
  from?: Maybe<Scalars['String']>;
  from_array?: Maybe<Scalars['jsonb']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "inbound_messages" */
export type Inbound_MessagesFrom_ArrayArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "inbound_messages" */
export type Inbound_MessagesMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "inbound_messages" */
export type Inbound_MessagesMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};

/** aggregated selection of "inbound_messages" */
export type Inbound_Messages_Aggregate = {
  __typename?: 'inbound_messages_aggregate';
  aggregate?: Maybe<Inbound_Messages_Aggregate_Fields>;
  nodes: Array<Inbound_Messages>;
};

/** aggregate fields of "inbound_messages" */
export type Inbound_Messages_Aggregate_Fields = {
  __typename?: 'inbound_messages_aggregate_fields';
  avg?: Maybe<Inbound_Messages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inbound_Messages_Max_Fields>;
  min?: Maybe<Inbound_Messages_Min_Fields>;
  stddev?: Maybe<Inbound_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Messages_Sum_Fields>;
  var_pop?: Maybe<Inbound_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Messages_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Messages_Variance_Fields>;
};


/** aggregate fields of "inbound_messages" */
export type Inbound_Messages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Messages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Inbound_Messages_Append_Input = {
  from_array?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Inbound_Messages_Avg_Fields = {
  __typename?: 'inbound_messages_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inbound_messages". All fields are combined with a logical 'AND'. */
export type Inbound_Messages_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Messages_Bool_Exp>>;
  _not?: Maybe<Inbound_Messages_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Messages_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from?: Maybe<String_Comparison_Exp>;
  from_array?: Maybe<Jsonb_Comparison_Exp>;
  headers_raw?: Maybe<String_Comparison_Exp>;
  html?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  messages?: Maybe<Messages_Bool_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  to?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_messages" */
export enum Inbound_Messages_Constraint {
  /** unique or primary key constraint */
  InboundMessagesPkey = 'inbound_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Inbound_Messages_Delete_At_Path_Input = {
  from_array?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Inbound_Messages_Delete_Elem_Input = {
  from_array?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Inbound_Messages_Delete_Key_Input = {
  from_array?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "inbound_messages" */
export type Inbound_Messages_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "inbound_messages" */
export type Inbound_Messages_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  from_array?: Maybe<Scalars['jsonb']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  messages?: Maybe<Messages_Arr_Rel_Insert_Input>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Inbound_Messages_Max_Fields = {
  __typename?: 'inbound_messages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Inbound_Messages_Min_Fields = {
  __typename?: 'inbound_messages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "inbound_messages" */
export type Inbound_Messages_Mutation_Response = {
  __typename?: 'inbound_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Messages>;
};

/** input type for inserting object relation for remote table "inbound_messages" */
export type Inbound_Messages_Obj_Rel_Insert_Input = {
  data: Inbound_Messages_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Inbound_Messages_On_Conflict>;
};

/** on conflict condition type for table "inbound_messages" */
export type Inbound_Messages_On_Conflict = {
  constraint: Inbound_Messages_Constraint;
  update_columns?: Array<Inbound_Messages_Update_Column>;
  where?: Maybe<Inbound_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_messages". */
export type Inbound_Messages_Order_By = {
  created_at?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  from_array?: Maybe<Order_By>;
  headers_raw?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  messages_aggregate?: Maybe<Messages_Aggregate_Order_By>;
  subject?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_messages */
export type Inbound_Messages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Inbound_Messages_Prepend_Input = {
  from_array?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "inbound_messages" */
export enum Inbound_Messages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  FromArray = 'from_array',
  /** column name */
  HeadersRaw = 'headers_raw',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Subject = 'subject',
  /** column name */
  Text = 'text',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "inbound_messages" */
export type Inbound_Messages_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  from_array?: Maybe<Scalars['jsonb']>;
  headers_raw?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Inbound_Messages_Stddev_Fields = {
  __typename?: 'inbound_messages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Messages_Stddev_Pop_Fields = {
  __typename?: 'inbound_messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Messages_Stddev_Samp_Fields = {
  __typename?: 'inbound_messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Inbound_Messages_Sum_Fields = {
  __typename?: 'inbound_messages_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "inbound_messages" */
export enum Inbound_Messages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  FromArray = 'from_array',
  /** column name */
  HeadersRaw = 'headers_raw',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Subject = 'subject',
  /** column name */
  Text = 'text',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Inbound_Messages_Var_Pop_Fields = {
  __typename?: 'inbound_messages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inbound_Messages_Var_Samp_Fields = {
  __typename?: 'inbound_messages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inbound_Messages_Variance_Fields = {
  __typename?: 'inbound_messages_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "invoices" */
export type Invoices = {
  __typename?: 'invoices';
  amount?: Maybe<Scalars['float8']>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  invoice_number?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "invoices" */
export type Invoices_Aggregate = {
  __typename?: 'invoices_aggregate';
  aggregate?: Maybe<Invoices_Aggregate_Fields>;
  nodes: Array<Invoices>;
};

/** aggregate fields of "invoices" */
export type Invoices_Aggregate_Fields = {
  __typename?: 'invoices_aggregate_fields';
  avg?: Maybe<Invoices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_Max_Fields>;
  min?: Maybe<Invoices_Min_Fields>;
  stddev?: Maybe<Invoices_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_Sum_Fields>;
  var_pop?: Maybe<Invoices_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_Var_Samp_Fields>;
  variance?: Maybe<Invoices_Variance_Fields>;
};


/** aggregate fields of "invoices" */
export type Invoices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoices" */
export type Invoices_Aggregate_Order_By = {
  avg?: Maybe<Invoices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoices_Max_Order_By>;
  min?: Maybe<Invoices_Min_Order_By>;
  stddev?: Maybe<Invoices_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoices_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoices_Sum_Order_By>;
  var_pop?: Maybe<Invoices_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoices_Var_Samp_Order_By>;
  variance?: Maybe<Invoices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoices" */
export type Invoices_Arr_Rel_Insert_Input = {
  data: Array<Invoices_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Invoices_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_Avg_Fields = {
  __typename?: 'invoices_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoices" */
export type Invoices_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices". All fields are combined with a logical 'AND'. */
export type Invoices_Bool_Exp = {
  _and?: Maybe<Array<Invoices_Bool_Exp>>;
  _not?: Maybe<Invoices_Bool_Exp>;
  _or?: Maybe<Array<Invoices_Bool_Exp>>;
  amount?: Maybe<Float8_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_number?: Maybe<String_Comparison_Exp>;
  invoice_url?: Maybe<String_Comparison_Exp>;
  paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices" */
export enum Invoices_Constraint {
  /** unique or primary key constraint */
  InvoicesPkey = 'invoices_pkey'
}

/** input type for incrementing numeric columns in table "invoices" */
export type Invoices_Inc_Input = {
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoices" */
export type Invoices_Insert_Input = {
  amount?: Maybe<Scalars['float8']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoices_Max_Fields = {
  __typename?: 'invoices_max_fields';
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoices" */
export type Invoices_Max_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  invoice_url?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_Min_Fields = {
  __typename?: 'invoices_min_fields';
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoices" */
export type Invoices_Min_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  invoice_url?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoices" */
export type Invoices_Mutation_Response = {
  __typename?: 'invoices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices>;
};

/** on conflict condition type for table "invoices" */
export type Invoices_On_Conflict = {
  constraint: Invoices_Constraint;
  update_columns?: Array<Invoices_Update_Column>;
  where?: Maybe<Invoices_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices". */
export type Invoices_Order_By = {
  amount?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  invoice_url?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: invoices */
export type Invoices_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invoices" */
export enum Invoices_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  InvoiceUrl = 'invoice_url',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoices" */
export type Invoices_Set_Input = {
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  invoice_number?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invoices_Stddev_Fields = {
  __typename?: 'invoices_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoices" */
export type Invoices_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_Stddev_Pop_Fields = {
  __typename?: 'invoices_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoices" */
export type Invoices_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_Stddev_Samp_Fields = {
  __typename?: 'invoices_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoices" */
export type Invoices_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Invoices_Sum_Fields = {
  __typename?: 'invoices_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoices" */
export type Invoices_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "invoices" */
export enum Invoices_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  InvoiceUrl = 'invoice_url',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Invoices_Var_Pop_Fields = {
  __typename?: 'invoices_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoices" */
export type Invoices_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_Var_Samp_Fields = {
  __typename?: 'invoices_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoices" */
export type Invoices_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_Variance_Fields = {
  __typename?: 'invoices_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoices" */
export type Invoices_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "links" */
export type Links = {
  __typename?: 'links';
  /** An object relationship */
  applicant?: Maybe<Applicants>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "links" */
export type Links_Aggregate = {
  __typename?: 'links_aggregate';
  aggregate?: Maybe<Links_Aggregate_Fields>;
  nodes: Array<Links>;
};

/** aggregate fields of "links" */
export type Links_Aggregate_Fields = {
  __typename?: 'links_aggregate_fields';
  avg?: Maybe<Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Links_Max_Fields>;
  min?: Maybe<Links_Min_Fields>;
  stddev?: Maybe<Links_Stddev_Fields>;
  stddev_pop?: Maybe<Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Links_Stddev_Samp_Fields>;
  sum?: Maybe<Links_Sum_Fields>;
  var_pop?: Maybe<Links_Var_Pop_Fields>;
  var_samp?: Maybe<Links_Var_Samp_Fields>;
  variance?: Maybe<Links_Variance_Fields>;
};


/** aggregate fields of "links" */
export type Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "links" */
export type Links_Aggregate_Order_By = {
  avg?: Maybe<Links_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Links_Max_Order_By>;
  min?: Maybe<Links_Min_Order_By>;
  stddev?: Maybe<Links_Stddev_Order_By>;
  stddev_pop?: Maybe<Links_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Links_Stddev_Samp_Order_By>;
  sum?: Maybe<Links_Sum_Order_By>;
  var_pop?: Maybe<Links_Var_Pop_Order_By>;
  var_samp?: Maybe<Links_Var_Samp_Order_By>;
  variance?: Maybe<Links_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "links" */
export type Links_Arr_Rel_Insert_Input = {
  data: Array<Links_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Links_On_Conflict>;
};

/** aggregate avg on columns */
export type Links_Avg_Fields = {
  __typename?: 'links_avg_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "links" */
export type Links_Avg_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "links". All fields are combined with a logical 'AND'. */
export type Links_Bool_Exp = {
  _and?: Maybe<Array<Links_Bool_Exp>>;
  _not?: Maybe<Links_Bool_Exp>;
  _or?: Maybe<Array<Links_Bool_Exp>>;
  applicant?: Maybe<Applicants_Bool_Exp>;
  applicant_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "links" */
export enum Links_Constraint {
  /** unique or primary key constraint */
  LinksPkey = 'links_pkey'
}

/** input type for incrementing numeric columns in table "links" */
export type Links_Inc_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "links" */
export type Links_Insert_Input = {
  applicant?: Maybe<Applicants_Obj_Rel_Insert_Input>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Links_Max_Fields = {
  __typename?: 'links_max_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "links" */
export type Links_Max_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Links_Min_Fields = {
  __typename?: 'links_min_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "links" */
export type Links_Min_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "links" */
export type Links_Mutation_Response = {
  __typename?: 'links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Links>;
};

/** on conflict condition type for table "links" */
export type Links_On_Conflict = {
  constraint: Links_Constraint;
  update_columns?: Array<Links_Update_Column>;
  where?: Maybe<Links_Bool_Exp>;
};

/** Ordering options when selecting data from "links". */
export type Links_Order_By = {
  applicant?: Maybe<Applicants_Order_By>;
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: links */
export type Links_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "links" */
export enum Links_Select_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "links" */
export type Links_Set_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Links_Stddev_Fields = {
  __typename?: 'links_stddev_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "links" */
export type Links_Stddev_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Links_Stddev_Pop_Fields = {
  __typename?: 'links_stddev_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "links" */
export type Links_Stddev_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Links_Stddev_Samp_Fields = {
  __typename?: 'links_stddev_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "links" */
export type Links_Stddev_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Links_Sum_Fields = {
  __typename?: 'links_sum_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "links" */
export type Links_Sum_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "links" */
export enum Links_Update_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Links_Var_Pop_Fields = {
  __typename?: 'links_var_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "links" */
export type Links_Var_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Links_Var_Samp_Fields = {
  __typename?: 'links_var_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "links" */
export type Links_Var_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Links_Variance_Fields = {
  __typename?: 'links_variance_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "links" */
export type Links_Variance_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails = {
  __typename?: 'mailbox_inbound_emails';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  message: Scalars['String'];
  message_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Aggregate = {
  __typename?: 'mailbox_inbound_emails_aggregate';
  aggregate?: Maybe<Mailbox_Inbound_Emails_Aggregate_Fields>;
  nodes: Array<Mailbox_Inbound_Emails>;
};

/** aggregate fields of "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Aggregate_Fields = {
  __typename?: 'mailbox_inbound_emails_aggregate_fields';
  avg?: Maybe<Mailbox_Inbound_Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mailbox_Inbound_Emails_Max_Fields>;
  min?: Maybe<Mailbox_Inbound_Emails_Min_Fields>;
  stddev?: Maybe<Mailbox_Inbound_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Mailbox_Inbound_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mailbox_Inbound_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Mailbox_Inbound_Emails_Sum_Fields>;
  var_pop?: Maybe<Mailbox_Inbound_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Mailbox_Inbound_Emails_Var_Samp_Fields>;
  variance?: Maybe<Mailbox_Inbound_Emails_Variance_Fields>;
};


/** aggregate fields of "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mailbox_Inbound_Emails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mailbox_Inbound_Emails_Avg_Fields = {
  __typename?: 'mailbox_inbound_emails_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mailbox_inbound_emails". All fields are combined with a logical 'AND'. */
export type Mailbox_Inbound_Emails_Bool_Exp = {
  _and?: Maybe<Array<Mailbox_Inbound_Emails_Bool_Exp>>;
  _not?: Maybe<Mailbox_Inbound_Emails_Bool_Exp>;
  _or?: Maybe<Array<Mailbox_Inbound_Emails_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  message_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mailbox_inbound_emails" */
export enum Mailbox_Inbound_Emails_Constraint {
  /** unique or primary key constraint */
  MailboxInboundEmailsPkey = 'mailbox_inbound_emails_pkey'
}

/** input type for incrementing numeric columns in table "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Mailbox_Inbound_Emails_Max_Fields = {
  __typename?: 'mailbox_inbound_emails_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Mailbox_Inbound_Emails_Min_Fields = {
  __typename?: 'mailbox_inbound_emails_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Mutation_Response = {
  __typename?: 'mailbox_inbound_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mailbox_Inbound_Emails>;
};

/** on conflict condition type for table "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_On_Conflict = {
  constraint: Mailbox_Inbound_Emails_Constraint;
  update_columns?: Array<Mailbox_Inbound_Emails_Update_Column>;
  where?: Maybe<Mailbox_Inbound_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "mailbox_inbound_emails". */
export type Mailbox_Inbound_Emails_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: mailbox_inbound_emails */
export type Mailbox_Inbound_Emails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "mailbox_inbound_emails" */
export enum Mailbox_Inbound_Emails_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mailbox_inbound_emails" */
export type Mailbox_Inbound_Emails_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Mailbox_Inbound_Emails_Stddev_Fields = {
  __typename?: 'mailbox_inbound_emails_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mailbox_Inbound_Emails_Stddev_Pop_Fields = {
  __typename?: 'mailbox_inbound_emails_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mailbox_Inbound_Emails_Stddev_Samp_Fields = {
  __typename?: 'mailbox_inbound_emails_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Mailbox_Inbound_Emails_Sum_Fields = {
  __typename?: 'mailbox_inbound_emails_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "mailbox_inbound_emails" */
export enum Mailbox_Inbound_Emails_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Mailbox_Inbound_Emails_Var_Pop_Fields = {
  __typename?: 'mailbox_inbound_emails_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mailbox_Inbound_Emails_Var_Samp_Fields = {
  __typename?: 'mailbox_inbound_emails_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mailbox_Inbound_Emails_Variance_Fields = {
  __typename?: 'mailbox_inbound_emails_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "message_applicants" */
export type Message_Applicants = {
  __typename?: 'message_applicants';
  /** An object relationship */
  applicant?: Maybe<Applicants>;
  applicant_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  message: Messages_Old;
  message_id: Scalars['Int'];
  sender_data?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};


/** columns and relationships of "message_applicants" */
export type Message_ApplicantsSender_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "message_applicants" */
export type Message_Applicants_Aggregate = {
  __typename?: 'message_applicants_aggregate';
  aggregate?: Maybe<Message_Applicants_Aggregate_Fields>;
  nodes: Array<Message_Applicants>;
};

/** aggregate fields of "message_applicants" */
export type Message_Applicants_Aggregate_Fields = {
  __typename?: 'message_applicants_aggregate_fields';
  avg?: Maybe<Message_Applicants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Message_Applicants_Max_Fields>;
  min?: Maybe<Message_Applicants_Min_Fields>;
  stddev?: Maybe<Message_Applicants_Stddev_Fields>;
  stddev_pop?: Maybe<Message_Applicants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Message_Applicants_Stddev_Samp_Fields>;
  sum?: Maybe<Message_Applicants_Sum_Fields>;
  var_pop?: Maybe<Message_Applicants_Var_Pop_Fields>;
  var_samp?: Maybe<Message_Applicants_Var_Samp_Fields>;
  variance?: Maybe<Message_Applicants_Variance_Fields>;
};


/** aggregate fields of "message_applicants" */
export type Message_Applicants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Applicants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "message_applicants" */
export type Message_Applicants_Aggregate_Order_By = {
  avg?: Maybe<Message_Applicants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Applicants_Max_Order_By>;
  min?: Maybe<Message_Applicants_Min_Order_By>;
  stddev?: Maybe<Message_Applicants_Stddev_Order_By>;
  stddev_pop?: Maybe<Message_Applicants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Message_Applicants_Stddev_Samp_Order_By>;
  sum?: Maybe<Message_Applicants_Sum_Order_By>;
  var_pop?: Maybe<Message_Applicants_Var_Pop_Order_By>;
  var_samp?: Maybe<Message_Applicants_Var_Samp_Order_By>;
  variance?: Maybe<Message_Applicants_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Message_Applicants_Append_Input = {
  sender_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "message_applicants" */
export type Message_Applicants_Arr_Rel_Insert_Input = {
  data: Array<Message_Applicants_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Message_Applicants_On_Conflict>;
};

/** aggregate avg on columns */
export type Message_Applicants_Avg_Fields = {
  __typename?: 'message_applicants_avg_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "message_applicants" */
export type Message_Applicants_Avg_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "message_applicants". All fields are combined with a logical 'AND'. */
export type Message_Applicants_Bool_Exp = {
  _and?: Maybe<Array<Message_Applicants_Bool_Exp>>;
  _not?: Maybe<Message_Applicants_Bool_Exp>;
  _or?: Maybe<Array<Message_Applicants_Bool_Exp>>;
  applicant?: Maybe<Applicants_Bool_Exp>;
  applicant_id?: Maybe<Int_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<Messages_Old_Bool_Exp>;
  message_id?: Maybe<Int_Comparison_Exp>;
  sender_data?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_applicants" */
export enum Message_Applicants_Constraint {
  /** unique or primary key constraint */
  MessageApplicantsPkey = 'message_applicants_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Message_Applicants_Delete_At_Path_Input = {
  sender_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Message_Applicants_Delete_Elem_Input = {
  sender_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Message_Applicants_Delete_Key_Input = {
  sender_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "message_applicants" */
export type Message_Applicants_Inc_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "message_applicants" */
export type Message_Applicants_Insert_Input = {
  applicant?: Maybe<Applicants_Obj_Rel_Insert_Input>;
  applicant_id?: Maybe<Scalars['Int']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Messages_Old_Obj_Rel_Insert_Input>;
  message_id?: Maybe<Scalars['Int']>;
  sender_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Message_Applicants_Max_Fields = {
  __typename?: 'message_applicants_max_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "message_applicants" */
export type Message_Applicants_Max_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Applicants_Min_Fields = {
  __typename?: 'message_applicants_min_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "message_applicants" */
export type Message_Applicants_Min_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "message_applicants" */
export type Message_Applicants_Mutation_Response = {
  __typename?: 'message_applicants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Applicants>;
};

/** on conflict condition type for table "message_applicants" */
export type Message_Applicants_On_Conflict = {
  constraint: Message_Applicants_Constraint;
  update_columns?: Array<Message_Applicants_Update_Column>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};

/** Ordering options when selecting data from "message_applicants". */
export type Message_Applicants_Order_By = {
  applicant?: Maybe<Applicants_Order_By>;
  applicant_id?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Messages_Old_Order_By>;
  message_id?: Maybe<Order_By>;
  sender_data?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: message_applicants */
export type Message_Applicants_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Message_Applicants_Prepend_Input = {
  sender_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "message_applicants" */
export enum Message_Applicants_Select_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  SenderData = 'sender_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "message_applicants" */
export type Message_Applicants_Set_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
  sender_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Message_Applicants_Stddev_Fields = {
  __typename?: 'message_applicants_stddev_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "message_applicants" */
export type Message_Applicants_Stddev_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Message_Applicants_Stddev_Pop_Fields = {
  __typename?: 'message_applicants_stddev_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "message_applicants" */
export type Message_Applicants_Stddev_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Message_Applicants_Stddev_Samp_Fields = {
  __typename?: 'message_applicants_stddev_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "message_applicants" */
export type Message_Applicants_Stddev_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Message_Applicants_Sum_Fields = {
  __typename?: 'message_applicants_sum_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "message_applicants" */
export type Message_Applicants_Sum_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "message_applicants" */
export enum Message_Applicants_Update_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  SenderData = 'sender_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Message_Applicants_Var_Pop_Fields = {
  __typename?: 'message_applicants_var_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "message_applicants" */
export type Message_Applicants_Var_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Message_Applicants_Var_Samp_Fields = {
  __typename?: 'message_applicants_var_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "message_applicants" */
export type Message_Applicants_Var_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Message_Applicants_Variance_Fields = {
  __typename?: 'message_applicants_variance_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "message_applicants" */
export type Message_Applicants_Variance_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "message_replies" */
export type Message_Replies = {
  __typename?: 'message_replies';
  /** An object relationship */
  applicant?: Maybe<Applicants>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  messageByMessageId: Messages_Old;
  message_id: Scalars['Int'];
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  sender_data?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "message_replies" */
export type Message_RepliesSender_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "message_replies" */
export type Message_Replies_Aggregate = {
  __typename?: 'message_replies_aggregate';
  aggregate?: Maybe<Message_Replies_Aggregate_Fields>;
  nodes: Array<Message_Replies>;
};

/** aggregate fields of "message_replies" */
export type Message_Replies_Aggregate_Fields = {
  __typename?: 'message_replies_aggregate_fields';
  avg?: Maybe<Message_Replies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Message_Replies_Max_Fields>;
  min?: Maybe<Message_Replies_Min_Fields>;
  stddev?: Maybe<Message_Replies_Stddev_Fields>;
  stddev_pop?: Maybe<Message_Replies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Message_Replies_Stddev_Samp_Fields>;
  sum?: Maybe<Message_Replies_Sum_Fields>;
  var_pop?: Maybe<Message_Replies_Var_Pop_Fields>;
  var_samp?: Maybe<Message_Replies_Var_Samp_Fields>;
  variance?: Maybe<Message_Replies_Variance_Fields>;
};


/** aggregate fields of "message_replies" */
export type Message_Replies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Replies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "message_replies" */
export type Message_Replies_Aggregate_Order_By = {
  avg?: Maybe<Message_Replies_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Replies_Max_Order_By>;
  min?: Maybe<Message_Replies_Min_Order_By>;
  stddev?: Maybe<Message_Replies_Stddev_Order_By>;
  stddev_pop?: Maybe<Message_Replies_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Message_Replies_Stddev_Samp_Order_By>;
  sum?: Maybe<Message_Replies_Sum_Order_By>;
  var_pop?: Maybe<Message_Replies_Var_Pop_Order_By>;
  var_samp?: Maybe<Message_Replies_Var_Samp_Order_By>;
  variance?: Maybe<Message_Replies_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Message_Replies_Append_Input = {
  sender_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "message_replies" */
export type Message_Replies_Arr_Rel_Insert_Input = {
  data: Array<Message_Replies_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Message_Replies_On_Conflict>;
};

/** aggregate avg on columns */
export type Message_Replies_Avg_Fields = {
  __typename?: 'message_replies_avg_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "message_replies" */
export type Message_Replies_Avg_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "message_replies". All fields are combined with a logical 'AND'. */
export type Message_Replies_Bool_Exp = {
  _and?: Maybe<Array<Message_Replies_Bool_Exp>>;
  _not?: Maybe<Message_Replies_Bool_Exp>;
  _or?: Maybe<Array<Message_Replies_Bool_Exp>>;
  applicant?: Maybe<Applicants_Bool_Exp>;
  applicant_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  messageByMessageId?: Maybe<Messages_Old_Bool_Exp>;
  message_id?: Maybe<Int_Comparison_Exp>;
  opened_at?: Maybe<Timestamptz_Comparison_Exp>;
  postmark_email_id?: Maybe<String_Comparison_Exp>;
  sender_data?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_replies" */
export enum Message_Replies_Constraint {
  /** unique or primary key constraint */
  MessageRepliesPkey = 'message_replies_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Message_Replies_Delete_At_Path_Input = {
  sender_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Message_Replies_Delete_Elem_Input = {
  sender_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Message_Replies_Delete_Key_Input = {
  sender_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "message_replies" */
export type Message_Replies_Inc_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "message_replies" */
export type Message_Replies_Insert_Input = {
  applicant?: Maybe<Applicants_Obj_Rel_Insert_Input>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  messageByMessageId?: Maybe<Messages_Old_Obj_Rel_Insert_Input>;
  message_id?: Maybe<Scalars['Int']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  sender_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Message_Replies_Max_Fields = {
  __typename?: 'message_replies_max_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['Int']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "message_replies" */
export type Message_Replies_Max_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  opened_at?: Maybe<Order_By>;
  postmark_email_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Replies_Min_Fields = {
  __typename?: 'message_replies_min_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['Int']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "message_replies" */
export type Message_Replies_Min_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  opened_at?: Maybe<Order_By>;
  postmark_email_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "message_replies" */
export type Message_Replies_Mutation_Response = {
  __typename?: 'message_replies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Replies>;
};

/** on conflict condition type for table "message_replies" */
export type Message_Replies_On_Conflict = {
  constraint: Message_Replies_Constraint;
  update_columns?: Array<Message_Replies_Update_Column>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};

/** Ordering options when selecting data from "message_replies". */
export type Message_Replies_Order_By = {
  applicant?: Maybe<Applicants_Order_By>;
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  messageByMessageId?: Maybe<Messages_Old_Order_By>;
  message_id?: Maybe<Order_By>;
  opened_at?: Maybe<Order_By>;
  postmark_email_id?: Maybe<Order_By>;
  sender_data?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: message_replies */
export type Message_Replies_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Message_Replies_Prepend_Input = {
  sender_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "message_replies" */
export enum Message_Replies_Select_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  PostmarkEmailId = 'postmark_email_id',
  /** column name */
  SenderData = 'sender_data',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "message_replies" */
export type Message_Replies_Set_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['Int']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  sender_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Message_Replies_Stddev_Fields = {
  __typename?: 'message_replies_stddev_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "message_replies" */
export type Message_Replies_Stddev_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Message_Replies_Stddev_Pop_Fields = {
  __typename?: 'message_replies_stddev_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "message_replies" */
export type Message_Replies_Stddev_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Message_Replies_Stddev_Samp_Fields = {
  __typename?: 'message_replies_stddev_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "message_replies" */
export type Message_Replies_Stddev_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Message_Replies_Sum_Fields = {
  __typename?: 'message_replies_sum_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "message_replies" */
export type Message_Replies_Sum_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** update columns of table "message_replies" */
export enum Message_Replies_Update_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  PostmarkEmailId = 'postmark_email_id',
  /** column name */
  SenderData = 'sender_data',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Message_Replies_Var_Pop_Fields = {
  __typename?: 'message_replies_var_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "message_replies" */
export type Message_Replies_Var_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Message_Replies_Var_Samp_Fields = {
  __typename?: 'message_replies_var_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "message_replies" */
export type Message_Replies_Var_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Message_Replies_Variance_Fields = {
  __typename?: 'message_replies_variance_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  message_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "message_replies" */
export type Message_Replies_Variance_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
};

/** columns and relationships of "message_templates" */
export type Message_Templates = {
  __typename?: 'message_templates';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
  type?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "message_templates" */
export type Message_Templates_Aggregate = {
  __typename?: 'message_templates_aggregate';
  aggregate?: Maybe<Message_Templates_Aggregate_Fields>;
  nodes: Array<Message_Templates>;
};

/** aggregate fields of "message_templates" */
export type Message_Templates_Aggregate_Fields = {
  __typename?: 'message_templates_aggregate_fields';
  avg?: Maybe<Message_Templates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Message_Templates_Max_Fields>;
  min?: Maybe<Message_Templates_Min_Fields>;
  stddev?: Maybe<Message_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Message_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Message_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Message_Templates_Sum_Fields>;
  var_pop?: Maybe<Message_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Message_Templates_Var_Samp_Fields>;
  variance?: Maybe<Message_Templates_Variance_Fields>;
};


/** aggregate fields of "message_templates" */
export type Message_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "message_templates" */
export type Message_Templates_Aggregate_Order_By = {
  avg?: Maybe<Message_Templates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Templates_Max_Order_By>;
  min?: Maybe<Message_Templates_Min_Order_By>;
  stddev?: Maybe<Message_Templates_Stddev_Order_By>;
  stddev_pop?: Maybe<Message_Templates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Message_Templates_Stddev_Samp_Order_By>;
  sum?: Maybe<Message_Templates_Sum_Order_By>;
  var_pop?: Maybe<Message_Templates_Var_Pop_Order_By>;
  var_samp?: Maybe<Message_Templates_Var_Samp_Order_By>;
  variance?: Maybe<Message_Templates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "message_templates" */
export type Message_Templates_Arr_Rel_Insert_Input = {
  data: Array<Message_Templates_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Message_Templates_On_Conflict>;
};

/** aggregate avg on columns */
export type Message_Templates_Avg_Fields = {
  __typename?: 'message_templates_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "message_templates" */
export type Message_Templates_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "message_templates". All fields are combined with a logical 'AND'. */
export type Message_Templates_Bool_Exp = {
  _and?: Maybe<Array<Message_Templates_Bool_Exp>>;
  _not?: Maybe<Message_Templates_Bool_Exp>;
  _or?: Maybe<Array<Message_Templates_Bool_Exp>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_templates" */
export enum Message_Templates_Constraint {
  /** unique or primary key constraint */
  MessageTemplatesPkey = 'message_templates_pkey'
}

/** input type for incrementing numeric columns in table "message_templates" */
export type Message_Templates_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "message_templates" */
export type Message_Templates_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Message_Templates_Max_Fields = {
  __typename?: 'message_templates_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "message_templates" */
export type Message_Templates_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Templates_Min_Fields = {
  __typename?: 'message_templates_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "message_templates" */
export type Message_Templates_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "message_templates" */
export type Message_Templates_Mutation_Response = {
  __typename?: 'message_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Templates>;
};

/** on conflict condition type for table "message_templates" */
export type Message_Templates_On_Conflict = {
  constraint: Message_Templates_Constraint;
  update_columns?: Array<Message_Templates_Update_Column>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "message_templates". */
export type Message_Templates_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: message_templates */
export type Message_Templates_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "message_templates" */
export enum Message_Templates_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  Subject = 'subject',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "message_templates" */
export type Message_Templates_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Message_Templates_Stddev_Fields = {
  __typename?: 'message_templates_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "message_templates" */
export type Message_Templates_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Message_Templates_Stddev_Pop_Fields = {
  __typename?: 'message_templates_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "message_templates" */
export type Message_Templates_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Message_Templates_Stddev_Samp_Fields = {
  __typename?: 'message_templates_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "message_templates" */
export type Message_Templates_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Message_Templates_Sum_Fields = {
  __typename?: 'message_templates_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "message_templates" */
export type Message_Templates_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** update columns of table "message_templates" */
export enum Message_Templates_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  Subject = 'subject',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Message_Templates_Var_Pop_Fields = {
  __typename?: 'message_templates_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "message_templates" */
export type Message_Templates_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Message_Templates_Var_Samp_Fields = {
  __typename?: 'message_templates_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "message_templates" */
export type Message_Templates_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Message_Templates_Variance_Fields = {
  __typename?: 'message_templates_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "message_templates" */
export type Message_Templates_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** columns and relationships of "messages" */
export type Messages = {
  __typename?: 'messages';
  /** An object relationship */
  applicant: Applicants;
  applicant_id: Scalars['Int'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['time']>;
  id: Scalars['Int'];
  /** An object relationship */
  inbound_message?: Maybe<Inbound_Messages>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  parent?: Maybe<Messages>;
  parent_id?: Maybe<Scalars['Int']>;
  provider_message_id?: Maybe<Scalars['String']>;
  provider_thread_id?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  replies: Array<Messages>;
  /** An aggregate relationship */
  replies_aggregate: Messages_Aggregate;
  subject?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "messages" */
export type MessagesRepliesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "messages" */
export type MessagesReplies_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  __typename?: 'messages_aggregate';
  aggregate?: Maybe<Messages_Aggregate_Fields>;
  nodes: Array<Messages>;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  __typename?: 'messages_aggregate_fields';
  avg?: Maybe<Messages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Messages_Max_Fields>;
  min?: Maybe<Messages_Min_Fields>;
  stddev?: Maybe<Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Messages_Sum_Fields>;
  var_pop?: Maybe<Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Messages_Var_Samp_Fields>;
  variance?: Maybe<Messages_Variance_Fields>;
};


/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Messages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "messages" */
export type Messages_Aggregate_Order_By = {
  avg?: Maybe<Messages_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Messages_Max_Order_By>;
  min?: Maybe<Messages_Min_Order_By>;
  stddev?: Maybe<Messages_Stddev_Order_By>;
  stddev_pop?: Maybe<Messages_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Messages_Stddev_Samp_Order_By>;
  sum?: Maybe<Messages_Sum_Order_By>;
  var_pop?: Maybe<Messages_Var_Pop_Order_By>;
  var_samp?: Maybe<Messages_Var_Samp_Order_By>;
  variance?: Maybe<Messages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "messages" */
export type Messages_Arr_Rel_Insert_Input = {
  data: Array<Messages_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Messages_On_Conflict>;
};

/** aggregate avg on columns */
export type Messages_Avg_Fields = {
  __typename?: 'messages_avg_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "messages" */
export type Messages_Avg_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  _and?: Maybe<Array<Messages_Bool_Exp>>;
  _not?: Maybe<Messages_Bool_Exp>;
  _or?: Maybe<Array<Messages_Bool_Exp>>;
  applicant?: Maybe<Applicants_Bool_Exp>;
  applicant_id?: Maybe<Int_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Time_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_message?: Maybe<Inbound_Messages_Bool_Exp>;
  inbound_message_id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  opened_at?: Maybe<Timestamptz_Comparison_Exp>;
  parent?: Maybe<Messages_Bool_Exp>;
  parent_id?: Maybe<Int_Comparison_Exp>;
  provider_message_id?: Maybe<String_Comparison_Exp>;
  provider_thread_id?: Maybe<String_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
  replies?: Maybe<Messages_Bool_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages" */
export enum Messages_Constraint {
  /** unique or primary key constraint */
  MessagesPkey1 = 'messages_pkey1'
}

/** input type for incrementing numeric columns in table "messages" */
export type Messages_Inc_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  applicant?: Maybe<Applicants_Obj_Rel_Insert_Input>;
  applicant_id?: Maybe<Scalars['Int']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['time']>;
  id?: Maybe<Scalars['Int']>;
  inbound_message?: Maybe<Inbound_Messages_Obj_Rel_Insert_Input>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  parent?: Maybe<Messages_Obj_Rel_Insert_Input>;
  parent_id?: Maybe<Scalars['Int']>;
  provider_message_id?: Maybe<Scalars['String']>;
  provider_thread_id?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  replies?: Maybe<Messages_Arr_Rel_Insert_Input>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
  __typename?: 'messages_max_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['Int']>;
  provider_message_id?: Maybe<Scalars['String']>;
  provider_thread_id?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "messages" */
export type Messages_Max_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  opened_at?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  provider_message_id?: Maybe<Order_By>;
  provider_thread_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
  __typename?: 'messages_min_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['Int']>;
  provider_message_id?: Maybe<Scalars['String']>;
  provider_thread_id?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "messages" */
export type Messages_Min_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  opened_at?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  provider_message_id?: Maybe<Order_By>;
  provider_thread_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  __typename?: 'messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages>;
};

/** input type for inserting object relation for remote table "messages" */
export type Messages_Obj_Rel_Insert_Input = {
  data: Messages_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Messages_On_Conflict>;
};

/** columns and relationships of "messages_old" */
export type Messages_Old = {
  __typename?: 'messages_old';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_read: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  /** An array relationship */
  message_applicants: Array<Message_Applicants>;
  /** An aggregate relationship */
  message_applicants_aggregate: Message_Applicants_Aggregate;
  /** An array relationship */
  message_replies: Array<Message_Replies>;
  /** An aggregate relationship */
  message_replies_aggregate: Message_Replies_Aggregate;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  postmark_message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "messages_old" */
export type Messages_OldMessage_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "messages_old" */
export type Messages_OldMessage_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "messages_old" */
export type Messages_OldMessage_RepliesArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


/** columns and relationships of "messages_old" */
export type Messages_OldMessage_Replies_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};

/** aggregated selection of "messages_old" */
export type Messages_Old_Aggregate = {
  __typename?: 'messages_old_aggregate';
  aggregate?: Maybe<Messages_Old_Aggregate_Fields>;
  nodes: Array<Messages_Old>;
};

/** aggregate fields of "messages_old" */
export type Messages_Old_Aggregate_Fields = {
  __typename?: 'messages_old_aggregate_fields';
  avg?: Maybe<Messages_Old_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Messages_Old_Max_Fields>;
  min?: Maybe<Messages_Old_Min_Fields>;
  stddev?: Maybe<Messages_Old_Stddev_Fields>;
  stddev_pop?: Maybe<Messages_Old_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Messages_Old_Stddev_Samp_Fields>;
  sum?: Maybe<Messages_Old_Sum_Fields>;
  var_pop?: Maybe<Messages_Old_Var_Pop_Fields>;
  var_samp?: Maybe<Messages_Old_Var_Samp_Fields>;
  variance?: Maybe<Messages_Old_Variance_Fields>;
};


/** aggregate fields of "messages_old" */
export type Messages_Old_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Messages_Old_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Messages_Old_Avg_Fields = {
  __typename?: 'messages_old_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "messages_old". All fields are combined with a logical 'AND'. */
export type Messages_Old_Bool_Exp = {
  _and?: Maybe<Array<Messages_Old_Bool_Exp>>;
  _not?: Maybe<Messages_Old_Bool_Exp>;
  _or?: Maybe<Array<Messages_Old_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_read?: Maybe<Boolean_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  message_applicants?: Maybe<Message_Applicants_Bool_Exp>;
  message_replies?: Maybe<Message_Replies_Bool_Exp>;
  opened_at?: Maybe<Timestamptz_Comparison_Exp>;
  postmark_email_id?: Maybe<String_Comparison_Exp>;
  postmark_message_id?: Maybe<String_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages_old" */
export enum Messages_Old_Constraint {
  /** unique or primary key constraint */
  MessagesPkey = 'messages_pkey'
}

/** input type for incrementing numeric columns in table "messages_old" */
export type Messages_Old_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "messages_old" */
export type Messages_Old_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_read?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  message_applicants?: Maybe<Message_Applicants_Arr_Rel_Insert_Input>;
  message_replies?: Maybe<Message_Replies_Arr_Rel_Insert_Input>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  postmark_message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Messages_Old_Max_Fields = {
  __typename?: 'messages_old_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  postmark_message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Messages_Old_Min_Fields = {
  __typename?: 'messages_old_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  postmark_message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "messages_old" */
export type Messages_Old_Mutation_Response = {
  __typename?: 'messages_old_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages_Old>;
};

/** input type for inserting object relation for remote table "messages_old" */
export type Messages_Old_Obj_Rel_Insert_Input = {
  data: Messages_Old_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Messages_Old_On_Conflict>;
};

/** on conflict condition type for table "messages_old" */
export type Messages_Old_On_Conflict = {
  constraint: Messages_Old_Constraint;
  update_columns?: Array<Messages_Old_Update_Column>;
  where?: Maybe<Messages_Old_Bool_Exp>;
};

/** Ordering options when selecting data from "messages_old". */
export type Messages_Old_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_read?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  message_applicants_aggregate?: Maybe<Message_Applicants_Aggregate_Order_By>;
  message_replies_aggregate?: Maybe<Message_Replies_Aggregate_Order_By>;
  opened_at?: Maybe<Order_By>;
  postmark_email_id?: Maybe<Order_By>;
  postmark_message_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: messages_old */
export type Messages_Old_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "messages_old" */
export enum Messages_Old_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  Message = 'message',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  PostmarkEmailId = 'postmark_email_id',
  /** column name */
  PostmarkMessageId = 'postmark_message_id',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "messages_old" */
export type Messages_Old_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_read?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  postmark_email_id?: Maybe<Scalars['String']>;
  postmark_message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Messages_Old_Stddev_Fields = {
  __typename?: 'messages_old_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Messages_Old_Stddev_Pop_Fields = {
  __typename?: 'messages_old_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Messages_Old_Stddev_Samp_Fields = {
  __typename?: 'messages_old_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Messages_Old_Sum_Fields = {
  __typename?: 'messages_old_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "messages_old" */
export enum Messages_Old_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  Message = 'message',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  PostmarkEmailId = 'postmark_email_id',
  /** column name */
  PostmarkMessageId = 'postmark_message_id',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Messages_Old_Var_Pop_Fields = {
  __typename?: 'messages_old_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Messages_Old_Var_Samp_Fields = {
  __typename?: 'messages_old_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Messages_Old_Variance_Fields = {
  __typename?: 'messages_old_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** on conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  constraint: Messages_Constraint;
  update_columns?: Array<Messages_Update_Column>;
  where?: Maybe<Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  applicant?: Maybe<Applicants_Order_By>;
  applicant_id?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message?: Maybe<Inbound_Messages_Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  opened_at?: Maybe<Order_By>;
  parent?: Maybe<Messages_Order_By>;
  parent_id?: Maybe<Order_By>;
  provider_message_id?: Maybe<Order_By>;
  provider_thread_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  replies_aggregate?: Maybe<Messages_Aggregate_Order_By>;
  subject?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "messages" */
export enum Messages_Select_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundMessageId = 'inbound_message_id',
  /** column name */
  Message = 'message',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProviderMessageId = 'provider_message_id',
  /** column name */
  ProviderThreadId = 'provider_thread_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['time']>;
  id?: Maybe<Scalars['Int']>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['Int']>;
  provider_message_id?: Maybe<Scalars['String']>;
  provider_thread_id?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Messages_Stddev_Fields = {
  __typename?: 'messages_stddev_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "messages" */
export type Messages_Stddev_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Messages_Stddev_Pop_Fields = {
  __typename?: 'messages_stddev_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "messages" */
export type Messages_Stddev_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Messages_Stddev_Samp_Fields = {
  __typename?: 'messages_stddev_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "messages" */
export type Messages_Stddev_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Messages_Sum_Fields = {
  __typename?: 'messages_sum_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_message_id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "messages" */
export type Messages_Sum_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "messages" */
export enum Messages_Update_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundMessageId = 'inbound_message_id',
  /** column name */
  Message = 'message',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProviderMessageId = 'provider_message_id',
  /** column name */
  ProviderThreadId = 'provider_thread_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Messages_Var_Pop_Fields = {
  __typename?: 'messages_var_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "messages" */
export type Messages_Var_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Messages_Var_Samp_Fields = {
  __typename?: 'messages_var_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "messages" */
export type Messages_Var_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Messages_Variance_Fields = {
  __typename?: 'messages_variance_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_message_id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "messages" */
export type Messages_Variance_Order_By = {
  applicant_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_message_id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "activity_log_applicants" */
  delete_activity_log_applicants?: Maybe<Activity_Log_Applicants_Mutation_Response>;
  /** delete single row from the table: "activity_log_applicants" */
  delete_activity_log_applicants_by_pk?: Maybe<Activity_Log_Applicants>;
  /** delete data from the table: "activity_log_events" */
  delete_activity_log_events?: Maybe<Activity_Log_Events_Mutation_Response>;
  /** delete single row from the table: "activity_log_events" */
  delete_activity_log_events_by_pk?: Maybe<Activity_Log_Events>;
  /** delete data from the table: "api_tokens" */
  delete_api_tokens?: Maybe<Api_Tokens_Mutation_Response>;
  /** delete single row from the table: "api_tokens" */
  delete_api_tokens_by_pk?: Maybe<Api_Tokens>;
  /** delete data from the table: "applicants" */
  delete_applicants?: Maybe<Applicants_Mutation_Response>;
  /** delete single row from the table: "applicants" */
  delete_applicants_by_pk?: Maybe<Applicants>;
  /** delete data from the table: "companies" */
  delete_companies?: Maybe<Companies_Mutation_Response>;
  /** delete single row from the table: "companies" */
  delete_companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "company_users" */
  delete_company_users?: Maybe<Company_Users_Mutation_Response>;
  /** delete single row from the table: "company_users" */
  delete_company_users_by_pk?: Maybe<Company_Users>;
  /** delete data from the table: "email_reset" */
  delete_email_reset?: Maybe<Email_Reset_Mutation_Response>;
  /** delete single row from the table: "email_reset" */
  delete_email_reset_by_pk?: Maybe<Email_Reset>;
  /** delete data from the table: "failed_jobs" */
  delete_failed_jobs?: Maybe<Failed_Jobs_Mutation_Response>;
  /** delete single row from the table: "failed_jobs" */
  delete_failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  delete_file?: Maybe<DeletefileResponse>;
  /** delete data from the table: "file_stores" */
  delete_file_stores?: Maybe<File_Stores_Mutation_Response>;
  /** delete single row from the table: "file_stores" */
  delete_file_stores_by_pk?: Maybe<File_Stores>;
  /** delete data from the table: "inbound_mails" */
  delete_inbound_mails?: Maybe<Inbound_Mails_Mutation_Response>;
  /** delete single row from the table: "inbound_mails" */
  delete_inbound_mails_by_pk?: Maybe<Inbound_Mails>;
  /** delete data from the table: "inbound_messages" */
  delete_inbound_messages?: Maybe<Inbound_Messages_Mutation_Response>;
  /** delete single row from the table: "inbound_messages" */
  delete_inbound_messages_by_pk?: Maybe<Inbound_Messages>;
  /** delete data from the table: "invoices" */
  delete_invoices?: Maybe<Invoices_Mutation_Response>;
  /** delete single row from the table: "invoices" */
  delete_invoices_by_pk?: Maybe<Invoices>;
  /** delete data from the table: "links" */
  delete_links?: Maybe<Links_Mutation_Response>;
  /** delete single row from the table: "links" */
  delete_links_by_pk?: Maybe<Links>;
  /** delete data from the table: "mailbox_inbound_emails" */
  delete_mailbox_inbound_emails?: Maybe<Mailbox_Inbound_Emails_Mutation_Response>;
  /** delete single row from the table: "mailbox_inbound_emails" */
  delete_mailbox_inbound_emails_by_pk?: Maybe<Mailbox_Inbound_Emails>;
  /** delete data from the table: "message_applicants" */
  delete_message_applicants?: Maybe<Message_Applicants_Mutation_Response>;
  /** delete single row from the table: "message_applicants" */
  delete_message_applicants_by_pk?: Maybe<Message_Applicants>;
  /** delete data from the table: "message_replies" */
  delete_message_replies?: Maybe<Message_Replies_Mutation_Response>;
  /** delete single row from the table: "message_replies" */
  delete_message_replies_by_pk?: Maybe<Message_Replies>;
  /** delete data from the table: "message_templates" */
  delete_message_templates?: Maybe<Message_Templates_Mutation_Response>;
  /** delete single row from the table: "message_templates" */
  delete_message_templates_by_pk?: Maybe<Message_Templates>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<Messages_Mutation_Response>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<Messages>;
  /** delete data from the table: "messages_old" */
  delete_messages_old?: Maybe<Messages_Old_Mutation_Response>;
  /** delete single row from the table: "messages_old" */
  delete_messages_old_by_pk?: Maybe<Messages_Old>;
  /** delete data from the table: "notes" */
  delete_notes?: Maybe<Notes_Mutation_Response>;
  /** delete single row from the table: "notes" */
  delete_notes_by_pk?: Maybe<Notes>;
  /** delete data from the table: "online_payments" */
  delete_online_payments?: Maybe<Online_Payments_Mutation_Response>;
  /** delete single row from the table: "online_payments" */
  delete_online_payments_by_pk?: Maybe<Online_Payments>;
  /** delete data from the table: "password_reset" */
  delete_password_reset?: Maybe<Password_Reset_Mutation_Response>;
  /** delete single row from the table: "password_reset" */
  delete_password_reset_by_pk?: Maybe<Password_Reset>;
  /** delete data from the table: "payments" */
  delete_payments?: Maybe<Payments_Mutation_Response>;
  /** delete single row from the table: "payments" */
  delete_payments_by_pk?: Maybe<Payments>;
  /** delete data from the table: "stages" */
  delete_stages?: Maybe<Stages_Mutation_Response>;
  /** delete single row from the table: "stages" */
  delete_stages_by_pk?: Maybe<Stages>;
  /** delete data from the table: "subscribers" */
  delete_subscribers?: Maybe<Subscribers_Mutation_Response>;
  /** delete single row from the table: "subscribers" */
  delete_subscribers_by_pk?: Maybe<Subscribers>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vacancies" */
  delete_vacancies?: Maybe<Vacancies_Mutation_Response>;
  /** delete single row from the table: "vacancies" */
  delete_vacancies_by_pk?: Maybe<Vacancies>;
  email_reset?: Maybe<EmailResetResponse>;
  forgot_password_request?: Maybe<ForgotPasswordRequestResponse>;
  generate_api_token?: Maybe<GenerateApiTokenResponse>;
  /** insert data into the table: "activity_log_applicants" */
  insert_activity_log_applicants?: Maybe<Activity_Log_Applicants_Mutation_Response>;
  /** insert a single row into the table: "activity_log_applicants" */
  insert_activity_log_applicants_one?: Maybe<Activity_Log_Applicants>;
  /** insert data into the table: "activity_log_events" */
  insert_activity_log_events?: Maybe<Activity_Log_Events_Mutation_Response>;
  /** insert a single row into the table: "activity_log_events" */
  insert_activity_log_events_one?: Maybe<Activity_Log_Events>;
  /** insert data into the table: "api_tokens" */
  insert_api_tokens?: Maybe<Api_Tokens_Mutation_Response>;
  /** insert a single row into the table: "api_tokens" */
  insert_api_tokens_one?: Maybe<Api_Tokens>;
  /** insert data into the table: "applicants" */
  insert_applicants?: Maybe<Applicants_Mutation_Response>;
  /** insert a single row into the table: "applicants" */
  insert_applicants_one?: Maybe<Applicants>;
  /** insert data into the table: "companies" */
  insert_companies?: Maybe<Companies_Mutation_Response>;
  /** insert a single row into the table: "companies" */
  insert_companies_one?: Maybe<Companies>;
  /** insert data into the table: "company_users" */
  insert_company_users?: Maybe<Company_Users_Mutation_Response>;
  /** insert a single row into the table: "company_users" */
  insert_company_users_one?: Maybe<Company_Users>;
  /** insert data into the table: "email_reset" */
  insert_email_reset?: Maybe<Email_Reset_Mutation_Response>;
  /** insert a single row into the table: "email_reset" */
  insert_email_reset_one?: Maybe<Email_Reset>;
  /** insert data into the table: "failed_jobs" */
  insert_failed_jobs?: Maybe<Failed_Jobs_Mutation_Response>;
  /** insert a single row into the table: "failed_jobs" */
  insert_failed_jobs_one?: Maybe<Failed_Jobs>;
  /** insert data into the table: "file_stores" */
  insert_file_stores?: Maybe<File_Stores_Mutation_Response>;
  /** insert a single row into the table: "file_stores" */
  insert_file_stores_one?: Maybe<File_Stores>;
  /** insert data into the table: "inbound_mails" */
  insert_inbound_mails?: Maybe<Inbound_Mails_Mutation_Response>;
  /** insert a single row into the table: "inbound_mails" */
  insert_inbound_mails_one?: Maybe<Inbound_Mails>;
  /** insert data into the table: "inbound_messages" */
  insert_inbound_messages?: Maybe<Inbound_Messages_Mutation_Response>;
  /** insert a single row into the table: "inbound_messages" */
  insert_inbound_messages_one?: Maybe<Inbound_Messages>;
  /** insert data into the table: "invoices" */
  insert_invoices?: Maybe<Invoices_Mutation_Response>;
  /** insert a single row into the table: "invoices" */
  insert_invoices_one?: Maybe<Invoices>;
  /** insert data into the table: "links" */
  insert_links?: Maybe<Links_Mutation_Response>;
  /** insert a single row into the table: "links" */
  insert_links_one?: Maybe<Links>;
  /** insert data into the table: "mailbox_inbound_emails" */
  insert_mailbox_inbound_emails?: Maybe<Mailbox_Inbound_Emails_Mutation_Response>;
  /** insert a single row into the table: "mailbox_inbound_emails" */
  insert_mailbox_inbound_emails_one?: Maybe<Mailbox_Inbound_Emails>;
  /** insert data into the table: "message_applicants" */
  insert_message_applicants?: Maybe<Message_Applicants_Mutation_Response>;
  /** insert a single row into the table: "message_applicants" */
  insert_message_applicants_one?: Maybe<Message_Applicants>;
  /** insert data into the table: "message_replies" */
  insert_message_replies?: Maybe<Message_Replies_Mutation_Response>;
  /** insert a single row into the table: "message_replies" */
  insert_message_replies_one?: Maybe<Message_Replies>;
  /** insert data into the table: "message_templates" */
  insert_message_templates?: Maybe<Message_Templates_Mutation_Response>;
  /** insert a single row into the table: "message_templates" */
  insert_message_templates_one?: Maybe<Message_Templates>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<Messages_Mutation_Response>;
  /** insert data into the table: "messages_old" */
  insert_messages_old?: Maybe<Messages_Old_Mutation_Response>;
  /** insert a single row into the table: "messages_old" */
  insert_messages_old_one?: Maybe<Messages_Old>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<Messages>;
  /** insert data into the table: "notes" */
  insert_notes?: Maybe<Notes_Mutation_Response>;
  /** insert a single row into the table: "notes" */
  insert_notes_one?: Maybe<Notes>;
  /** insert data into the table: "online_payments" */
  insert_online_payments?: Maybe<Online_Payments_Mutation_Response>;
  /** insert a single row into the table: "online_payments" */
  insert_online_payments_one?: Maybe<Online_Payments>;
  /** insert data into the table: "password_reset" */
  insert_password_reset?: Maybe<Password_Reset_Mutation_Response>;
  /** insert a single row into the table: "password_reset" */
  insert_password_reset_one?: Maybe<Password_Reset>;
  /** insert data into the table: "payments" */
  insert_payments?: Maybe<Payments_Mutation_Response>;
  /** insert a single row into the table: "payments" */
  insert_payments_one?: Maybe<Payments>;
  /** insert data into the table: "stages" */
  insert_stages?: Maybe<Stages_Mutation_Response>;
  /** insert a single row into the table: "stages" */
  insert_stages_one?: Maybe<Stages>;
  /** insert data into the table: "subscribers" */
  insert_subscribers?: Maybe<Subscribers_Mutation_Response>;
  /** insert a single row into the table: "subscribers" */
  insert_subscribers_one?: Maybe<Subscribers>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vacancies" */
  insert_vacancies?: Maybe<Vacancies_Mutation_Response>;
  /** insert a single row into the table: "vacancies" */
  insert_vacancies_one?: Maybe<Vacancies>;
  insert_vacancy?: Maybe<VacanciesResponse>;
  invite_teammate?: Maybe<InviteTeammateResponse>;
  login?: Maybe<LoginResponse>;
  password_reset?: Maybe<ForgotPasswordResetResponse>;
  register?: Maybe<UserResponse>;
  revert_email_reset?: Maybe<RevertEmailResetResponse>;
  send_message?: Maybe<SendMessageResponse>;
  stripe_cancel_subscription?: Maybe<StripeCancelSubscriptionResponse>;
  stripe_create_checkout_session?: Maybe<StripeCheckoutSessionResponse>;
  stripe_customer_portal?: Maybe<StripeCustomerPortalResponse>;
  unsubscribe?: Maybe<UnsubscribeResponse>;
  /** update data of the table: "activity_log_applicants" */
  update_activity_log_applicants?: Maybe<Activity_Log_Applicants_Mutation_Response>;
  /** update single row of the table: "activity_log_applicants" */
  update_activity_log_applicants_by_pk?: Maybe<Activity_Log_Applicants>;
  /** update data of the table: "activity_log_events" */
  update_activity_log_events?: Maybe<Activity_Log_Events_Mutation_Response>;
  /** update single row of the table: "activity_log_events" */
  update_activity_log_events_by_pk?: Maybe<Activity_Log_Events>;
  /** update data of the table: "api_tokens" */
  update_api_tokens?: Maybe<Api_Tokens_Mutation_Response>;
  /** update single row of the table: "api_tokens" */
  update_api_tokens_by_pk?: Maybe<Api_Tokens>;
  /** update data of the table: "applicants" */
  update_applicants?: Maybe<Applicants_Mutation_Response>;
  /** update single row of the table: "applicants" */
  update_applicants_by_pk?: Maybe<Applicants>;
  /** update data of the table: "companies" */
  update_companies?: Maybe<Companies_Mutation_Response>;
  /** update single row of the table: "companies" */
  update_companies_by_pk?: Maybe<Companies>;
  /** update data of the table: "company_users" */
  update_company_users?: Maybe<Company_Users_Mutation_Response>;
  /** update single row of the table: "company_users" */
  update_company_users_by_pk?: Maybe<Company_Users>;
  update_email?: Maybe<UpdateEmailResponse>;
  /** update data of the table: "email_reset" */
  update_email_reset?: Maybe<Email_Reset_Mutation_Response>;
  /** update single row of the table: "email_reset" */
  update_email_reset_by_pk?: Maybe<Email_Reset>;
  /** update data of the table: "failed_jobs" */
  update_failed_jobs?: Maybe<Failed_Jobs_Mutation_Response>;
  /** update single row of the table: "failed_jobs" */
  update_failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** update data of the table: "file_stores" */
  update_file_stores?: Maybe<File_Stores_Mutation_Response>;
  /** update single row of the table: "file_stores" */
  update_file_stores_by_pk?: Maybe<File_Stores>;
  /** update data of the table: "inbound_mails" */
  update_inbound_mails?: Maybe<Inbound_Mails_Mutation_Response>;
  /** update single row of the table: "inbound_mails" */
  update_inbound_mails_by_pk?: Maybe<Inbound_Mails>;
  /** update data of the table: "inbound_messages" */
  update_inbound_messages?: Maybe<Inbound_Messages_Mutation_Response>;
  /** update single row of the table: "inbound_messages" */
  update_inbound_messages_by_pk?: Maybe<Inbound_Messages>;
  /** update data of the table: "invoices" */
  update_invoices?: Maybe<Invoices_Mutation_Response>;
  /** update single row of the table: "invoices" */
  update_invoices_by_pk?: Maybe<Invoices>;
  /** update data of the table: "links" */
  update_links?: Maybe<Links_Mutation_Response>;
  /** update single row of the table: "links" */
  update_links_by_pk?: Maybe<Links>;
  /** update data of the table: "mailbox_inbound_emails" */
  update_mailbox_inbound_emails?: Maybe<Mailbox_Inbound_Emails_Mutation_Response>;
  /** update single row of the table: "mailbox_inbound_emails" */
  update_mailbox_inbound_emails_by_pk?: Maybe<Mailbox_Inbound_Emails>;
  /** update data of the table: "message_applicants" */
  update_message_applicants?: Maybe<Message_Applicants_Mutation_Response>;
  /** update single row of the table: "message_applicants" */
  update_message_applicants_by_pk?: Maybe<Message_Applicants>;
  /** update data of the table: "message_replies" */
  update_message_replies?: Maybe<Message_Replies_Mutation_Response>;
  /** update single row of the table: "message_replies" */
  update_message_replies_by_pk?: Maybe<Message_Replies>;
  /** update data of the table: "message_templates" */
  update_message_templates?: Maybe<Message_Templates_Mutation_Response>;
  /** update single row of the table: "message_templates" */
  update_message_templates_by_pk?: Maybe<Message_Templates>;
  /** update data of the table: "messages" */
  update_messages?: Maybe<Messages_Mutation_Response>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<Messages>;
  /** update data of the table: "messages_old" */
  update_messages_old?: Maybe<Messages_Old_Mutation_Response>;
  /** update single row of the table: "messages_old" */
  update_messages_old_by_pk?: Maybe<Messages_Old>;
  /** update data of the table: "notes" */
  update_notes?: Maybe<Notes_Mutation_Response>;
  /** update single row of the table: "notes" */
  update_notes_by_pk?: Maybe<Notes>;
  /** update data of the table: "online_payments" */
  update_online_payments?: Maybe<Online_Payments_Mutation_Response>;
  /** update single row of the table: "online_payments" */
  update_online_payments_by_pk?: Maybe<Online_Payments>;
  /** update data of the table: "password_reset" */
  update_password_reset?: Maybe<Password_Reset_Mutation_Response>;
  /** update single row of the table: "password_reset" */
  update_password_reset_by_pk?: Maybe<Password_Reset>;
  /** update data of the table: "payments" */
  update_payments?: Maybe<Payments_Mutation_Response>;
  /** update single row of the table: "payments" */
  update_payments_by_pk?: Maybe<Payments>;
  update_profile?: Maybe<UpdateProfileResponse>;
  /** update data of the table: "stages" */
  update_stages?: Maybe<Stages_Mutation_Response>;
  /** update single row of the table: "stages" */
  update_stages_by_pk?: Maybe<Stages>;
  /** update data of the table: "subscribers" */
  update_subscribers?: Maybe<Subscribers_Mutation_Response>;
  /** update single row of the table: "subscribers" */
  update_subscribers_by_pk?: Maybe<Subscribers>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vacancies" */
  update_vacancies?: Maybe<Vacancies_Mutation_Response>;
  /** update single row of the table: "vacancies" */
  update_vacancies_by_pk?: Maybe<Vacancies>;
};


/** mutation root */
export type Mutation_RootDelete_Activity_Log_ApplicantsArgs = {
  where: Activity_Log_Applicants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activity_Log_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Activity_Log_EventsArgs = {
  where: Activity_Log_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activity_Log_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Api_TokensArgs = {
  where: Api_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Api_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ApplicantsArgs = {
  where: Applicants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompaniesArgs = {
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Companies_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_UsersArgs = {
  where: Company_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Email_ResetArgs = {
  where: Email_Reset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Reset_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Failed_JobsArgs = {
  where: Failed_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Failed_Jobs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  fileable_id?: Maybe<Array<Scalars['Int']>>;
};


/** mutation root */
export type Mutation_RootDelete_File_StoresArgs = {
  where: File_Stores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Stores_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Inbound_MailsArgs = {
  where: Inbound_Mails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Mails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Inbound_MessagesArgs = {
  where: Inbound_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InvoicesArgs = {
  where: Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LinksArgs = {
  where: Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Links_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mailbox_Inbound_EmailsArgs = {
  where: Mailbox_Inbound_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mailbox_Inbound_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Message_ApplicantsArgs = {
  where: Message_Applicants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Message_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Message_RepliesArgs = {
  where: Message_Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Message_Replies_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Message_TemplatesArgs = {
  where: Message_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Message_Templates_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Messages_OldArgs = {
  where: Messages_Old_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Messages_Old_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NotesArgs = {
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Online_PaymentsArgs = {
  where: Online_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Online_Payments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Password_ResetArgs = {
  where: Password_Reset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentsArgs = {
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StagesArgs = {
  where: Stages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubscribersArgs = {
  where: Subscribers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscribers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VacanciesArgs = {
  where: Vacancies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vacancies_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootEmail_ResetArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootForgot_Password_RequestArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGenerate_Api_TokenArgs = {
  company_id: Scalars['Int'];
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Activity_Log_ApplicantsArgs = {
  objects: Array<Activity_Log_Applicants_Insert_Input>;
  on_conflict?: Maybe<Activity_Log_Applicants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activity_Log_Applicants_OneArgs = {
  object: Activity_Log_Applicants_Insert_Input;
  on_conflict?: Maybe<Activity_Log_Applicants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activity_Log_EventsArgs = {
  objects: Array<Activity_Log_Events_Insert_Input>;
  on_conflict?: Maybe<Activity_Log_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activity_Log_Events_OneArgs = {
  object: Activity_Log_Events_Insert_Input;
  on_conflict?: Maybe<Activity_Log_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_TokensArgs = {
  objects: Array<Api_Tokens_Insert_Input>;
  on_conflict?: Maybe<Api_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_Tokens_OneArgs = {
  object: Api_Tokens_Insert_Input;
  on_conflict?: Maybe<Api_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApplicantsArgs = {
  objects: Array<Applicants_Insert_Input>;
  on_conflict?: Maybe<Applicants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Applicants_OneArgs = {
  object: Applicants_Insert_Input;
  on_conflict?: Maybe<Applicants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompaniesArgs = {
  objects: Array<Companies_Insert_Input>;
  on_conflict?: Maybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_OneArgs = {
  object: Companies_Insert_Input;
  on_conflict?: Maybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_UsersArgs = {
  objects: Array<Company_Users_Insert_Input>;
  on_conflict?: Maybe<Company_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Users_OneArgs = {
  object: Company_Users_Insert_Input;
  on_conflict?: Maybe<Company_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_ResetArgs = {
  objects: Array<Email_Reset_Insert_Input>;
  on_conflict?: Maybe<Email_Reset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Reset_OneArgs = {
  object: Email_Reset_Insert_Input;
  on_conflict?: Maybe<Email_Reset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Failed_JobsArgs = {
  objects: Array<Failed_Jobs_Insert_Input>;
  on_conflict?: Maybe<Failed_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Failed_Jobs_OneArgs = {
  object: Failed_Jobs_Insert_Input;
  on_conflict?: Maybe<Failed_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_StoresArgs = {
  objects: Array<File_Stores_Insert_Input>;
  on_conflict?: Maybe<File_Stores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Stores_OneArgs = {
  object: File_Stores_Insert_Input;
  on_conflict?: Maybe<File_Stores_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_MailsArgs = {
  objects: Array<Inbound_Mails_Insert_Input>;
  on_conflict?: Maybe<Inbound_Mails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Mails_OneArgs = {
  object: Inbound_Mails_Insert_Input;
  on_conflict?: Maybe<Inbound_Mails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_MessagesArgs = {
  objects: Array<Inbound_Messages_Insert_Input>;
  on_conflict?: Maybe<Inbound_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Messages_OneArgs = {
  object: Inbound_Messages_Insert_Input;
  on_conflict?: Maybe<Inbound_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoicesArgs = {
  objects: Array<Invoices_Insert_Input>;
  on_conflict?: Maybe<Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_OneArgs = {
  object: Invoices_Insert_Input;
  on_conflict?: Maybe<Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LinksArgs = {
  objects: Array<Links_Insert_Input>;
  on_conflict?: Maybe<Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Links_OneArgs = {
  object: Links_Insert_Input;
  on_conflict?: Maybe<Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mailbox_Inbound_EmailsArgs = {
  objects: Array<Mailbox_Inbound_Emails_Insert_Input>;
  on_conflict?: Maybe<Mailbox_Inbound_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mailbox_Inbound_Emails_OneArgs = {
  object: Mailbox_Inbound_Emails_Insert_Input;
  on_conflict?: Maybe<Mailbox_Inbound_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_ApplicantsArgs = {
  objects: Array<Message_Applicants_Insert_Input>;
  on_conflict?: Maybe<Message_Applicants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_Applicants_OneArgs = {
  object: Message_Applicants_Insert_Input;
  on_conflict?: Maybe<Message_Applicants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_RepliesArgs = {
  objects: Array<Message_Replies_Insert_Input>;
  on_conflict?: Maybe<Message_Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_Replies_OneArgs = {
  object: Message_Replies_Insert_Input;
  on_conflict?: Maybe<Message_Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_TemplatesArgs = {
  objects: Array<Message_Templates_Insert_Input>;
  on_conflict?: Maybe<Message_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_Templates_OneArgs = {
  object: Message_Templates_Insert_Input;
  on_conflict?: Maybe<Message_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: Array<Messages_Insert_Input>;
  on_conflict?: Maybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OldArgs = {
  objects: Array<Messages_Old_Insert_Input>;
  on_conflict?: Maybe<Messages_Old_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_Old_OneArgs = {
  object: Messages_Old_Insert_Input;
  on_conflict?: Maybe<Messages_Old_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input;
  on_conflict?: Maybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotesArgs = {
  objects: Array<Notes_Insert_Input>;
  on_conflict?: Maybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notes_OneArgs = {
  object: Notes_Insert_Input;
  on_conflict?: Maybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Online_PaymentsArgs = {
  objects: Array<Online_Payments_Insert_Input>;
  on_conflict?: Maybe<Online_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Online_Payments_OneArgs = {
  object: Online_Payments_Insert_Input;
  on_conflict?: Maybe<Online_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_ResetArgs = {
  objects: Array<Password_Reset_Insert_Input>;
  on_conflict?: Maybe<Password_Reset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_OneArgs = {
  object: Password_Reset_Insert_Input;
  on_conflict?: Maybe<Password_Reset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentsArgs = {
  objects: Array<Payments_Insert_Input>;
  on_conflict?: Maybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payments_OneArgs = {
  object: Payments_Insert_Input;
  on_conflict?: Maybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StagesArgs = {
  objects: Array<Stages_Insert_Input>;
  on_conflict?: Maybe<Stages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stages_OneArgs = {
  object: Stages_Insert_Input;
  on_conflict?: Maybe<Stages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscribersArgs = {
  objects: Array<Subscribers_Insert_Input>;
  on_conflict?: Maybe<Subscribers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscribers_OneArgs = {
  object: Subscribers_Insert_Input;
  on_conflict?: Maybe<Subscribers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VacanciesArgs = {
  objects: Array<Vacancies_Insert_Input>;
  on_conflict?: Maybe<Vacancies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vacancies_OneArgs = {
  object: Vacancies_Insert_Input;
  on_conflict?: Maybe<Vacancies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VacancyArgs = {
  data: VacanciesInput;
};


/** mutation root */
export type Mutation_RootInvite_TeammateArgs = {
  company_id: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPassword_ResetArgs = {
  confirm_password: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  data: UserInput;
};


/** mutation root */
export type Mutation_RootRevert_Email_ResetArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSend_MessageArgs = {
  data: SendMessageInput;
};


/** mutation root */
export type Mutation_RootStripe_Cancel_SubscriptionArgs = {
  company_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootStripe_Create_Checkout_SessionArgs = {
  data: CheckoutSessionInput;
};


/** mutation root */
export type Mutation_RootStripe_Customer_PortalArgs = {
  session_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUnsubscribeArgs = {
  token?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_Log_ApplicantsArgs = {
  _inc?: Maybe<Activity_Log_Applicants_Inc_Input>;
  _set?: Maybe<Activity_Log_Applicants_Set_Input>;
  where: Activity_Log_Applicants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_Log_Applicants_By_PkArgs = {
  _inc?: Maybe<Activity_Log_Applicants_Inc_Input>;
  _set?: Maybe<Activity_Log_Applicants_Set_Input>;
  pk_columns: Activity_Log_Applicants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_Log_EventsArgs = {
  _inc?: Maybe<Activity_Log_Events_Inc_Input>;
  _set?: Maybe<Activity_Log_Events_Set_Input>;
  where: Activity_Log_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activity_Log_Events_By_PkArgs = {
  _inc?: Maybe<Activity_Log_Events_Inc_Input>;
  _set?: Maybe<Activity_Log_Events_Set_Input>;
  pk_columns: Activity_Log_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Api_TokensArgs = {
  _inc?: Maybe<Api_Tokens_Inc_Input>;
  _set?: Maybe<Api_Tokens_Set_Input>;
  where: Api_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Tokens_By_PkArgs = {
  _inc?: Maybe<Api_Tokens_Inc_Input>;
  _set?: Maybe<Api_Tokens_Set_Input>;
  pk_columns: Api_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicantsArgs = {
  _inc?: Maybe<Applicants_Inc_Input>;
  _set?: Maybe<Applicants_Set_Input>;
  where: Applicants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Applicants_By_PkArgs = {
  _inc?: Maybe<Applicants_Inc_Input>;
  _set?: Maybe<Applicants_Set_Input>;
  pk_columns: Applicants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompaniesArgs = {
  _inc?: Maybe<Companies_Inc_Input>;
  _set?: Maybe<Companies_Set_Input>;
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_By_PkArgs = {
  _inc?: Maybe<Companies_Inc_Input>;
  _set?: Maybe<Companies_Set_Input>;
  pk_columns: Companies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_UsersArgs = {
  _inc?: Maybe<Company_Users_Inc_Input>;
  _set?: Maybe<Company_Users_Set_Input>;
  where: Company_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Users_By_PkArgs = {
  _inc?: Maybe<Company_Users_Inc_Input>;
  _set?: Maybe<Company_Users_Set_Input>;
  pk_columns: Company_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Email_ResetArgs = {
  _inc?: Maybe<Email_Reset_Inc_Input>;
  _set?: Maybe<Email_Reset_Set_Input>;
  where: Email_Reset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Reset_By_PkArgs = {
  _inc?: Maybe<Email_Reset_Inc_Input>;
  _set?: Maybe<Email_Reset_Set_Input>;
  pk_columns: Email_Reset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Failed_JobsArgs = {
  _inc?: Maybe<Failed_Jobs_Inc_Input>;
  _set?: Maybe<Failed_Jobs_Set_Input>;
  where: Failed_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Failed_Jobs_By_PkArgs = {
  _inc?: Maybe<Failed_Jobs_Inc_Input>;
  _set?: Maybe<Failed_Jobs_Set_Input>;
  pk_columns: Failed_Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_StoresArgs = {
  _inc?: Maybe<File_Stores_Inc_Input>;
  _set?: Maybe<File_Stores_Set_Input>;
  where: File_Stores_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Stores_By_PkArgs = {
  _inc?: Maybe<File_Stores_Inc_Input>;
  _set?: Maybe<File_Stores_Set_Input>;
  pk_columns: File_Stores_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_MailsArgs = {
  _inc?: Maybe<Inbound_Mails_Inc_Input>;
  _set?: Maybe<Inbound_Mails_Set_Input>;
  where: Inbound_Mails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Mails_By_PkArgs = {
  _inc?: Maybe<Inbound_Mails_Inc_Input>;
  _set?: Maybe<Inbound_Mails_Set_Input>;
  pk_columns: Inbound_Mails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_MessagesArgs = {
  _append?: Maybe<Inbound_Messages_Append_Input>;
  _delete_at_path?: Maybe<Inbound_Messages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inbound_Messages_Delete_Elem_Input>;
  _delete_key?: Maybe<Inbound_Messages_Delete_Key_Input>;
  _inc?: Maybe<Inbound_Messages_Inc_Input>;
  _prepend?: Maybe<Inbound_Messages_Prepend_Input>;
  _set?: Maybe<Inbound_Messages_Set_Input>;
  where: Inbound_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Messages_By_PkArgs = {
  _append?: Maybe<Inbound_Messages_Append_Input>;
  _delete_at_path?: Maybe<Inbound_Messages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inbound_Messages_Delete_Elem_Input>;
  _delete_key?: Maybe<Inbound_Messages_Delete_Key_Input>;
  _inc?: Maybe<Inbound_Messages_Inc_Input>;
  _prepend?: Maybe<Inbound_Messages_Prepend_Input>;
  _set?: Maybe<Inbound_Messages_Set_Input>;
  pk_columns: Inbound_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoicesArgs = {
  _inc?: Maybe<Invoices_Inc_Input>;
  _set?: Maybe<Invoices_Set_Input>;
  where: Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_By_PkArgs = {
  _inc?: Maybe<Invoices_Inc_Input>;
  _set?: Maybe<Invoices_Set_Input>;
  pk_columns: Invoices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LinksArgs = {
  _inc?: Maybe<Links_Inc_Input>;
  _set?: Maybe<Links_Set_Input>;
  where: Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Links_By_PkArgs = {
  _inc?: Maybe<Links_Inc_Input>;
  _set?: Maybe<Links_Set_Input>;
  pk_columns: Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mailbox_Inbound_EmailsArgs = {
  _inc?: Maybe<Mailbox_Inbound_Emails_Inc_Input>;
  _set?: Maybe<Mailbox_Inbound_Emails_Set_Input>;
  where: Mailbox_Inbound_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mailbox_Inbound_Emails_By_PkArgs = {
  _inc?: Maybe<Mailbox_Inbound_Emails_Inc_Input>;
  _set?: Maybe<Mailbox_Inbound_Emails_Set_Input>;
  pk_columns: Mailbox_Inbound_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Message_ApplicantsArgs = {
  _append?: Maybe<Message_Applicants_Append_Input>;
  _delete_at_path?: Maybe<Message_Applicants_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Message_Applicants_Delete_Elem_Input>;
  _delete_key?: Maybe<Message_Applicants_Delete_Key_Input>;
  _inc?: Maybe<Message_Applicants_Inc_Input>;
  _prepend?: Maybe<Message_Applicants_Prepend_Input>;
  _set?: Maybe<Message_Applicants_Set_Input>;
  where: Message_Applicants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Message_Applicants_By_PkArgs = {
  _append?: Maybe<Message_Applicants_Append_Input>;
  _delete_at_path?: Maybe<Message_Applicants_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Message_Applicants_Delete_Elem_Input>;
  _delete_key?: Maybe<Message_Applicants_Delete_Key_Input>;
  _inc?: Maybe<Message_Applicants_Inc_Input>;
  _prepend?: Maybe<Message_Applicants_Prepend_Input>;
  _set?: Maybe<Message_Applicants_Set_Input>;
  pk_columns: Message_Applicants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Message_RepliesArgs = {
  _append?: Maybe<Message_Replies_Append_Input>;
  _delete_at_path?: Maybe<Message_Replies_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Message_Replies_Delete_Elem_Input>;
  _delete_key?: Maybe<Message_Replies_Delete_Key_Input>;
  _inc?: Maybe<Message_Replies_Inc_Input>;
  _prepend?: Maybe<Message_Replies_Prepend_Input>;
  _set?: Maybe<Message_Replies_Set_Input>;
  where: Message_Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Message_Replies_By_PkArgs = {
  _append?: Maybe<Message_Replies_Append_Input>;
  _delete_at_path?: Maybe<Message_Replies_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Message_Replies_Delete_Elem_Input>;
  _delete_key?: Maybe<Message_Replies_Delete_Key_Input>;
  _inc?: Maybe<Message_Replies_Inc_Input>;
  _prepend?: Maybe<Message_Replies_Prepend_Input>;
  _set?: Maybe<Message_Replies_Set_Input>;
  pk_columns: Message_Replies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Message_TemplatesArgs = {
  _inc?: Maybe<Message_Templates_Inc_Input>;
  _set?: Maybe<Message_Templates_Set_Input>;
  where: Message_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Message_Templates_By_PkArgs = {
  _inc?: Maybe<Message_Templates_Inc_Input>;
  _set?: Maybe<Message_Templates_Set_Input>;
  pk_columns: Message_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _inc?: Maybe<Messages_Inc_Input>;
  _set?: Maybe<Messages_Set_Input>;
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _inc?: Maybe<Messages_Inc_Input>;
  _set?: Maybe<Messages_Set_Input>;
  pk_columns: Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_OldArgs = {
  _inc?: Maybe<Messages_Old_Inc_Input>;
  _set?: Maybe<Messages_Old_Set_Input>;
  where: Messages_Old_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_Old_By_PkArgs = {
  _inc?: Maybe<Messages_Old_Inc_Input>;
  _set?: Maybe<Messages_Old_Set_Input>;
  pk_columns: Messages_Old_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotesArgs = {
  _inc?: Maybe<Notes_Inc_Input>;
  _set?: Maybe<Notes_Set_Input>;
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_By_PkArgs = {
  _inc?: Maybe<Notes_Inc_Input>;
  _set?: Maybe<Notes_Set_Input>;
  pk_columns: Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Online_PaymentsArgs = {
  _inc?: Maybe<Online_Payments_Inc_Input>;
  _set?: Maybe<Online_Payments_Set_Input>;
  where: Online_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Online_Payments_By_PkArgs = {
  _inc?: Maybe<Online_Payments_Inc_Input>;
  _set?: Maybe<Online_Payments_Set_Input>;
  pk_columns: Online_Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Password_ResetArgs = {
  _inc?: Maybe<Password_Reset_Inc_Input>;
  _set?: Maybe<Password_Reset_Set_Input>;
  where: Password_Reset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_By_PkArgs = {
  _inc?: Maybe<Password_Reset_Inc_Input>;
  _set?: Maybe<Password_Reset_Set_Input>;
  pk_columns: Password_Reset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentsArgs = {
  _inc?: Maybe<Payments_Inc_Input>;
  _set?: Maybe<Payments_Set_Input>;
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payments_By_PkArgs = {
  _inc?: Maybe<Payments_Inc_Input>;
  _set?: Maybe<Payments_Set_Input>;
  pk_columns: Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  company_profile_data?: Maybe<CompanyProfileInput>;
  user_profile_data?: Maybe<UserProfileInput>;
};


/** mutation root */
export type Mutation_RootUpdate_StagesArgs = {
  _inc?: Maybe<Stages_Inc_Input>;
  _set?: Maybe<Stages_Set_Input>;
  where: Stages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stages_By_PkArgs = {
  _inc?: Maybe<Stages_Inc_Input>;
  _set?: Maybe<Stages_Set_Input>;
  pk_columns: Stages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubscribersArgs = {
  _inc?: Maybe<Subscribers_Inc_Input>;
  _set?: Maybe<Subscribers_Set_Input>;
  where: Subscribers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscribers_By_PkArgs = {
  _inc?: Maybe<Subscribers_Inc_Input>;
  _set?: Maybe<Subscribers_Set_Input>;
  pk_columns: Subscribers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VacanciesArgs = {
  _inc?: Maybe<Vacancies_Inc_Input>;
  _set?: Maybe<Vacancies_Set_Input>;
  where: Vacancies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vacancies_By_PkArgs = {
  _inc?: Maybe<Vacancies_Inc_Input>;
  _set?: Maybe<Vacancies_Set_Input>;
  pk_columns: Vacancies_Pk_Columns_Input;
};

/** columns and relationships of "notes" */
export type Notes = {
  __typename?: 'notes';
  /** An object relationship */
  applicant?: Maybe<Applicants>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "notes" */
export type Notes_Aggregate = {
  __typename?: 'notes_aggregate';
  aggregate?: Maybe<Notes_Aggregate_Fields>;
  nodes: Array<Notes>;
};

/** aggregate fields of "notes" */
export type Notes_Aggregate_Fields = {
  __typename?: 'notes_aggregate_fields';
  avg?: Maybe<Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Notes_Max_Fields>;
  min?: Maybe<Notes_Min_Fields>;
  stddev?: Maybe<Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Notes_Sum_Fields>;
  var_pop?: Maybe<Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Notes_Var_Samp_Fields>;
  variance?: Maybe<Notes_Variance_Fields>;
};


/** aggregate fields of "notes" */
export type Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notes" */
export type Notes_Aggregate_Order_By = {
  avg?: Maybe<Notes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Notes_Max_Order_By>;
  min?: Maybe<Notes_Min_Order_By>;
  stddev?: Maybe<Notes_Stddev_Order_By>;
  stddev_pop?: Maybe<Notes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Notes_Stddev_Samp_Order_By>;
  sum?: Maybe<Notes_Sum_Order_By>;
  var_pop?: Maybe<Notes_Var_Pop_Order_By>;
  var_samp?: Maybe<Notes_Var_Samp_Order_By>;
  variance?: Maybe<Notes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "notes" */
export type Notes_Arr_Rel_Insert_Input = {
  data: Array<Notes_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Notes_On_Conflict>;
};

/** aggregate avg on columns */
export type Notes_Avg_Fields = {
  __typename?: 'notes_avg_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "notes" */
export type Notes_Avg_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "notes". All fields are combined with a logical 'AND'. */
export type Notes_Bool_Exp = {
  _and?: Maybe<Array<Notes_Bool_Exp>>;
  _not?: Maybe<Notes_Bool_Exp>;
  _or?: Maybe<Array<Notes_Bool_Exp>>;
  applicant?: Maybe<Applicants_Bool_Exp>;
  applicant_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "notes" */
export enum Notes_Constraint {
  /** unique or primary key constraint */
  NotesPkey = 'notes_pkey'
}

/** input type for incrementing numeric columns in table "notes" */
export type Notes_Inc_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "notes" */
export type Notes_Insert_Input = {
  applicant?: Maybe<Applicants_Obj_Rel_Insert_Input>;
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Notes_Max_Fields = {
  __typename?: 'notes_max_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "notes" */
export type Notes_Max_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Notes_Min_Fields = {
  __typename?: 'notes_min_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "notes" */
export type Notes_Min_Order_By = {
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "notes" */
export type Notes_Mutation_Response = {
  __typename?: 'notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notes>;
};

/** on conflict condition type for table "notes" */
export type Notes_On_Conflict = {
  constraint: Notes_Constraint;
  update_columns?: Array<Notes_Update_Column>;
  where?: Maybe<Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "notes". */
export type Notes_Order_By = {
  applicant?: Maybe<Applicants_Order_By>;
  applicant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: notes */
export type Notes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "notes" */
export enum Notes_Select_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "notes" */
export type Notes_Set_Input = {
  applicant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Notes_Stddev_Fields = {
  __typename?: 'notes_stddev_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "notes" */
export type Notes_Stddev_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Notes_Stddev_Pop_Fields = {
  __typename?: 'notes_stddev_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "notes" */
export type Notes_Stddev_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Notes_Stddev_Samp_Fields = {
  __typename?: 'notes_stddev_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "notes" */
export type Notes_Stddev_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Notes_Sum_Fields = {
  __typename?: 'notes_sum_fields';
  applicant_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "notes" */
export type Notes_Sum_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "notes" */
export enum Notes_Update_Column {
  /** column name */
  ApplicantId = 'applicant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Notes_Var_Pop_Fields = {
  __typename?: 'notes_var_pop_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "notes" */
export type Notes_Var_Pop_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Notes_Var_Samp_Fields = {
  __typename?: 'notes_var_samp_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "notes" */
export type Notes_Var_Samp_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Notes_Variance_Fields = {
  __typename?: 'notes_variance_fields';
  applicant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "notes" */
export type Notes_Variance_Order_By = {
  applicant_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "online_payments" */
export type Online_Payments = {
  __typename?: 'online_payments';
  created_at: Scalars['timestamptz'];
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vacancy: Vacancies;
  vacancy_id: Scalars['Int'];
};

/** aggregated selection of "online_payments" */
export type Online_Payments_Aggregate = {
  __typename?: 'online_payments_aggregate';
  aggregate?: Maybe<Online_Payments_Aggregate_Fields>;
  nodes: Array<Online_Payments>;
};

/** aggregate fields of "online_payments" */
export type Online_Payments_Aggregate_Fields = {
  __typename?: 'online_payments_aggregate_fields';
  avg?: Maybe<Online_Payments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Online_Payments_Max_Fields>;
  min?: Maybe<Online_Payments_Min_Fields>;
  stddev?: Maybe<Online_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Online_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Online_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Online_Payments_Sum_Fields>;
  var_pop?: Maybe<Online_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Online_Payments_Var_Samp_Fields>;
  variance?: Maybe<Online_Payments_Variance_Fields>;
};


/** aggregate fields of "online_payments" */
export type Online_Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Online_Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "online_payments" */
export type Online_Payments_Aggregate_Order_By = {
  avg?: Maybe<Online_Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Online_Payments_Max_Order_By>;
  min?: Maybe<Online_Payments_Min_Order_By>;
  stddev?: Maybe<Online_Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Online_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Online_Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Online_Payments_Sum_Order_By>;
  var_pop?: Maybe<Online_Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Online_Payments_Var_Samp_Order_By>;
  variance?: Maybe<Online_Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "online_payments" */
export type Online_Payments_Arr_Rel_Insert_Input = {
  data: Array<Online_Payments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Online_Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Online_Payments_Avg_Fields = {
  __typename?: 'online_payments_avg_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "online_payments" */
export type Online_Payments_Avg_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "online_payments". All fields are combined with a logical 'AND'. */
export type Online_Payments_Bool_Exp = {
  _and?: Maybe<Array<Online_Payments_Bool_Exp>>;
  _not?: Maybe<Online_Payments_Bool_Exp>;
  _or?: Maybe<Array<Online_Payments_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<String_Comparison_Exp>;
  expire_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  subscription_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vacancy?: Maybe<Vacancies_Bool_Exp>;
  vacancy_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "online_payments" */
export enum Online_Payments_Constraint {
  /** unique or primary key constraint */
  OnlinePaymentsPkey = 'online_payments_pkey'
}

/** input type for incrementing numeric columns in table "online_payments" */
export type Online_Payments_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "online_payments" */
export type Online_Payments_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy?: Maybe<Vacancies_Obj_Rel_Insert_Input>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Online_Payments_Max_Fields = {
  __typename?: 'online_payments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "online_payments" */
export type Online_Payments_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Online_Payments_Min_Fields = {
  __typename?: 'online_payments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "online_payments" */
export type Online_Payments_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "online_payments" */
export type Online_Payments_Mutation_Response = {
  __typename?: 'online_payments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Online_Payments>;
};

/** on conflict condition type for table "online_payments" */
export type Online_Payments_On_Conflict = {
  constraint: Online_Payments_Constraint;
  update_columns?: Array<Online_Payments_Update_Column>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};

/** Ordering options when selecting data from "online_payments". */
export type Online_Payments_Order_By = {
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vacancy?: Maybe<Vacancies_Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** primary key columns input for table: online_payments */
export type Online_Payments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "online_payments" */
export enum Online_Payments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VacancyId = 'vacancy_id'
}

/** input type for updating data in table "online_payments" */
export type Online_Payments_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Online_Payments_Stddev_Fields = {
  __typename?: 'online_payments_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "online_payments" */
export type Online_Payments_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Online_Payments_Stddev_Pop_Fields = {
  __typename?: 'online_payments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "online_payments" */
export type Online_Payments_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Online_Payments_Stddev_Samp_Fields = {
  __typename?: 'online_payments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "online_payments" */
export type Online_Payments_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Online_Payments_Sum_Fields = {
  __typename?: 'online_payments_sum_fields';
  id?: Maybe<Scalars['Int']>;
  vacancy_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "online_payments" */
export type Online_Payments_Sum_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** update columns of table "online_payments" */
export enum Online_Payments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VacancyId = 'vacancy_id'
}

/** aggregate var_pop on columns */
export type Online_Payments_Var_Pop_Fields = {
  __typename?: 'online_payments_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "online_payments" */
export type Online_Payments_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Online_Payments_Var_Samp_Fields = {
  __typename?: 'online_payments_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "online_payments" */
export type Online_Payments_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Online_Payments_Variance_Fields = {
  __typename?: 'online_payments_variance_fields';
  id?: Maybe<Scalars['Float']>;
  vacancy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "online_payments" */
export type Online_Payments_Variance_Order_By = {
  id?: Maybe<Order_By>;
  vacancy_id?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "password_reset" */
export type Password_Reset = {
  __typename?: 'password_reset';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  expire_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "password_reset" */
export type Password_Reset_Aggregate = {
  __typename?: 'password_reset_aggregate';
  aggregate?: Maybe<Password_Reset_Aggregate_Fields>;
  nodes: Array<Password_Reset>;
};

/** aggregate fields of "password_reset" */
export type Password_Reset_Aggregate_Fields = {
  __typename?: 'password_reset_aggregate_fields';
  avg?: Maybe<Password_Reset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Password_Reset_Max_Fields>;
  min?: Maybe<Password_Reset_Min_Fields>;
  stddev?: Maybe<Password_Reset_Stddev_Fields>;
  stddev_pop?: Maybe<Password_Reset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Password_Reset_Stddev_Samp_Fields>;
  sum?: Maybe<Password_Reset_Sum_Fields>;
  var_pop?: Maybe<Password_Reset_Var_Pop_Fields>;
  var_samp?: Maybe<Password_Reset_Var_Samp_Fields>;
  variance?: Maybe<Password_Reset_Variance_Fields>;
};


/** aggregate fields of "password_reset" */
export type Password_Reset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Password_Reset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Password_Reset_Avg_Fields = {
  __typename?: 'password_reset_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "password_reset". All fields are combined with a logical 'AND'. */
export type Password_Reset_Bool_Exp = {
  _and?: Maybe<Array<Password_Reset_Bool_Exp>>;
  _not?: Maybe<Password_Reset_Bool_Exp>;
  _or?: Maybe<Array<Password_Reset_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  expire_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "password_reset" */
export enum Password_Reset_Constraint {
  /** unique or primary key constraint */
  PasswordResetPkey = 'password_reset_pkey'
}

/** input type for incrementing numeric columns in table "password_reset" */
export type Password_Reset_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "password_reset" */
export type Password_Reset_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Password_Reset_Max_Fields = {
  __typename?: 'password_reset_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Password_Reset_Min_Fields = {
  __typename?: 'password_reset_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "password_reset" */
export type Password_Reset_Mutation_Response = {
  __typename?: 'password_reset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Password_Reset>;
};

/** on conflict condition type for table "password_reset" */
export type Password_Reset_On_Conflict = {
  constraint: Password_Reset_Constraint;
  update_columns?: Array<Password_Reset_Update_Column>;
  where?: Maybe<Password_Reset_Bool_Exp>;
};

/** Ordering options when selecting data from "password_reset". */
export type Password_Reset_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: password_reset */
export type Password_Reset_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "password_reset" */
export enum Password_Reset_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "password_reset" */
export type Password_Reset_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Password_Reset_Stddev_Fields = {
  __typename?: 'password_reset_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Password_Reset_Stddev_Pop_Fields = {
  __typename?: 'password_reset_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Password_Reset_Stddev_Samp_Fields = {
  __typename?: 'password_reset_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Password_Reset_Sum_Fields = {
  __typename?: 'password_reset_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "password_reset" */
export enum Password_Reset_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Password_Reset_Var_Pop_Fields = {
  __typename?: 'password_reset_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Password_Reset_Var_Samp_Fields = {
  __typename?: 'password_reset_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Password_Reset_Variance_Fields = {
  __typename?: 'password_reset_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payments" */
export type Payments = {
  __typename?: 'payments';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  status: Scalars['String'];
  subscription_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "payments" */
export type Payments_Aggregate = {
  __typename?: 'payments_aggregate';
  aggregate?: Maybe<Payments_Aggregate_Fields>;
  nodes: Array<Payments>;
};

/** aggregate fields of "payments" */
export type Payments_Aggregate_Fields = {
  __typename?: 'payments_aggregate_fields';
  avg?: Maybe<Payments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payments_Max_Fields>;
  min?: Maybe<Payments_Min_Fields>;
  stddev?: Maybe<Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Payments_Sum_Fields>;
  var_pop?: Maybe<Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Payments_Var_Samp_Fields>;
  variance?: Maybe<Payments_Variance_Fields>;
};


/** aggregate fields of "payments" */
export type Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payments" */
export type Payments_Aggregate_Order_By = {
  avg?: Maybe<Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payments_Max_Order_By>;
  min?: Maybe<Payments_Min_Order_By>;
  stddev?: Maybe<Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Payments_Sum_Order_By>;
  var_pop?: Maybe<Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Payments_Var_Samp_Order_By>;
  variance?: Maybe<Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payments" */
export type Payments_Arr_Rel_Insert_Input = {
  data: Array<Payments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Payments_Avg_Fields = {
  __typename?: 'payments_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payments" */
export type Payments_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type Payments_Bool_Exp = {
  _and?: Maybe<Array<Payments_Bool_Exp>>;
  _not?: Maybe<Payments_Bool_Exp>;
  _or?: Maybe<Array<Payments_Bool_Exp>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<String_Comparison_Exp>;
  expire_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  subscription_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payments" */
export enum Payments_Constraint {
  /** unique or primary key constraint */
  PaymentsPkey = 'payments_pkey'
}

/** input type for incrementing numeric columns in table "payments" */
export type Payments_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "payments" */
export type Payments_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payments_Max_Fields = {
  __typename?: 'payments_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payments" */
export type Payments_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payments_Min_Fields = {
  __typename?: 'payments_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payments" */
export type Payments_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payments" */
export type Payments_Mutation_Response = {
  __typename?: 'payments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payments>;
};

/** on conflict condition type for table "payments" */
export type Payments_On_Conflict = {
  constraint: Payments_Constraint;
  update_columns?: Array<Payments_Update_Column>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** Ordering options when selecting data from "payments". */
export type Payments_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  expire_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: payments */
export type Payments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "payments" */
export enum Payments_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payments" */
export type Payments_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expire_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payments_Stddev_Fields = {
  __typename?: 'payments_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payments" */
export type Payments_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payments_Stddev_Pop_Fields = {
  __typename?: 'payments_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payments" */
export type Payments_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payments_Stddev_Samp_Fields = {
  __typename?: 'payments_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payments" */
export type Payments_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payments_Sum_Fields = {
  __typename?: 'payments_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payments" */
export type Payments_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "payments" */
export enum Payments_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Payments_Var_Pop_Fields = {
  __typename?: 'payments_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payments" */
export type Payments_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payments_Var_Samp_Fields = {
  __typename?: 'payments_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payments" */
export type Payments_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payments_Variance_Fields = {
  __typename?: 'payments_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payments" */
export type Payments_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  activity_log_applicants: Array<Activity_Log_Applicants>;
  /** An aggregate relationship */
  activity_log_applicants_aggregate: Activity_Log_Applicants_Aggregate;
  /** fetch data from the table: "activity_log_applicants" using primary key columns */
  activity_log_applicants_by_pk?: Maybe<Activity_Log_Applicants>;
  /** fetch data from the table: "activity_log_events" */
  activity_log_events: Array<Activity_Log_Events>;
  /** fetch aggregated fields from the table: "activity_log_events" */
  activity_log_events_aggregate: Activity_Log_Events_Aggregate;
  /** fetch data from the table: "activity_log_events" using primary key columns */
  activity_log_events_by_pk?: Maybe<Activity_Log_Events>;
  /** An array relationship */
  api_tokens: Array<Api_Tokens>;
  /** An aggregate relationship */
  api_tokens_aggregate: Api_Tokens_Aggregate;
  /** fetch data from the table: "api_tokens" using primary key columns */
  api_tokens_by_pk?: Maybe<Api_Tokens>;
  /** An array relationship */
  applicants: Array<Applicants>;
  /** An aggregate relationship */
  applicants_aggregate: Applicants_Aggregate;
  /** fetch data from the table: "applicants" using primary key columns */
  applicants_by_pk?: Maybe<Applicants>;
  /** execute function "auth_user" which returns "users" */
  auth_user: Array<Users>;
  /** execute function "auth_user" and query aggregates on result of table type "users" */
  auth_user_aggregate: Users_Aggregate;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** An array relationship */
  company_users: Array<Company_Users>;
  /** An aggregate relationship */
  company_users_aggregate: Company_Users_Aggregate;
  /** fetch data from the table: "company_users" using primary key columns */
  company_users_by_pk?: Maybe<Company_Users>;
  /** fetch data from the table: "email_reset" */
  email_reset: Array<Email_Reset>;
  /** fetch aggregated fields from the table: "email_reset" */
  email_reset_aggregate: Email_Reset_Aggregate;
  /** fetch data from the table: "email_reset" using primary key columns */
  email_reset_by_pk?: Maybe<Email_Reset>;
  /** fetch data from the table: "failed_jobs" */
  failed_jobs: Array<Failed_Jobs>;
  /** fetch aggregated fields from the table: "failed_jobs" */
  failed_jobs_aggregate: Failed_Jobs_Aggregate;
  /** fetch data from the table: "failed_jobs" using primary key columns */
  failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** fetch data from the table: "file_stores" */
  file_stores: Array<File_Stores>;
  /** fetch aggregated fields from the table: "file_stores" */
  file_stores_aggregate: File_Stores_Aggregate;
  /** fetch data from the table: "file_stores" using primary key columns */
  file_stores_by_pk?: Maybe<File_Stores>;
  /** fetch data from the table: "inbound_mails" */
  inbound_mails: Array<Inbound_Mails>;
  /** fetch aggregated fields from the table: "inbound_mails" */
  inbound_mails_aggregate: Inbound_Mails_Aggregate;
  /** fetch data from the table: "inbound_mails" using primary key columns */
  inbound_mails_by_pk?: Maybe<Inbound_Mails>;
  /** fetch data from the table: "inbound_messages" */
  inbound_messages: Array<Inbound_Messages>;
  /** fetch aggregated fields from the table: "inbound_messages" */
  inbound_messages_aggregate: Inbound_Messages_Aggregate;
  /** fetch data from the table: "inbound_messages" using primary key columns */
  inbound_messages_by_pk?: Maybe<Inbound_Messages>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  job_posting_data?: Maybe<JobPostingDataResponse>;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  /** fetch data from the table: "links" using primary key columns */
  links_by_pk?: Maybe<Links>;
  /** fetch data from the table: "mailbox_inbound_emails" */
  mailbox_inbound_emails: Array<Mailbox_Inbound_Emails>;
  /** fetch aggregated fields from the table: "mailbox_inbound_emails" */
  mailbox_inbound_emails_aggregate: Mailbox_Inbound_Emails_Aggregate;
  /** fetch data from the table: "mailbox_inbound_emails" using primary key columns */
  mailbox_inbound_emails_by_pk?: Maybe<Mailbox_Inbound_Emails>;
  /** An array relationship */
  message_applicants: Array<Message_Applicants>;
  /** An aggregate relationship */
  message_applicants_aggregate: Message_Applicants_Aggregate;
  /** fetch data from the table: "message_applicants" using primary key columns */
  message_applicants_by_pk?: Maybe<Message_Applicants>;
  /** An array relationship */
  message_replies: Array<Message_Replies>;
  /** An aggregate relationship */
  message_replies_aggregate: Message_Replies_Aggregate;
  /** fetch data from the table: "message_replies" using primary key columns */
  message_replies_by_pk?: Maybe<Message_Replies>;
  /** An array relationship */
  message_templates: Array<Message_Templates>;
  /** An aggregate relationship */
  message_templates_aggregate: Message_Templates_Aggregate;
  /** fetch data from the table: "message_templates" using primary key columns */
  message_templates_by_pk?: Maybe<Message_Templates>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "messages_old" */
  messages_old: Array<Messages_Old>;
  /** fetch aggregated fields from the table: "messages_old" */
  messages_old_aggregate: Messages_Old_Aggregate;
  /** fetch data from the table: "messages_old" using primary key columns */
  messages_old_by_pk?: Maybe<Messages_Old>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** An array relationship */
  online_payments: Array<Online_Payments>;
  /** An aggregate relationship */
  online_payments_aggregate: Online_Payments_Aggregate;
  /** fetch data from the table: "online_payments" using primary key columns */
  online_payments_by_pk?: Maybe<Online_Payments>;
  /** fetch data from the table: "password_reset" */
  password_reset: Array<Password_Reset>;
  /** fetch aggregated fields from the table: "password_reset" */
  password_reset_aggregate: Password_Reset_Aggregate;
  /** fetch data from the table: "password_reset" using primary key columns */
  password_reset_by_pk?: Maybe<Password_Reset>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** An array relationship */
  stages: Array<Stages>;
  /** An aggregate relationship */
  stages_aggregate: Stages_Aggregate;
  /** fetch data from the table: "stages" using primary key columns */
  stages_by_pk?: Maybe<Stages>;
  /** An array relationship */
  subscribers: Array<Subscribers>;
  /** An aggregate relationship */
  subscribers_aggregate: Subscribers_Aggregate;
  /** fetch data from the table: "subscribers" using primary key columns */
  subscribers_by_pk?: Maybe<Subscribers>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  vacancies: Array<Vacancies>;
  /** An aggregate relationship */
  vacancies_aggregate: Vacancies_Aggregate;
  /** fetch data from the table: "vacancies" using primary key columns */
  vacancies_by_pk?: Maybe<Vacancies>;
};


export type Query_RootActivity_Log_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


export type Query_RootActivity_Log_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


export type Query_RootActivity_Log_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootActivity_Log_EventsArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Events_Order_By>>;
  where?: Maybe<Activity_Log_Events_Bool_Exp>;
};


export type Query_RootActivity_Log_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Events_Order_By>>;
  where?: Maybe<Activity_Log_Events_Bool_Exp>;
};


export type Query_RootActivity_Log_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootApi_TokensArgs = {
  distinct_on?: Maybe<Array<Api_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Tokens_Order_By>>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};


export type Query_RootApi_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Tokens_Order_By>>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};


export type Query_RootApi_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootApplicantsArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


export type Query_RootApplicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


export type Query_RootApplicants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuth_UserArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootAuth_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


export type Query_RootCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


export type Query_RootCompanies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_UsersArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


export type Query_RootCompany_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


export type Query_RootCompany_Users_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmail_ResetArgs = {
  distinct_on?: Maybe<Array<Email_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Reset_Order_By>>;
  where?: Maybe<Email_Reset_Bool_Exp>;
};


export type Query_RootEmail_Reset_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Reset_Order_By>>;
  where?: Maybe<Email_Reset_Bool_Exp>;
};


export type Query_RootEmail_Reset_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFailed_JobsArgs = {
  distinct_on?: Maybe<Array<Failed_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Failed_Jobs_Order_By>>;
  where?: Maybe<Failed_Jobs_Bool_Exp>;
};


export type Query_RootFailed_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Failed_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Failed_Jobs_Order_By>>;
  where?: Maybe<Failed_Jobs_Bool_Exp>;
};


export type Query_RootFailed_Jobs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFile_StoresArgs = {
  distinct_on?: Maybe<Array<File_Stores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Stores_Order_By>>;
  where?: Maybe<File_Stores_Bool_Exp>;
};


export type Query_RootFile_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Stores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Stores_Order_By>>;
  where?: Maybe<File_Stores_Bool_Exp>;
};


export type Query_RootFile_Stores_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInbound_MailsArgs = {
  distinct_on?: Maybe<Array<Inbound_Mails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Mails_Order_By>>;
  where?: Maybe<Inbound_Mails_Bool_Exp>;
};


export type Query_RootInbound_Mails_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Mails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Mails_Order_By>>;
  where?: Maybe<Inbound_Mails_Bool_Exp>;
};


export type Query_RootInbound_Mails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInbound_MessagesArgs = {
  distinct_on?: Maybe<Array<Inbound_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Messages_Order_By>>;
  where?: Maybe<Inbound_Messages_Bool_Exp>;
};


export type Query_RootInbound_Messages_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Messages_Order_By>>;
  where?: Maybe<Inbound_Messages_Bool_Exp>;
};


export type Query_RootInbound_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Query_RootInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Query_RootInvoices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootJob_Posting_DataArgs = {
  period: Scalars['String'];
};


export type Query_RootLinksArgs = {
  distinct_on?: Maybe<Array<Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Links_Order_By>>;
  where?: Maybe<Links_Bool_Exp>;
};


export type Query_RootLinks_AggregateArgs = {
  distinct_on?: Maybe<Array<Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Links_Order_By>>;
  where?: Maybe<Links_Bool_Exp>;
};


export type Query_RootLinks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMailbox_Inbound_EmailsArgs = {
  distinct_on?: Maybe<Array<Mailbox_Inbound_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mailbox_Inbound_Emails_Order_By>>;
  where?: Maybe<Mailbox_Inbound_Emails_Bool_Exp>;
};


export type Query_RootMailbox_Inbound_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Mailbox_Inbound_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mailbox_Inbound_Emails_Order_By>>;
  where?: Maybe<Mailbox_Inbound_Emails_Bool_Exp>;
};


export type Query_RootMailbox_Inbound_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessage_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


export type Query_RootMessage_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


export type Query_RootMessage_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessage_RepliesArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


export type Query_RootMessage_Replies_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


export type Query_RootMessage_Replies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessage_TemplatesArgs = {
  distinct_on?: Maybe<Array<Message_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Templates_Order_By>>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};


export type Query_RootMessage_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Templates_Order_By>>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};


export type Query_RootMessage_Templates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessages_OldArgs = {
  distinct_on?: Maybe<Array<Messages_Old_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Old_Order_By>>;
  where?: Maybe<Messages_Old_Bool_Exp>;
};


export type Query_RootMessages_Old_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Old_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Old_Order_By>>;
  where?: Maybe<Messages_Old_Bool_Exp>;
};


export type Query_RootMessages_Old_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOnline_PaymentsArgs = {
  distinct_on?: Maybe<Array<Online_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Payments_Order_By>>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};


export type Query_RootOnline_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Payments_Order_By>>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};


export type Query_RootOnline_Payments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPassword_ResetArgs = {
  distinct_on?: Maybe<Array<Password_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Password_Reset_Order_By>>;
  where?: Maybe<Password_Reset_Bool_Exp>;
};


export type Query_RootPassword_Reset_AggregateArgs = {
  distinct_on?: Maybe<Array<Password_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Password_Reset_Order_By>>;
  where?: Maybe<Password_Reset_Bool_Exp>;
};


export type Query_RootPassword_Reset_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStagesArgs = {
  distinct_on?: Maybe<Array<Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stages_Order_By>>;
  where?: Maybe<Stages_Bool_Exp>;
};


export type Query_RootStages_AggregateArgs = {
  distinct_on?: Maybe<Array<Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stages_Order_By>>;
  where?: Maybe<Stages_Bool_Exp>;
};


export type Query_RootStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubscribersArgs = {
  distinct_on?: Maybe<Array<Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscribers_Order_By>>;
  where?: Maybe<Subscribers_Bool_Exp>;
};


export type Query_RootSubscribers_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscribers_Order_By>>;
  where?: Maybe<Subscribers_Bool_Exp>;
};


export type Query_RootSubscribers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVacanciesArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};


export type Query_RootVacancies_AggregateArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};


export type Query_RootVacancies_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "stages" */
export type Stages = {
  __typename?: 'stages';
  /** An array relationship */
  applicants: Array<Applicants>;
  /** An aggregate relationship */
  applicants_aggregate: Applicants_Aggregate;
  color?: Maybe<Scalars['String']>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_shown?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "stages" */
export type StagesApplicantsArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


/** columns and relationships of "stages" */
export type StagesApplicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};

/** aggregated selection of "stages" */
export type Stages_Aggregate = {
  __typename?: 'stages_aggregate';
  aggregate?: Maybe<Stages_Aggregate_Fields>;
  nodes: Array<Stages>;
};

/** aggregate fields of "stages" */
export type Stages_Aggregate_Fields = {
  __typename?: 'stages_aggregate_fields';
  avg?: Maybe<Stages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stages_Max_Fields>;
  min?: Maybe<Stages_Min_Fields>;
  stddev?: Maybe<Stages_Stddev_Fields>;
  stddev_pop?: Maybe<Stages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stages_Stddev_Samp_Fields>;
  sum?: Maybe<Stages_Sum_Fields>;
  var_pop?: Maybe<Stages_Var_Pop_Fields>;
  var_samp?: Maybe<Stages_Var_Samp_Fields>;
  variance?: Maybe<Stages_Variance_Fields>;
};


/** aggregate fields of "stages" */
export type Stages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stages" */
export type Stages_Aggregate_Order_By = {
  avg?: Maybe<Stages_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stages_Max_Order_By>;
  min?: Maybe<Stages_Min_Order_By>;
  stddev?: Maybe<Stages_Stddev_Order_By>;
  stddev_pop?: Maybe<Stages_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stages_Stddev_Samp_Order_By>;
  sum?: Maybe<Stages_Sum_Order_By>;
  var_pop?: Maybe<Stages_Var_Pop_Order_By>;
  var_samp?: Maybe<Stages_Var_Samp_Order_By>;
  variance?: Maybe<Stages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stages" */
export type Stages_Arr_Rel_Insert_Input = {
  data: Array<Stages_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Stages_On_Conflict>;
};

/** aggregate avg on columns */
export type Stages_Avg_Fields = {
  __typename?: 'stages_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stages" */
export type Stages_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stages". All fields are combined with a logical 'AND'. */
export type Stages_Bool_Exp = {
  _and?: Maybe<Array<Stages_Bool_Exp>>;
  _not?: Maybe<Stages_Bool_Exp>;
  _or?: Maybe<Array<Stages_Bool_Exp>>;
  applicants?: Maybe<Applicants_Bool_Exp>;
  color?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_shown?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stages" */
export enum Stages_Constraint {
  /** unique or primary key constraint */
  StatusesPkey = 'statuses_pkey'
}

/** input type for incrementing numeric columns in table "stages" */
export type Stages_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_shown?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stages" */
export type Stages_Insert_Input = {
  applicants?: Maybe<Applicants_Arr_Rel_Insert_Input>;
  color?: Maybe<Scalars['String']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_shown?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stages_Max_Fields = {
  __typename?: 'stages_max_fields';
  color?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_shown?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stages" */
export type Stages_Max_Order_By = {
  color?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stages_Min_Fields = {
  __typename?: 'stages_min_fields';
  color?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_shown?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stages" */
export type Stages_Min_Order_By = {
  color?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "stages" */
export type Stages_Mutation_Response = {
  __typename?: 'stages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stages>;
};

/** input type for inserting object relation for remote table "stages" */
export type Stages_Obj_Rel_Insert_Input = {
  data: Stages_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Stages_On_Conflict>;
};

/** on conflict condition type for table "stages" */
export type Stages_On_Conflict = {
  constraint: Stages_Constraint;
  update_columns?: Array<Stages_Update_Column>;
  where?: Maybe<Stages_Bool_Exp>;
};

/** Ordering options when selecting data from "stages". */
export type Stages_Order_By = {
  applicants_aggregate?: Maybe<Applicants_Aggregate_Order_By>;
  color?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: stages */
export type Stages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stages" */
export enum Stages_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stages" */
export type Stages_Set_Input = {
  color?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_shown?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stages_Stddev_Fields = {
  __typename?: 'stages_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stages" */
export type Stages_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stages_Stddev_Pop_Fields = {
  __typename?: 'stages_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stages" */
export type Stages_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stages_Stddev_Samp_Fields = {
  __typename?: 'stages_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stages" */
export type Stages_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Stages_Sum_Fields = {
  __typename?: 'stages_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_shown?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stages" */
export type Stages_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "stages" */
export enum Stages_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Stages_Var_Pop_Fields = {
  __typename?: 'stages_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stages" */
export type Stages_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stages_Var_Samp_Fields = {
  __typename?: 'stages_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stages" */
export type Stages_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stages_Variance_Fields = {
  __typename?: 'stages_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_shown?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stages" */
export type Stages_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** columns and relationships of "subscribers" */
export type Subscribers = {
  __typename?: 'subscribers';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  unsubscribed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "subscribers" */
export type Subscribers_Aggregate = {
  __typename?: 'subscribers_aggregate';
  aggregate?: Maybe<Subscribers_Aggregate_Fields>;
  nodes: Array<Subscribers>;
};

/** aggregate fields of "subscribers" */
export type Subscribers_Aggregate_Fields = {
  __typename?: 'subscribers_aggregate_fields';
  avg?: Maybe<Subscribers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscribers_Max_Fields>;
  min?: Maybe<Subscribers_Min_Fields>;
  stddev?: Maybe<Subscribers_Stddev_Fields>;
  stddev_pop?: Maybe<Subscribers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscribers_Stddev_Samp_Fields>;
  sum?: Maybe<Subscribers_Sum_Fields>;
  var_pop?: Maybe<Subscribers_Var_Pop_Fields>;
  var_samp?: Maybe<Subscribers_Var_Samp_Fields>;
  variance?: Maybe<Subscribers_Variance_Fields>;
};


/** aggregate fields of "subscribers" */
export type Subscribers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Subscribers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscribers" */
export type Subscribers_Aggregate_Order_By = {
  avg?: Maybe<Subscribers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Subscribers_Max_Order_By>;
  min?: Maybe<Subscribers_Min_Order_By>;
  stddev?: Maybe<Subscribers_Stddev_Order_By>;
  stddev_pop?: Maybe<Subscribers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Subscribers_Stddev_Samp_Order_By>;
  sum?: Maybe<Subscribers_Sum_Order_By>;
  var_pop?: Maybe<Subscribers_Var_Pop_Order_By>;
  var_samp?: Maybe<Subscribers_Var_Samp_Order_By>;
  variance?: Maybe<Subscribers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscribers" */
export type Subscribers_Arr_Rel_Insert_Input = {
  data: Array<Subscribers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Subscribers_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscribers_Avg_Fields = {
  __typename?: 'subscribers_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subscribers" */
export type Subscribers_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscribers". All fields are combined with a logical 'AND'. */
export type Subscribers_Bool_Exp = {
  _and?: Maybe<Array<Subscribers_Bool_Exp>>;
  _not?: Maybe<Subscribers_Bool_Exp>;
  _or?: Maybe<Array<Subscribers_Bool_Exp>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  unsubscribed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscribers" */
export enum Subscribers_Constraint {
  /** unique or primary key constraint */
  SubscribersPkey = 'subscribers_pkey'
}

/** input type for incrementing numeric columns in table "subscribers" */
export type Subscribers_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "subscribers" */
export type Subscribers_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  unsubscribed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Subscribers_Max_Fields = {
  __typename?: 'subscribers_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  unsubscribed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subscribers" */
export type Subscribers_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  unsubscribed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Subscribers_Min_Fields = {
  __typename?: 'subscribers_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  unsubscribed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subscribers" */
export type Subscribers_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  unsubscribed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "subscribers" */
export type Subscribers_Mutation_Response = {
  __typename?: 'subscribers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscribers>;
};

/** on conflict condition type for table "subscribers" */
export type Subscribers_On_Conflict = {
  constraint: Subscribers_Constraint;
  update_columns?: Array<Subscribers_Update_Column>;
  where?: Maybe<Subscribers_Bool_Exp>;
};

/** Ordering options when selecting data from "subscribers". */
export type Subscribers_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  unsubscribed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: subscribers */
export type Subscribers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "subscribers" */
export enum Subscribers_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Source = 'source',
  /** column name */
  UnsubscribedAt = 'unsubscribed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "subscribers" */
export type Subscribers_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  unsubscribed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Subscribers_Stddev_Fields = {
  __typename?: 'subscribers_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subscribers" */
export type Subscribers_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscribers_Stddev_Pop_Fields = {
  __typename?: 'subscribers_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subscribers" */
export type Subscribers_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscribers_Stddev_Samp_Fields = {
  __typename?: 'subscribers_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subscribers" */
export type Subscribers_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Subscribers_Sum_Fields = {
  __typename?: 'subscribers_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "subscribers" */
export type Subscribers_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "subscribers" */
export enum Subscribers_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Source = 'source',
  /** column name */
  UnsubscribedAt = 'unsubscribed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Subscribers_Var_Pop_Fields = {
  __typename?: 'subscribers_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subscribers" */
export type Subscribers_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscribers_Var_Samp_Fields = {
  __typename?: 'subscribers_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subscribers" */
export type Subscribers_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscribers_Variance_Fields = {
  __typename?: 'subscribers_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subscribers" */
export type Subscribers_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  activity_log_applicants: Array<Activity_Log_Applicants>;
  /** An aggregate relationship */
  activity_log_applicants_aggregate: Activity_Log_Applicants_Aggregate;
  /** fetch data from the table: "activity_log_applicants" using primary key columns */
  activity_log_applicants_by_pk?: Maybe<Activity_Log_Applicants>;
  /** fetch data from the table: "activity_log_events" */
  activity_log_events: Array<Activity_Log_Events>;
  /** fetch aggregated fields from the table: "activity_log_events" */
  activity_log_events_aggregate: Activity_Log_Events_Aggregate;
  /** fetch data from the table: "activity_log_events" using primary key columns */
  activity_log_events_by_pk?: Maybe<Activity_Log_Events>;
  /** An array relationship */
  api_tokens: Array<Api_Tokens>;
  /** An aggregate relationship */
  api_tokens_aggregate: Api_Tokens_Aggregate;
  /** fetch data from the table: "api_tokens" using primary key columns */
  api_tokens_by_pk?: Maybe<Api_Tokens>;
  /** An array relationship */
  applicants: Array<Applicants>;
  /** An aggregate relationship */
  applicants_aggregate: Applicants_Aggregate;
  /** fetch data from the table: "applicants" using primary key columns */
  applicants_by_pk?: Maybe<Applicants>;
  /** execute function "auth_user" which returns "users" */
  auth_user: Array<Users>;
  /** execute function "auth_user" and query aggregates on result of table type "users" */
  auth_user_aggregate: Users_Aggregate;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** An array relationship */
  company_users: Array<Company_Users>;
  /** An aggregate relationship */
  company_users_aggregate: Company_Users_Aggregate;
  /** fetch data from the table: "company_users" using primary key columns */
  company_users_by_pk?: Maybe<Company_Users>;
  /** fetch data from the table: "email_reset" */
  email_reset: Array<Email_Reset>;
  /** fetch aggregated fields from the table: "email_reset" */
  email_reset_aggregate: Email_Reset_Aggregate;
  /** fetch data from the table: "email_reset" using primary key columns */
  email_reset_by_pk?: Maybe<Email_Reset>;
  /** fetch data from the table: "failed_jobs" */
  failed_jobs: Array<Failed_Jobs>;
  /** fetch aggregated fields from the table: "failed_jobs" */
  failed_jobs_aggregate: Failed_Jobs_Aggregate;
  /** fetch data from the table: "failed_jobs" using primary key columns */
  failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** fetch data from the table: "file_stores" */
  file_stores: Array<File_Stores>;
  /** fetch aggregated fields from the table: "file_stores" */
  file_stores_aggregate: File_Stores_Aggregate;
  /** fetch data from the table: "file_stores" using primary key columns */
  file_stores_by_pk?: Maybe<File_Stores>;
  /** fetch data from the table: "inbound_mails" */
  inbound_mails: Array<Inbound_Mails>;
  /** fetch aggregated fields from the table: "inbound_mails" */
  inbound_mails_aggregate: Inbound_Mails_Aggregate;
  /** fetch data from the table: "inbound_mails" using primary key columns */
  inbound_mails_by_pk?: Maybe<Inbound_Mails>;
  /** fetch data from the table: "inbound_messages" */
  inbound_messages: Array<Inbound_Messages>;
  /** fetch aggregated fields from the table: "inbound_messages" */
  inbound_messages_aggregate: Inbound_Messages_Aggregate;
  /** fetch data from the table: "inbound_messages" using primary key columns */
  inbound_messages_by_pk?: Maybe<Inbound_Messages>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** An array relationship */
  links: Array<Links>;
  /** An aggregate relationship */
  links_aggregate: Links_Aggregate;
  /** fetch data from the table: "links" using primary key columns */
  links_by_pk?: Maybe<Links>;
  /** fetch data from the table: "mailbox_inbound_emails" */
  mailbox_inbound_emails: Array<Mailbox_Inbound_Emails>;
  /** fetch aggregated fields from the table: "mailbox_inbound_emails" */
  mailbox_inbound_emails_aggregate: Mailbox_Inbound_Emails_Aggregate;
  /** fetch data from the table: "mailbox_inbound_emails" using primary key columns */
  mailbox_inbound_emails_by_pk?: Maybe<Mailbox_Inbound_Emails>;
  /** An array relationship */
  message_applicants: Array<Message_Applicants>;
  /** An aggregate relationship */
  message_applicants_aggregate: Message_Applicants_Aggregate;
  /** fetch data from the table: "message_applicants" using primary key columns */
  message_applicants_by_pk?: Maybe<Message_Applicants>;
  /** An array relationship */
  message_replies: Array<Message_Replies>;
  /** An aggregate relationship */
  message_replies_aggregate: Message_Replies_Aggregate;
  /** fetch data from the table: "message_replies" using primary key columns */
  message_replies_by_pk?: Maybe<Message_Replies>;
  /** An array relationship */
  message_templates: Array<Message_Templates>;
  /** An aggregate relationship */
  message_templates_aggregate: Message_Templates_Aggregate;
  /** fetch data from the table: "message_templates" using primary key columns */
  message_templates_by_pk?: Maybe<Message_Templates>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "messages_old" */
  messages_old: Array<Messages_Old>;
  /** fetch aggregated fields from the table: "messages_old" */
  messages_old_aggregate: Messages_Old_Aggregate;
  /** fetch data from the table: "messages_old" using primary key columns */
  messages_old_by_pk?: Maybe<Messages_Old>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** An array relationship */
  online_payments: Array<Online_Payments>;
  /** An aggregate relationship */
  online_payments_aggregate: Online_Payments_Aggregate;
  /** fetch data from the table: "online_payments" using primary key columns */
  online_payments_by_pk?: Maybe<Online_Payments>;
  /** fetch data from the table: "password_reset" */
  password_reset: Array<Password_Reset>;
  /** fetch aggregated fields from the table: "password_reset" */
  password_reset_aggregate: Password_Reset_Aggregate;
  /** fetch data from the table: "password_reset" using primary key columns */
  password_reset_by_pk?: Maybe<Password_Reset>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** An array relationship */
  stages: Array<Stages>;
  /** An aggregate relationship */
  stages_aggregate: Stages_Aggregate;
  /** fetch data from the table: "stages" using primary key columns */
  stages_by_pk?: Maybe<Stages>;
  /** An array relationship */
  subscribers: Array<Subscribers>;
  /** An aggregate relationship */
  subscribers_aggregate: Subscribers_Aggregate;
  /** fetch data from the table: "subscribers" using primary key columns */
  subscribers_by_pk?: Maybe<Subscribers>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  vacancies: Array<Vacancies>;
  /** An aggregate relationship */
  vacancies_aggregate: Vacancies_Aggregate;
  /** fetch data from the table: "vacancies" using primary key columns */
  vacancies_by_pk?: Maybe<Vacancies>;
};


export type Subscription_RootActivity_Log_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


export type Subscription_RootActivity_Log_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Applicants_Order_By>>;
  where?: Maybe<Activity_Log_Applicants_Bool_Exp>;
};


export type Subscription_RootActivity_Log_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootActivity_Log_EventsArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Events_Order_By>>;
  where?: Maybe<Activity_Log_Events_Bool_Exp>;
};


export type Subscription_RootActivity_Log_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Activity_Log_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activity_Log_Events_Order_By>>;
  where?: Maybe<Activity_Log_Events_Bool_Exp>;
};


export type Subscription_RootActivity_Log_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootApi_TokensArgs = {
  distinct_on?: Maybe<Array<Api_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Tokens_Order_By>>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};


export type Subscription_RootApi_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Tokens_Order_By>>;
  where?: Maybe<Api_Tokens_Bool_Exp>;
};


export type Subscription_RootApi_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootApplicantsArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


export type Subscription_RootApplicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


export type Subscription_RootApplicants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAuth_UserArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootAuth_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_UsersArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


export type Subscription_RootCompany_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


export type Subscription_RootCompany_Users_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmail_ResetArgs = {
  distinct_on?: Maybe<Array<Email_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Reset_Order_By>>;
  where?: Maybe<Email_Reset_Bool_Exp>;
};


export type Subscription_RootEmail_Reset_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Reset_Order_By>>;
  where?: Maybe<Email_Reset_Bool_Exp>;
};


export type Subscription_RootEmail_Reset_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFailed_JobsArgs = {
  distinct_on?: Maybe<Array<Failed_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Failed_Jobs_Order_By>>;
  where?: Maybe<Failed_Jobs_Bool_Exp>;
};


export type Subscription_RootFailed_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Failed_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Failed_Jobs_Order_By>>;
  where?: Maybe<Failed_Jobs_Bool_Exp>;
};


export type Subscription_RootFailed_Jobs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFile_StoresArgs = {
  distinct_on?: Maybe<Array<File_Stores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Stores_Order_By>>;
  where?: Maybe<File_Stores_Bool_Exp>;
};


export type Subscription_RootFile_Stores_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Stores_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Stores_Order_By>>;
  where?: Maybe<File_Stores_Bool_Exp>;
};


export type Subscription_RootFile_Stores_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInbound_MailsArgs = {
  distinct_on?: Maybe<Array<Inbound_Mails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Mails_Order_By>>;
  where?: Maybe<Inbound_Mails_Bool_Exp>;
};


export type Subscription_RootInbound_Mails_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Mails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Mails_Order_By>>;
  where?: Maybe<Inbound_Mails_Bool_Exp>;
};


export type Subscription_RootInbound_Mails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInbound_MessagesArgs = {
  distinct_on?: Maybe<Array<Inbound_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Messages_Order_By>>;
  where?: Maybe<Inbound_Messages_Bool_Exp>;
};


export type Subscription_RootInbound_Messages_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Messages_Order_By>>;
  where?: Maybe<Inbound_Messages_Bool_Exp>;
};


export type Subscription_RootInbound_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootInvoices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLinksArgs = {
  distinct_on?: Maybe<Array<Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Links_Order_By>>;
  where?: Maybe<Links_Bool_Exp>;
};


export type Subscription_RootLinks_AggregateArgs = {
  distinct_on?: Maybe<Array<Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Links_Order_By>>;
  where?: Maybe<Links_Bool_Exp>;
};


export type Subscription_RootLinks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMailbox_Inbound_EmailsArgs = {
  distinct_on?: Maybe<Array<Mailbox_Inbound_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mailbox_Inbound_Emails_Order_By>>;
  where?: Maybe<Mailbox_Inbound_Emails_Bool_Exp>;
};


export type Subscription_RootMailbox_Inbound_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Mailbox_Inbound_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mailbox_Inbound_Emails_Order_By>>;
  where?: Maybe<Mailbox_Inbound_Emails_Bool_Exp>;
};


export type Subscription_RootMailbox_Inbound_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessage_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


export type Subscription_RootMessage_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


export type Subscription_RootMessage_Applicants_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessage_RepliesArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


export type Subscription_RootMessage_Replies_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Replies_Order_By>>;
  where?: Maybe<Message_Replies_Bool_Exp>;
};


export type Subscription_RootMessage_Replies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessage_TemplatesArgs = {
  distinct_on?: Maybe<Array<Message_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Templates_Order_By>>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};


export type Subscription_RootMessage_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Templates_Order_By>>;
  where?: Maybe<Message_Templates_Bool_Exp>;
};


export type Subscription_RootMessage_Templates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessages_OldArgs = {
  distinct_on?: Maybe<Array<Messages_Old_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Old_Order_By>>;
  where?: Maybe<Messages_Old_Bool_Exp>;
};


export type Subscription_RootMessages_Old_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Old_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Old_Order_By>>;
  where?: Maybe<Messages_Old_Bool_Exp>;
};


export type Subscription_RootMessages_Old_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOnline_PaymentsArgs = {
  distinct_on?: Maybe<Array<Online_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Payments_Order_By>>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};


export type Subscription_RootOnline_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Payments_Order_By>>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};


export type Subscription_RootOnline_Payments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPassword_ResetArgs = {
  distinct_on?: Maybe<Array<Password_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Password_Reset_Order_By>>;
  where?: Maybe<Password_Reset_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_AggregateArgs = {
  distinct_on?: Maybe<Array<Password_Reset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Password_Reset_Order_By>>;
  where?: Maybe<Password_Reset_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStagesArgs = {
  distinct_on?: Maybe<Array<Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stages_Order_By>>;
  where?: Maybe<Stages_Bool_Exp>;
};


export type Subscription_RootStages_AggregateArgs = {
  distinct_on?: Maybe<Array<Stages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stages_Order_By>>;
  where?: Maybe<Stages_Bool_Exp>;
};


export type Subscription_RootStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubscribersArgs = {
  distinct_on?: Maybe<Array<Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscribers_Order_By>>;
  where?: Maybe<Subscribers_Bool_Exp>;
};


export type Subscription_RootSubscribers_AggregateArgs = {
  distinct_on?: Maybe<Array<Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subscribers_Order_By>>;
  where?: Maybe<Subscribers_Bool_Exp>;
};


export type Subscription_RootSubscribers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVacanciesArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};


export type Subscription_RootVacancies_AggregateArgs = {
  distinct_on?: Maybe<Array<Vacancies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vacancies_Order_By>>;
  where?: Maybe<Vacancies_Bool_Exp>;
};


export type Subscription_RootVacancies_By_PkArgs = {
  id: Scalars['Int'];
};


/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: Maybe<Scalars['time']>;
  _gt?: Maybe<Scalars['time']>;
  _gte?: Maybe<Scalars['time']>;
  _in?: Maybe<Array<Scalars['time']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['time']>;
  _lte?: Maybe<Scalars['time']>;
  _neq?: Maybe<Scalars['time']>;
  _nin?: Maybe<Array<Scalars['time']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  company_users: Array<Company_Users>;
  /** An aggregate relationship */
  company_users_aggregate: Company_Users_Aggregate;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  /** An object relationship */
  file_store?: Maybe<File_Stores>;
  file_store_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An array relationship */
  message_applicants: Array<Message_Applicants>;
  /** An aggregate relationship */
  message_applicants_aggregate: Message_Applicants_Aggregate;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  password: Scalars['String'];
  remember_token?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "users" */
export type UsersCompany_UsersArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompany_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Users_Order_By>>;
  where?: Maybe<Company_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessage_ApplicantsArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessage_Applicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Applicants_Order_By>>;
  where?: Maybe<Message_Applicants_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  company_users?: Maybe<Company_Users_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  file_store?: Maybe<File_Stores_Bool_Exp>;
  file_store_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  message_applicants?: Maybe<Message_Applicants_Bool_Exp>;
  messages?: Maybe<Messages_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  remember_token?: Maybe<String_Comparison_Exp>;
  signature?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  company_users?: Maybe<Company_Users_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  file_store?: Maybe<File_Stores_Obj_Rel_Insert_Input>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  message_applicants?: Maybe<Message_Applicants_Arr_Rel_Insert_Input>;
  messages?: Maybe<Messages_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  password?: Maybe<Scalars['String']>;
  remember_token?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  remember_token?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  remember_token?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  remember_token?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  remember_token?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  company_users_aggregate?: Maybe<Company_Users_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  file_store?: Maybe<File_Stores_Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_applicants_aggregate?: Maybe<Message_Applicants_Aggregate_Order_By>;
  messages_aggregate?: Maybe<Messages_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  password?: Maybe<Order_By>;
  remember_token?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  RememberToken = 'remember_token',
  /** column name */
  Signature = 'signature',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  remember_token?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  RememberToken = 'remember_token',
  /** column name */
  Signature = 'signature',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "vacancies" */
export type Vacancies = {
  __typename?: 'vacancies';
  /** An array relationship */
  applicants: Array<Applicants>;
  /** An aggregate relationship */
  applicants_aggregate: Applicants_Aggregate;
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deadline?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  file_store?: Maybe<File_Stores>;
  file_store_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_promoted?: Maybe<Scalars['Boolean']>;
  is_shown: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  /** An array relationship */
  online_payments: Array<Online_Payments>;
  /** An aggregate relationship */
  online_payments_aggregate: Online_Payments_Aggregate;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subscriber_notified_at?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "vacancies" */
export type VacanciesApplicantsArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


/** columns and relationships of "vacancies" */
export type VacanciesApplicants_AggregateArgs = {
  distinct_on?: Maybe<Array<Applicants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Applicants_Order_By>>;
  where?: Maybe<Applicants_Bool_Exp>;
};


/** columns and relationships of "vacancies" */
export type VacanciesOnline_PaymentsArgs = {
  distinct_on?: Maybe<Array<Online_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Payments_Order_By>>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};


/** columns and relationships of "vacancies" */
export type VacanciesOnline_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Payments_Order_By>>;
  where?: Maybe<Online_Payments_Bool_Exp>;
};

/** aggregated selection of "vacancies" */
export type Vacancies_Aggregate = {
  __typename?: 'vacancies_aggregate';
  aggregate?: Maybe<Vacancies_Aggregate_Fields>;
  nodes: Array<Vacancies>;
};

/** aggregate fields of "vacancies" */
export type Vacancies_Aggregate_Fields = {
  __typename?: 'vacancies_aggregate_fields';
  avg?: Maybe<Vacancies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vacancies_Max_Fields>;
  min?: Maybe<Vacancies_Min_Fields>;
  stddev?: Maybe<Vacancies_Stddev_Fields>;
  stddev_pop?: Maybe<Vacancies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vacancies_Stddev_Samp_Fields>;
  sum?: Maybe<Vacancies_Sum_Fields>;
  var_pop?: Maybe<Vacancies_Var_Pop_Fields>;
  var_samp?: Maybe<Vacancies_Var_Samp_Fields>;
  variance?: Maybe<Vacancies_Variance_Fields>;
};


/** aggregate fields of "vacancies" */
export type Vacancies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vacancies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vacancies" */
export type Vacancies_Aggregate_Order_By = {
  avg?: Maybe<Vacancies_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vacancies_Max_Order_By>;
  min?: Maybe<Vacancies_Min_Order_By>;
  stddev?: Maybe<Vacancies_Stddev_Order_By>;
  stddev_pop?: Maybe<Vacancies_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vacancies_Stddev_Samp_Order_By>;
  sum?: Maybe<Vacancies_Sum_Order_By>;
  var_pop?: Maybe<Vacancies_Var_Pop_Order_By>;
  var_samp?: Maybe<Vacancies_Var_Samp_Order_By>;
  variance?: Maybe<Vacancies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vacancies" */
export type Vacancies_Arr_Rel_Insert_Input = {
  data: Array<Vacancies_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Vacancies_On_Conflict>;
};

/** aggregate avg on columns */
export type Vacancies_Avg_Fields = {
  __typename?: 'vacancies_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vacancies" */
export type Vacancies_Avg_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vacancies". All fields are combined with a logical 'AND'. */
export type Vacancies_Bool_Exp = {
  _and?: Maybe<Array<Vacancies_Bool_Exp>>;
  _not?: Maybe<Vacancies_Bool_Exp>;
  _or?: Maybe<Array<Vacancies_Bool_Exp>>;
  applicants?: Maybe<Applicants_Bool_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deadline?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  file_store?: Maybe<File_Stores_Bool_Exp>;
  file_store_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_promoted?: Maybe<Boolean_Comparison_Exp>;
  is_shown?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  online_payments?: Maybe<Online_Payments_Bool_Exp>;
  salary_from?: Maybe<Float8_Comparison_Exp>;
  salary_to?: Maybe<Float8_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  subscriber_notified_at?: Maybe<Timestamptz_Comparison_Exp>;
  task?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "vacancies" */
export enum Vacancies_Constraint {
  /** unique or primary key constraint */
  VacanciesPkey = 'vacancies_pkey'
}

/** input type for incrementing numeric columns in table "vacancies" */
export type Vacancies_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vacancies" */
export type Vacancies_Insert_Input = {
  applicants?: Maybe<Applicants_Arr_Rel_Insert_Input>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  file_store?: Maybe<File_Stores_Obj_Rel_Insert_Input>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_promoted?: Maybe<Scalars['Boolean']>;
  is_shown?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  online_payments?: Maybe<Online_Payments_Arr_Rel_Insert_Input>;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subscriber_notified_at?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Vacancies_Max_Fields = {
  __typename?: 'vacancies_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subscriber_notified_at?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "vacancies" */
export type Vacancies_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscriber_notified_at?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vacancies_Min_Fields = {
  __typename?: 'vacancies_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subscriber_notified_at?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "vacancies" */
export type Vacancies_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscriber_notified_at?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "vacancies" */
export type Vacancies_Mutation_Response = {
  __typename?: 'vacancies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vacancies>;
};

/** input type for inserting object relation for remote table "vacancies" */
export type Vacancies_Obj_Rel_Insert_Input = {
  data: Vacancies_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Vacancies_On_Conflict>;
};

/** on conflict condition type for table "vacancies" */
export type Vacancies_On_Conflict = {
  constraint: Vacancies_Constraint;
  update_columns?: Array<Vacancies_Update_Column>;
  where?: Maybe<Vacancies_Bool_Exp>;
};

/** Ordering options when selecting data from "vacancies". */
export type Vacancies_Order_By = {
  applicants_aggregate?: Maybe<Applicants_Aggregate_Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  file_store?: Maybe<File_Stores_Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_promoted?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  online_payments_aggregate?: Maybe<Online_Payments_Aggregate_Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscriber_notified_at?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: vacancies */
export type Vacancies_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "vacancies" */
export enum Vacancies_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPromoted = 'is_promoted',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  Location = 'location',
  /** column name */
  SalaryFrom = 'salary_from',
  /** column name */
  SalaryTo = 'salary_to',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriberNotifiedAt = 'subscriber_notified_at',
  /** column name */
  Task = 'task',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "vacancies" */
export type Vacancies_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_promoted?: Maybe<Scalars['Boolean']>;
  is_shown?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  subscriber_notified_at?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Vacancies_Stddev_Fields = {
  __typename?: 'vacancies_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vacancies" */
export type Vacancies_Stddev_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vacancies_Stddev_Pop_Fields = {
  __typename?: 'vacancies_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vacancies" */
export type Vacancies_Stddev_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vacancies_Stddev_Samp_Fields = {
  __typename?: 'vacancies_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vacancies" */
export type Vacancies_Stddev_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Vacancies_Sum_Fields = {
  __typename?: 'vacancies_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  file_store_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  salary_from?: Maybe<Scalars['float8']>;
  salary_to?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vacancies" */
export type Vacancies_Sum_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** update columns of table "vacancies" */
export enum Vacancies_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileStoreId = 'file_store_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPromoted = 'is_promoted',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  Location = 'location',
  /** column name */
  SalaryFrom = 'salary_from',
  /** column name */
  SalaryTo = 'salary_to',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriberNotifiedAt = 'subscriber_notified_at',
  /** column name */
  Task = 'task',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Vacancies_Var_Pop_Fields = {
  __typename?: 'vacancies_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vacancies" */
export type Vacancies_Var_Pop_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vacancies_Var_Samp_Fields = {
  __typename?: 'vacancies_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vacancies" */
export type Vacancies_Var_Samp_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Vacancies_Variance_Fields = {
  __typename?: 'vacancies_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  file_store_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  salary_from?: Maybe<Scalars['Float']>;
  salary_to?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vacancies" */
export type Vacancies_Variance_Order_By = {
  company_id?: Maybe<Order_By>;
  file_store_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  salary_from?: Maybe<Order_By>;
  salary_to?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export type ActivityApplicantFragment = (
  { __typename?: 'activity_log_applicants' }
  & { activity_log_event: (
    { __typename?: 'activity_log_events' }
    & ActivityEventFieldsFragment
  ) }
  & ActivityApplicantFieldsFragment
);

export type ActivityApplicantFieldsFragment = (
  { __typename?: 'activity_log_applicants' }
  & Pick<Activity_Log_Applicants, 'id' | 'event_id' | 'applicant_id' | 'data' | 'updated_at' | 'created_at'>
);

export type ActivityEventFieldsFragment = (
  { __typename?: 'activity_log_events' }
  & Pick<Activity_Log_Events, 'id' | 'event' | 'description' | 'created_at' | 'updated_at'>
);

export type ApplicantFragment = (
  { __typename?: 'applicants' }
  & { vacancy: (
    { __typename?: 'vacancies' }
    & VacancyFieldsFragment
  ), file_store?: Maybe<(
    { __typename?: 'file_stores' }
    & FileStoreFieldsFragment
  )> }
  & ApplicantFieldsFragment
);

export type ApplicantActivityFragment = (
  { __typename?: 'activity_log_applicants' }
  & Pick<Activity_Log_Applicants, 'id' | 'data' | 'event_id' | 'applicant_id'>
);

export type ApplicantFieldsFragment = (
  { __typename?: 'applicants' }
  & Pick<Applicants, 'id' | 'name' | 'email' | 'description' | 'phone' | 'inbound_identifier' | 'vacancy_id' | 'file_store_id' | 'status_id' | 'created_at' | 'updated_at'>
);

export type FileStoreFieldsFragment = (
  { __typename?: 'file_stores' }
  & Pick<File_Stores, 'id' | 'name' | 'original_name' | 'file_name' | 'file_size' | 'mime_type' | 'original_ext' | 'fileable_type' | 'fileable_id' | 'type' | 'status' | 'file_path' | 'created_at' | 'updated_at'>
);

export type InboxMessageFragment = (
  { __typename?: 'messages' }
  & Pick<Messages, 'id' | 'subject' | 'read_at' | 'created_at'>
  & { applicant: (
    { __typename?: 'applicants' }
    & Pick<Applicants, 'id' | 'name'>
    & { vacancy: (
      { __typename?: 'vacancies' }
      & Pick<Vacancies, 'title'>
    ) }
  ), repliesCount: (
    { __typename?: 'messages_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'messages_aggregate_fields' }
      & Pick<Messages_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type MessageFragment = (
  { __typename?: 'messages' }
  & { applicant: (
    { __typename?: 'applicants' }
    & { vacancy: (
      { __typename?: 'vacancies' }
      & VacancyFieldsFragment
    ) }
    & ApplicantFieldsFragment
  ), user?: Maybe<(
    { __typename?: 'users' }
    & NewUserFragment
  )>, parent?: Maybe<(
    { __typename?: 'messages' }
    & MessageFieldsFragment
  )>, repliesCount: (
    { __typename?: 'messages_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'messages_aggregate_fields' }
      & Pick<Messages_Aggregate_Fields, 'count'>
    )> }
  ), replies: Array<(
    { __typename?: 'messages' }
    & { applicant: (
      { __typename?: 'applicants' }
      & { vacancy: (
        { __typename?: 'vacancies' }
        & VacancyFieldsFragment
      ) }
      & ApplicantFieldsFragment
    ), user?: Maybe<(
      { __typename?: 'users' }
      & NewUserFragment
    )> }
    & MessageFieldsFragment
  )> }
  & MessageFieldsFragment
);

export type MessageFieldsFragment = (
  { __typename?: 'messages' }
  & Pick<Messages, 'id' | 'company_id' | 'user_id' | 'applicant_id' | 'parent_id' | 'provider_message_id' | 'provider_thread_id' | 'subject' | 'message' | 'opened_at' | 'read_at' | 'created_at' | 'updated_at' | 'deleted_at'>
);

export type NotesFragment = (
  { __typename?: 'notes' }
  & Pick<Notes, 'id' | 'created_at' | 'applicant_id' | 'description'>
  & { user: (
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'name'>
  ) }
);

export type UsersSettingsFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'email' | 'id' | 'name'>
  & { file_store?: Maybe<(
    { __typename?: 'file_stores' }
    & FileStoreFieldsFragment
  )>, company_users: Array<(
    { __typename?: 'company_users' }
    & Pick<Company_Users, 'role' | 'company_id'>
    & { company: (
      { __typename?: 'companies' }
      & Pick<Companies, 'industry' | 'name'>
      & { file_store?: Maybe<(
        { __typename?: 'file_stores' }
        & Pick<File_Stores, 'file_name' | 'file_path' | 'original_name'>
      )> }
    ) }
  )> }
);

export type MessageTemplatesFragment = (
  { __typename?: 'message_templates' }
  & Pick<Message_Templates, 'id' | 'name' | 'message' | 'subject' | 'created_at' | 'company_id' | 'updated_at'>
);

export type StageFieldsFragment = (
  { __typename?: 'stages' }
  & Pick<Stages, 'id' | 'name' | 'color' | 'order' | 'is_shown' | 'company_id'>
);

export type TeamMembersFragment = (
  { __typename?: 'company_users' }
  & Pick<Company_Users, 'role' | 'id' | 'user_id'>
  & { user: (
    { __typename?: 'users' }
    & Pick<Users, 'email' | 'created_at' | 'name'>
    & { file_store?: Maybe<(
      { __typename?: 'file_stores' }
      & Pick<File_Stores, 'file_name' | 'file_path'>
    )> }
  ) }
);

export type TokenFragment = (
  { __typename?: 'api_tokens' }
  & Pick<Api_Tokens, 'company_id' | 'created_at' | 'id' | 'name' | 'token' | 'updated_at'>
);

export type SubscribersFragment = (
  { __typename?: 'subscribers' }
  & Pick<Subscribers, 'id' | 'email' | 'name' | 'source' | 'created_at' | 'unsubscribed_at'>
);

export type NewUserFragment = (
  { __typename?: 'users' }
  & { file_store?: Maybe<(
    { __typename?: 'file_stores' }
    & FileStoreFieldsFragment
  )> }
  & UserFieldsFragment
);

export type UserFieldsFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'id' | 'name' | 'email'>
);

export type VacancyFragment = (
  { __typename?: 'vacancies' }
  & { file_store?: Maybe<(
    { __typename?: 'file_stores' }
    & Pick<File_Stores, 'file_path' | 'original_name'>
  )>, company: (
    { __typename?: 'companies' }
    & Pick<Companies, 'name'>
    & { file_store?: Maybe<(
      { __typename?: 'file_stores' }
      & Pick<File_Stores, 'file_path'>
    )> }
  ), payments: Array<(
    { __typename?: 'online_payments' }
    & Pick<Online_Payments, 'status' | 'id'>
    & { expiresAt: Online_Payments['expire_at'], vacancyId: Online_Payments['vacancy_id'] }
  )> }
  & VacancyFieldsFragment
);

export type VacancyFieldsFragment = (
  { __typename?: 'vacancies' }
  & Pick<Vacancies, 'id' | 'company_id' | 'created_at' | 'updated_at' | 'deadline' | 'description' | 'task' | 'location' | 'slug' | 'status' | 'title' | 'salary_from' | 'salary_to' | 'type' | 'subscriber_notified_at'>
  & { isShownInDashboard: Vacancies['is_shown'], isPromoted: Vacancies['is_promoted'] }
);

export type UpdateApplicantMutationVariables = Exact<{
  id: Scalars['Int'];
  set?: Maybe<Applicants_Set_Input>;
}>;


export type UpdateApplicantMutation = (
  { __typename?: 'mutation_root' }
  & { update_applicants_by_pk?: Maybe<(
    { __typename?: 'applicants' }
    & ApplicantFragment
  )> }
);

export type DeleteApplicantMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteApplicantMutation = (
  { __typename?: 'mutation_root' }
  & { delete_applicants?: Maybe<(
    { __typename?: 'applicants_mutation_response' }
    & { returning: Array<(
      { __typename?: 'applicants' }
      & ApplicantFragment
    )> }
  )> }
);

export type InsertApplicantMutationVariables = Exact<{
  object: Applicants_Insert_Input;
}>;


export type InsertApplicantMutation = (
  { __typename?: 'mutation_root' }
  & { insert_applicants_one?: Maybe<(
    { __typename?: 'applicants' }
    & ApplicantFragment
  )> }
);

export type InsertApplicantLinksMutationVariables = Exact<{
  object: Links_Insert_Input;
}>;


export type InsertApplicantLinksMutation = (
  { __typename?: 'mutation_root' }
  & { insert_links_one?: Maybe<(
    { __typename?: 'links' }
    & Pick<Links, 'applicant_id' | 'id' | 'link' | 'created_at'>
  )> }
);

export type InsertActivityApplicantMutationVariables = Exact<{
  object: Activity_Log_Applicants_Insert_Input;
}>;


export type InsertActivityApplicantMutation = (
  { __typename?: 'mutation_root' }
  & { insert_activity_log_applicants_one?: Maybe<(
    { __typename?: 'activity_log_applicants' }
    & ActivityApplicantFieldsFragment
  )> }
);

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteNoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_notes_by_pk?: Maybe<(
    { __typename?: 'notes' }
    & NotesFragment
  )> }
);

export type InsertNoteMutationVariables = Exact<{
  object: Notes_Insert_Input;
}>;


export type InsertNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_notes_one?: Maybe<(
    { __typename?: 'notes' }
    & NotesFragment
  )> }
);

export type ForgotPasswordRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordRequestMutation = (
  { __typename?: 'mutation_root' }
  & { forgot_password_request?: Maybe<(
    { __typename?: 'ForgotPasswordRequestResponse' }
    & Pick<ForgotPasswordRequestResponse, 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'mutation_root' }
  & { login?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token' | 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type RegisterMutationVariables = Exact<{
  data: UserInput;
}>;


export type RegisterMutation = (
  { __typename?: 'mutation_root' }
  & { register?: Maybe<(
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'id' | 'name' | 'email' | 'token' | 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type PasswordResetMutationVariables = Exact<{
  confirm_password: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type PasswordResetMutation = (
  { __typename?: 'mutation_root' }
  & { password_reset?: Maybe<(
    { __typename?: 'ForgotPasswordResetResponse' }
    & Pick<ForgotPasswordResetResponse, 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type DeleteFileStoreByUserIdMutationVariables = Exact<{
  fileableId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteFileStoreByUserIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_file?: Maybe<(
    { __typename?: 'DeletefileResponse' }
    & Pick<DeletefileResponse, 'statusCode' | 'statusIsOk' | 'statusMessage' | 'statusPath'>
  )> }
);

export type UpdateMessageByPkMutationVariables = Exact<{
  id: Scalars['Int'];
  set?: Maybe<Messages_Set_Input>;
}>;


export type UpdateMessageByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_messages_by_pk?: Maybe<(
    { __typename?: 'messages' }
    & MessageFieldsFragment
  )> }
);

export type SendMessageMutationVariables = Exact<{
  data: SendMessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'mutation_root' }
  & { send_message?: Maybe<(
    { __typename?: 'SendMessageResponse' }
    & Pick<SendMessageResponse, 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk' | 'applicant_id' | 'message_applicant_id'>
  )> }
);

export type CancleCheckoutSubscriptionMutationVariables = Exact<{
  company_id: Scalars['Int'];
}>;


export type CancleCheckoutSubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { stripe_cancel_subscription?: Maybe<(
    { __typename?: 'StripeCancelSubscriptionResponse' }
    & Pick<StripeCancelSubscriptionResponse, 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type CreateCheckoutSessionMutationVariables = Exact<{
  data: CheckoutSessionInput;
}>;


export type CreateCheckoutSessionMutation = (
  { __typename?: 'mutation_root' }
  & { stripe_create_checkout_session?: Maybe<(
    { __typename?: 'StripeCheckoutSessionResponse' }
    & Pick<StripeCheckoutSessionResponse, 'sessionId' | 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type UpdateAccountSettingsMutationVariables = Exact<{
  user_profile_data?: Maybe<UserProfileInput>;
  company_profile_data?: Maybe<CompanyProfileInput>;
}>;


export type UpdateAccountSettingsMutation = (
  { __typename?: 'mutation_root' }
  & { update_profile?: Maybe<(
    { __typename?: 'UpdateProfileResponse' }
    & Pick<UpdateProfileResponse, 'statusCode' | 'statusIsOk' | 'statusMessage' | 'statusPath'>
  )> }
);

export type ApiTokenInsertMutationVariables = Exact<{
  companyId: Scalars['Int'];
  name: Scalars['String'];
}>;


export type ApiTokenInsertMutation = (
  { __typename?: 'mutation_root' }
  & { generate_api_token?: Maybe<(
    { __typename?: 'GenerateApiTokenResponse' }
    & Pick<GenerateApiTokenResponse, 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk'>
  )> }
);

export type ApiTokenDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiTokenDeleteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_api_tokens_by_pk?: Maybe<(
    { __typename?: 'api_tokens' }
    & TokenFragment
  )> }
);

export type StageInsertMutationVariables = Exact<{
  object: Stages_Insert_Input;
}>;


export type StageInsertMutation = (
  { __typename?: 'mutation_root' }
  & { insert_stages_one?: Maybe<(
    { __typename?: 'stages' }
    & StageFieldsFragment
  )> }
);

export type StageUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  set?: Maybe<Stages_Set_Input>;
}>;


export type StageUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_stages_by_pk?: Maybe<(
    { __typename?: 'stages' }
    & StageFieldsFragment
  )> }
);

export type StageDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StageDeleteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_stages_by_pk?: Maybe<(
    { __typename?: 'stages' }
    & StageFieldsFragment
  )> }
);

export type MessageTemplateInsertMutationVariables = Exact<{
  object: Message_Templates_Insert_Input;
}>;


export type MessageTemplateInsertMutation = (
  { __typename?: 'mutation_root' }
  & { insert_message_templates_one?: Maybe<(
    { __typename?: 'message_templates' }
    & MessageTemplatesFragment
  )> }
);

export type MessageTemplateUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  set: Message_Templates_Set_Input;
}>;


export type MessageTemplateUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_message_templates_by_pk?: Maybe<(
    { __typename?: 'message_templates' }
    & MessageTemplatesFragment
  )> }
);

export type MessageTemplateDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MessageTemplateDeleteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_message_templates_by_pk?: Maybe<(
    { __typename?: 'message_templates' }
    & MessageTemplatesFragment
  )> }
);

export type TeamMemberInsertMutationVariables = Exact<{
  companyId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['Int'];
}>;


export type TeamMemberInsertMutation = (
  { __typename?: 'mutation_root' }
  & { invite_teammate?: Maybe<(
    { __typename?: 'InviteTeammateResponse' }
    & Pick<InviteTeammateResponse, 'id' | 'statusCode' | 'statusIsOk' | 'statusMessage'>
  )> }
);

export type TeamMemberUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  role: Scalars['Int'];
}>;


export type TeamMemberUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_company_users?: Maybe<(
    { __typename?: 'company_users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'company_users' }
      & Pick<Company_Users, 'id'>
    )> }
  )> }
);

export type TeamMemberDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TeamMemberDeleteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_company_users?: Maybe<(
    { __typename?: 'company_users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'company_users' }
      & Pick<Company_Users, 'id'>
    )> }
  )> }
);

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateEmailMutation = (
  { __typename?: 'mutation_root' }
  & { updateEmail?: Maybe<(
    { __typename?: 'UpdateEmailResponse' }
    & Pick<UpdateEmailResponse, 'statusIsOk' | 'statusMessage'>
  )> }
);

export type UpdateEmailResetMutationVariables = Exact<{
  email: Scalars['String'];
  expireAt: Scalars['timestamptz'];
}>;


export type UpdateEmailResetMutation = (
  { __typename?: 'mutation_root' }
  & { updateEmailReset?: Maybe<(
    { __typename?: 'email_reset_mutation_response' }
    & { returning: Array<(
      { __typename?: 'email_reset' }
      & Pick<Email_Reset, 'id'>
    )> }
  )> }
);

export type ConfirmEmailResetMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmEmailResetMutation = (
  { __typename?: 'mutation_root' }
  & { confirmEmailReset?: Maybe<(
    { __typename?: 'EmailResetResponse' }
    & Pick<EmailResetResponse, 'statusIsOk'>
  )> }
);

export type RevertEmailResetMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RevertEmailResetMutation = (
  { __typename?: 'mutation_root' }
  & { revertEmailReset?: Maybe<(
    { __typename?: 'RevertEmailResetResponse' }
    & Pick<RevertEmailResetResponse, 'statusIsOk'>
  )> }
);

export type DeleteEmailResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type DeleteEmailResetMutation = (
  { __typename?: 'mutation_root' }
  & { deleteEmailReset?: Maybe<(
    { __typename?: 'email_reset_mutation_response' }
    & { returning: Array<(
      { __typename?: 'email_reset' }
      & Pick<Email_Reset, 'id'>
    )> }
  )> }
);

export type DeleteSubscribersMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteSubscribersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_subscribers?: Maybe<(
    { __typename?: 'subscribers_mutation_response' }
    & { returning: Array<(
      { __typename?: 'subscribers' }
      & SubscribersFragment
    )> }
  )> }
);

export type InsertVacanciesMutationVariables = Exact<{
  data: VacanciesInput;
}>;


export type InsertVacanciesMutation = (
  { __typename?: 'mutation_root' }
  & { insertVacancy?: Maybe<(
    { __typename?: 'VacanciesResponse' }
    & Pick<VacanciesResponse, 'statusCode' | 'statusMessage' | 'statusPath' | 'statusIsOk' | 'is_shown' | 'status' | 'vacancy_id'>
  )> }
);

export type UpdateVacanciesByPkMutationVariables = Exact<{
  id: Scalars['Int'];
  _set?: Maybe<Vacancies_Set_Input>;
}>;


export type UpdateVacanciesByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_vacancies_by_pk?: Maybe<(
    { __typename?: 'vacancies' }
    & VacancyFragment
  )> }
);

export type DeleteVacanciesMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVacanciesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_vacancies_by_pk?: Maybe<(
    { __typename?: 'vacancies' }
    & VacancyFragment
  )> }
);

export type GetFileStoreByApplicantIdQueryVariables = Exact<{
  fileableId: Scalars['Int'];
}>;


export type GetFileStoreByApplicantIdQuery = (
  { __typename?: 'query_root' }
  & { file_stores: Array<(
    { __typename?: 'file_stores' }
    & FileStoreFieldsFragment
  )> }
);

export type GetApplicantsQueryVariables = Exact<{
  company_id: Scalars['Int'];
}>;


export type GetApplicantsQuery = (
  { __typename?: 'query_root' }
  & { applicants: Array<(
    { __typename?: 'applicants' }
    & Pick<Applicants, 'email' | 'id'>
  )> }
);

export type ApplicantsLinkQueryVariables = Exact<{
  applicant_id: Scalars['Int'];
}>;


export type ApplicantsLinkQuery = (
  { __typename?: 'query_root' }
  & { links: Array<(
    { __typename?: 'links' }
    & Pick<Links, 'id' | 'link'>
  )> }
);

export type FindPreviousApplicationsQueryVariables = Exact<{
  applicantId: Scalars['Int'];
  applicantEmail: Scalars['String'];
}>;


export type FindPreviousApplicationsQuery = (
  { __typename?: 'query_root' }
  & { applicants: Array<(
    { __typename?: 'applicants' }
    & Pick<Applicants, 'created_at'>
    & { stage?: Maybe<(
      { __typename?: 'stages' }
      & Pick<Stages, 'name' | 'color'>
    )>, vacancy: (
      { __typename?: 'vacancies' }
      & Pick<Vacancies, 'id' | 'title'>
    ) }
  )> }
);

export type AuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthUserQuery = (
  { __typename?: 'query_root' }
  & { auth_user: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'email' | 'id' | 'name'>
  )> }
);

export type GetCompanyUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCompanyUserQuery = (
  { __typename?: 'query_root' }
  & { company_users: Array<(
    { __typename?: 'company_users' }
    & Pick<Company_Users, 'id' | 'company_id' | 'role'>
  )> }
);

export type ValidateResetTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidateResetTokenQuery = (
  { __typename?: 'query_root' }
  & { password_reset: Array<(
    { __typename?: 'password_reset' }
    & Pick<Password_Reset, 'expire_at'>
  )> }
);

export type VacanciesStatsQueryVariables = Exact<{
  period: Scalars['String'];
}>;


export type VacanciesStatsQuery = (
  { __typename?: 'query_root' }
  & { job_posting_data?: Maybe<(
    { __typename?: 'JobPostingDataResponse' }
    & Pick<JobPostingDataResponse, 'detailed_jobs_data' | 'jobs_data' | 'total_applicants' | 'statusCode' | 'statusIsOk' | 'statusMessage' | 'statusPath'>
  )> }
);

export type ApplicantMessageQueryVariables = Exact<{
  message_id: Scalars['Int'];
}>;


export type ApplicantMessageQuery = (
  { __typename?: 'query_root' }
  & { message_applicants_by_pk?: Maybe<(
    { __typename?: 'message_applicants' }
    & Pick<Message_Applicants, 'id' | 'company_id'>
    & { applicant?: Maybe<(
      { __typename?: 'applicants' }
      & Pick<Applicants, 'id' | 'name' | 'email' | 'description'>
    )>, user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'name' | 'email'>
      & { file_store?: Maybe<(
        { __typename?: 'file_stores' }
        & Pick<File_Stores, 'file_name' | 'file_path'>
      )> }
    ), message: (
      { __typename?: 'messages_old' }
      & Pick<Messages_Old, 'id' | 'is_read' | 'message' | 'subject' | 'created_at' | 'opened_at'>
      & { message_replies: Array<(
        { __typename?: 'message_replies' }
        & Pick<Message_Replies, 'id' | 'message' | 'created_at' | 'sender_data' | 'opened_at'>
      )> }
    ) }
  )> }
);

export type MessagesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where: Messages_Bool_Exp;
}>;


export type MessagesQuery = (
  { __typename?: 'query_root' }
  & { messages: Array<(
    { __typename?: 'messages' }
    & InboxMessageFragment
  )> }
);

export type MessagesCountQueryVariables = Exact<{
  where: Message_Applicants_Bool_Exp;
}>;


export type MessagesCountQuery = (
  { __typename?: 'query_root' }
  & { message_applicants_aggregate: (
    { __typename?: 'message_applicants_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'message_applicants_aggregate_fields' }
      & Pick<Message_Applicants_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type NotesQueryVariables = Exact<{
  applicantId: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type NotesQuery = (
  { __typename?: 'query_root' }
  & { notes: Array<(
    { __typename?: 'notes' }
    & NotesFragment
  )> }
);

export type AccountSettingQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AccountSettingQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & UsersSettingsFragment
  )> }
);

export type InvoicesQueryVariables = Exact<{
  company_id: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type InvoicesQuery = (
  { __typename?: 'query_root' }
  & { invoices: Array<(
    { __typename?: 'invoices' }
    & Pick<Invoices, 'id' | 'amount'>
    & { companyId: Invoices['company_id'], createdAt: Invoices['created_at'], invoiceNumber: Invoices['invoice_number'], invoiceUrl: Invoices['invoice_url'], paidAt: Invoices['paid_at'], updatedAt: Invoices['updated_at'] }
  )>, invoicesAggregate: (
    { __typename?: 'invoices_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'invoices_aggregate_fields' }
      & Pick<Invoices_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ApplicantActivitySubscriptionVariables = Exact<{
  applicant_id: Scalars['Int'];
}>;


export type ApplicantActivitySubscription = (
  { __typename?: 'subscription_root' }
  & { activity_log_applicants: Array<(
    { __typename?: 'activity_log_applicants' }
    & ActivityApplicantFragment
  )> }
);

export type ApplicantSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApplicantSubscription = (
  { __typename?: 'subscription_root' }
  & { applicants_by_pk?: Maybe<(
    { __typename?: 'applicants' }
    & ApplicantFragment
  )> }
);

export type ApplicantsSubscriptionVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where: Applicants_Bool_Exp;
}>;


export type ApplicantsSubscription = (
  { __typename?: 'subscription_root' }
  & { applicants: Array<(
    { __typename?: 'applicants' }
    & ApplicantFragment
  )> }
);

export type AllApplicantsSubscriptionVariables = Exact<{
  where_count: Applicants_Bool_Exp;
}>;


export type AllApplicantsSubscription = (
  { __typename?: 'subscription_root' }
  & { count_all: (
    { __typename?: 'applicants_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'applicants_aggregate_fields' }
      & Pick<Applicants_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ActiveApplicantsSubscriptionVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where_count: Applicants_Bool_Exp;
}>;


export type ActiveApplicantsSubscription = (
  { __typename?: 'subscription_root' }
  & { count_active: (
    { __typename?: 'applicants_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'applicants_aggregate_fields' }
      & Pick<Applicants_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type MessageByPkSubscriptionVariables = Exact<{
  messageId: Scalars['Int'];
}>;


export type MessageByPkSubscription = (
  { __typename?: 'subscription_root' }
  & { messages_by_pk?: Maybe<(
    { __typename?: 'messages' }
    & MessageFragment
  )> }
);

export type NewMessagesCountSubscriptionVariables = Exact<{
  where: Messages_Bool_Exp;
}>;


export type NewMessagesCountSubscription = (
  { __typename?: 'subscription_root' }
  & { messages_aggregate: (
    { __typename?: 'messages_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'messages_aggregate_fields' }
      & Pick<Messages_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AllMessagesCountSubscriptionVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type AllMessagesCountSubscription = (
  { __typename?: 'subscription_root' }
  & { message_applicants_aggregate: (
    { __typename?: 'message_applicants_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'message_applicants_aggregate_fields' }
      & Pick<Message_Applicants_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type NumberOffNotesSubscriptionVariables = Exact<{
  applicantId: Scalars['Int'];
}>;


export type NumberOffNotesSubscription = (
  { __typename?: 'subscription_root' }
  & { notesAggregate: (
    { __typename?: 'notes_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'notes_aggregate_fields' }
      & Pick<Notes_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type StagesSubscriptionVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type StagesSubscription = (
  { __typename?: 'subscription_root' }
  & { stages: Array<(
    { __typename?: 'stages' }
    & StageFieldsFragment
  )> }
);

export type StagesStatsSubscriptionVariables = Exact<{
  where?: Maybe<Stages_Bool_Exp>;
  limit: Scalars['Int'];
}>;


export type StagesStatsSubscription = (
  { __typename?: 'subscription_root' }
  & { stages: Array<(
    { __typename?: 'stages' }
    & Pick<Stages, 'id' | 'name' | 'is_shown'>
    & { applicants_aggregate: (
      { __typename?: 'applicants_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'applicants_aggregate_fields' }
        & Pick<Applicants_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type SubscribersSubscriptionVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where: Subscribers_Bool_Exp;
}>;


export type SubscribersSubscription = (
  { __typename?: 'subscription_root' }
  & { subscribers: Array<(
    { __typename?: 'subscribers' }
    & SubscribersFragment
  )> }
);

export type AllSubscribersSubscriptionVariables = Exact<{
  where: Subscribers_Bool_Exp;
}>;


export type AllSubscribersSubscription = (
  { __typename?: 'subscription_root' }
  & { count_all: (
    { __typename?: 'subscribers_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'subscribers_aggregate_fields' }
      & Pick<Subscribers_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type VacancySubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type VacancySubscription = (
  { __typename?: 'subscription_root' }
  & { vacancies_by_pk?: Maybe<(
    { __typename?: 'vacancies' }
    & { applicants_count: (
      { __typename?: 'applicants_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'applicants_aggregate_fields' }
        & Pick<Applicants_Aggregate_Fields, 'count'>
      )> }
    ) }
    & VacancyFragment
  )> }
);

export type VacanciesSubscriptionVariables = Exact<{
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Vacancies_Bool_Exp>;
}>;


export type VacanciesSubscription = (
  { __typename?: 'subscription_root' }
  & { vacancies: Array<(
    { __typename?: 'vacancies' }
    & { applicants_aggregate: (
      { __typename?: 'applicants_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'applicants_aggregate_fields' }
        & Pick<Applicants_Aggregate_Fields, 'count'>
      )> }
    ) }
    & VacancyFragment
  )> }
);

export type AllVacanciesCountSubscriptionVariables = Exact<{
  where_all?: Maybe<Vacancies_Bool_Exp>;
}>;


export type AllVacanciesCountSubscription = (
  { __typename?: 'subscription_root' }
  & { count_all: (
    { __typename?: 'vacancies_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'vacancies_aggregate_fields' }
      & Pick<Vacancies_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type PublishedVacanciesCountSubscriptionVariables = Exact<{
  where_published?: Maybe<Vacancies_Bool_Exp>;
}>;


export type PublishedVacanciesCountSubscription = (
  { __typename?: 'subscription_root' }
  & { count_published: (
    { __typename?: 'vacancies_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'vacancies_aggregate_fields' }
      & Pick<Vacancies_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type DraftVacanciesCountSubscriptionVariables = Exact<{
  where_draft?: Maybe<Vacancies_Bool_Exp>;
}>;


export type DraftVacanciesCountSubscription = (
  { __typename?: 'subscription_root' }
  & { count_draft: (
    { __typename?: 'vacancies_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'vacancies_aggregate_fields' }
      & Pick<Vacancies_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type VacancyCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VacancyCountSubscription = (
  { __typename?: 'subscription_root' }
  & { vacanciesAggreate: (
    { __typename?: 'vacancies_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'vacancies_aggregate_fields' }
      & Pick<Vacancies_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type MessageTemplatesSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MessageTemplatesSubscription = (
  { __typename?: 'subscription_root' }
  & { message_templates: Array<(
    { __typename?: 'message_templates' }
    & MessageTemplatesFragment
  )> }
);

export type PaymentsSubscriptionVariables = Exact<{
  company_id: Scalars['Int'];
}>;


export type PaymentsSubscription = (
  { __typename?: 'subscription_root' }
  & { payments: Array<(
    { __typename?: 'payments' }
    & Pick<Payments, 'id' | 'status'>
    & { companyId: Payments['company_id'], createdAt: Payments['created_at'], expiredAt: Payments['expire_at'], subscriptionId: Payments['subscription_id'], updatedAt: Payments['updated_at'] }
  )> }
);

export type TeamMembersSubscriptionVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type TeamMembersSubscription = (
  { __typename?: 'subscription_root' }
  & { company_users: Array<(
    { __typename?: 'company_users' }
    & TeamMembersFragment
  )> }
);

export type TokensSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TokensSubscription = (
  { __typename?: 'subscription_root' }
  & { api_tokens: Array<(
    { __typename?: 'api_tokens' }
    & TokenFragment
  )> }
);

export const ActivityApplicantFieldsFragmentDoc = gql`
    fragment ActivityApplicantFields on activity_log_applicants {
  id
  event_id
  applicant_id
  data
  updated_at
  created_at
}
    `;
export const ActivityEventFieldsFragmentDoc = gql`
    fragment ActivityEventFields on activity_log_events {
  id
  event
  description
  created_at
  updated_at
}
    `;
export const ActivityApplicantFragmentDoc = gql`
    fragment ActivityApplicant on activity_log_applicants {
  ...ActivityApplicantFields
  activity_log_event {
    ...ActivityEventFields
  }
}
    ${ActivityApplicantFieldsFragmentDoc}
${ActivityEventFieldsFragmentDoc}`;
export const ApplicantFieldsFragmentDoc = gql`
    fragment ApplicantFields on applicants {
  id
  name
  email
  description
  phone
  inbound_identifier
  vacancy_id
  file_store_id
  status_id
  created_at
  updated_at
}
    `;
export const VacancyFieldsFragmentDoc = gql`
    fragment VacancyFields on vacancies {
  isShownInDashboard: is_shown
  isPromoted: is_promoted
  id
  company_id
  created_at
  updated_at
  deadline
  description
  task
  location
  slug
  status
  title
  salary_from
  salary_to
  type
  subscriber_notified_at
}
    `;
export const FileStoreFieldsFragmentDoc = gql`
    fragment FileStoreFields on file_stores {
  id
  name
  original_name
  file_name
  file_size
  mime_type
  original_ext
  fileable_type
  fileable_id
  type
  status
  file_path
  created_at
  updated_at
}
    `;
export const ApplicantFragmentDoc = gql`
    fragment Applicant on applicants {
  ...ApplicantFields
  vacancy {
    ...VacancyFields
  }
  file_store {
    ...FileStoreFields
  }
}
    ${ApplicantFieldsFragmentDoc}
${VacancyFieldsFragmentDoc}
${FileStoreFieldsFragmentDoc}`;
export const ApplicantActivityFragmentDoc = gql`
    fragment ApplicantActivity on activity_log_applicants {
  id
  data
  event_id
  applicant_id
}
    `;
export const InboxMessageFragmentDoc = gql`
    fragment InboxMessage on messages {
  id
  subject
  read_at
  created_at
  applicant {
    id
    name
    vacancy {
      title
    }
  }
  repliesCount: replies_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const MessageFieldsFragmentDoc = gql`
    fragment MessageFields on messages {
  id
  company_id
  user_id
  applicant_id
  parent_id
  provider_message_id
  provider_thread_id
  subject
  message
  opened_at
  read_at
  created_at
  updated_at
  deleted_at
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on users {
  id
  name
  email
}
    `;
export const NewUserFragmentDoc = gql`
    fragment NewUser on users {
  ...UserFields
  file_store {
    ...FileStoreFields
  }
}
    ${UserFieldsFragmentDoc}
${FileStoreFieldsFragmentDoc}`;
export const MessageFragmentDoc = gql`
    fragment Message on messages {
  ...MessageFields
  applicant {
    ...ApplicantFields
    vacancy {
      ...VacancyFields
    }
  }
  user {
    ...NewUser
  }
  parent {
    ...MessageFields
  }
  repliesCount: replies_aggregate {
    aggregate {
      count
    }
  }
  replies(order_by: {created_at: desc}) {
    ...MessageFields
    applicant {
      ...ApplicantFields
      vacancy {
        ...VacancyFields
      }
    }
    user {
      ...NewUser
    }
  }
}
    ${MessageFieldsFragmentDoc}
${ApplicantFieldsFragmentDoc}
${VacancyFieldsFragmentDoc}
${NewUserFragmentDoc}`;
export const NotesFragmentDoc = gql`
    fragment Notes on notes {
  id
  created_at
  applicant_id
  description
  user {
    id
    name
  }
}
    `;
export const UsersSettingsFragmentDoc = gql`
    fragment usersSettings on users {
  email
  id
  name
  file_store {
    ...FileStoreFields
  }
  company_users {
    role
    company_id
    company {
      industry
      name
      file_store {
        file_name
        file_path
        original_name
      }
    }
  }
}
    ${FileStoreFieldsFragmentDoc}`;
export const MessageTemplatesFragmentDoc = gql`
    fragment MessageTemplates on message_templates {
  id
  name
  message
  subject
  created_at
  company_id
  updated_at
}
    `;
export const StageFieldsFragmentDoc = gql`
    fragment StageFields on stages {
  id
  name
  color
  order
  is_shown
  company_id
}
    `;
export const TeamMembersFragmentDoc = gql`
    fragment TeamMembers on company_users {
  role
  id
  user_id
  user {
    email
    created_at
    name
    file_store {
      file_name
      file_path
    }
  }
}
    `;
export const TokenFragmentDoc = gql`
    fragment Token on api_tokens {
  company_id
  created_at
  id
  name
  token
  updated_at
}
    `;
export const SubscribersFragmentDoc = gql`
    fragment Subscribers on subscribers {
  id
  email
  name
  source
  created_at
  unsubscribed_at
}
    `;
export const VacancyFragmentDoc = gql`
    fragment Vacancy on vacancies {
  ...VacancyFields
  file_store {
    file_path
    original_name
  }
  company {
    name
    file_store {
      file_path
    }
  }
  payments: online_payments {
    expiresAt: expire_at
    vacancyId: vacancy_id
    status
    id
  }
}
    ${VacancyFieldsFragmentDoc}`;
export const UpdateApplicantDocument = gql`
    mutation UpdateApplicant($id: Int!, $set: applicants_set_input) {
  update_applicants_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...Applicant
  }
}
    ${ApplicantFragmentDoc}`;
export type UpdateApplicantMutationFn = Apollo.MutationFunction<UpdateApplicantMutation, UpdateApplicantMutationVariables>;

/**
 * __useUpdateApplicantMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantMutation, { data, loading, error }] = useUpdateApplicantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateApplicantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantMutation, UpdateApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicantMutation, UpdateApplicantMutationVariables>(UpdateApplicantDocument, options);
      }
export type UpdateApplicantMutationHookResult = ReturnType<typeof useUpdateApplicantMutation>;
export type UpdateApplicantMutationResult = Apollo.MutationResult<UpdateApplicantMutation>;
export type UpdateApplicantMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantMutation, UpdateApplicantMutationVariables>;
export const DeleteApplicantDocument = gql`
    mutation DeleteApplicant($ids: [Int!]!) {
  delete_applicants(where: {id: {_in: $ids}}) {
    returning {
      ...Applicant
    }
  }
}
    ${ApplicantFragmentDoc}`;
export type DeleteApplicantMutationFn = Apollo.MutationFunction<DeleteApplicantMutation, DeleteApplicantMutationVariables>;

/**
 * __useDeleteApplicantMutation__
 *
 * To run a mutation, you first call `useDeleteApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicantMutation, { data, loading, error }] = useDeleteApplicantMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteApplicantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApplicantMutation, DeleteApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApplicantMutation, DeleteApplicantMutationVariables>(DeleteApplicantDocument, options);
      }
export type DeleteApplicantMutationHookResult = ReturnType<typeof useDeleteApplicantMutation>;
export type DeleteApplicantMutationResult = Apollo.MutationResult<DeleteApplicantMutation>;
export type DeleteApplicantMutationOptions = Apollo.BaseMutationOptions<DeleteApplicantMutation, DeleteApplicantMutationVariables>;
export const InsertApplicantDocument = gql`
    mutation InsertApplicant($object: applicants_insert_input!) {
  insert_applicants_one(object: $object) {
    ...Applicant
  }
}
    ${ApplicantFragmentDoc}`;
export type InsertApplicantMutationFn = Apollo.MutationFunction<InsertApplicantMutation, InsertApplicantMutationVariables>;

/**
 * __useInsertApplicantMutation__
 *
 * To run a mutation, you first call `useInsertApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertApplicantMutation, { data, loading, error }] = useInsertApplicantMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertApplicantMutation(baseOptions?: Apollo.MutationHookOptions<InsertApplicantMutation, InsertApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertApplicantMutation, InsertApplicantMutationVariables>(InsertApplicantDocument, options);
      }
export type InsertApplicantMutationHookResult = ReturnType<typeof useInsertApplicantMutation>;
export type InsertApplicantMutationResult = Apollo.MutationResult<InsertApplicantMutation>;
export type InsertApplicantMutationOptions = Apollo.BaseMutationOptions<InsertApplicantMutation, InsertApplicantMutationVariables>;
export const InsertApplicantLinksDocument = gql`
    mutation InsertApplicantLinks($object: links_insert_input!) {
  insert_links_one(object: $object) {
    applicant_id
    id
    link
    created_at
  }
}
    `;
export type InsertApplicantLinksMutationFn = Apollo.MutationFunction<InsertApplicantLinksMutation, InsertApplicantLinksMutationVariables>;

/**
 * __useInsertApplicantLinksMutation__
 *
 * To run a mutation, you first call `useInsertApplicantLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertApplicantLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertApplicantLinksMutation, { data, loading, error }] = useInsertApplicantLinksMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertApplicantLinksMutation(baseOptions?: Apollo.MutationHookOptions<InsertApplicantLinksMutation, InsertApplicantLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertApplicantLinksMutation, InsertApplicantLinksMutationVariables>(InsertApplicantLinksDocument, options);
      }
export type InsertApplicantLinksMutationHookResult = ReturnType<typeof useInsertApplicantLinksMutation>;
export type InsertApplicantLinksMutationResult = Apollo.MutationResult<InsertApplicantLinksMutation>;
export type InsertApplicantLinksMutationOptions = Apollo.BaseMutationOptions<InsertApplicantLinksMutation, InsertApplicantLinksMutationVariables>;
export const InsertActivityApplicantDocument = gql`
    mutation InsertActivityApplicant($object: activity_log_applicants_insert_input!) {
  insert_activity_log_applicants_one(object: $object) {
    ...ActivityApplicantFields
  }
}
    ${ActivityApplicantFieldsFragmentDoc}`;
export type InsertActivityApplicantMutationFn = Apollo.MutationFunction<InsertActivityApplicantMutation, InsertActivityApplicantMutationVariables>;

/**
 * __useInsertActivityApplicantMutation__
 *
 * To run a mutation, you first call `useInsertActivityApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertActivityApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertActivityApplicantMutation, { data, loading, error }] = useInsertActivityApplicantMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertActivityApplicantMutation(baseOptions?: Apollo.MutationHookOptions<InsertActivityApplicantMutation, InsertActivityApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertActivityApplicantMutation, InsertActivityApplicantMutationVariables>(InsertActivityApplicantDocument, options);
      }
export type InsertActivityApplicantMutationHookResult = ReturnType<typeof useInsertActivityApplicantMutation>;
export type InsertActivityApplicantMutationResult = Apollo.MutationResult<InsertActivityApplicantMutation>;
export type InsertActivityApplicantMutationOptions = Apollo.BaseMutationOptions<InsertActivityApplicantMutation, InsertActivityApplicantMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation DeleteNote($id: Int!) {
  delete_notes_by_pk(id: $id) {
    ...Notes
  }
}
    ${NotesFragmentDoc}`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const InsertNoteDocument = gql`
    mutation InsertNote($object: notes_insert_input!) {
  insert_notes_one(object: $object) {
    ...Notes
  }
}
    ${NotesFragmentDoc}`;
export type InsertNoteMutationFn = Apollo.MutationFunction<InsertNoteMutation, InsertNoteMutationVariables>;

/**
 * __useInsertNoteMutation__
 *
 * To run a mutation, you first call `useInsertNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNoteMutation, { data, loading, error }] = useInsertNoteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertNoteMutation(baseOptions?: Apollo.MutationHookOptions<InsertNoteMutation, InsertNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertNoteMutation, InsertNoteMutationVariables>(InsertNoteDocument, options);
      }
export type InsertNoteMutationHookResult = ReturnType<typeof useInsertNoteMutation>;
export type InsertNoteMutationResult = Apollo.MutationResult<InsertNoteMutation>;
export type InsertNoteMutationOptions = Apollo.BaseMutationOptions<InsertNoteMutation, InsertNoteMutationVariables>;
export const ForgotPasswordRequestDocument = gql`
    mutation ForgotPasswordRequest($email: String!) {
  forgot_password_request(email: $email) {
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type ForgotPasswordRequestMutationFn = Apollo.MutationFunction<ForgotPasswordRequestMutation, ForgotPasswordRequestMutationVariables>;

/**
 * __useForgotPasswordRequestMutation__
 *
 * To run a mutation, you first call `useForgotPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordRequestMutation, { data, loading, error }] = useForgotPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordRequestMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordRequestMutation, ForgotPasswordRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordRequestMutation, ForgotPasswordRequestMutationVariables>(ForgotPasswordRequestDocument, options);
      }
export type ForgotPasswordRequestMutationHookResult = ReturnType<typeof useForgotPasswordRequestMutation>;
export type ForgotPasswordRequestMutationResult = Apollo.MutationResult<ForgotPasswordRequestMutation>;
export type ForgotPasswordRequestMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordRequestMutation, ForgotPasswordRequestMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($data: UserInput!) {
  register(data: $data) {
    id
    name
    email
    token
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($confirm_password: String!, $password: String!, $token: String!) {
  password_reset(
    confirm_password: $confirm_password
    password: $password
    token: $token
  ) {
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      confirm_password: // value for 'confirm_password'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const DeleteFileStoreByUserIdDocument = gql`
    mutation DeleteFileStoreByUserId($fileableId: [Int!]) {
  delete_file(fileable_id: $fileableId) {
    statusCode
    statusIsOk
    statusMessage
    statusPath
  }
}
    `;
export type DeleteFileStoreByUserIdMutationFn = Apollo.MutationFunction<DeleteFileStoreByUserIdMutation, DeleteFileStoreByUserIdMutationVariables>;

/**
 * __useDeleteFileStoreByUserIdMutation__
 *
 * To run a mutation, you first call `useDeleteFileStoreByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileStoreByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileStoreByUserIdMutation, { data, loading, error }] = useDeleteFileStoreByUserIdMutation({
 *   variables: {
 *      fileableId: // value for 'fileableId'
 *   },
 * });
 */
export function useDeleteFileStoreByUserIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileStoreByUserIdMutation, DeleteFileStoreByUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileStoreByUserIdMutation, DeleteFileStoreByUserIdMutationVariables>(DeleteFileStoreByUserIdDocument, options);
      }
export type DeleteFileStoreByUserIdMutationHookResult = ReturnType<typeof useDeleteFileStoreByUserIdMutation>;
export type DeleteFileStoreByUserIdMutationResult = Apollo.MutationResult<DeleteFileStoreByUserIdMutation>;
export type DeleteFileStoreByUserIdMutationOptions = Apollo.BaseMutationOptions<DeleteFileStoreByUserIdMutation, DeleteFileStoreByUserIdMutationVariables>;
export const UpdateMessageByPkDocument = gql`
    mutation UpdateMessageByPk($id: Int!, $set: messages_set_input) {
  update_messages_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...MessageFields
  }
}
    ${MessageFieldsFragmentDoc}`;
export type UpdateMessageByPkMutationFn = Apollo.MutationFunction<UpdateMessageByPkMutation, UpdateMessageByPkMutationVariables>;

/**
 * __useUpdateMessageByPkMutation__
 *
 * To run a mutation, you first call `useUpdateMessageByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageByPkMutation, { data, loading, error }] = useUpdateMessageByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateMessageByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageByPkMutation, UpdateMessageByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageByPkMutation, UpdateMessageByPkMutationVariables>(UpdateMessageByPkDocument, options);
      }
export type UpdateMessageByPkMutationHookResult = ReturnType<typeof useUpdateMessageByPkMutation>;
export type UpdateMessageByPkMutationResult = Apollo.MutationResult<UpdateMessageByPkMutation>;
export type UpdateMessageByPkMutationOptions = Apollo.BaseMutationOptions<UpdateMessageByPkMutation, UpdateMessageByPkMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($data: SendMessageInput!) {
  send_message(data: $data) {
    statusCode
    statusMessage
    statusPath
    statusIsOk
    applicant_id
    message_applicant_id
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const CancleCheckoutSubscriptionDocument = gql`
    mutation CancleCheckoutSubscription($company_id: Int!) {
  stripe_cancel_subscription(company_id: $company_id) {
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type CancleCheckoutSubscriptionMutationFn = Apollo.MutationFunction<CancleCheckoutSubscriptionMutation, CancleCheckoutSubscriptionMutationVariables>;

/**
 * __useCancleCheckoutSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancleCheckoutSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancleCheckoutSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancleCheckoutSubscriptionMutation, { data, loading, error }] = useCancleCheckoutSubscriptionMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useCancleCheckoutSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancleCheckoutSubscriptionMutation, CancleCheckoutSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancleCheckoutSubscriptionMutation, CancleCheckoutSubscriptionMutationVariables>(CancleCheckoutSubscriptionDocument, options);
      }
export type CancleCheckoutSubscriptionMutationHookResult = ReturnType<typeof useCancleCheckoutSubscriptionMutation>;
export type CancleCheckoutSubscriptionMutationResult = Apollo.MutationResult<CancleCheckoutSubscriptionMutation>;
export type CancleCheckoutSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancleCheckoutSubscriptionMutation, CancleCheckoutSubscriptionMutationVariables>;
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession($data: CheckoutSessionInput!) {
  stripe_create_checkout_session(data: $data) {
    sessionId
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument, options);
      }
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationResult = Apollo.MutationResult<CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const UpdateAccountSettingsDocument = gql`
    mutation UpdateAccountSettings($user_profile_data: UserProfileInput, $company_profile_data: CompanyProfileInput) {
  update_profile(
    user_profile_data: $user_profile_data
    company_profile_data: $company_profile_data
  ) {
    statusCode
    statusIsOk
    statusMessage
    statusPath
  }
}
    `;
export type UpdateAccountSettingsMutationFn = Apollo.MutationFunction<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;

/**
 * __useUpdateAccountSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSettingsMutation, { data, loading, error }] = useUpdateAccountSettingsMutation({
 *   variables: {
 *      user_profile_data: // value for 'user_profile_data'
 *      company_profile_data: // value for 'company_profile_data'
 *   },
 * });
 */
export function useUpdateAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>(UpdateAccountSettingsDocument, options);
      }
export type UpdateAccountSettingsMutationHookResult = ReturnType<typeof useUpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationResult = Apollo.MutationResult<UpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;
export const ApiTokenInsertDocument = gql`
    mutation ApiTokenInsert($companyId: Int!, $name: String!) {
  generate_api_token(company_id: $companyId, name: $name) {
    statusCode
    statusMessage
    statusPath
    statusIsOk
  }
}
    `;
export type ApiTokenInsertMutationFn = Apollo.MutationFunction<ApiTokenInsertMutation, ApiTokenInsertMutationVariables>;

/**
 * __useApiTokenInsertMutation__
 *
 * To run a mutation, you first call `useApiTokenInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiTokenInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiTokenInsertMutation, { data, loading, error }] = useApiTokenInsertMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useApiTokenInsertMutation(baseOptions?: Apollo.MutationHookOptions<ApiTokenInsertMutation, ApiTokenInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiTokenInsertMutation, ApiTokenInsertMutationVariables>(ApiTokenInsertDocument, options);
      }
export type ApiTokenInsertMutationHookResult = ReturnType<typeof useApiTokenInsertMutation>;
export type ApiTokenInsertMutationResult = Apollo.MutationResult<ApiTokenInsertMutation>;
export type ApiTokenInsertMutationOptions = Apollo.BaseMutationOptions<ApiTokenInsertMutation, ApiTokenInsertMutationVariables>;
export const ApiTokenDeleteDocument = gql`
    mutation ApiTokenDelete($id: Int!) {
  delete_api_tokens_by_pk(id: $id) {
    ...Token
  }
}
    ${TokenFragmentDoc}`;
export type ApiTokenDeleteMutationFn = Apollo.MutationFunction<ApiTokenDeleteMutation, ApiTokenDeleteMutationVariables>;

/**
 * __useApiTokenDeleteMutation__
 *
 * To run a mutation, you first call `useApiTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiTokenDeleteMutation, { data, loading, error }] = useApiTokenDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiTokenDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ApiTokenDeleteMutation, ApiTokenDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiTokenDeleteMutation, ApiTokenDeleteMutationVariables>(ApiTokenDeleteDocument, options);
      }
export type ApiTokenDeleteMutationHookResult = ReturnType<typeof useApiTokenDeleteMutation>;
export type ApiTokenDeleteMutationResult = Apollo.MutationResult<ApiTokenDeleteMutation>;
export type ApiTokenDeleteMutationOptions = Apollo.BaseMutationOptions<ApiTokenDeleteMutation, ApiTokenDeleteMutationVariables>;
export const StageInsertDocument = gql`
    mutation StageInsert($object: stages_insert_input!) {
  insert_stages_one(object: $object) {
    ...StageFields
  }
}
    ${StageFieldsFragmentDoc}`;
export type StageInsertMutationFn = Apollo.MutationFunction<StageInsertMutation, StageInsertMutationVariables>;

/**
 * __useStageInsertMutation__
 *
 * To run a mutation, you first call `useStageInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageInsertMutation, { data, loading, error }] = useStageInsertMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useStageInsertMutation(baseOptions?: Apollo.MutationHookOptions<StageInsertMutation, StageInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageInsertMutation, StageInsertMutationVariables>(StageInsertDocument, options);
      }
export type StageInsertMutationHookResult = ReturnType<typeof useStageInsertMutation>;
export type StageInsertMutationResult = Apollo.MutationResult<StageInsertMutation>;
export type StageInsertMutationOptions = Apollo.BaseMutationOptions<StageInsertMutation, StageInsertMutationVariables>;
export const StageUpdateDocument = gql`
    mutation StageUpdate($id: Int!, $set: stages_set_input) {
  update_stages_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...StageFields
  }
}
    ${StageFieldsFragmentDoc}`;
export type StageUpdateMutationFn = Apollo.MutationFunction<StageUpdateMutation, StageUpdateMutationVariables>;

/**
 * __useStageUpdateMutation__
 *
 * To run a mutation, you first call `useStageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageUpdateMutation, { data, loading, error }] = useStageUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useStageUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StageUpdateMutation, StageUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageUpdateMutation, StageUpdateMutationVariables>(StageUpdateDocument, options);
      }
export type StageUpdateMutationHookResult = ReturnType<typeof useStageUpdateMutation>;
export type StageUpdateMutationResult = Apollo.MutationResult<StageUpdateMutation>;
export type StageUpdateMutationOptions = Apollo.BaseMutationOptions<StageUpdateMutation, StageUpdateMutationVariables>;
export const StageDeleteDocument = gql`
    mutation StageDelete($id: Int!) {
  delete_stages_by_pk(id: $id) {
    ...StageFields
  }
}
    ${StageFieldsFragmentDoc}`;
export type StageDeleteMutationFn = Apollo.MutationFunction<StageDeleteMutation, StageDeleteMutationVariables>;

/**
 * __useStageDeleteMutation__
 *
 * To run a mutation, you first call `useStageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageDeleteMutation, { data, loading, error }] = useStageDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStageDeleteMutation(baseOptions?: Apollo.MutationHookOptions<StageDeleteMutation, StageDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageDeleteMutation, StageDeleteMutationVariables>(StageDeleteDocument, options);
      }
export type StageDeleteMutationHookResult = ReturnType<typeof useStageDeleteMutation>;
export type StageDeleteMutationResult = Apollo.MutationResult<StageDeleteMutation>;
export type StageDeleteMutationOptions = Apollo.BaseMutationOptions<StageDeleteMutation, StageDeleteMutationVariables>;
export const MessageTemplateInsertDocument = gql`
    mutation MessageTemplateInsert($object: message_templates_insert_input!) {
  insert_message_templates_one(object: $object) {
    ...MessageTemplates
  }
}
    ${MessageTemplatesFragmentDoc}`;
export type MessageTemplateInsertMutationFn = Apollo.MutationFunction<MessageTemplateInsertMutation, MessageTemplateInsertMutationVariables>;

/**
 * __useMessageTemplateInsertMutation__
 *
 * To run a mutation, you first call `useMessageTemplateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageTemplateInsertMutation, { data, loading, error }] = useMessageTemplateInsertMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useMessageTemplateInsertMutation(baseOptions?: Apollo.MutationHookOptions<MessageTemplateInsertMutation, MessageTemplateInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageTemplateInsertMutation, MessageTemplateInsertMutationVariables>(MessageTemplateInsertDocument, options);
      }
export type MessageTemplateInsertMutationHookResult = ReturnType<typeof useMessageTemplateInsertMutation>;
export type MessageTemplateInsertMutationResult = Apollo.MutationResult<MessageTemplateInsertMutation>;
export type MessageTemplateInsertMutationOptions = Apollo.BaseMutationOptions<MessageTemplateInsertMutation, MessageTemplateInsertMutationVariables>;
export const MessageTemplateUpdateDocument = gql`
    mutation MessageTemplateUpdate($id: Int!, $set: message_templates_set_input!) {
  update_message_templates_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...MessageTemplates
  }
}
    ${MessageTemplatesFragmentDoc}`;
export type MessageTemplateUpdateMutationFn = Apollo.MutationFunction<MessageTemplateUpdateMutation, MessageTemplateUpdateMutationVariables>;

/**
 * __useMessageTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useMessageTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageTemplateUpdateMutation, { data, loading, error }] = useMessageTemplateUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useMessageTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MessageTemplateUpdateMutation, MessageTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageTemplateUpdateMutation, MessageTemplateUpdateMutationVariables>(MessageTemplateUpdateDocument, options);
      }
export type MessageTemplateUpdateMutationHookResult = ReturnType<typeof useMessageTemplateUpdateMutation>;
export type MessageTemplateUpdateMutationResult = Apollo.MutationResult<MessageTemplateUpdateMutation>;
export type MessageTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<MessageTemplateUpdateMutation, MessageTemplateUpdateMutationVariables>;
export const MessageTemplateDeleteDocument = gql`
    mutation MessageTemplateDelete($id: Int!) {
  delete_message_templates_by_pk(id: $id) {
    ...MessageTemplates
  }
}
    ${MessageTemplatesFragmentDoc}`;
export type MessageTemplateDeleteMutationFn = Apollo.MutationFunction<MessageTemplateDeleteMutation, MessageTemplateDeleteMutationVariables>;

/**
 * __useMessageTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useMessageTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageTemplateDeleteMutation, { data, loading, error }] = useMessageTemplateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageTemplateDeleteMutation(baseOptions?: Apollo.MutationHookOptions<MessageTemplateDeleteMutation, MessageTemplateDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageTemplateDeleteMutation, MessageTemplateDeleteMutationVariables>(MessageTemplateDeleteDocument, options);
      }
export type MessageTemplateDeleteMutationHookResult = ReturnType<typeof useMessageTemplateDeleteMutation>;
export type MessageTemplateDeleteMutationResult = Apollo.MutationResult<MessageTemplateDeleteMutation>;
export type MessageTemplateDeleteMutationOptions = Apollo.BaseMutationOptions<MessageTemplateDeleteMutation, MessageTemplateDeleteMutationVariables>;
export const TeamMemberInsertDocument = gql`
    mutation TeamMemberInsert($companyId: Int!, $email: String!, $name: String!, $role: Int!) {
  invite_teammate(company_id: $companyId, email: $email, name: $name, role: $role) {
    id
    statusCode
    statusIsOk
    statusMessage
  }
}
    `;
export type TeamMemberInsertMutationFn = Apollo.MutationFunction<TeamMemberInsertMutation, TeamMemberInsertMutationVariables>;

/**
 * __useTeamMemberInsertMutation__
 *
 * To run a mutation, you first call `useTeamMemberInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberInsertMutation, { data, loading, error }] = useTeamMemberInsertMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useTeamMemberInsertMutation(baseOptions?: Apollo.MutationHookOptions<TeamMemberInsertMutation, TeamMemberInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamMemberInsertMutation, TeamMemberInsertMutationVariables>(TeamMemberInsertDocument, options);
      }
export type TeamMemberInsertMutationHookResult = ReturnType<typeof useTeamMemberInsertMutation>;
export type TeamMemberInsertMutationResult = Apollo.MutationResult<TeamMemberInsertMutation>;
export type TeamMemberInsertMutationOptions = Apollo.BaseMutationOptions<TeamMemberInsertMutation, TeamMemberInsertMutationVariables>;
export const TeamMemberUpdateDocument = gql`
    mutation TeamMemberUpdate($id: Int!, $companyId: Int!, $role: Int!) {
  update_company_users(
    where: {user_id: {_eq: $id}, company_id: {_eq: $companyId}}
    _set: {role: $role}
  ) {
    returning {
      id
    }
  }
}
    `;
export type TeamMemberUpdateMutationFn = Apollo.MutationFunction<TeamMemberUpdateMutation, TeamMemberUpdateMutationVariables>;

/**
 * __useTeamMemberUpdateMutation__
 *
 * To run a mutation, you first call `useTeamMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberUpdateMutation, { data, loading, error }] = useTeamMemberUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useTeamMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TeamMemberUpdateMutation, TeamMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamMemberUpdateMutation, TeamMemberUpdateMutationVariables>(TeamMemberUpdateDocument, options);
      }
export type TeamMemberUpdateMutationHookResult = ReturnType<typeof useTeamMemberUpdateMutation>;
export type TeamMemberUpdateMutationResult = Apollo.MutationResult<TeamMemberUpdateMutation>;
export type TeamMemberUpdateMutationOptions = Apollo.BaseMutationOptions<TeamMemberUpdateMutation, TeamMemberUpdateMutationVariables>;
export const TeamMemberDeleteDocument = gql`
    mutation TeamMemberDelete($id: Int!) {
  delete_company_users(where: {user_id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type TeamMemberDeleteMutationFn = Apollo.MutationFunction<TeamMemberDeleteMutation, TeamMemberDeleteMutationVariables>;

/**
 * __useTeamMemberDeleteMutation__
 *
 * To run a mutation, you first call `useTeamMemberDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberDeleteMutation, { data, loading, error }] = useTeamMemberDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamMemberDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TeamMemberDeleteMutation, TeamMemberDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamMemberDeleteMutation, TeamMemberDeleteMutationVariables>(TeamMemberDeleteDocument, options);
      }
export type TeamMemberDeleteMutationHookResult = ReturnType<typeof useTeamMemberDeleteMutation>;
export type TeamMemberDeleteMutationResult = Apollo.MutationResult<TeamMemberDeleteMutation>;
export type TeamMemberDeleteMutationOptions = Apollo.BaseMutationOptions<TeamMemberDeleteMutation, TeamMemberDeleteMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($email: String!, $password: String!) {
  updateEmail: update_email(email: $email, password: $password) {
    statusIsOk
    statusMessage
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateEmailResetDocument = gql`
    mutation UpdateEmailReset($email: String!, $expireAt: timestamptz!) {
  updateEmailReset: update_email_reset(
    where: {email: {_eq: $email}}
    _set: {expire_at: $expireAt}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateEmailResetMutationFn = Apollo.MutationFunction<UpdateEmailResetMutation, UpdateEmailResetMutationVariables>;

/**
 * __useUpdateEmailResetMutation__
 *
 * To run a mutation, you first call `useUpdateEmailResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailResetMutation, { data, loading, error }] = useUpdateEmailResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      expireAt: // value for 'expireAt'
 *   },
 * });
 */
export function useUpdateEmailResetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailResetMutation, UpdateEmailResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailResetMutation, UpdateEmailResetMutationVariables>(UpdateEmailResetDocument, options);
      }
export type UpdateEmailResetMutationHookResult = ReturnType<typeof useUpdateEmailResetMutation>;
export type UpdateEmailResetMutationResult = Apollo.MutationResult<UpdateEmailResetMutation>;
export type UpdateEmailResetMutationOptions = Apollo.BaseMutationOptions<UpdateEmailResetMutation, UpdateEmailResetMutationVariables>;
export const ConfirmEmailResetDocument = gql`
    mutation ConfirmEmailReset($token: String!) {
  confirmEmailReset: email_reset(token: $token) {
    statusIsOk
  }
}
    `;
export type ConfirmEmailResetMutationFn = Apollo.MutationFunction<ConfirmEmailResetMutation, ConfirmEmailResetMutationVariables>;

/**
 * __useConfirmEmailResetMutation__
 *
 * To run a mutation, you first call `useConfirmEmailResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailResetMutation, { data, loading, error }] = useConfirmEmailResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailResetMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailResetMutation, ConfirmEmailResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailResetMutation, ConfirmEmailResetMutationVariables>(ConfirmEmailResetDocument, options);
      }
export type ConfirmEmailResetMutationHookResult = ReturnType<typeof useConfirmEmailResetMutation>;
export type ConfirmEmailResetMutationResult = Apollo.MutationResult<ConfirmEmailResetMutation>;
export type ConfirmEmailResetMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailResetMutation, ConfirmEmailResetMutationVariables>;
export const RevertEmailResetDocument = gql`
    mutation RevertEmailReset($token: String!) {
  revertEmailReset: revert_email_reset(token: $token) {
    statusIsOk
  }
}
    `;
export type RevertEmailResetMutationFn = Apollo.MutationFunction<RevertEmailResetMutation, RevertEmailResetMutationVariables>;

/**
 * __useRevertEmailResetMutation__
 *
 * To run a mutation, you first call `useRevertEmailResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertEmailResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertEmailResetMutation, { data, loading, error }] = useRevertEmailResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRevertEmailResetMutation(baseOptions?: Apollo.MutationHookOptions<RevertEmailResetMutation, RevertEmailResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertEmailResetMutation, RevertEmailResetMutationVariables>(RevertEmailResetDocument, options);
      }
export type RevertEmailResetMutationHookResult = ReturnType<typeof useRevertEmailResetMutation>;
export type RevertEmailResetMutationResult = Apollo.MutationResult<RevertEmailResetMutation>;
export type RevertEmailResetMutationOptions = Apollo.BaseMutationOptions<RevertEmailResetMutation, RevertEmailResetMutationVariables>;
export const DeleteEmailResetDocument = gql`
    mutation DeleteEmailReset($email: String!) {
  deleteEmailReset: delete_email_reset(where: {email: {_eq: $email}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteEmailResetMutationFn = Apollo.MutationFunction<DeleteEmailResetMutation, DeleteEmailResetMutationVariables>;

/**
 * __useDeleteEmailResetMutation__
 *
 * To run a mutation, you first call `useDeleteEmailResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailResetMutation, { data, loading, error }] = useDeleteEmailResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteEmailResetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailResetMutation, DeleteEmailResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailResetMutation, DeleteEmailResetMutationVariables>(DeleteEmailResetDocument, options);
      }
export type DeleteEmailResetMutationHookResult = ReturnType<typeof useDeleteEmailResetMutation>;
export type DeleteEmailResetMutationResult = Apollo.MutationResult<DeleteEmailResetMutation>;
export type DeleteEmailResetMutationOptions = Apollo.BaseMutationOptions<DeleteEmailResetMutation, DeleteEmailResetMutationVariables>;
export const DeleteSubscribersDocument = gql`
    mutation DeleteSubscribers($ids: [Int!]!) {
  delete_subscribers(where: {id: {_in: $ids}}) {
    returning {
      ...Subscribers
    }
  }
}
    ${SubscribersFragmentDoc}`;
export type DeleteSubscribersMutationFn = Apollo.MutationFunction<DeleteSubscribersMutation, DeleteSubscribersMutationVariables>;

/**
 * __useDeleteSubscribersMutation__
 *
 * To run a mutation, you first call `useDeleteSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscribersMutation, { data, loading, error }] = useDeleteSubscribersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSubscribersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubscribersMutation, DeleteSubscribersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscribersMutation, DeleteSubscribersMutationVariables>(DeleteSubscribersDocument, options);
      }
export type DeleteSubscribersMutationHookResult = ReturnType<typeof useDeleteSubscribersMutation>;
export type DeleteSubscribersMutationResult = Apollo.MutationResult<DeleteSubscribersMutation>;
export type DeleteSubscribersMutationOptions = Apollo.BaseMutationOptions<DeleteSubscribersMutation, DeleteSubscribersMutationVariables>;
export const InsertVacanciesDocument = gql`
    mutation insertVacancies($data: VacanciesInput!) {
  insertVacancy: insert_vacancy(data: $data) {
    statusCode
    statusMessage
    statusPath
    statusIsOk
    is_shown
    status
    vacancy_id
  }
}
    `;
export type InsertVacanciesMutationFn = Apollo.MutationFunction<InsertVacanciesMutation, InsertVacanciesMutationVariables>;

/**
 * __useInsertVacanciesMutation__
 *
 * To run a mutation, you first call `useInsertVacanciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVacanciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVacanciesMutation, { data, loading, error }] = useInsertVacanciesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertVacanciesMutation(baseOptions?: Apollo.MutationHookOptions<InsertVacanciesMutation, InsertVacanciesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertVacanciesMutation, InsertVacanciesMutationVariables>(InsertVacanciesDocument, options);
      }
export type InsertVacanciesMutationHookResult = ReturnType<typeof useInsertVacanciesMutation>;
export type InsertVacanciesMutationResult = Apollo.MutationResult<InsertVacanciesMutation>;
export type InsertVacanciesMutationOptions = Apollo.BaseMutationOptions<InsertVacanciesMutation, InsertVacanciesMutationVariables>;
export const UpdateVacanciesByPkDocument = gql`
    mutation UpdateVacanciesByPk($id: Int!, $_set: vacancies_set_input) {
  update_vacancies_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Vacancy
  }
}
    ${VacancyFragmentDoc}`;
export type UpdateVacanciesByPkMutationFn = Apollo.MutationFunction<UpdateVacanciesByPkMutation, UpdateVacanciesByPkMutationVariables>;

/**
 * __useUpdateVacanciesByPkMutation__
 *
 * To run a mutation, you first call `useUpdateVacanciesByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacanciesByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacanciesByPkMutation, { data, loading, error }] = useUpdateVacanciesByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateVacanciesByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVacanciesByPkMutation, UpdateVacanciesByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVacanciesByPkMutation, UpdateVacanciesByPkMutationVariables>(UpdateVacanciesByPkDocument, options);
      }
export type UpdateVacanciesByPkMutationHookResult = ReturnType<typeof useUpdateVacanciesByPkMutation>;
export type UpdateVacanciesByPkMutationResult = Apollo.MutationResult<UpdateVacanciesByPkMutation>;
export type UpdateVacanciesByPkMutationOptions = Apollo.BaseMutationOptions<UpdateVacanciesByPkMutation, UpdateVacanciesByPkMutationVariables>;
export const DeleteVacanciesDocument = gql`
    mutation deleteVacancies($id: Int!) {
  delete_vacancies_by_pk(id: $id) {
    ...Vacancy
  }
}
    ${VacancyFragmentDoc}`;
export type DeleteVacanciesMutationFn = Apollo.MutationFunction<DeleteVacanciesMutation, DeleteVacanciesMutationVariables>;

/**
 * __useDeleteVacanciesMutation__
 *
 * To run a mutation, you first call `useDeleteVacanciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacanciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacanciesMutation, { data, loading, error }] = useDeleteVacanciesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVacanciesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVacanciesMutation, DeleteVacanciesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVacanciesMutation, DeleteVacanciesMutationVariables>(DeleteVacanciesDocument, options);
      }
export type DeleteVacanciesMutationHookResult = ReturnType<typeof useDeleteVacanciesMutation>;
export type DeleteVacanciesMutationResult = Apollo.MutationResult<DeleteVacanciesMutation>;
export type DeleteVacanciesMutationOptions = Apollo.BaseMutationOptions<DeleteVacanciesMutation, DeleteVacanciesMutationVariables>;
export const GetFileStoreByApplicantIdDocument = gql`
    query GetFileStoreByApplicantId($fileableId: Int!) {
  file_stores(
    where: {fileable_id: {_eq: $fileableId}}
    order_by: {updated_at: desc}
  ) {
    ...FileStoreFields
  }
}
    ${FileStoreFieldsFragmentDoc}`;

/**
 * __useGetFileStoreByApplicantIdQuery__
 *
 * To run a query within a React component, call `useGetFileStoreByApplicantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileStoreByApplicantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileStoreByApplicantIdQuery({
 *   variables: {
 *      fileableId: // value for 'fileableId'
 *   },
 * });
 */
export function useGetFileStoreByApplicantIdQuery(baseOptions: Apollo.QueryHookOptions<GetFileStoreByApplicantIdQuery, GetFileStoreByApplicantIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileStoreByApplicantIdQuery, GetFileStoreByApplicantIdQueryVariables>(GetFileStoreByApplicantIdDocument, options);
      }
export function useGetFileStoreByApplicantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileStoreByApplicantIdQuery, GetFileStoreByApplicantIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileStoreByApplicantIdQuery, GetFileStoreByApplicantIdQueryVariables>(GetFileStoreByApplicantIdDocument, options);
        }
export type GetFileStoreByApplicantIdQueryHookResult = ReturnType<typeof useGetFileStoreByApplicantIdQuery>;
export type GetFileStoreByApplicantIdLazyQueryHookResult = ReturnType<typeof useGetFileStoreByApplicantIdLazyQuery>;
export type GetFileStoreByApplicantIdQueryResult = Apollo.QueryResult<GetFileStoreByApplicantIdQuery, GetFileStoreByApplicantIdQueryVariables>;
export const GetApplicantsDocument = gql`
    query GetApplicants($company_id: Int!) {
  applicants(
    where: {vacancy: {company_id: {_eq: $company_id}}}
    order_by: {name: asc}
  ) {
    email
    id
  }
}
    `;

/**
 * __useGetApplicantsQuery__
 *
 * To run a query within a React component, call `useGetApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetApplicantsQuery(baseOptions: Apollo.QueryHookOptions<GetApplicantsQuery, GetApplicantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicantsQuery, GetApplicantsQueryVariables>(GetApplicantsDocument, options);
      }
export function useGetApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantsQuery, GetApplicantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicantsQuery, GetApplicantsQueryVariables>(GetApplicantsDocument, options);
        }
export type GetApplicantsQueryHookResult = ReturnType<typeof useGetApplicantsQuery>;
export type GetApplicantsLazyQueryHookResult = ReturnType<typeof useGetApplicantsLazyQuery>;
export type GetApplicantsQueryResult = Apollo.QueryResult<GetApplicantsQuery, GetApplicantsQueryVariables>;
export const ApplicantsLinkDocument = gql`
    query ApplicantsLink($applicant_id: Int!) {
  links(where: {applicant_id: {_eq: $applicant_id}}) {
    id
    link
  }
}
    `;

/**
 * __useApplicantsLinkQuery__
 *
 * To run a query within a React component, call `useApplicantsLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantsLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantsLinkQuery({
 *   variables: {
 *      applicant_id: // value for 'applicant_id'
 *   },
 * });
 */
export function useApplicantsLinkQuery(baseOptions: Apollo.QueryHookOptions<ApplicantsLinkQuery, ApplicantsLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicantsLinkQuery, ApplicantsLinkQueryVariables>(ApplicantsLinkDocument, options);
      }
export function useApplicantsLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicantsLinkQuery, ApplicantsLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicantsLinkQuery, ApplicantsLinkQueryVariables>(ApplicantsLinkDocument, options);
        }
export type ApplicantsLinkQueryHookResult = ReturnType<typeof useApplicantsLinkQuery>;
export type ApplicantsLinkLazyQueryHookResult = ReturnType<typeof useApplicantsLinkLazyQuery>;
export type ApplicantsLinkQueryResult = Apollo.QueryResult<ApplicantsLinkQuery, ApplicantsLinkQueryVariables>;
export const FindPreviousApplicationsDocument = gql`
    query FindPreviousApplications($applicantId: Int!, $applicantEmail: String!) {
  applicants(
    where: {id: {_neq: $applicantId}, _and: {email: {_ilike: $applicantEmail}}}
  ) {
    created_at
    stage {
      name
      color
    }
    vacancy {
      id
      title
    }
  }
}
    `;

/**
 * __useFindPreviousApplicationsQuery__
 *
 * To run a query within a React component, call `useFindPreviousApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPreviousApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPreviousApplicationsQuery({
 *   variables: {
 *      applicantId: // value for 'applicantId'
 *      applicantEmail: // value for 'applicantEmail'
 *   },
 * });
 */
export function useFindPreviousApplicationsQuery(baseOptions: Apollo.QueryHookOptions<FindPreviousApplicationsQuery, FindPreviousApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPreviousApplicationsQuery, FindPreviousApplicationsQueryVariables>(FindPreviousApplicationsDocument, options);
      }
export function useFindPreviousApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPreviousApplicationsQuery, FindPreviousApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPreviousApplicationsQuery, FindPreviousApplicationsQueryVariables>(FindPreviousApplicationsDocument, options);
        }
export type FindPreviousApplicationsQueryHookResult = ReturnType<typeof useFindPreviousApplicationsQuery>;
export type FindPreviousApplicationsLazyQueryHookResult = ReturnType<typeof useFindPreviousApplicationsLazyQuery>;
export type FindPreviousApplicationsQueryResult = Apollo.QueryResult<FindPreviousApplicationsQuery, FindPreviousApplicationsQueryVariables>;
export const AuthUserDocument = gql`
    query authUser {
  auth_user {
    email
    id
    name
  }
}
    `;

/**
 * __useAuthUserQuery__
 *
 * To run a query within a React component, call `useAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthUserQuery(baseOptions?: Apollo.QueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, options);
      }
export function useAuthUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, options);
        }
export type AuthUserQueryHookResult = ReturnType<typeof useAuthUserQuery>;
export type AuthUserLazyQueryHookResult = ReturnType<typeof useAuthUserLazyQuery>;
export type AuthUserQueryResult = Apollo.QueryResult<AuthUserQuery, AuthUserQueryVariables>;
export const GetCompanyUserDocument = gql`
    query getCompanyUser($id: Int!) {
  company_users(where: {user_id: {_eq: $id}}) {
    id
    company_id
    role
  }
}
    `;

/**
 * __useGetCompanyUserQuery__
 *
 * To run a query within a React component, call `useGetCompanyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyUserQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyUserQuery, GetCompanyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUserQuery, GetCompanyUserQueryVariables>(GetCompanyUserDocument, options);
      }
export function useGetCompanyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUserQuery, GetCompanyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUserQuery, GetCompanyUserQueryVariables>(GetCompanyUserDocument, options);
        }
export type GetCompanyUserQueryHookResult = ReturnType<typeof useGetCompanyUserQuery>;
export type GetCompanyUserLazyQueryHookResult = ReturnType<typeof useGetCompanyUserLazyQuery>;
export type GetCompanyUserQueryResult = Apollo.QueryResult<GetCompanyUserQuery, GetCompanyUserQueryVariables>;
export const ValidateResetTokenDocument = gql`
    query validateResetToken($token: String!) {
  password_reset(where: {token: {_eq: $token}}) {
    expire_at
  }
}
    `;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateResetTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
      }
export function useValidateResetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export type ValidateResetTokenQueryHookResult = ReturnType<typeof useValidateResetTokenQuery>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<typeof useValidateResetTokenLazyQuery>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>;
export const VacanciesStatsDocument = gql`
    query VacanciesStats($period: String!) {
  job_posting_data(period: $period) {
    detailed_jobs_data
    jobs_data
    total_applicants
    statusCode
    statusIsOk
    statusMessage
    statusPath
  }
}
    `;

/**
 * __useVacanciesStatsQuery__
 *
 * To run a query within a React component, call `useVacanciesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacanciesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacanciesStatsQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useVacanciesStatsQuery(baseOptions: Apollo.QueryHookOptions<VacanciesStatsQuery, VacanciesStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacanciesStatsQuery, VacanciesStatsQueryVariables>(VacanciesStatsDocument, options);
      }
export function useVacanciesStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacanciesStatsQuery, VacanciesStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacanciesStatsQuery, VacanciesStatsQueryVariables>(VacanciesStatsDocument, options);
        }
export type VacanciesStatsQueryHookResult = ReturnType<typeof useVacanciesStatsQuery>;
export type VacanciesStatsLazyQueryHookResult = ReturnType<typeof useVacanciesStatsLazyQuery>;
export type VacanciesStatsQueryResult = Apollo.QueryResult<VacanciesStatsQuery, VacanciesStatsQueryVariables>;
export const ApplicantMessageDocument = gql`
    query ApplicantMessage($message_id: Int!) {
  message_applicants_by_pk(id: $message_id) {
    id
    company_id
    applicant {
      id
      name
      email
      description
    }
    user {
      id
      name
      email
      file_store {
        file_name
        file_path
      }
    }
    message {
      id
      is_read
      message
      subject
      created_at
      opened_at
      message_replies(order_by: {created_at: desc}) {
        id
        message
        created_at
        sender_data
        opened_at
      }
    }
  }
}
    `;

/**
 * __useApplicantMessageQuery__
 *
 * To run a query within a React component, call `useApplicantMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantMessageQuery({
 *   variables: {
 *      message_id: // value for 'message_id'
 *   },
 * });
 */
export function useApplicantMessageQuery(baseOptions: Apollo.QueryHookOptions<ApplicantMessageQuery, ApplicantMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicantMessageQuery, ApplicantMessageQueryVariables>(ApplicantMessageDocument, options);
      }
export function useApplicantMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicantMessageQuery, ApplicantMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicantMessageQuery, ApplicantMessageQueryVariables>(ApplicantMessageDocument, options);
        }
export type ApplicantMessageQueryHookResult = ReturnType<typeof useApplicantMessageQuery>;
export type ApplicantMessageLazyQueryHookResult = ReturnType<typeof useApplicantMessageLazyQuery>;
export type ApplicantMessageQueryResult = Apollo.QueryResult<ApplicantMessageQuery, ApplicantMessageQueryVariables>;
export const MessagesDocument = gql`
    query Messages($offset: Int!, $limit: Int!, $where: messages_bool_exp!) {
  messages(
    where: $where
    offset: $offset
    limit: $limit
    order_by: {created_at: desc}
  ) {
    ...InboxMessage
  }
}
    ${InboxMessageFragmentDoc}`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const MessagesCountDocument = gql`
    query MessagesCount($where: message_applicants_bool_exp!) {
  message_applicants_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useMessagesCountQuery__
 *
 * To run a query within a React component, call `useMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMessagesCountQuery(baseOptions: Apollo.QueryHookOptions<MessagesCountQuery, MessagesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesCountQuery, MessagesCountQueryVariables>(MessagesCountDocument, options);
      }
export function useMessagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesCountQuery, MessagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesCountQuery, MessagesCountQueryVariables>(MessagesCountDocument, options);
        }
export type MessagesCountQueryHookResult = ReturnType<typeof useMessagesCountQuery>;
export type MessagesCountLazyQueryHookResult = ReturnType<typeof useMessagesCountLazyQuery>;
export type MessagesCountQueryResult = Apollo.QueryResult<MessagesCountQuery, MessagesCountQueryVariables>;
export const NotesDocument = gql`
    query Notes($applicantId: Int!, $offset: Int!, $limit: Int!) {
  notes(
    offset: $offset
    limit: $limit
    order_by: {created_at: desc}
    where: {applicant_id: {_eq: $applicantId}}
  ) {
    ...Notes
  }
}
    ${NotesFragmentDoc}`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      applicantId: // value for 'applicantId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotesQuery(baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const AccountSettingDocument = gql`
    query AccountSetting($id: Int!) {
  users(where: {id: {_eq: $id}}) {
    ...usersSettings
  }
}
    ${UsersSettingsFragmentDoc}`;

/**
 * __useAccountSettingQuery__
 *
 * To run a query within a React component, call `useAccountSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountSettingQuery(baseOptions: Apollo.QueryHookOptions<AccountSettingQuery, AccountSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSettingQuery, AccountSettingQueryVariables>(AccountSettingDocument, options);
      }
export function useAccountSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSettingQuery, AccountSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSettingQuery, AccountSettingQueryVariables>(AccountSettingDocument, options);
        }
export type AccountSettingQueryHookResult = ReturnType<typeof useAccountSettingQuery>;
export type AccountSettingLazyQueryHookResult = ReturnType<typeof useAccountSettingLazyQuery>;
export type AccountSettingQueryResult = Apollo.QueryResult<AccountSettingQuery, AccountSettingQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($company_id: Int!, $offset: Int!, $limit: Int!) {
  invoices(
    offset: $offset
    limit: $limit
    order_by: {created_at: desc}
    where: {company_id: {_eq: $company_id}}
  ) {
    id
    amount
    companyId: company_id
    createdAt: created_at
    invoiceNumber: invoice_number
    invoiceUrl: invoice_url
    paidAt: paid_at
    updatedAt: updated_at
  }
  invoicesAggregate: invoices_aggregate(where: {company_id: {_eq: $company_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const ApplicantActivityDocument = gql`
    subscription ApplicantActivity($applicant_id: Int!) {
  activity_log_applicants(
    where: {applicant_id: {_eq: $applicant_id}}
    order_by: {created_at: asc}
  ) {
    ...ActivityApplicant
  }
}
    ${ActivityApplicantFragmentDoc}`;

/**
 * __useApplicantActivitySubscription__
 *
 * To run a query within a React component, call `useApplicantActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useApplicantActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantActivitySubscription({
 *   variables: {
 *      applicant_id: // value for 'applicant_id'
 *   },
 * });
 */
export function useApplicantActivitySubscription(baseOptions: Apollo.SubscriptionHookOptions<ApplicantActivitySubscription, ApplicantActivitySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ApplicantActivitySubscription, ApplicantActivitySubscriptionVariables>(ApplicantActivityDocument, options);
      }
export type ApplicantActivitySubscriptionHookResult = ReturnType<typeof useApplicantActivitySubscription>;
export type ApplicantActivitySubscriptionResult = Apollo.SubscriptionResult<ApplicantActivitySubscription>;
export const ApplicantDocument = gql`
    subscription Applicant($id: Int!) {
  applicants_by_pk(id: $id) {
    ...Applicant
  }
}
    ${ApplicantFragmentDoc}`;

/**
 * __useApplicantSubscription__
 *
 * To run a query within a React component, call `useApplicantSubscription` and pass it any options that fit your needs.
 * When your component renders, `useApplicantSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicantSubscription(baseOptions: Apollo.SubscriptionHookOptions<ApplicantSubscription, ApplicantSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ApplicantSubscription, ApplicantSubscriptionVariables>(ApplicantDocument, options);
      }
export type ApplicantSubscriptionHookResult = ReturnType<typeof useApplicantSubscription>;
export type ApplicantSubscriptionResult = Apollo.SubscriptionResult<ApplicantSubscription>;
export const ApplicantsDocument = gql`
    subscription Applicants($limit: Int!, $offset: Int!, $where: applicants_bool_exp!) {
  applicants(
    limit: $limit
    offset: $offset
    where: $where
    order_by: {created_at: desc}
  ) {
    ...Applicant
  }
}
    ${ApplicantFragmentDoc}`;

/**
 * __useApplicantsSubscription__
 *
 * To run a query within a React component, call `useApplicantsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useApplicantsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantsSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApplicantsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ApplicantsSubscription, ApplicantsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ApplicantsSubscription, ApplicantsSubscriptionVariables>(ApplicantsDocument, options);
      }
export type ApplicantsSubscriptionHookResult = ReturnType<typeof useApplicantsSubscription>;
export type ApplicantsSubscriptionResult = Apollo.SubscriptionResult<ApplicantsSubscription>;
export const AllApplicantsDocument = gql`
    subscription AllApplicants($where_count: applicants_bool_exp!) {
  count_all: applicants_aggregate(where: $where_count) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAllApplicantsSubscription__
 *
 * To run a query within a React component, call `useAllApplicantsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllApplicantsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApplicantsSubscription({
 *   variables: {
 *      where_count: // value for 'where_count'
 *   },
 * });
 */
export function useAllApplicantsSubscription(baseOptions: Apollo.SubscriptionHookOptions<AllApplicantsSubscription, AllApplicantsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllApplicantsSubscription, AllApplicantsSubscriptionVariables>(AllApplicantsDocument, options);
      }
export type AllApplicantsSubscriptionHookResult = ReturnType<typeof useAllApplicantsSubscription>;
export type AllApplicantsSubscriptionResult = Apollo.SubscriptionResult<AllApplicantsSubscription>;
export const ActiveApplicantsDocument = gql`
    subscription ActiveApplicants($limit: Int!, $offset: Int!, $where_count: applicants_bool_exp!) {
  count_active: applicants_aggregate(
    where: $where_count
    limit: $limit
    offset: $offset
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useActiveApplicantsSubscription__
 *
 * To run a query within a React component, call `useActiveApplicantsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActiveApplicantsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveApplicantsSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where_count: // value for 'where_count'
 *   },
 * });
 */
export function useActiveApplicantsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ActiveApplicantsSubscription, ActiveApplicantsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ActiveApplicantsSubscription, ActiveApplicantsSubscriptionVariables>(ActiveApplicantsDocument, options);
      }
export type ActiveApplicantsSubscriptionHookResult = ReturnType<typeof useActiveApplicantsSubscription>;
export type ActiveApplicantsSubscriptionResult = Apollo.SubscriptionResult<ActiveApplicantsSubscription>;
export const MessageByPkDocument = gql`
    subscription MessageByPk($messageId: Int!) {
  messages_by_pk(id: $messageId) {
    ...Message
  }
}
    ${MessageFragmentDoc}`;

/**
 * __useMessageByPkSubscription__
 *
 * To run a query within a React component, call `useMessageByPkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageByPkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageByPkSubscription({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMessageByPkSubscription(baseOptions: Apollo.SubscriptionHookOptions<MessageByPkSubscription, MessageByPkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageByPkSubscription, MessageByPkSubscriptionVariables>(MessageByPkDocument, options);
      }
export type MessageByPkSubscriptionHookResult = ReturnType<typeof useMessageByPkSubscription>;
export type MessageByPkSubscriptionResult = Apollo.SubscriptionResult<MessageByPkSubscription>;
export const NewMessagesCountDocument = gql`
    subscription NewMessagesCount($where: messages_bool_exp!) {
  messages_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useNewMessagesCountSubscription__
 *
 * To run a query within a React component, call `useNewMessagesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessagesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessagesCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNewMessagesCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewMessagesCountSubscription, NewMessagesCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewMessagesCountSubscription, NewMessagesCountSubscriptionVariables>(NewMessagesCountDocument, options);
      }
export type NewMessagesCountSubscriptionHookResult = ReturnType<typeof useNewMessagesCountSubscription>;
export type NewMessagesCountSubscriptionResult = Apollo.SubscriptionResult<NewMessagesCountSubscription>;
export const AllMessagesCountDocument = gql`
    subscription AllMessagesCount($companyId: Int!) {
  message_applicants_aggregate(where: {company_id: {_eq: $companyId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAllMessagesCountSubscription__
 *
 * To run a query within a React component, call `useAllMessagesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllMessagesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessagesCountSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllMessagesCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<AllMessagesCountSubscription, AllMessagesCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllMessagesCountSubscription, AllMessagesCountSubscriptionVariables>(AllMessagesCountDocument, options);
      }
export type AllMessagesCountSubscriptionHookResult = ReturnType<typeof useAllMessagesCountSubscription>;
export type AllMessagesCountSubscriptionResult = Apollo.SubscriptionResult<AllMessagesCountSubscription>;
export const NumberOffNotesDocument = gql`
    subscription NumberOffNotes($applicantId: Int!) {
  notesAggregate: notes_aggregate(where: {applicant_id: {_eq: $applicantId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useNumberOffNotesSubscription__
 *
 * To run a query within a React component, call `useNumberOffNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNumberOffNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOffNotesSubscription({
 *   variables: {
 *      applicantId: // value for 'applicantId'
 *   },
 * });
 */
export function useNumberOffNotesSubscription(baseOptions: Apollo.SubscriptionHookOptions<NumberOffNotesSubscription, NumberOffNotesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NumberOffNotesSubscription, NumberOffNotesSubscriptionVariables>(NumberOffNotesDocument, options);
      }
export type NumberOffNotesSubscriptionHookResult = ReturnType<typeof useNumberOffNotesSubscription>;
export type NumberOffNotesSubscriptionResult = Apollo.SubscriptionResult<NumberOffNotesSubscription>;
export const StagesDocument = gql`
    subscription Stages($companyId: Int!) {
  stages(where: {company_id: {_eq: $companyId}}, order_by: {order: asc}) {
    ...StageFields
  }
}
    ${StageFieldsFragmentDoc}`;

/**
 * __useStagesSubscription__
 *
 * To run a query within a React component, call `useStagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStagesSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useStagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<StagesSubscription, StagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StagesSubscription, StagesSubscriptionVariables>(StagesDocument, options);
      }
export type StagesSubscriptionHookResult = ReturnType<typeof useStagesSubscription>;
export type StagesSubscriptionResult = Apollo.SubscriptionResult<StagesSubscription>;
export const StagesStatsDocument = gql`
    subscription StagesStats($where: stages_bool_exp, $limit: Int!) {
  stages(where: $where, limit: $limit) {
    id
    name
    is_shown
    applicants_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useStagesStatsSubscription__
 *
 * To run a query within a React component, call `useStagesStatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStagesStatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStagesStatsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useStagesStatsSubscription(baseOptions: Apollo.SubscriptionHookOptions<StagesStatsSubscription, StagesStatsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StagesStatsSubscription, StagesStatsSubscriptionVariables>(StagesStatsDocument, options);
      }
export type StagesStatsSubscriptionHookResult = ReturnType<typeof useStagesStatsSubscription>;
export type StagesStatsSubscriptionResult = Apollo.SubscriptionResult<StagesStatsSubscription>;
export const SubscribersDocument = gql`
    subscription Subscribers($limit: Int!, $offset: Int!, $where: subscribers_bool_exp!) {
  subscribers(
    limit: $limit
    offset: $offset
    where: $where
    order_by: {created_at: desc}
  ) {
    ...Subscribers
  }
}
    ${SubscribersFragmentDoc}`;

/**
 * __useSubscribersSubscription__
 *
 * To run a query within a React component, call `useSubscribersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribersSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribersSubscription, SubscribersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribersSubscription, SubscribersSubscriptionVariables>(SubscribersDocument, options);
      }
export type SubscribersSubscriptionHookResult = ReturnType<typeof useSubscribersSubscription>;
export type SubscribersSubscriptionResult = Apollo.SubscriptionResult<SubscribersSubscription>;
export const AllSubscribersDocument = gql`
    subscription AllSubscribers($where: subscribers_bool_exp!) {
  count_all: subscribers_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAllSubscribersSubscription__
 *
 * To run a query within a React component, call `useAllSubscribersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllSubscribersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSubscribersSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllSubscribersSubscription(baseOptions: Apollo.SubscriptionHookOptions<AllSubscribersSubscription, AllSubscribersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllSubscribersSubscription, AllSubscribersSubscriptionVariables>(AllSubscribersDocument, options);
      }
export type AllSubscribersSubscriptionHookResult = ReturnType<typeof useAllSubscribersSubscription>;
export type AllSubscribersSubscriptionResult = Apollo.SubscriptionResult<AllSubscribersSubscription>;
export const VacancyDocument = gql`
    subscription Vacancy($id: Int!) {
  vacancies_by_pk(id: $id) {
    ...Vacancy
    applicants_count: applicants_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${VacancyFragmentDoc}`;

/**
 * __useVacancySubscription__
 *
 * To run a query within a React component, call `useVacancySubscription` and pass it any options that fit your needs.
 * When your component renders, `useVacancySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacancySubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVacancySubscription(baseOptions: Apollo.SubscriptionHookOptions<VacancySubscription, VacancySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VacancySubscription, VacancySubscriptionVariables>(VacancyDocument, options);
      }
export type VacancySubscriptionHookResult = ReturnType<typeof useVacancySubscription>;
export type VacancySubscriptionResult = Apollo.SubscriptionResult<VacancySubscription>;
export const VacanciesDocument = gql`
    subscription Vacancies($limit: Int!, $offset: Int, $where: vacancies_bool_exp) {
  vacancies(limit: $limit, offset: $offset, where: $where, order_by: {id: desc}) {
    ...Vacancy
    applicants_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${VacancyFragmentDoc}`;

/**
 * __useVacanciesSubscription__
 *
 * To run a query within a React component, call `useVacanciesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVacanciesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacanciesSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVacanciesSubscription(baseOptions: Apollo.SubscriptionHookOptions<VacanciesSubscription, VacanciesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VacanciesSubscription, VacanciesSubscriptionVariables>(VacanciesDocument, options);
      }
export type VacanciesSubscriptionHookResult = ReturnType<typeof useVacanciesSubscription>;
export type VacanciesSubscriptionResult = Apollo.SubscriptionResult<VacanciesSubscription>;
export const AllVacanciesCountDocument = gql`
    subscription AllVacanciesCount($where_all: vacancies_bool_exp) {
  count_all: vacancies_aggregate(where: $where_all) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAllVacanciesCountSubscription__
 *
 * To run a query within a React component, call `useAllVacanciesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllVacanciesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVacanciesCountSubscription({
 *   variables: {
 *      where_all: // value for 'where_all'
 *   },
 * });
 */
export function useAllVacanciesCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AllVacanciesCountSubscription, AllVacanciesCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllVacanciesCountSubscription, AllVacanciesCountSubscriptionVariables>(AllVacanciesCountDocument, options);
      }
export type AllVacanciesCountSubscriptionHookResult = ReturnType<typeof useAllVacanciesCountSubscription>;
export type AllVacanciesCountSubscriptionResult = Apollo.SubscriptionResult<AllVacanciesCountSubscription>;
export const PublishedVacanciesCountDocument = gql`
    subscription PublishedVacanciesCount($where_published: vacancies_bool_exp) {
  count_published: vacancies_aggregate(where: $where_published) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePublishedVacanciesCountSubscription__
 *
 * To run a query within a React component, call `usePublishedVacanciesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedVacanciesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedVacanciesCountSubscription({
 *   variables: {
 *      where_published: // value for 'where_published'
 *   },
 * });
 */
export function usePublishedVacanciesCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PublishedVacanciesCountSubscription, PublishedVacanciesCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PublishedVacanciesCountSubscription, PublishedVacanciesCountSubscriptionVariables>(PublishedVacanciesCountDocument, options);
      }
export type PublishedVacanciesCountSubscriptionHookResult = ReturnType<typeof usePublishedVacanciesCountSubscription>;
export type PublishedVacanciesCountSubscriptionResult = Apollo.SubscriptionResult<PublishedVacanciesCountSubscription>;
export const DraftVacanciesCountDocument = gql`
    subscription DraftVacanciesCount($where_draft: vacancies_bool_exp) {
  count_draft: vacancies_aggregate(where: $where_draft) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDraftVacanciesCountSubscription__
 *
 * To run a query within a React component, call `useDraftVacanciesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDraftVacanciesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftVacanciesCountSubscription({
 *   variables: {
 *      where_draft: // value for 'where_draft'
 *   },
 * });
 */
export function useDraftVacanciesCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DraftVacanciesCountSubscription, DraftVacanciesCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DraftVacanciesCountSubscription, DraftVacanciesCountSubscriptionVariables>(DraftVacanciesCountDocument, options);
      }
export type DraftVacanciesCountSubscriptionHookResult = ReturnType<typeof useDraftVacanciesCountSubscription>;
export type DraftVacanciesCountSubscriptionResult = Apollo.SubscriptionResult<DraftVacanciesCountSubscription>;
export const VacancyCountDocument = gql`
    subscription VacancyCount {
  vacanciesAggreate: vacancies_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useVacancyCountSubscription__
 *
 * To run a query within a React component, call `useVacancyCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVacancyCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacancyCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVacancyCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VacancyCountSubscription, VacancyCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VacancyCountSubscription, VacancyCountSubscriptionVariables>(VacancyCountDocument, options);
      }
export type VacancyCountSubscriptionHookResult = ReturnType<typeof useVacancyCountSubscription>;
export type VacancyCountSubscriptionResult = Apollo.SubscriptionResult<VacancyCountSubscription>;
export const MessageTemplatesDocument = gql`
    subscription MessageTemplates($id: Int!) {
  message_templates(where: {company_id: {_eq: $id}}, order_by: {updated_at: desc}) {
    ...MessageTemplates
  }
}
    ${MessageTemplatesFragmentDoc}`;

/**
 * __useMessageTemplatesSubscription__
 *
 * To run a query within a React component, call `useMessageTemplatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplatesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageTemplatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<MessageTemplatesSubscription, MessageTemplatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageTemplatesSubscription, MessageTemplatesSubscriptionVariables>(MessageTemplatesDocument, options);
      }
export type MessageTemplatesSubscriptionHookResult = ReturnType<typeof useMessageTemplatesSubscription>;
export type MessageTemplatesSubscriptionResult = Apollo.SubscriptionResult<MessageTemplatesSubscription>;
export const PaymentsDocument = gql`
    subscription Payments($company_id: Int!) {
  payments(where: {company_id: {_eq: $company_id}}) {
    id
    status
    companyId: company_id
    createdAt: created_at
    expiredAt: expire_at
    subscriptionId: subscription_id
    updatedAt: updated_at
  }
}
    `;

/**
 * __usePaymentsSubscription__
 *
 * To run a query within a React component, call `usePaymentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsSubscription({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function usePaymentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<PaymentsSubscription, PaymentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PaymentsSubscription, PaymentsSubscriptionVariables>(PaymentsDocument, options);
      }
export type PaymentsSubscriptionHookResult = ReturnType<typeof usePaymentsSubscription>;
export type PaymentsSubscriptionResult = Apollo.SubscriptionResult<PaymentsSubscription>;
export const TeamMembersDocument = gql`
    subscription TeamMembers($id: Int) {
  company_users(where: {company_id: {_eq: $id}}, order_by: {created_at: asc}) {
    ...TeamMembers
  }
}
    ${TeamMembersFragmentDoc}`;

/**
 * __useTeamMembersSubscription__
 *
 * To run a query within a React component, call `useTeamMembersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamMembersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TeamMembersSubscription, TeamMembersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TeamMembersSubscription, TeamMembersSubscriptionVariables>(TeamMembersDocument, options);
      }
export type TeamMembersSubscriptionHookResult = ReturnType<typeof useTeamMembersSubscription>;
export type TeamMembersSubscriptionResult = Apollo.SubscriptionResult<TeamMembersSubscription>;
export const TokensDocument = gql`
    subscription Tokens($id: Int!) {
  api_tokens(where: {company_id: {_eq: $id}}, order_by: {created_at: asc}) {
    ...Token
  }
}
    ${TokenFragmentDoc}`;

/**
 * __useTokensSubscription__
 *
 * To run a query within a React component, call `useTokensSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTokensSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTokensSubscription(baseOptions: Apollo.SubscriptionHookOptions<TokensSubscription, TokensSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TokensSubscription, TokensSubscriptionVariables>(TokensDocument, options);
      }
export type TokensSubscriptionHookResult = ReturnType<typeof useTokensSubscription>;
export type TokensSubscriptionResult = Apollo.SubscriptionResult<TokensSubscription>;
import React, { useState } from 'react';

//components
import { Carousel } from '../../molecules';

// assets
import { ReactComponent as CarouselItem1 } from '../../../static/img/CarouselItem1.svg';
import { ReactComponent as CarouselItem2 } from '../../../static/img/CarouselItem2.svg';
import { ReactComponent as CarouselItem3 } from '../../../static/img/CarouselItem3.svg';
import { ReactComponent as CarouselItem4 } from '../../../static/img/CarouselItem4.svg';

const AuthRightSide = () => {
  const [items] = useState([
    {
      imgSrc: <CarouselItem1 />,
      header: 'Job Posting Management',
      paragraph: `Create the perfect job description for your open positions with ease and without much effort with our user friendly template and post it to the most relevant channels within a single submission.`,
    },
    {
      imgSrc: <CarouselItem2 />,
      header: 'Candidate Management',
      paragraph: `With our applicant tracking features, it’s easy to know what stage of the hiring process your candidates are. Have complete visibility into how candidates are moving into your funnel.`,
    },
    {
      imgSrc: <CarouselItem3 />,
      header: 'Collaborative Recruiting',
      paragraph: `Discovr Plus is set up to allow everyone on your team to contribute to the hiring effort. With features like user roles, you can easily divvy up the work and get everyone involved.`,
    },
    {
      imgSrc: <CarouselItem4 />,
      header: 'Centralized Communication',
      paragraph: `Send and receive emails with candidates from within Discovr Plus. Each message exchanged gets displayed in a feed for future reference.`,
    },
  ]);

  return (
    <div className="auth__right">
      <div className="auth__right__carousel">
        <Carousel items={items} />
      </div>
    </div>
  );
};

export default AuthRightSide;

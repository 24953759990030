import React, { FC } from 'react';

// utils
import dayjs from 'dayjs';

// hooks
import { useL10N } from '../../../../../hooks/useL10N';

// gql
import {
  useUpdateEmailResetMutation,
  useDeleteEmailResetMutation,
  useUpdateEmailMutation,
} from '../../../../../gql/gen/graphql';

// components
import { Button } from '../../../../atoms';

// types
import { ToastrTypesEnums } from '../../../../../enums';
import { EmailChangeAlertBoxProps } from './EmailChangeAlertBox.types';

const EmailChangeAlertBox: FC<EmailChangeAlertBoxProps> = ({
  masterEmail,
  password,
  email,
  onCloseAlertBox,
  onPushToastr,
}) => {
  // l10n
  const { t } = useL10N();

  // update email reset mutation - used to revert change by expiring token
  const [
    updateEmailReset,
    { loading: resetLoading },
  ] = useUpdateEmailResetMutation({
    onError: () => {
      onPushToastr(
        ToastrTypesEnums.DANGER,
        t('settings.user.updateEmail.revertFailure')
      );
    },
    onCompleted: () => {
      onCloseAlertBox();
      onPushToastr(
        ToastrTypesEnums.SUCCESS,
        t('settings.user.updateEmail.revertSuccess')
      );
    },
  });

  // delete email reset mutation - used to delete registered change, needed for resed
  const [
    deleteEmailReset,
    { loading: deleteLoading },
  ] = useDeleteEmailResetMutation({
    onError: () => {
      onPushToastr(
        ToastrTypesEnums.DANGER,
        t('settings.user.updateEmail.resetFailure')
      );
    },
  });

  // insert email reset mutation - used to change email
  const [updateEmail, { loading: updateLoading }] = useUpdateEmailMutation({
    onError: () => {
      onPushToastr(
        ToastrTypesEnums.DANGER,
        t('settings.user.updateEmail.resetFailure')
      );
    },
    onCompleted: ({ updateEmail }) => {
      if (updateEmail?.statusIsOk) {
        onPushToastr(
          ToastrTypesEnums.SUCCESS,
          t('settings.user.updateEmail.resetSuccess')
        );
      } else
        onPushToastr(
          ToastrTypesEnums.DANGER,
          t('settings.user.updateEmail.resetFailure')
        );
    },
  });

  // handles revert feature
  const handleRevertEmailChange = () => {
    updateEmailReset({
      variables: {
        email: masterEmail,
        expireAt: dayjs().toISOString(),
      },
    });
  };

  // handles creating new change email request - clean ups the old
  const handleResendEmailChangeConfirmation = () => {
    deleteEmailReset({
      variables: {
        email: masterEmail,
      },
    }).then(() =>
      updateEmail({
        variables: {
          email,
          password,
        },
      })
    );
  };

  return (
    <div className="email-change-alert-box">
      <p
        className="email-change-alert-box__title"
        dangerouslySetInnerHTML={{
          __html: t('settings.user.updateEmail.title', {
            email: email,
          }),
        }}
      />
      <ul className="mt-4 list-disc ml-6 text-primary">
        <li>
          <div className="flex flex-row">
            <p>{t('settings.user.updateEmail.resendTitle')}</p>
            <Button
              label={t('settings.user.updateEmail.resendCTA')}
              className="ml-1 text-green-200 font-semiBold"
              onClick={handleResendEmailChangeConfirmation}
              loading={deleteLoading || updateLoading}
            />
          </div>
        </li>
        <li>
          <div className="flex flex-row">
            <p>{t('settings.user.updateEmail.revertTitle')}</p>
            <Button
              label={t('settings.user.updateEmail.revertCTA')}
              className="ml-1 text-green-200 font-semiBold"
              onClick={handleRevertEmailChange}
              loading={resetLoading}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default EmailChangeAlertBox;

export enum ButtonTypesEnums {
  DEFAULT = 'default',
  GREEN = 'green',
  BLUE = 'blue',
  WHITE = 'white',
  WHITE_BORDER = 'white-border',
  GRAY = 'gray',
  DARK_GRAY = 'dark-gray',
  DARK_BLUE = 'dark-blue',
  RED = 'red',
  INHERIT = 'inherit'
}

import React from 'react';
import { Link } from 'react-router-dom';

//components
import { ContentLoading } from '../../../atoms';
import { EmailListItem, EmptyMessage } from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useMessageResult } from '../../../../gql/hooks/messages/useMessageResult';

const MessagesHolder = () => {
  const { companyId } = useCompanyUser();
  const { t } = useL10N();

  const { messages, loading } = useMessageResult({ companyId, limit: 3 });

  const renderContent = () => {
    if (messages?.length === 0) return <EmptyMessage />;
    return (
      <>
        {messages.map((msg) => (
          <EmailListItem
            key={msg?.id}
            active=""
            email={{
              id: msg?.id.toString(),
              seen: msg?.read_at,
              date: msg?.created_at,
              subject: msg?.subject || '',
              name: msg?.applicant?.name || '',
              applicant_id: msg?.applicant?.id.toString(),
              vacancyTitle: msg?.applicant?.vacancy.title,
              repliesCount: msg?.repliesCount?.aggregate?.count || 0,
            }}
          />
        ))}
      </>
    );
  };

  return (
    <div className="job__content">
      <div className="job__content--header">
        <h1 className="job__content--header--title">{t('dashboard.messages')}</h1>
        {messages.length ? (
          <Link className="job__content--header--desc" to="/messages/inbox">
            {t('dashboard.seeAll')}
          </Link>
        ) : null}
      </div>
      <div className="job__content--content">
        {!loading ? (
          renderContent()
        ) : (
          <div className="pt-8 pb-16">
            <ContentLoading />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesHolder;

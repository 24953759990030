import React, {
  FC,
  useRef,
  ChangeEvent,
  isValidElement,
  cloneElement,
  ReactElement,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

//components
import { Arrows } from '../../atoms';

//types
import { TabsProps } from './Tabs.types';

const PhoneTabs: FC<TabsProps> = ({ tabs, beforeLabel }) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const active =
    tabs.find((tab) => pathname.includes(tab.match || tab.to)) || tabs[0];

  const handleOnSelect = (event: ChangeEvent<HTMLSelectElement>) =>
    push(event.target.value);

  return (
    <div className="tabs__phone">
      <div className="tabs__phone__main">
        {isValidElement(active?.before) && (
          <div className="tabs__phone__main--before">
            {cloneElement(active?.before as ReactElement, {
              active: true,
            })}
          </div>
        )}
        <select
          className="tabs__phone__main--select"
          name="tabs"
          id="tabs"
          value={active?.to}
          ref={selectRef}
          onChange={handleOnSelect}
        >
          {tabs.map((tab, key) => (
            <option key={key} value={tab.to} tabIndex={key}>
              {tab.label}
            </option>
          ))}
        </select>
        <div className="tabs__phone__main--after">
          {beforeLabel && <span>{beforeLabel}: </span>}
          <span className="tabs__phone__main--after--label">
            {active?.label}
          </span>
          {active?.after}
        </div>
        <div className="tabs__phone__main--arrows">
          <Arrows />
        </div>
      </div>
    </div>
  );
};

export default PhoneTabs;

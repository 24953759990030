import React from 'react';

import { Svg } from '../../../atoms';

const MessageEmpty = () => (
  <Svg
    width={84}
    height={59}
    viewBox="0 0 84 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx={42} cy={49} rx={42} ry={10} fill="#EDEFF4" />
    <path d="M26.727 16.491L18 22.82V49h48V22.82l-8.727-6.328" fill="#fff" />
    <path
      d="M26.727 16.491L18 22.82V49h48V22.82l-8.727-6.328"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M26.727 27.618V1h30.546v26.618M18 22.818L66 49M66 22.818L42 35.91"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default MessageEmpty;

import React from 'react';

import { Svg } from '../../../atoms';

export const LinkedinIcon = () => (
  <Svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3.5H.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V1.2c-.1-.4-.4-.7-.8-.7zM4.7 14.1H2.4V6.5h2.4v7.6h-.1zM3.6 5.5c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8H6.2V6.5h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z"
      fill="#0069A9"
    />
  </Svg>
);

export default LinkedinIcon;

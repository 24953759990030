import React from 'react';

import { Svg } from '../../../../atoms';

const NewTeamMember = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.667 5.667V8 5.667zm0 2.333v2.333V8zm0 0H15h-2.333zm0 0h-2.334 2.334zm-3.89-3.889a3.111 3.111 0 11-6.221 0 3.111 3.111 0 016.222 0v0zM1 14.222a4.667 4.667 0 019.333 0V15H1v-.778z"
      stroke="#fff"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default NewTeamMember;

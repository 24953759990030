import React from 'react';

import { Svg } from '../../../../atoms';

const NewTemplate = () => (
  <Svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.667 10.917h5.5-5.5zm2.75-2.75v5.5-5.5zM12 18.25H2.833A1.833 1.833 0 011 16.417V3.583A1.833 1.833 0 012.833 1.75h5.12c.244 0 .477.097.649.269l4.963 4.962a.916.916 0 01.268.649v8.787A1.833 1.833 0 0112 18.25z"
      stroke="#fff"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default NewTemplate;

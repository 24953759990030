import React from 'react';

import { Svg } from '../../../../atoms';

const Cart = () => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75 9.166h16.5-16.5zm3.667 4.584h.916-.916zm4.583 0h.917H11zm-5.5 3.666h11a2.75 2.75 0 002.75-2.75V7.333a2.75 2.75 0 00-2.75-2.75h-11a2.75 2.75 0 00-2.75 2.75v7.333a2.75 2.75 0 002.75 2.75z"
      stroke="#ADBAC8"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cart;

import React, { FC } from 'react';

//components
import { Dot } from '../../atoms';

//types
import { JobPostItemProps } from './JobPostItem.types';

// utils
import { generateFormatedDate } from '../../../utils';

const JobPostItem: FC<JobPostItemProps> = ({ item }) => (
  <div className="jobPost__item">
    <div className="flex items-center">
      <Dot style={{ backgroundColor: item.color }} />
      <p className="ml-3">{item.name}</p>
    </div>
    <p className="text-right ">{item.applicants}</p>
    <p className="text-right ">{generateFormatedDate(item.deadline)}</p>
  </div>
);

export default JobPostItem;

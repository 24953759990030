import React, { useState, createContext, FC, ReactElement } from 'react';

//types
import { Nullable } from '../interfaces/types/Nullable.types';

// component
import { CustomModal, TrialExpiredModal } from '../components/molecules';

//enums
import { ModalTypesEnums } from '../enums';

// types
import { PaymentContextProps } from './PaymentContext.types';

export const PaymentContext = createContext<Nullable<PaymentContextProps>>(
  null
);

export const PaymentProvider: FC = ({ children }): ReactElement => {
  const [showPaymentBadge, setShowPaymentBadge] = useState<boolean>(false);
  const [
    isTrialExpiredModalOpen,
    setIsTrialExpiredModalOpen,
  ] = useState<boolean>(false);

  const showBadge = () => {
    setShowPaymentBadge(true);
  };

  const closeBadge = () => {
    setShowPaymentBadge(false);
  };
  const showModal = () => {
    setIsTrialExpiredModalOpen(true);
    setShowPaymentBadge(false);
  };

  const closeModal = () => {
    setIsTrialExpiredModalOpen(false);
  };

  return (
    <PaymentContext.Provider
      value={{ showBadge, closeBadge, showModal, showPaymentBadge, closeModal }}
    >
      {children}
      {isTrialExpiredModalOpen && (
        <CustomModal
          open={isTrialExpiredModalOpen}
          header={false}
          type={ModalTypesEnums.SM}
        >
          <TrialExpiredModal />
        </CustomModal>
      )}
    </PaymentContext.Provider>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Button } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//enums
import { MessageTypes } from '../../../enums';

//utils
import { parseQueryString, serialize } from '../../../utils';
import { FilterItemsProps } from './MessagesFormDropdown.types';

const FilterItems = ({ handleCloseMenu }: FilterItemsProps) => {
  const {
    push,
    location: { search },
  } = useHistory();
  const { t } = useL10N();

  const { type, location } = parseQueryString(search);
  const types = (type && type.split(',')) || [];

  const handleCheck = (id: string) => {
    let tempTypes = types;
    tempTypes = [id];
    push(
      serialize({
        location,
        type: `${tempTypes.join(',')}`,
      })
    );
    handleCloseMenu();
  };

  return (
    <>
      <Button
        className="default__dropdown__messages-filter--item"
        label={t('messages.all')}
        onClick={() => handleCheck(MessageTypes.ALL)}
      />
      <Button
        className="default__dropdown__messages-filter--item"
        label={t('messages.read')}
        onClick={() => handleCheck(MessageTypes.READ)}
      />
      <Button
        className="default__dropdown__messages-filter--item"
        label={t('messages.unread')}
        onClick={() => handleCheck(MessageTypes.UNREAD)}
      />
    </>
  );
};

export default FilterItems;

import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

//components
import { LogoAuth } from '../../atoms';
import AuthFooter from '../../organisms/authFooter/AuthFooter';

//types
import { AuthFormContainerProps } from './AuthFormContainer.types';

const AuthFormContainer: FC<AuthFormContainerProps> = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <div className={`auth__left ${pathname === '/auth/login' ? '' : 'overflow-y-auto'}`}>
      <div>
        <Link to="/auth/login" aria-label="Logo">
          <LogoAuth />
        </Link>
      </div>
      <div className="auth__left--container">
        <div className="auth__form">{children}</div>
      </div>
      <AuthFooter borderTop={false} />
    </div>
  );
};

export default AuthFormContainer;

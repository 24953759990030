import React, { FC, useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

//components
import { Copy, Created, Button, ThreeDots } from '../../../atoms';
import CustomTooltip from '../../tooltips/CustomTooltip';
import { Title, CardElements, DeleteDropdown, DefaultDropdown } from '../../';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//gql
import { useApiTokenDeleteMutation } from '../../../../gql/gen/graphql';

//types
import { TokentemProps } from './TokenItem.types';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//enums
import { ButtonSizesEnums, ButtonTypesEnums, ToastrTypesEnums } from '../../../../enums';
import { generateFormatedDate } from '../../../../utils';

const TokenItem: FC<TokentemProps> = ({ token, id }) => {
  const { xl } = useWindowSize();
  const toast = useContext(toastrContext);
  const { t } = useL10N();

  const [deleteApiToken] = useApiTokenDeleteMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('tokens.deletedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('tokens.deletedSuccessfully'));
    },
  });

  const handleDeleteConfirm = () => {
    deleteApiToken({
      variables: { id: token.id },
    });
  };

  return (
    <div className="template__item">
      <div className="template__item__body">
        <div className="template__item__body--header">
          <span className="template__item__body--order">{id}</span>
          <Title className="mt-4" value={token.name} />
          {!xl && (
            <div className="template__item__body--header__dots">
              <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
                <DeleteDropdown onDeleteConfirm={handleDeleteConfirm} />
              </DefaultDropdown>
            </div>
          )}
        </div>
        <div className="mb-6 template__item__body--content">
          <CardElements
            elements={[
              {
                value: `Created at  ${generateFormatedDate(token.created_at)}`,
                Icon: <Created />,
              },
            ]}
          />
        </div>
        <div className="flex-col template__item__body--button">
          <span className="text-sm text-tertiary">{token.token}</span>
          <CustomTooltip
            label={'Copy to clipboard'}
            label_after="Copied to clipboard!"
            id={token.id}
          >
            <CopyToClipboard text={token.token}>
              <Button
                className="mt-4 mb-6 md:mt-0 md:mb-0 template__item__body--button--view"
                label="Copy"
                btnType={ButtonTypesEnums.WHITE_BORDER}
                btnSize={ButtonSizesEnums.XL}
                before={<Copy />}
              />
            </CopyToClipboard>
          </CustomTooltip>
        </div>
      </div>

      {xl && (
        <div className="items-center template__item__buttons">
          <span className="mr-3 text-tertiary">{token.token}</span>
          <CustomTooltip
            label={'Copy to clipboard'}
            label_after="Copied to clipboard!"
            id={token.id}
          >
            <CopyToClipboard text={token.token}>
              <Button
                className="template__item__body--button--view"
                label="Copy"
                btnType={ButtonTypesEnums.WHITE_BORDER}
                btnSize={ButtonSizesEnums.XL}
                before={<Copy />}
              />
            </CopyToClipboard>
          </CustomTooltip>
          <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
            <DeleteDropdown onDeleteConfirm={handleDeleteConfirm} />
          </DefaultDropdown>
        </div>
      )}
    </div>
  );
};

export default TokenItem;

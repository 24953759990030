import { useMessageTemplatesSubscription } from '../../gen/graphql'

const useMessageTemplate = ({ company_id }) => {
    const query = useMessageTemplatesSubscription({ variables: { id: company_id } });

    const { data: { message_templates = [] } = {}, loading, error } = query;

    return {
        message_templates,
        loading,
        error
    };
};

export default useMessageTemplate;
import React from 'react';

import { Svg } from '../../../atoms';

const Dashboard = () => (
  <Svg
    width="73"
    height="70"
    viewBox="0 0 73 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M56.3906 4.88561C62.1896 7.68019 67.3672 12.3652 70.1839 18.2009C73.0419 24.0367 73.539 31.0231 72.5035 37.763C71.5094 44.5439 69.0241 51.0783 64.8405 56.5031C60.657 61.9689 54.7751 66.3252 48.1477 68.4622C41.4789 70.5992 34.0645 70.476 27.3128 68.2978C20.6026 66.1608 14.5965 62.01 9.70875 56.7086C4.77961 51.4482 0.927428 45.0371 0.140423 38.3383C-0.605161 31.5985 1.71443 24.5709 4.82103 17.9955C7.96905 11.42 11.9041 5.25548 17.5374 2.37871C23.1707 -0.539152 30.4194 -0.21038 37.2953 0.447167C44.1299 1.06362 50.5916 2.04994 56.3906 4.88561Z"
      fill="#EDEFF4"
    />
    <path
      d="M59.8628 50.0065C59.8628 50.8349 60.5344 51.5065 61.3628 51.5065C62.1912 51.5065 62.8628 50.8349 62.8628 50.0065H59.8628ZM61.3628 1.5238H62.8628C62.8628 0.695377 62.1912 0.0238037 61.3628 0.0238037V1.5238ZM10.5714 1.5238V0.0238037C9.74295 0.0238037 9.07138 0.695377 9.07138 1.5238L10.5714 1.5238ZM10.5714 61.55H9.07138H10.5714ZM17.4975 69.9761H18.9975V66.9761H17.4975V69.9761ZM62.8628 50.0065V1.5238H59.8628V50.0065H62.8628ZM61.3628 0.0238037H10.5714V3.0238H61.3628V0.0238037ZM9.07138 1.5238V61.55H12.0714V1.5238H9.07138ZM9.07138 61.55C9.07138 63.7848 9.95913 65.928 11.5393 67.5082L13.6606 65.3868C12.6431 64.3693 12.0714 62.9891 12.0714 61.55H9.07138ZM11.5393 67.5082C13.1195 69.0884 15.2627 69.9761 17.4975 69.9761V66.9761C16.0584 66.9761 14.6782 66.4044 13.6606 65.3868L11.5393 67.5082Z"
      fill="#778CA3"
    />
    <path
      d="M24.4238 61.5501C24.4238 63.3871 23.6941 65.1487 22.3952 66.4476C21.0963 67.7465 19.3346 68.4762 17.4977 68.4762H63.6717C65.5086 68.4762 67.2703 67.7465 68.5692 66.4476C69.8681 65.1487 70.5978 63.3871 70.5978 61.5501V56.9327H24.4238V61.5501Z"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M22.1148 16.5304H49.8192"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.1148 29.2281H49.8192"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.1148 41.926H49.8192"
      stroke="#778CA3"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Dashboard;

import { useContext } from 'react';
import { gqlVar } from '../../utils';
import { authContext } from '../../contexts/AuthContext';

import { useGetCompanyUserQuery } from '../gen/graphql';

const useCompanyUser = () => {
  const auth = useContext(authContext);

  const query = useGetCompanyUserQuery(gqlVar({ id: auth?.user?.id || 0 }));
  const { data: { company_users = [] } = {}, loading, error } = query;

  return {
    company_user: company_users[0],
    companyId: company_users[0]?.company_id,
    loading,
    error,
  };
};

export default useCompanyUser;

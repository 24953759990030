import React from 'react';

import { Svg } from '../../../atoms';

const Lock = () => (
  <Svg
    width={34}
    height={38}
    viewBox="0 0 34 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.5}
      d="M17 25v4M5 37h24a4 4 0 004-4V21a4 4 0 00-4-4H5a4 4 0 00-4 4v12a4 4 0 004 4zm20-20V9A8 8 0 009 9v8h16z"
      stroke="#00234B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Lock;

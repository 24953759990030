import React, { FC, useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';

//components
import {
  Button,
  ThreeDots,
  Bag,
  BigBag,
  Candidates,
  Edit,
  Clock,
  Location,
  EuroSign,
  Star,
} from '../../atoms';
import { CustomModal, DeleteJob, PublishJob, PromoteJob } from '../../molecules';
import { Title, Status, CardElements, JobsDropdown, DefaultDropdown } from '..';

//hooks
import useWindowSize from '../../../hooks/useWindowSize';
import { useL10N } from '../../../hooks/useL10N';

//gql
import { useUpdateVacanciesByPkMutation } from '../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../contexts/ToastrContexts';

//types
import { JobsItemProps } from './JobsItem.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  VacaniesStatusTypesEnums,
  VacaniesTypesEnums,
  ModalTypesEnums,
  ToastrTypesEnums,
} from '../../../enums';

//utils
import { gqlVar, generateFormatedDate, generateDatesDiff } from '../../../utils';

const JobsItem: FC<JobsItemProps> = ({ vacancy, prevRoute = '/jobs/all' }) => {
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);

  const { xl, width } = useWindowSize();
  const { t } = useL10N();
  const { push } = useHistory();
  const toast = useContext(toastrContext);

  const [updateMut] = useUpdateVacanciesByPkMutation({
    onError: (err) => {
      toast?.add(ToastrTypesEnums.DANGER, t('jobs.postedError'));
      setShowPublishModal(false);
    },
    onCompleted: () => {
      if (vacancy.isShownInDashboard) {
        setShowPromoteModal(true);
      } else {
        toast?.add(ToastrTypesEnums.SUCCESS, t('jobs.postedSuccessfully'));
      }
      setShowPublishModal(false);
    },
  });

  const onHoverLeave = () => xl && setShowButtons(false);
  const onHoverEnter = () => xl && setShowButtons(true);
  const onEditClick = () => push(`/job/${vacancy.id}/edit?rel=${prevRoute}`);

  const onPublishJob = () => {
    updateMut(
      gqlVar({
        id: +vacancy?.id,
        _set: { status: VacaniesStatusTypesEnums.PUBLISHED },
      })
    );
  };
  const closeDeleteModal = () => setShowDeleteModal(false);

  const closePublishModal = () => {
    setShowPublishModal(false);
  };

  const closePromotedModal = () => setShowPromoteModal(false);

  const handleClosePromotedModal = () => {
    toast?.add(ToastrTypesEnums.SUCCESS, t('job.postedSuccessfully'));
    setShowPromoteModal(false);
    push('/jobs/published');
  };

  return (
    <>
      <div className="job__item" onMouseEnter={onHoverEnter} onMouseLeave={onHoverLeave}>
        <div className="job__item__body">
          <Link to={`/job/${vacancy.id}/all`} className="job__item__body__avatar">
            <BigBag />
          </Link>
          <div className="job__item__body__header">
            <div className="flex flex-col items-start w-full md:flex-row md:items-center">
              {' '}
              <Link to={`/job/${vacancy.id}/all`} className="job__item__body__header--content">
                <Title value={vacancy.title} />
              </Link>
              {vacancy.status === VacaniesStatusTypesEnums.DRAFT && (
                <Status value={t('jobs.drafts')} className="mt-2 md:mt-0" />
              )}
              {xl && vacancy.isPromoted && generateDatesDiff(vacancy?.payments[0]?.expiresAt) > 0 && (
                <div className="flex ml-4 text-sm uppercase text-yellow job__item__body__header--content">
                  <Star />
                  <span className="ml-1"> Promoted</span>
                </div>
              )}
            </div>
            {!xl && (
              <div className="job__item__body__header__dots">
                <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
                  <JobsDropdown
                    vacancy={vacancy}
                    toggleDeleteModal={setShowDeleteModal}
                    togglePublishModal={setShowPublishModal}
                    onEditClick={onEditClick}
                    showEditButton={true}
                  />
                </DefaultDropdown>
              </div>
            )}
          </div>
          <div className="job__item__body__content">
            <CardElements
              elements={[
                {
                  value: `${vacancy?.applicants_aggregate?.aggregate?.count} ${t(
                    'jobs.candidates'
                  )}`,
                  Icon: <Candidates />,
                },
                {
                  ...(vacancy.type && {
                    value: VacaniesTypesEnums[vacancy.type],
                    Icon: <Bag />,
                  }),
                },
                {
                  ...(vacancy.deadline && {
                    value: `${t('jobs.closingOn')} ${generateFormatedDate(vacancy.deadline)}`,
                    Icon: <Clock />,
                  }),
                },
                {
                  ...((vacancy.salary_from || vacancy.salary_to) && {
                    value: ` ${!!vacancy.salary_from ? vacancy.salary_from + ' - ' : ''}${
                      !!vacancy.salary_to ? vacancy.salary_to : ''
                    }`,
                    Icon: <EuroSign />,
                  }),
                },
                {
                  ...(vacancy.location && {
                    value: vacancy.location,
                    Icon: <Location type={2} />,
                  }),
                },
              ]}
            />
          </div>
          <div className="job__item__body__button">
            {vacancy.status !== VacaniesStatusTypesEnums.DRAFT && (
              <Link to={`/job/${vacancy.id}/all`} className="w-full">
                <Button
                  className="job__item__body__button--view"
                  label={t('job.viewApplicants')}
                  btnType={ButtonTypesEnums.BLUE}
                  btnSize={ButtonSizesEnums.XL}
                />
              </Link>
            )}
          </div>

          {xl && showButtons && (
            <div className="job__item__buttons">
              <Link to={`/job/${vacancy.id}/edit?rel=${prevRoute}`}>
                <Button
                  className="mr-2"
                  label={t('jobs.editJob')}
                  btnSize={ButtonSizesEnums.SM}
                  btnType={ButtonTypesEnums.WHITE_BORDER}
                  before={<Edit />}
                />
              </Link>
              {vacancy.status !== VacaniesStatusTypesEnums.DRAFT && (
                <Link to={`/job/${vacancy.id}/all`}>
                  <Button
                    className="mr-2"
                    label={t('job.viewApplicants')}
                    btnSize={ButtonSizesEnums.SM}
                    btnType={ButtonTypesEnums.BLUE}
                  />
                </Link>
              )}

              <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
                <JobsDropdown
                  vacancy={vacancy}
                  toggleDeleteModal={setShowDeleteModal}
                  togglePublishModal={setShowPublishModal}
                />
              </DefaultDropdown>
            </div>
          )}
        </div>
        {!xl && vacancy.isPromoted && generateDatesDiff(vacancy?.payments[0]?.expiresAt) > 0 && (
          <div className="flex justify-center mt-4 text-sm uppercase text-yellow">
            <Star />
            <span className="ml-1"> Promoted</span>
          </div>
        )}
      </div>
      <CustomModal
        open={showDeleteModal}
        closeModal={closeDeleteModal}
        title="Delete"
        type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
      >
        <DeleteJob closeModal={closeDeleteModal} vacancyId={vacancy.id} />
      </CustomModal>
      <CustomModal
        open={showPublishModal}
        closeModal={closePublishModal}
        title={t('job.confirmPublication')}
        type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
      >
        <PublishJob closeModal={closePublishModal} handleSubmit={onPublishJob} />
      </CustomModal>

      <CustomModal
        open={showPromoteModal}
        closeModal={handleClosePromotedModal}
        title={t('job.confirmPublication')}
        type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
      >
        <PromoteJob closeModal={closePromotedModal} vacancyId={+vacancy?.id} />
      </CustomModal>
    </>
  );
};

export default JobsItem;

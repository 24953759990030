import React, { FC, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';

//components
import { LetterSvg, Clock, AtSign, Phone } from '../../atoms';
import { Title, CardElements } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//utils
import { splitFullName } from '../../../utils/splitFullName';
import { generateFormatedDate } from '../../../utils';

// typings
import { ApplicantFragment } from '../../../gql/gen/graphql';
interface CandidateItemProps {
  candidate: ApplicantFragment;
  displayPhoneNumber?: boolean;
  className?: string;
}

const CandidateItem: FC<CandidateItemProps> = ({
  candidate,
  className = '',
  displayPhoneNumber = true,
}) => {
  const { t } = useL10N();

  const { location } = useHistory();

  const renderUserInitials = useMemo(
    () => (
      <Link to={`/candidate/${candidate.id}/overview`}>
        <LetterSvg
          first={splitFullName(candidate.name)?.firstName || ''}
          second={splitFullName(candidate.name)?.lastName || ''}
        />
      </Link>
    ),
    [candidate.name, candidate.id]
  );

  const renderCardElements = useMemo(
    () => [
      {
        ...(candidate.created_at && {
          value: `${t('candidate.appliedOn')} ${generateFormatedDate(candidate.created_at)}`,
          Icon: <Clock />,
        }),
      },
      {
        ...(candidate.email && {
          value: candidate.email,
          Icon: <AtSign />,
        }),
      },
      {
        ...(candidate.phone &&
          displayPhoneNumber && {
            value: candidate.phone,
            Icon: <Phone />,
          }),
      },
    ],
    [candidate, displayPhoneNumber, t]
  );

  return (
    <div className={clsx('candidate__item', className)}>
      <div className="candidate__item__body">
        <div className="candidate__item__body__avatar">{renderUserInitials}</div>
        <div className="candidate__item__body__header">
          <Link
            to={{
              pathname: `/candidate/${candidate.id}/overview`,
              state: { prevLoc: location },
            }}
          >
            <Title value={candidate.name} />
          </Link>
          {candidate.vacancy?.title && (
            <div className="candidate__item__body__header__tag">
              <p>{candidate.vacancy.title}</p>
            </div>
          )}
        </div>
        <div className="candidate__item__body__content">
          <CardElements elements={renderCardElements} />
        </div>
      </div>
    </div>
  );
};

export default CandidateItem;

export const parseQueryString = (search: any) => {
  const pairs = search.slice(1).split('&');

  const result: any = {};
  pairs.forEach((pair: any) => {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

import React, { useEffect, useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import { ContentLoading } from '../../components/atoms';
import { HeaderLayout } from '../../components/templates';

// gql
import useCompanyUser from '../../gql/hooks/useCompanyUser';
import { useCreateCheckoutSessionMutation } from '../../gql/gen/graphql';

import { API_URI } from '../../config';

//utils
import { parseQueryString } from '../../utils';

const StripeSubscription = () => {
  // hooks
  const {
    location: { search },
  } = useHistory();
  const { company_user } = useCompanyUser();

  // parser
  const { type, vacancy_id, is_vacancy } = parseQueryString(search);

  // state
  const [price, setPrice] = useState<string>('');
  const [key, setKey] = useState<string>('');

  // mutation
  const [createCheckoutSession] = useCreateCheckoutSessionMutation();

  // handlers
  const handleCreateSession = useCallback(() => {
    if (price) {
      createCheckoutSession({
        variables: {
          data: {
            plan: type,
            price_id: price,
            ...(vacancy_id && { vacancy_id: +vacancy_id }),
            ...(!is_vacancy && { company_id: company_user.company_id }),
          },
        },
      }).then(({ data }) => {
        const redirectStripe = async () => {
          const stripe = await loadStripe(key);
          await stripe
            ?.redirectToCheckout({
              sessionId: data?.stripe_create_checkout_session?.sessionId || '',
            })
            .catch((error) => console.log(error));
        };
        if (
          key &&
          !!data?.stripe_create_checkout_session?.sessionId &&
          data?.stripe_create_checkout_session?.statusIsOk
        ) {
          redirectStripe();
        }
      });
    }
  }, [
    price,
    key,
    createCheckoutSession,
    type,
    vacancy_id,
    is_vacancy,
    company_user,
  ]);

  useEffect(() => {
    if (type) {
      fetch(`${API_URI}/stripe/setup/${type}`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          const { publishableKey, basicPrice } = data;
          setKey(publishableKey);
          setPrice(basicPrice);
          if (company_user?.company_id) {
            handleCreateSession();
          }
        });
    }
  }, [company_user, handleCreateSession, type]);

  return (
    <HeaderLayout l10nTitleKey="head.titles.payment">
      <div className="mt-16">
        <ContentLoading />
      </div>
    </HeaderLayout>
  );
};
export default StripeSubscription;

/**
 * @param start - the start value
 * @param end - the end value
 *
 * * This function returns an array with elements from start to end based on params
 */
export const range = (start: number, end: number): number[] => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

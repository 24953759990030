import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

//components
import { LayoutContainer } from '../../../templates';
import { Breadcrumbs, Button, Create } from '../../../atoms';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//gql
import { useMessageByPk } from '../../../../gql/hooks/messages/useMessageByPk';

//types
import { BreadcrumbsPropsItem } from '../../../atoms/ui/navigation/Breadcrumbs.types';

//enums
import { MessagesHeaderTypes } from './MessageHeader.enum';
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../../enums';

const MessagesHeader = () => {
  const { t } = useL10N();
  const { search } = useLocation();

  const { tab } = useParams<{ tab?: string }>();
  const { xl } = useWindowSize();
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbsPropsItem>>([]);
  const [breadcrumbTranslations] = useState({
    title: t('message.title'),
    newMessage: t('message.newMessage'),
  });

  const { result } = useMessageByPk({ messageId: Number(tab) });

  useEffect(() => {
    let _breadcrumbs: BreadcrumbsPropsItem[] = [
      { name: breadcrumbTranslations.title, to: '/messages/inbox' },
    ];
    if (tab !== undefined) {
      if (
        tab === MessagesHeaderTypes.COMPOSE ||
        tab === MessagesHeaderTypes.REPLY ||
        tab === MessagesHeaderTypes.NEW
      ) {
        _breadcrumbs.push({ name: breadcrumbTranslations.newMessage });
      } else {
        const idRegex = new RegExp(MessagesHeaderTypes.ID);
        if (idRegex.test(tab)) {
          _breadcrumbs.push({ name: result?.applicant?.name || '' });
        }
      }
    } else if (xl) {
      _breadcrumbs.push({ name: breadcrumbTranslations.newMessage });
    }
    setBreadcrumbs(_breadcrumbs);
  }, [tab, xl, result, breadcrumbTranslations]);

  return (
    <LayoutContainer>
      <div className="messages__header">
        <Breadcrumbs withArrow={breadcrumbs.length >= 2} list={breadcrumbs} />
        <div className="messages__header__content">
          <h1 className="messages__header__content--title">{t('message.title')}</h1>
          <Link to={`/messages/inbox/new${search}`}>
            <Button
              label={t('button.newMessage')}
              btnSize={ButtonSizesEnums.XS}
              btnType={ButtonTypesEnums.GREEN}
              before={<Create />}
            />
          </Link>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default MessagesHeader;

import React from 'react';
import { Link } from 'react-router-dom';

//components
import { LayoutContainer } from '../../templates';
import { ArrowDown, Logo, Hamburger } from '../../atoms';
import { NavItems, DefaultDropdown, AccountItems } from '../../molecules';

//hooks
import useWindowSize from '../../../hooks/useWindowSize';

//gql
import useAuth from '../../../gql/hooks/auth/useAuth';

const Header = () => {
  const { width } = useWindowSize();
  const auth = useAuth();

  return (
    <LayoutContainer className="sticky top-0 z-50 w-full bg-primary">
      <div className="header">
        <div>
          <Link to="/dashboard" aria-label="Logo">
            <Logo />
          </Link>
        </div>
        {width && width > 900 && <NavItems />}
        <DefaultDropdown
          className="header__dropdown"
          closeIcon={true}
          withOpacity={(width || 0) <= 900}
          triggerProps={{
            className: 'header__dropdown--btn',
            label: width && width > 900 ? auth?.user?.name || '' : '',
            after: width && width > 900 ? <ArrowDown /> : <Hamburger />,
          }}
        >
          <AccountItems />
        </DefaultDropdown>
      </div>
    </LayoutContainer>
  );
};

export default Header;

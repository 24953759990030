import React, { FC, useEffect, useState } from 'react';

import Tooltip from '../../atoms/ui/tooltip/Tooltip';
import { CustomTooltipProps } from './CustomTooltip.types';

const CustomTooltip: FC<CustomTooltipProps> = ({ id, label, label_after, children }) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(label);
  }, [label]);

  const onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setValue(label_after);
  };

  const onMouseLeave = () => value === label_after && setValue(label);

  return (
    <Tooltip label={value} id={id}>
      <div onMouseLeave={onMouseLeave} onClick={onClick}>
        {children}
      </div>
    </Tooltip>
  );
};

export default CustomTooltip;

import React from 'react';

import { Svg } from '../../../../atoms';

const MyAccount = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.6}
      d="M3.694 15.32A12.775 12.775 0 0110 13.667c2.292 0 4.443.6 6.306 1.653M12.75 8.167a2.75 2.75 0 11-5.5 0 2.75 2.75 0 015.5 0zM18.25 10a8.25 8.25 0 11-16.5 0 8.25 8.25 0 0116.5 0z"
      stroke="#778CA3"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default MyAccount;

import React from 'react';

import { Svg } from '../../../atoms';

const CreateEdit = () => (
  <Svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 22H22H10ZM16 16V28V16ZM26 38H6C4.93913 38 3.92172 37.5786 3.17157 36.8284C2.42143 36.0783 2 35.0609 2 34V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H17.172C17.7024 2.00011 18.211 2.2109 18.586 2.586L29.414 13.414C29.7891 13.789 29.9999 14.2976 30 14.828V34C30 35.0609 29.5786 36.0783 28.8284 36.8284C28.0783 37.5786 27.0609 38 26 38Z"
      stroke="#08D4A5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CreateEdit;

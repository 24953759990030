import React from 'react';

import { Svg } from '../../../atoms';

const Settings = () => (
  <Svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.65 4.634c.852-3.512 5.848-3.512 6.7 0a3.447 3.447 0 005.146 2.132c3.086-1.88 6.62 1.652 4.74 4.74a3.449 3.449 0 002.13 5.144c3.512.852 3.512 5.848 0 6.7a3.447 3.447 0 00-2.132 5.146c1.88 3.086-1.652 6.62-4.74 4.74a3.449 3.449 0 00-5.144 2.13c-.852 3.512-5.848 3.512-6.7 0a3.447 3.447 0 00-5.146-2.132c-3.086 1.88-6.62-1.652-4.74-4.74a3.448 3.448 0 00-2.13-5.144c-3.512-.852-3.512-5.848 0-6.7a3.447 3.447 0 002.132-5.146c-1.88-3.086 1.652-6.62 4.74-4.74 1.992 1.216 4.592.14 5.144-2.13z"
      stroke="#08D4A5"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 20a6 6 0 11-12 0 6 6 0 0112 0v0z"
      stroke="#08D4A5"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Settings;

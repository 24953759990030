import React from 'react';
import { Link } from 'react-router-dom';

//components
import { LayoutContainer } from '../../templates';
import { Button, Create } from '../../atoms';
import { Filter } from '../../molecules';

//hooks
import useWindowSize from '../../../hooks/useWindowSize';
import { useL10N } from '../../../hooks/useL10N';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const JobsHeader = () => {
  const { xl } = useWindowSize();
  const { t } = useL10N();

  return (
    <LayoutContainer>
      <div className="jobs__header">
        <div className="jobs__header__content">
          <h1 className="jobs__header__content--title">{t('jobs.title')}</h1>
          <Link to="/job/create">
            <Button
              className="jobs__header__content--btn"
              label={t('jobs.createNewJob')}
              btnSize={!xl ? ButtonSizesEnums.XS : ButtonSizesEnums.MD}
              btnType={ButtonTypesEnums.GREEN}
              before={<Create />}
            />
          </Link>
        </div>
        <div className="jobs__header__filter">
          <Filter
            placeholder={t('jobs.searchPlaceholder')}
            showFilterDropdown
          />
        </div>
      </div>
    </LayoutContainer>
  );
};

export default JobsHeader;

import React, { FC } from 'react';

//components
import { CardElement } from '..';

//types
import { CardElementsProps } from './CardElements.types';

const CardElements: FC<CardElementsProps> = ({ elements }) => (
  <div className="elements">
    {elements.map((el, key) => (
      <CardElement
        key={key}
        className="elements__item"
        Icon={el.Icon}
        value={el.value}
      />
    ))}
  </div>
);
export default CardElements;

import React from 'react';

import { Svg } from '../../../atoms';

const PaymentConfirmed = () => (
  <Svg
    width={52}
    height={52}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.787 26.762L19.573 2s5.592-.145 5.592 6.39v11.982h19.17a4.487 4.487 0 014.575 4.285 4.313 4.313 0 01-1.048 2.804 4.329 4.329 0 01-.788 7.828 4.338 4.338 0 01-2.444 6.618 3.262 3.262 0 01-2.256 5.623h-17.21a31.593 31.593 0 01-14.377-3.195"
      stroke="#08D4A5"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
    <path
      d="M8.39 22.768H4.396A2.396 2.396 0 002 25.164v19.17a2.396 2.396 0 002.396 2.397H8.39a2.396 2.396 0 002.396-2.397v-19.17a2.396 2.396 0 00-2.396-2.396z"
      stroke="#08D4A5"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);

export default PaymentConfirmed;

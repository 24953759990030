// react
import React, { ReactElement, useEffect } from 'react';

// router
import { useHistory } from 'react-router-dom';

// components
import { Button, Dropdown } from '../../atoms';

// hooks
import { useL10N } from '../../../hooks/useL10N';
import useDropdown from '../../../hooks/useDropdown';

//utils
import { parseQueryString, serialize, toCamelCase } from '../../../utils';

//types
import { PreviousDaysEnum } from '../../../enums';

const DashboardSortDropdown = (): ReactElement => {
  // hooks
  const { t } = useL10N();
  const { push, location } = useHistory();
  const { open, onMenuClick, closeMenu } = useDropdown();
  const { sortJobs, ...params } = parseQueryString(location.search);

  // handler
  const handlePushPreviousDays = (sortJobs: string) => () => {
    push(serialize({ ...params, sortJobs }));
    closeMenu();
  };

  const handleGetTranslationSort = (text: string) =>
    t(`dashboard.sortDropdown.${toCamelCase({ text, splitChar: '_' })}`);

  // effect
  useEffect(() => {
    if (!sortJobs) {
      push(serialize({ ...params, sortJobs: PreviousDaysEnum.LAST_7_DAYS }));
    }
  }, [params, push, sortJobs]);

  return (
    <Dropdown
      open={open}
      closeMenu={closeMenu}
      className="dashboard__sortDropdown"
      trigger={
        <Button
          label={
            sortJobs
              ? handleGetTranslationSort(sortJobs)
              : t('dashboard.lastDays')
          }
          className="dashboard__sortDropdown_btn"
          onClick={onMenuClick}
        />
      }
    >
      <div className="dashboard__sortDropdown_btns">
        {Object.values(PreviousDaysEnum).map((sortItem, index) => (
          <div key={sortItem} className="w-full">
            <Button
              label={handleGetTranslationSort(sortItem)}
              onClick={handlePushPreviousDays(sortItem)}
            />
            {Object.values(PreviousDaysEnum).length !== index + 1 && (
              <hr className="w-full pt-3 mt-2 border-gray-100" />
            )}
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default DashboardSortDropdown;

import React from 'react';

//components
import { ContentLoading } from '../../../../atoms';
import MyAccountForm from './MyAccountForm';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import useUserProfile from '../../../../../gql/hooks/useUserProfile';

//types
import { MyAccountDefaultData } from './MyAccount.types';

//enums
import { ContentLoadingEnums } from '../../../../../enums';
import { industries } from '../../../../../static';

const MyAccount = () => {
  const { t } = useL10N();

  const { user, company, loading, company_id } = useUserProfile();
  let defaultValues: MyAccountDefaultData = {};
  const selectedValueIndex = industries.findIndex((item) => item.label === company?.industry);

  if (user && company) {
    defaultValues = {
      ...defaultValues,
      name: user.name,
      email: user.email,
      master_email: user.email,
      user_path: user?.file_store?.file_path,
      user_file: null,
      company_name: company.name,
      company_file: null,
      company_path: company?.file_store?.file_path,
      industry: {
        label: company?.industry,
        value: selectedValueIndex,
      },
    };
  }

  return (
    <section className="myAccount">
      <div className="content__header">
        <span className="content__header--title">{t('settings.myAccount')}</span>
      </div>
      {!loading && Object.keys(defaultValues).length ? (
        <MyAccountForm defaultData={defaultValues} user={user} company_id={company_id} />
      ) : (
        <ContentLoading size={ContentLoadingEnums.LG} className="my-12" />
      )}
    </section>
  );
};

export default MyAccount;

import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentLoading } from '../../components/atoms';

//components
import { SubscriberItem, SubscribersEmptyView, ReactPagination } from '../../components/molecules';
import { SubscribersHeader, SubscribersActions } from '../../components/organisms';
import { LayoutContainer, PagesContainer, HeaderLayout } from '../../components/templates';

//gql
import useSubscribers from '../../gql/hooks/subscribers/useSubscribers';
import useCompanyUser from '../../gql/hooks/useCompanyUser';

// enums
import { PaginationTypeEnums } from '../../enums/Pagination.enum';

//utils
import { parseQueryString } from '../../utils';

export type SelectTypeId = string | number;

export interface MatchParams {
  id: string;
  tab: string;
}

const Subscribers = () => {
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [selected, setSelected] = useState<Array<SelectTypeId>>([]);

  const {
    location: { pathname, search },
  } = useHistory();
  const { page } = parseQueryString(search);

  const { company_user } = useCompanyUser();

  const { result, loading } = useSubscribers({
    company_id: company_user?.company_id,
  });

  useEffect(() => {
    if (pathname && search) {
      setSelectAll(false);
      setSelected([]);
    }
  }, [pathname, search]);

  useEffect(() => {
    if (result.data && result.data.length) {
      setSelectAll(result.data.every((j) => selected.includes(j.id.toString())));
    } else setSelectAll(false);
  }, [result, selected]);

  const findId = (id: string) => (selected.indexOf(id) >= 0 ? true : false);

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && result.data)
      setSelected(result.data.map(({ id }) => id.toString()));
    else setSelected([]);
    setSelectAll(!selectAll);
  };

  const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const temp = findId(event.target.id);
    if (temp) setSelected(selected.filter((id) => id !== event.target.id));
    else setSelected([...selected, event.target.id]);
  };

  const renderContent = () => {
    if (!result.data?.length && result?.meta?.all < 10) return <SubscribersEmptyView />;
    return (
      <div className="subscribers__content">
        {result?.data &&
          result?.data.map((subscriberItem, index) => (
            <SubscriberItem
              key={subscriberItem.id}
              orderNumber={
                page
                  ? page * PaginationTypeEnums.LIMIT - (PaginationTypeEnums.LIMIT - 1) + index
                  : index + 1
              }
              subscriber={subscriberItem}
              onSelect={onSelect}
              select={findId(subscriberItem.id.toString())}
            />
          ))}
      </div>
    );
  };

  return (
    <HeaderLayout l10nTitleKey="head.titles.subscribers">
      <PagesContainer>
        <div className="subscribers">
          <SubscribersHeader />
          <LayoutContainer className="">
            <div className="subscribers__inner">
              <div className="subscribers__actions">
                {result.data && result.data.length > 0 ? (
                  <SubscribersActions
                    all={selectAll}
                    onSelect={onSelectAll}
                    selected={result.data
                      .filter((j) => selected.includes(j.id.toString()))
                      .map((job) => job.id)}
                  />
                ) : (
                  ''
                )}

                {!loading ? renderContent() : <ContentLoading />}
              </div>
            </div>

            {!!result.meta && !loading && (
              <ReactPagination
                className="subscribers__pagination"
                loading={loading}
                pagination={{ numberOfItems: result.meta?.all }}
              />
            )}
          </LayoutContainer>
        </div>
      </PagesContainer>
    </HeaderLayout>
  );
};

export default Subscribers;

import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

//components
import { Button } from '../../../atoms';
import { FieldInput, CustomSelect } from '../../index';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import {
  useTeamMemberInsertMutation,
  useTeamMemberUpdateMutation,
} from '../../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import {
  TeamMemberFormProps,
  TeamMemberFormTypes,
} from './TeamMemberForm.types';
import { TeamMembersSchema } from '../../../../validations';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ToastrTypesEnums,
} from '../../../../enums';
const TeamMemberForm: FC<TeamMemberFormProps> = ({ closeModal, member }) => {
  const toast = useContext(toastrContext);
  const { company_user } = useCompanyUser();
  const { t } = useL10N();

  const { register, handleSubmit, errors } = useForm<TeamMemberFormTypes>({
    defaultValues: {
      name: member?.user?.name || '',
      role: member?.role || 0,
      email: member?.user?.email || '',
    },
    ...(!member && {
      resolver: yupResolver(TeamMembersSchema({ t })),
    }),
  });

  const [addTeamMember, { loading }] = useTeamMemberInsertMutation({
    update: () => {},
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('teamMemeber.createdError'));
    },
    onCompleted: ({ invite_teammate }) => {
      toast?.add(
        invite_teammate?.statusIsOk
          ? ToastrTypesEnums.SUCCESS
          : ToastrTypesEnums.DANGER,
        invite_teammate?.statusIsOk
          ? t('teamMemeber.createdSuccess')
          : invite_teammate?.statusMessage || t('teamMemeber.createdError')
      );
    },
  });

  const [
    updateTeamMember,
    { loading: updateLoading },
  ] = useTeamMemberUpdateMutation({
    update: () => {},
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('teamMemeber.editedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('teamMemeber.editedSuccessfully'));
    },
  });

  const onSubmit = (data: TeamMemberFormTypes) => {
    if (member) {
      updateTeamMember({
        variables: {
          companyId: +company_user?.company_id,
          role: +data?.role,
          id: +member?.user_id,
        },
      }).then(() => {
        closeModal && closeModal();
      });
    } else {
      addTeamMember({
        variables: {
          companyId: company_user?.company_id,
          email: data?.email || '',
          role: +data?.role,
          name: data?.name,
        },
      }).then(({ data }) => {
        data?.invite_teammate?.statusIsOk && closeModal && closeModal();
      });
    }
  };

  return (
    <form className="teamMember__form" onSubmit={handleSubmit(onSubmit)}>
      {!member && (
        <>
          <div className="teamMember__form--field">
            <FieldInput
              labelProps={{ children: 'Full name' }}
              inputProps={{
                name: 'name',
                inputRef: register,
              }}
              error={errors.name?.message}
            />
          </div>
          <div className="teamMember__form--field">
            <FieldInput
              labelProps={{ children: 'Email' }}
              inputProps={{
                name: 'email',
                inputRef: register,
              }}
              error={errors.email?.message}
            />
          </div>
        </>
      )}
      <div className="teamMember__form--field">
        <CustomSelect
          labelProps={{ children: 'Role' }}
          selectProps={{
            name: 'role',
            selectRef: register,
            data: [
              { value: 10, label: 'Admin' },
              { value: 40, label: 'Editor' },
            ],
          }}
          error={errors?.role?.message}
        />
        <ErrorMessage
          errors={errors}
          name="role"
          render={({ message }) => {
            return <p className={'error--input mt-3'}>{message}</p>;
          }}
        />
      </div>
      <div className="teamMember__form__buttons">
        <Button
          label="Cancel"
          btnType={ButtonTypesEnums.WHITE_BORDER}
          btnSize={ButtonSizesEnums.XM}
          onClick={closeModal}
        />
        <Button
          className="teamMember__form__buttons--main"
          label={member ? 'Save' : 'Add'}
          type="submit"
          loading={member ? updateLoading : loading}
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.LG}
        />
      </div>
    </form>
  );
};

export default TeamMemberForm;

import React, { FC } from 'react';

// atoms
import { Svg } from '../../../../atoms';

// types
import { IconProps } from '../Icon.types';

const Reorder: FC<IconProps> = ({ className = '' }) => (
  <Svg
    className={className}
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66699 8.66634H16.3337M1.66699 1.33301H16.3337H1.66699Z"
      stroke="#CBD5E0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Reorder;

import React, { FC, MouseEvent } from 'react';

//components
import { Button, ExportSubscribers, Delete } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { SubscribersActionsDropdownProps } from '../../../interfaces';

const SubscribersActionsDropdown: FC<SubscribersActionsDropdownProps> = ({
  closeMenu,
  onExportSubscribers,
  onDeleteSubscriber,
  subscribers_ids,
}) => {
  const { t } = useL10N();

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (event.currentTarget.id === 'delete_subscribers') {
      onDeleteSubscriber();
    }
    if (event.currentTarget.id === 'export_subscribers') {
      onExportSubscribers();
    }
    closeMenu && closeMenu();
  };

  return (
    <div className="default__dropdown__subscriber__actions">
      <Button
        id="export_subscribers"
        label={t('subscribers.export')}
        before={<ExportSubscribers />}
        onClick={handleOnClick}
        className="default__dropdown__subscriber__actions--item"
        disabled={!subscribers_ids?.length}
      />
      <Button
        id="delete_subscribers"
        label={t('subscribers.delete')}
        before={<Delete />}
        onClick={handleOnClick}
        className="default__dropdown__subscriber__actions--item"
        disabled={!subscribers_ids?.length}
      />
    </div>
  );
};

export default SubscribersActionsDropdown;

// react
import React, { FC, memo } from 'react';

// atoms
import { Activity as ActivityIcon } from '../../../atoms';

// utils
import { getActivityIcon, generateFormatedDate, findEventDescription } from '../../../../utils';

// types
import { ActivityCardProps } from './ActivityCard.types';
import classNames from 'classnames';

const ActivityCard: FC<ActivityCardProps> = ({
  activity: { data, event_id, created_at, activity_log_event },
  className,
}) => (
  <div className="activity__content__item">
    <div className="activity__content__item--icon">
      <ActivityIcon type={getActivityIcon(event_id)} />
    </div>
    <div className={classNames('activity__content__item__content', className)}>
      <h1 className="activity__content__item__content--title">
        {findEventDescription(data, activity_log_event.description)}
      </h1>
      <span className="activity__content__item__content--date">
        {generateFormatedDate(created_at)}
      </span>
    </div>
  </div>
);

export default memo(ActivityCard);

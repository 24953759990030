import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const InfoIcon: FC<IconProps> = ({ className = '' }) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2 9A7.2 7.2 0 111.8 9a7.2 7.2 0 0114.4 0zm-6.3 3.6a.9.9 0 11-1.8 0 .9.9 0 011.8 0zM9 4.5a.9.9 0 00-.9.9V9a.9.9 0 101.8 0V5.4a.9.9 0 00-.9-.9z"
      fill="#504116"
      opacity={0.5}
    />
  </Svg>
);

export default InfoIcon;

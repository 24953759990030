import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { LogoProps } from './Logo.types';

const Logo: FC<LogoProps> = ({ withName = true, opacity = false }) => (
  <Svg
    width={withName ? '115' : '28'}
    height="42"
    viewBox={`0 0 ${withName ? 115 : 28} 42`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {opacity ? (
      <>
        <g opacity="0.8">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.96418 14.7122V19.6653H0V22.7672H4.96418V27.7203H8.07377V22.7672H13.0379V19.6653H8.07377V14.7122H4.96418Z"
            fill="#778CA3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.6322 21C27.6322 9.90812 18.9917 0.797593 8.07434 0V3.11033C17.276 3.89997 24.5231 11.6189 24.5231 21C24.5231 30.3806 17.276 38.1 8.07434 38.8901V42C18.9917 41.2024 27.6322 32.0914 27.6322 21Z"
            fill="#778CA3"
          />
        </g>
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.96842 14.7123V19.6654H0V22.7673H4.96842V27.7204H8.08066V22.7673H13.0491V19.6654H8.08066V14.7123H4.96842Z"
          fill="white"
        />
        {withName && (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.5272 25.2862H40.621C41.1943 25.2862 41.7244 25.1776 42.2104 24.96C42.6964 24.7428 43.1106 24.4414 43.4533 24.0561C43.7961 23.6709 44.0642 23.208 44.2577 22.6674C44.4507 22.1268 44.5474 21.5276 44.5474 20.8691C44.5474 20.2231 44.4507 19.6268 44.2577 19.0801C44.0642 18.5334 43.7961 18.0672 43.4533 17.682C43.1106 17.2972 42.6964 16.9962 42.2104 16.7781C41.7244 16.5609 41.1943 16.4519 40.621 16.4519H38.5272V25.2862ZM36.0967 14.4579H40.752C41.6742 14.4579 42.5217 14.6166 43.2946 14.9334C44.0675 15.2503 44.731 15.6908 45.286 16.2562C45.8401 16.8221 46.2735 17.4989 46.5853 18.2876C46.8966 19.0768 47.0525 19.9371 47.0525 20.8691C47.0525 21.801 46.8966 22.6613 46.5853 23.4505C46.2735 24.2396 45.8401 24.9165 45.286 25.4819C44.731 26.0469 44.0675 26.4882 43.2946 26.8047C42.5217 27.122 41.6742 27.2802 40.752 27.2802H36.0967V14.4579Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.7444 27.2802H52.1749V14.4579H49.7444V27.2802Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M55.204 24.3913C56.4131 25.1617 57.6095 25.5469 58.7938 25.5469C59.5042 25.5469 60.0804 25.3789 60.5236 25.0442C60.9655 24.7086 61.1871 24.2672 61.1871 23.7201C61.1871 23.2857 61.0345 22.9407 60.7288 22.6856C60.4236 22.4315 60.0367 22.2147 59.57 22.0341C59.1023 21.8539 58.6003 21.6802 58.0645 21.5122C57.5288 21.3441 57.0273 21.1176 56.5596 20.8316C56.0924 20.5461 55.7055 20.1796 55.4003 19.7321C55.0951 19.2851 54.9424 18.6883 54.9424 17.9431C54.9424 17.3964 55.0547 16.8965 55.2791 16.443C55.5031 15.9894 55.8177 15.5981 56.2229 15.2686C56.6282 14.94 57.1142 14.6849 57.6814 14.5047C58.2481 14.3245 58.8684 14.2341 59.5418 14.2341C60.6885 14.2341 61.8412 14.471 63.0006 14.9423V17.1605C62.4517 16.8371 61.8999 16.5951 61.3463 16.4336C60.7913 16.2721 60.2457 16.1911 59.7099 16.1911C59.0243 16.1911 58.4665 16.3465 58.0368 16.6573C57.6067 16.9677 57.3917 17.3838 57.3917 17.9061C57.3917 18.3279 57.5443 18.6607 57.8495 18.9027C58.1547 19.1451 58.5416 19.3567 59.0088 19.5364C59.476 19.7171 59.978 19.8908 60.5138 20.0583C61.05 20.2259 61.5515 20.4562 62.0187 20.7478C62.4859 21.0399 62.8728 21.4162 63.1781 21.8759C63.4833 22.335 63.6364 22.9379 63.6364 23.6831C63.6364 24.2424 63.5204 24.7582 63.2898 25.2301C63.0593 25.7023 62.7386 26.1063 62.3272 26.4414C61.9159 26.777 61.4238 27.0377 60.8504 27.2245C60.2771 27.4103 59.647 27.5039 58.9619 27.5039C58.3388 27.5039 57.7124 27.4323 57.0832 27.2896C56.4535 27.1468 55.8271 26.9329 55.204 26.6464V24.3913Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.4664 20.8692C65.4664 19.8998 65.6219 19.0086 65.9332 18.1946C66.2445 17.3811 66.6817 16.6823 67.2423 16.0981C67.8034 15.5144 68.4697 15.0576 69.2426 14.7281C70.0155 14.399 70.8631 14.2343 71.7858 14.2343C73.0447 14.2343 74.2787 14.5451 75.4873 15.1662V17.3656C74.9262 17.0179 74.3688 16.7506 73.8143 16.5638C73.2597 16.3775 72.7202 16.2844 72.1971 16.2844C71.5735 16.2844 71.003 16.3963 70.486 16.62C69.969 16.8433 69.5262 17.1574 69.1581 17.5613C68.7909 17.9652 68.5045 18.4497 68.2988 19.0147C68.0931 19.5806 67.9898 20.1984 67.9898 20.8692C67.9898 21.5399 68.0931 22.1554 68.2988 22.7143C68.5045 23.2732 68.7909 23.7576 69.1581 24.1677C69.5262 24.5777 69.969 24.895 70.486 25.1183C71.003 25.3416 71.5735 25.4539 72.1971 25.4539C72.7202 25.4539 73.2597 25.3603 73.8143 25.1745C74.3688 24.9877 74.9262 24.7209 75.4873 24.3727V26.5717C74.2787 27.1937 73.0447 27.5041 71.7858 27.5041C70.8631 27.5041 70.0155 27.3393 69.2426 27.0102C68.4697 26.6812 67.8034 26.2244 67.2423 25.6402C66.6817 25.056 66.2445 24.3577 65.9332 23.5432C65.6219 22.7302 65.4664 21.8385 65.4664 20.8692Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M79.7333 20.8692C79.7333 21.5399 79.823 22.1554 80.0038 22.7143C80.1845 23.2732 80.44 23.7576 80.7705 24.1677C81.1006 24.5777 81.4965 24.895 81.9581 25.1183C82.4192 25.3416 82.9305 25.4539 83.4912 25.4539C84.0514 25.4539 84.5627 25.3416 85.0238 25.1183C85.4849 24.895 85.8812 24.5777 86.2113 24.1677C86.5414 23.7576 86.7969 23.2732 86.9781 22.7143C87.1584 22.1554 87.2491 21.5399 87.2491 20.8692C87.2491 20.1984 87.1584 19.5829 86.9781 19.024C86.7969 18.4651 86.5414 17.9802 86.2113 17.5702C85.8812 17.1606 85.4849 16.8433 85.0238 16.62C84.5627 16.3963 84.0514 16.2844 83.4912 16.2844C82.9305 16.2844 82.4192 16.3963 81.9581 16.62C81.4965 16.8433 81.1006 17.1606 80.7705 17.5702C80.44 17.9802 80.1845 18.4651 80.0038 19.024C79.823 19.5829 79.7333 20.1984 79.7333 20.8692ZM77.2089 20.8692C77.2089 19.8998 77.3643 19.0086 77.6766 18.1946C77.9879 17.3811 78.4209 16.6823 78.9759 16.0981C79.53 15.5144 80.1911 15.0576 80.957 14.7281C81.7242 14.399 82.5685 14.2343 83.4912 14.2343C84.4134 14.2343 85.2577 14.399 86.0245 14.7281C86.7908 15.0576 87.4514 15.5144 88.006 16.0981C88.5605 16.6823 88.994 17.3811 89.3053 18.1946C89.6166 19.0086 89.773 19.8998 89.773 20.8692C89.773 21.8385 89.6166 22.7302 89.3053 23.5432C88.994 24.3577 88.5605 25.056 88.006 25.6402C87.4514 26.2244 86.7908 26.6812 86.0245 27.0102C85.2577 27.3393 84.4134 27.5041 83.4912 27.5041C82.5685 27.5041 81.7242 27.3393 80.957 27.0102C80.1911 26.6812 79.53 26.2244 78.9759 25.6402C78.4209 25.056 77.9879 24.3577 77.6766 23.5432C77.3643 22.7302 77.2089 21.8385 77.2089 20.8692Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M97.6998 27.2801H95.606L90.539 14.4578H93.0446L96.6531 23.9254L100.243 14.4578H102.767L97.6998 27.2801Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M107.104 20.5896H108.843C109.516 20.5896 110.064 20.394 110.488 20.0027C110.911 19.6113 111.124 19.1114 111.124 18.502C111.124 17.8814 110.911 17.3744 110.488 16.9831C110.064 16.5918 109.516 16.3966 108.843 16.3966H107.104V20.5896ZM104.711 14.4579H109.179C109.827 14.4579 110.423 14.5576 110.965 14.7565C111.507 14.955 111.974 15.2316 112.367 15.5855C112.76 15.9398 113.065 16.3657 113.283 16.8619C113.501 17.3595 113.61 17.9062 113.61 18.502C113.61 19.3722 113.382 20.13 112.927 20.7759C112.473 21.4223 111.834 21.8941 111.011 22.1923L114.059 27.2802H111.404L108.637 22.5279H107.104V27.2802H104.711V14.4579Z"
              fill="white"
            />
          </>
        )}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6551 21C27.6551 9.90812 19.0072 0.797593 8.08057 0V3.11033C17.2901 3.89997 24.5433 11.6189 24.5433 21C24.5433 30.3806 17.2901 38.1 8.08057 38.8901V42C19.0072 41.2024 27.6551 32.0914 27.6551 21Z"
          fill="#08D4A5"
        />
      </>
    )}
  </Svg>
);

export default Logo;

import React from 'react';

import { Svg } from '../../../atoms';

export const Hamburger = () => (
  <Svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15H23M1 1H23H1ZM1 8H23H1Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Hamburger;

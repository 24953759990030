import React from 'react';

// router
import { Link } from 'react-router-dom';

// classNames
import cx from 'classnames';

// hooks
import { useL10N } from '../../../../hooks/useL10N';

// components
import { ContentLoading, UserIcon } from '../../../atoms';
import { StatisticsHolder } from '../../../molecules';

// gql
import useDashboardStatistics from '../../../../gql/hooks/dashboard/useDashboardStatistics';
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';

const DashboardStatistics = () => {
  // hook
  const { t } = useL10N();

  // graphql hooks
  const { companyId } = useCompanyUser();
  const { result, loading } = useDashboardStatistics({
    id: companyId,
  });

  const renderStagesResults = () => {
    if (!result?.stages?.length)
      return (
        <Link to="/settings/stages" className="dashboard__statistics--noStages">
          {t('button.addStage')}
        </Link>
      );
    return (
      result?.stages &&
      result.stages.map((stage) => (
        <StatisticsHolder
          key={stage.id}
          title={stage.name || ''}
          number={stage.applicants_aggregate.aggregate?.count || 0}
        />
      ))
    );
  };

  return (
    <div
      className={cx('dashboard__statistics', result?.stages?.length && 'mb-5', {
        [`h-56 flex justify-center items-center`]: loading,
      })}
    >
      {loading ? (
        <ContentLoading />
      ) : result?.stages?.length ? (
        <>
          <StatisticsHolder
            title={t('dashboard.totalApplicants')}
            number={result.total_applicants}
            icon={<UserIcon />}
          />
          {renderStagesResults()}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default DashboardStatistics;

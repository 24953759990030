import { useContext } from 'react';
import { gqlVar } from '../../utils';
import { authContext } from "../../contexts/AuthContext";

import { useAccountSettingQuery } from '../gen/graphql'

const useUserProfile = () => {
    const auth = useContext(authContext);

    const query = useAccountSettingQuery(gqlVar({ id: auth?.user?.id || 0 }));
    const { data: { users = [] } = {}, loading, error } = query;
    
    return {
        user: users[0],
        company_id:users[0]?.company_users[0].company_id,
        company: users[0]?.company_users[0].company,
        loading, error
    };
};

export default useUserProfile;
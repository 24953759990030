import React from 'react';

import { Svg } from '../../../../atoms';

const ManageStages = () => (
  <Svg
    width={16}
    height={14}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12.454h14M1 1h14M1 6.728h14"
      stroke="#778CA3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ManageStages;

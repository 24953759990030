import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import { ContentLoading, Button } from '../../../../atoms';
import { PaymentPlan } from '../../../../molecules';
import PaymentHistory from './PaymentHistory';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import {
  useCancleCheckoutSubscriptionMutation,
  usePaymentsSubscription,
} from '../../../../../gql/gen/graphql';
import useCompanyUser from '../../../../../gql/hooks/useCompanyUser';
import useInvoices from '../../../../../gql/hooks/settings/useInvoices';

//enums
import {
  PromoteJobTypesEnums,
  PaymentPlanTypeEnums,
  ButtonSizesEnums,
  ButtonTypesEnums,
} from '../../../../../enums';

const Payments = () => {
  const { t } = useL10N();
  const { push } = useHistory();
  const { company_user } = useCompanyUser();

  const { data: { payments = [] } = {}, loading } = usePaymentsSubscription({
    variables: { company_id: company_user?.company_id },
  });

  const { result, onLoadMore } = useInvoices({
    companyId: company_user?.company_id,
  });

  const [cancleCheckoutSubscription] = useCancleCheckoutSubscriptionMutation();

  const handleSubscriptionClicked = () => {
    push(`/stripe/subscription?type=${PromoteJobTypesEnums.STANDARD}`);
  };

  const handleSubscriptionCancle = () => {
    cancleCheckoutSubscription({
      variables: {
        company_id: company_user.company_id,
      },
    });
  };

  return (
    <section className="payments">
      <div className="content__header">
        <span className="content__header--title">{t('settings.payments')}</span>
      </div>
      {!loading ? (
        <div className="payments__body">
          <p className="text-sm text-primary mb-4">
            {t('settings.paymentsPlan')}
          </p>
          {payments.length > 0 &&
          payments[0].status === PaymentPlanTypeEnums.PAID ? (
            <PaymentPlan
              paidAt={payments[0].expiredAt}
              cancleSubscription={handleSubscriptionCancle}
            />
          ) : (
            <PaymentPlan subscriptionClicked={handleSubscriptionClicked} />
          )}
          {result?.data && result.data?.length > 0 ? (
            <PaymentHistory payments={result?.data || null} />
          ) : (
            ''
          )}
          {result?.invoicesAggregate &&
          result?.data &&
          result.invoicesAggregate?.aggregate?.count &&
          result.data.length < result.invoicesAggregate.aggregate.count ? (
            <div>
              <Button
                label={t('invoices.showAll')}
                btnSize={ButtonSizesEnums.SM}
                btnType={ButtonTypesEnums.GREEN}
                onClick={onLoadMore}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className="payments__body py-12">
          <ContentLoading />
        </div>
      )}
    </section>
  );
};

export default Payments;

import React, { FC, useState, useContext } from 'react';

//components
import { Checkbox, Status } from '../';
import { Delete, Email, Clock, Candidates } from '../../atoms';
import { CustomModal, DeleteSubscriber } from '../../molecules';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import { useDeleteSubscribersMutation } from '../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../contexts/ToastrContexts';

//types
import { SubscriberItemsProps } from './SubscriberItem.types';

//enums
import { ModalTypesEnums, ToastrTypesEnums } from '../../../enums';
import { generateFormatedDateTime } from '../../../utils';
import useWindowSize from '../../../hooks/useWindowSize';

const SubscriberItem: FC<SubscriberItemsProps> = ({
  orderNumber,
  select,
  onSelect,
  subscriber: { id, name, email, created_at, unsubscribed_at },
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const toast = useContext(toastrContext);
  const { t } = useL10N();
  const { width } = useWindowSize();

  const [deleteMut, { loading }] = useDeleteSubscribersMutation({
    variables: { ids: [id] },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('subscriber.deletedSuccess'));
      setShowDeleteModal(false);
    },
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('subscriber.deletedError'));
    },
  });

  const closeDeleteModal = () => setShowDeleteModal(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const handleDeleteSubscriber = () => deleteMut();

  return (
    <>
      {showDeleteModal && (
        <CustomModal
          open={showDeleteModal}
          closeModal={closeDeleteModal}
          title="Delete"
          type={width && width > 768 ? ModalTypesEnums.DEFAULT : ModalTypesEnums.SM}
        >
          <DeleteSubscriber
            closeModal={closeDeleteModal}
            onDeleteSubscriber={handleDeleteSubscriber}
            loading={loading}
          />
        </CustomModal>
      )}
      <div className="subscriber__item">
        <Checkbox
          inputProps={{
            id: id.toString(),
            name: `name-${id}`,
            checked: select,
            onChange: onSelect,
          }}
        />
        <div className="subscriber__item--content">{orderNumber}</div>
        <div className="subscriber__item--content">
          <Candidates />
          {name}
        </div>
        <div className="subscriber__item--content subscriber__item--email">
          <Email />
          {email}
        </div>
        <div className="subscriber__item--content">
          <Clock />
          <p> {generateFormatedDateTime(created_at)}</p>
        </div>
        <div className="subscriber__item--content">
          {!!unsubscribed_at && (
            <Status
              className="font-medium bg-opacity-25 text-yellow"
              value={t('subscriber.unsubscribed.label')}
            />
          )}
        </div>
        <div className="subscriber__item--delete">
          <span className="cursor-pointer" onClick={openDeleteModal}>
            <Delete />
          </span>
        </div>
      </div>
    </>
  );
};
export default SubscriberItem;

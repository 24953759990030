import React, { FC } from 'react';
import cx from 'classnames';

//types
import { TitleProps } from './Title.types';

const Title: FC<TitleProps> = ({ value, className = '' }) => (
  <p className={cx('title', { [className]: className })}>{value}</p>
);

export default Title;

import React from 'react';
import { useLocation } from 'react-router-dom';

//components
import { ContentLoading } from '../../../atoms';
import { MailFormHeader } from '../../../molecules';
import { MailForm } from '..';

// graphql
import { useMessageByPk } from '../../../../gql/hooks/messages/useMessageByPk';

//utils
import { parseQueryString } from '../../../../utils';

//enums
import { CreateMessageTypes } from '../../../../enums';

const Reply = () => {
  const { search } = useLocation();
  const queryParams = parseQueryString(search);

  const { result, loading } = useMessageByPk({
    messageId: queryParams.message_id,
  });

  if (loading) return <ContentLoading />;

  return (
    <>
      {result?.subject && (
        <>
          <MailFormHeader title={`Re: ${result.subject}`} />
          <MailForm
            type={CreateMessageTypes.REPLY}
            message={{
              message_id: result.id,
              subject: result.subject,
              message: '',
              to: [
                {
                  value: result?.applicant?.id || 0,
                  label: result?.applicant?.email || '',
                },
              ],
            }}
          />
        </>
      )}
    </>
  );
};

export default Reply;

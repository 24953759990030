import React from 'react';

//components
import { Activity, Overview, PreviousApplications } from '..';

const CandidateContent = () => (
  <div className="candidate__content__body">
    <div className="candidate__content__body--overview">
      <Overview />
    </div>
    <div className="candidate__content__body--activity">
      <Activity />
    </div>
    <PreviousApplications />
  </div>
);

export default CandidateContent;

import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';

//components
import { LayoutContainer } from '../../templates';
import { Logo } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

const Footer = ({ borderT = true }: { borderT?: boolean }) => {
  const { t } = useL10N();

  return (
    <LayoutContainer className="footer">
      <div
        className={cx(`footer__content`, {
          'footer__content--border': borderT,
        })}
      >
        <div className="flex flex-col-reverse items-center justify-center mt-3 sm:flex-row md:mt-0">
          <Logo withName={false} opacity={true} />
          <p className="mb-4 footer__content__copyright sm:mb-0">
            {t('footer.content', { date: dayjs().format('YYYY') })}
          </p>
        </div>

        <div className="footer__content__links">
          <a
            className="mr-3 footer__content__links--item"
            href="https://discovr.plus/legal/privacy"
          >
            {t('footer.privacy')}
          </a>
          <a className="footer__content__links--item" href="https://discovr.plus/legal/terms">
            {t('footer.termsAndCondition')}
          </a>
        </div>
      </div>
    </LayoutContainer>
  );
};
export default Footer;

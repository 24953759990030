// graphql
import { StageFieldsFragment, useStagesSubscription } from '../../gen/graphql';

// utils
import { gqlVar } from '../../../utils';

interface ApplicantsStagesReturnTypes {
  loading: boolean;
  result: StageFieldsFragment[];
}
interface ApplicantsStagesProps {
  companyId: number;
}

const useApplicantsStages = ({
  companyId,
}: ApplicantsStagesProps): ApplicantsStagesReturnTypes => {
  // graphql hook
  const { data, loading } = useStagesSubscription(gqlVar({ companyId }));

  return { result: data?.stages || [], loading };
};

export default useApplicantsStages;

import React from 'react';

import { Svg } from '../../../../atoms';

const Roles = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.6}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zm-6-3a2 2 0 11-4 0 2 2 0 014 0zM8 9a5 5 0 00-4.546 2.916A5.986 5.986 0 008 14a5.987 5.987 0 004.546-2.084A5 5 0 008 9z"
      fill="#778CA3"
    />
  </Svg>
);

export default Roles;

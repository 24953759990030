// apollo
import { ApolloError } from '@apollo/client';

// gql
import { MessageFragment, useMessageByPkSubscription } from '../../gen/graphql';

// utils
import { gqlVar } from '../../../utils';
import { Nullable } from '../../../interfaces/types/Nullable.types';

interface useMessageByPkReturnTypes {
  result: MessageFragment | Nullable<MessageFragment>;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useMessageByPk = ({
  messageId,
}: {
  messageId: number;
}): useMessageByPkReturnTypes => {
  // gql hooks
  const { data, loading, error } = useMessageByPkSubscription(
    gqlVar({ messageId })
  );

  return { result: data?.messages_by_pk || null, loading, error };
};

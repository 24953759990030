import React, { FC, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Button } from '../../../atoms';
import { ColorPickerSelect, FieldInput } from '../..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import { useStageInsertMutation, useStageUpdateMutation } from '../../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import { ManageStageFormProps, ManageStageFormTypes } from './ManageStageForm.types';
import { ManageStageSchema } from '../../../../validations';

//enums
import { ButtonSizesEnums, ButtonTypesEnums, ToastrTypesEnums } from '../../../../enums';

const ManageStageForm: FC<ManageStageFormProps> = ({ stage, orderId, company_id, closeModal }) => {
  const [color, setColor] = useState(stage?.color || '#000');
  const { t } = useL10N();
  const toast = useContext(toastrContext);

  const { register, handleSubmit, errors } = useForm<ManageStageFormTypes>({
    resolver: yupResolver(ManageStageSchema({ t })),
    defaultValues: {
      name: stage?.name || '',
    },
  });

  const [insertStage, { loading }] = useStageInsertMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('manageStage.createdError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('manageStage.createdSuccessfully'));
    },
  });

  const [updateStage, { loading: isUpdateLoading }] = useStageUpdateMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('manageStage.editedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('manageStage.editedSuccessfully'));
    },
  });

  const onSubmit = ({ name }) => {
    if (stage) {
      updateStage({
        variables: {
          id: stage.id,
          set: {
            name: name,
            color,
          },
        },
      }).then(() => {
        closeModal && closeModal();
      });
    } else {
      insertStage({
        variables: {
          object: {
            name: name,
            color,
            company_id,
            order: orderId !== 1 ? orderId : 1,
          },
        },
      }).then(() => {
        closeModal && closeModal();
      });
    }
  };

  const _handleChangeColor = (color: string) => {
    setColor(color);
  };

  return (
    <form className="manageStage__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="manageStage__form--content">
        <div className="manageStage__form--field">
          <FieldInput
            labelProps={{ children: 'Name' }}
            inputProps={{
              name: 'name',
              inputRef: register,
            }}
            error={errors.name?.message}
          />
        </div>
        <div className="manageStage__form--field">
          <ColorPickerSelect
            labelProps={{ children: 'Color' }}
            color={color}
            handleChangeColor={_handleChangeColor}
          />
        </div>
      </div>
      <div className="manageStage__form__buttons">
        <Button
          label="Cancel"
          btnType={ButtonTypesEnums.WHITE_BORDER}
          btnSize={ButtonSizesEnums.XM}
          onClick={closeModal}
        />
        <Button
          type="submit"
          className="manageStage__form__buttons--main"
          label={stage ? 'Save' : 'Create'}
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.LG}
          loading={stage ? isUpdateLoading : loading}
        />
      </div>
    </form>
  );
};

export default ManageStageForm;

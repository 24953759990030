import React from 'react';

//components
import { NoteForm } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';

const NewNote = () => {
  const { t } = useL10N();

  return (
    <>
      <div className="new__note">
        <h1>{t('note.addNewTitle')}</h1>
        <div className="note">
          <NoteForm />
        </div>
      </div>
    </>
  );
};

export default NewNote;

import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';
import { ActivityEventTypesEnums } from '../../../../enums';

const Activity: FC<IconProps> = ({ type = 1 }) => {
  const render = () => {
    switch (type) {
      case ActivityEventTypesEnums.APPLY:
        return (
          <path
            d="M3.35667 17.4489C5.68824 16.131 8.32175 15.4404 11 15.4444C13.7778 15.4444 16.3856 16.1722 18.6433 17.4489M14.3333 8.77778C14.3333 9.66183 13.9821 10.5097 13.357 11.1348C12.7319 11.7599 11.8841 12.1111 11 12.1111C10.1159 12.1111 9.2681 11.7599 8.64298 11.1348C8.01786 10.5097 7.66667 9.66183 7.66667 8.77778C7.66667 7.89372 8.01786 7.04588 8.64298 6.42075C9.2681 5.79563 10.1159 5.44444 11 5.44444C11.8841 5.44444 12.7319 5.79563 13.357 6.42075C13.9821 7.04588 14.3333 7.89372 14.3333 8.77778ZM21 11C21 12.3132 20.7413 13.6136 20.2388 14.8268C19.7362 16.0401 18.9997 17.1425 18.0711 18.0711C17.1425 18.9997 16.0401 19.7362 14.8268 20.2388C13.6136 20.7413 12.3132 21 11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7362 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 8.34784 2.05357 5.8043 3.92893 3.92893C5.8043 2.05357 8.34784 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11Z"
            stroke="#778CA3"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
      case ActivityEventTypesEnums.MOVE:
        return (
          <path
            d="M12.1111 7.66667L15.4444 11L12.1111 7.66667ZM15.4444 11L12.1111 14.3333L15.4444 11ZM15.4444 11H6.55556H15.4444ZM21 11C21 12.3132 20.7413 13.6136 20.2388 14.8268C19.7363 16.0401 18.9997 17.1425 18.0711 18.0711C17.1425 18.9997 16.0401 19.7363 14.8268 20.2388C13.6136 20.7413 12.3132 21 11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7363 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 8.34784 2.05357 5.8043 3.92893 3.92893C5.8043 2.05357 8.34784 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11Z"
            stroke="#73C8B4"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
      case ActivityEventTypesEnums.NOTE:
        return (
          <path
            d="M9.22341 3.37842H3.34954C2.72641 3.37842 2.12879 3.62596 1.68817 4.06658C1.24754 4.50721 1 5.10482 1 5.72796V18.6505C1 19.2736 1.24754 19.8712 1.68817 20.3118C2.12879 20.7525 2.72641 21 3.34954 21H16.272C16.8952 21 17.4928 20.7525 17.9334 20.3118C18.374 19.8712 18.6216 19.2736 18.6216 18.6505V12.7766M16.9605 1.71729C17.1772 1.49288 17.4365 1.31389 17.7231 1.19075C18.0098 1.06761 18.3181 1.0028 18.63 1.00009C18.942 0.997378 19.2514 1.05683 19.5401 1.17496C19.8289 1.2931 20.0912 1.46756 20.3118 1.68817C20.5324 1.90877 20.7069 2.1711 20.825 2.45985C20.9432 2.7486 21.0026 3.05799 20.9999 3.36996C20.9972 3.68193 20.9324 3.99024 20.8092 4.27689C20.6861 4.56355 20.5071 4.82281 20.2827 5.03954L10.1961 15.1261H6.87386V11.8039L16.9605 1.71729Z"
            stroke="#CC77A0"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
      case ActivityEventTypesEnums.DELETE_NOTE:
        return (
          <path
            d="M9.22341 3.37842H3.34954C2.72641 3.37842 2.12879 3.62596 1.68817 4.06658C1.24754 4.50721 1 5.10482 1 5.72796V18.6505C1 19.2736 1.24754 19.8712 1.68817 20.3118C2.12879 20.7525 2.72641 21 3.34954 21H16.272C16.8952 21 17.4928 20.7525 17.9334 20.3118C18.374 19.8712 18.6216 19.2736 18.6216 18.6505V12.7766M16.9605 1.71729C17.1772 1.49288 17.4365 1.31389 17.7231 1.19075C18.0098 1.06761 18.3181 1.0028 18.63 1.00009C18.942 0.997378 19.2514 1.05683 19.5401 1.17496C19.8289 1.2931 20.0912 1.46756 20.3118 1.68817C20.5324 1.90877 20.7069 2.1711 20.825 2.45985C20.9432 2.7486 21.0026 3.05799 20.9999 3.36996C20.9972 3.68193 20.9324 3.99024 20.8092 4.27689C20.6861 4.56355 20.5071 4.82281 20.2827 5.03954L10.1961 15.1261H6.87386V11.8039L16.9605 1.71729Z"
            stroke="#CC77A0"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
      case ActivityEventTypesEnums.EMAIL:
        return (
          <path
            d="M1 4.16667L9.32833 9.71889C9.67525 9.95035 10.083 10.0739 10.5 10.0739C10.917 10.0739 11.3247 9.95035 11.6717 9.71889L20 4.16667M3.11111 15.7778H17.8889C18.4488 15.7778 18.9858 15.5554 19.3817 15.1594C19.7776 14.7635 20 14.2266 20 13.6667V3.11111C20 2.55121 19.7776 2.01424 19.3817 1.61833C18.9858 1.22242 18.4488 1 17.8889 1H3.11111C2.55121 1 2.01424 1.22242 1.61833 1.61833C1.22242 2.01424 1 2.55121 1 3.11111V13.6667C1 14.2266 1.22242 14.7635 1.61833 15.1594C2.01424 15.5554 2.55121 15.7778 3.11111 15.7778Z"
            stroke="#E7A066"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
      default:
        return (
          <path
            d="M3.35667 17.4489C5.68824 16.131 8.32175 15.4404 11 15.4444C13.7778 15.4444 16.3856 16.1722 18.6433 17.4489M14.3333 8.77778C14.3333 9.66183 13.9821 10.5097 13.357 11.1348C12.7319 11.7599 11.8841 12.1111 11 12.1111C10.1159 12.1111 9.2681 11.7599 8.64298 11.1348C8.01786 10.5097 7.66667 9.66183 7.66667 8.77778C7.66667 7.89372 8.01786 7.04588 8.64298 6.42075C9.2681 5.79563 10.1159 5.44444 11 5.44444C11.8841 5.44444 12.7319 5.79563 13.357 6.42075C13.9821 7.04588 14.3333 7.89372 14.3333 8.77778ZM21 11C21 12.3132 20.7413 13.6136 20.2388 14.8268C19.7362 16.0401 18.9997 17.1425 18.0711 18.0711C17.1425 18.9997 16.0401 19.7362 14.8268 20.2388C13.6136 20.7413 12.3132 21 11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7362 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 8.34784 2.05357 5.8043 3.92893 3.92893C5.8043 2.05357 8.34784 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11Z"
            stroke="#778CA3"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
    }
  };

  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      {render()}
    </Svg>
  );
};

export default Activity;

import React, { cloneElement, FC, isValidElement, ReactElement } from 'react';

//types
import { CardElementProps } from './CardElement.types';

const CardElement: FC<CardElementProps> = ({ className = '', Icon, value }) => {
  if (!value) return null;
  return (
    <div className={`element ${className}`}>
      {isValidElement(Icon) && cloneElement(Icon as ReactElement)}
      <span className="element--title">{value}</span>
    </div>
  );
};

export default CardElement;

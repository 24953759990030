import React, { FC, useContext } from 'react';

//components
import { Button, Delete } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import { removeNotesCache } from '../../../gql/utils';
import { useDeleteNoteMutation } from '../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../contexts/ToastrContexts';

//types
import { NoteItemProps } from './NoteItem.types';

//enums
import { ToastrTypesEnums } from '../../../enums';

//uutils
import { generateFormatedDate } from '../../../utils';

const NoteItem: FC<NoteItemProps> = ({ note }) => {
  const { id, created_at, description, user } = note;
  const toast = useContext(toastrContext);
  const { t } = useL10N();

  const [deleteMut, { loading }] = useDeleteNoteMutation({
    update: removeNotesCache,
    variables: { id },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('note.deletedSuccessfully'));
    },
    onError: (err) => {
      toast?.add(ToastrTypesEnums.DANGER, t('note.deletedError'));
    },
  });

  const handleDeleteClicked = () => note && deleteMut();

  return (
    <div className="note">
      <p className="note--desc">{description}</p>
      <div className="note--border"></div>
      <div className="note--user">
        <p>
          {user?.name} • {generateFormatedDate(created_at)}
        </p>
        <Button
          label={t('button.delete')}
          disabled={loading}
          before={<Delete />}
          onClick={handleDeleteClicked}
        />
      </div>
    </div>
  );
};

export default NoteItem;

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Button, ErrorMessage, Send } from '../../components/atoms';
import { FieldInput, PasswordMessage } from '../../components/molecules';
import { PagesContainer, HeaderLayout } from '../../components/templates';

//hooks
import { useL10N } from '../../hooks/useL10N';

//gql
import { useForgotPasswordRequestMutation } from '../../gql/gen/graphql';
//types
import { ForgotPasswordSchema } from '../../validations';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../enums';

//utils
import { gqlVar } from '../../utils';

export type ForgotPasswordTypes = {
  email: string;
};

const ForgotPassword = () => {
  const { t } = useL10N();

  const { register, errors, handleSubmit } = useForm<ForgotPasswordTypes>({
    resolver: yupResolver(ForgotPasswordSchema({ t })),
  });

  const [forgotMutation, { loading, data }] = useForgotPasswordRequestMutation({
    onError: (err) => console.error(err),
  });

  const onSubmit = (data: ForgotPasswordTypes) => {
    forgotMutation(gqlVar({ ...data }));
  };

  return (
    <HeaderLayout l10nTitleKey="head.titles.forgotPassword">
      <PagesContainer>
        <h1 className="font-medium text-3xl leading-10 text-blue-100 not-italic mb-12">
          {t('auth.forgotPassword.title')}
        </h1>
        {data?.forgot_password_request?.statusIsOk ? (
          <PasswordMessage
            icon={<Send />}
            status={t('auth.forgotPassword.status')}
            message={t('auth.forgotPassword.message')}
          />
        ) : (
          <>
            <ErrorMessage
              error={data?.forgot_password_request?.statusMessage}
              className="auth__form__content--error"
            />
            <div className="bg-green-300 p-4 rounded-small text-blue-200 font-normal text-sm leading-4 not-italic mb-6">
              {t('auth.forgotPassword.desc')}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldInput
                labelProps={{ children: t('auth.forgotPassword.email') }}
                inputProps={{
                  inputRef: register,
                  placeholder: 'flamur.mavraj@ornio.no',
                  name: 'email',
                }}
                error={errors.email?.message}
              />
              <Button
                type="submit"
                label={t('auth.forgotPassword.sendPassword')}
                loading={loading}
                disabled={loading}
                btnType={ButtonTypesEnums.GREEN}
                className="w-full mt-6"
                btnSize={ButtonSizesEnums.XS}
              />
            </form>
          </>
        )}
      </PagesContainer>
    </HeaderLayout>
  );
};

export default ForgotPassword;

import React from 'react';

//hooks
import { useHistory } from 'react-router-dom';
import { useL10N } from '../../../hooks/useL10N';

// atoms
import { Button, Create } from '../../atoms';

// typings
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const EmptyActiveJobs = () => {
  // l10n
  const { t } = useL10N();

  // router
  const { push } = useHistory();

  // handlers
  const handleCreate = () => push('/job/create');

  return (
    <div className="absolute inset-0 w-100 h-100 flex flex-col items-center justify-center">
    <div className="flex flex-col items-center justify-center md:w-1/2 w-100">
      <h5 className="text-center text-base font-medium mb-2">
        {t('dashboard.emptyJobs.title')}
      </h5>
      <span className="text-center text-sm font-medium text-secondary mb-6">
        {t('dashboard.emptyJobs.description')}
      </span>
      <Button
        label={t('dashboard.createNewJob')}
        btnSize={ButtonSizesEnums.MD}
        btnType={ButtonTypesEnums.GREEN}
        before={<Create />}
        onClick={handleCreate}
      />
    </div>
  </div>
  )
}

export default EmptyActiveJobs

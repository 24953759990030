import React, { useState, FC, forwardRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';

//components
import { FieldInput } from '..';
import { Calendar as CalendarIcon } from '../../../atoms';

import { Opacity } from '../../../atoms';
//types
import { DatepickerProps } from './Datepicker.types';

// utils
import { generateFormatedDate } from '../../../../utils';

const Datepicker: FC<DatepickerProps> = forwardRef(
  (props: DatepickerProps, ref?: React.Ref<HTMLElement>) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [read, setRead] = useState<boolean>(false);

    const formatDate = (v?: string) => {
      if (read && dayjs(v).isValid()) return v && generateFormatedDate(v);
      return v;
    };

    return (
      <>
        <FieldInput
          labelProps={props.labelProps}
          inputProps={{
            onFocus: (e) => {
              read && setRead(false);
              e.target.type = 'date';
            },
            onBlur: (e) => {
              !read && setRead(true);
              e.target.type = 'text';
            },
            onChange: (e) => {
              props.value = e.target.value;
            },
            value: props.value === 'Invalid Date' ? '' : formatDate(props.value),
            ...props.inputProps,
            name: props.name,
          }}
          error={props.error}
          after={<CalendarIcon onClick={() => setOpen(!isOpen)} />}
        />
        <Opacity show={isOpen} className="bg-inherit" onClick={() => setOpen(false)} />
        {isOpen && (
          <div className="relative mt-4">
            <Calendar
              className="absolute z-30 rounded-md shadow-2xl"
              onChange={(date: any) => {
                setOpen(false);
                !read && setRead(true);
                props.onSetValue && props.onSetValue(props?.name || '', generateFormatedDate(date));
              }}
              minDate={dayjs().toDate()}
              value={dayjs(props.value).isValid() ? dayjs(props.value).toDate() : null}
            />
          </div>
        )}
      </>
    );
  }
);

export default Datepicker;

import React from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Button, JobsEmpty, Create } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const JobsEmptyView = () => {
  const { push } = useHistory();
  const { t } = useL10N();

  const handleCreate = () => push('/job/create');

  return (
    <div className="empty">
      <div className="empty--icon">
        <JobsEmpty />
      </div>
      <h1 className="empty__heading">{t('jobs.emptyTitle')}</h1>
      <p className="empty__desc">{t('jobs.emptyDesc')}</p>
      <Button
        label={t('jobs.createNewJob')}
        btnSize={ButtonSizesEnums.MD}
        btnType={ButtonTypesEnums.GREEN}
        before={<Create />}
        onClick={handleCreate}
      />
    </div>
  );
};

export default JobsEmptyView;

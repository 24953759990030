import React from 'react';

import { Svg } from '../../../atoms';

const Bag = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0H9C9.79565 0 10.5587 0.316071 11.1213 0.87868C11.6839 1.44129 12 2.20435 12 3V4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V9.57C13.4396 10.5187 10.7305 11.003 8 11C5.2695 11.003 2.56037 10.5188 0 9.57V6C0 5.46957 0.210714 4.96086 0.585786 4.58579C0.960859 4.21071 1.46957 4 2 4H4ZM6 3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H9C9.26522 2 9.51957 2.10536 9.70711 2.29289C9.89464 2.48043 10 2.73478 10 3V4H6V3ZM7 8C7 7.73478 7.10536 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7H8.01C8.27522 7 8.52957 7.10536 8.71711 7.29289C8.90464 7.48043 9.01 7.73478 9.01 8C9.01 8.26522 8.90464 8.51957 8.71711 8.70711C8.52957 8.89464 8.27522 9 8.01 9H8C7.73478 9 7.48043 8.89464 7.29289 8.70711C7.10536 8.51957 7 8.26522 7 8Z"
        fill="#778CA3"
      />
      <path
        d="M0 11.692V14C0 14.5304 0.210714 15.0392 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0392 16 14.5304 16 14V11.692C13.4224 12.5608 10.7201 13.0026 8 13C5.204 13 2.513 12.54 0 11.692Z"
        fill="#778CA3"
      />
    </g>
  </Svg>
);

export default Bag;

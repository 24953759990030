import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

//components
import { ContentLoading } from '../../../atoms';
import { CandidateMailsHeader, CandidateMailsItem } from '../../../molecules';

//gql
import { useUpdateMessageByPkMutation } from '../../../../gql/gen/graphql';
import { useMessageByPk } from '../../../../gql/hooks/messages/useMessageByPk';

//utils
import { gqlVar } from '../../../../utils';

//types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

//enums
import { ContentLoadingEnums } from '../../../../enums';

const CandidateMails = () => {
  //local state
  const [isReaded, setIsReaded] = useState<boolean>(false);
  // hook
  const { params } = useRouteMatch<RouteMatchProps>();

  // gql hooks
  const [updateMessageByPk] = useUpdateMessageByPkMutation();
  const { result, loading } = useMessageByPk({ messageId: +params?.id });

  // handler
  const handleUpdate = useCallback(
    (date: Date | null, readed?: boolean) => {
      if (!!readed) setIsReaded(readed);
      if (result) {
        updateMessageByPk(gqlVar({ id: result?.id, set: { read_at: date } }));
      }
    },
    [result, updateMessageByPk]
  );

  // effect
  useEffect(() => {
    if (!loading && !!result && !!result?.read_at && !isReaded) {
      handleUpdate(new Date(), true);
    }
  }, [handleUpdate, isReaded, loading, result]);

  if (loading) {
    return <ContentLoading size={ContentLoadingEnums.LG} className="mt-16" />;
  }

  return (
    <div className="candidate__mails">
      {!loading && result && (
        <>
          <CandidateMailsHeader
            messageId={result?.id}
            applicant={result?.applicant}
            isSeen={result?.read_at !== null}
            handleUpdate={handleUpdate}
          />
          <div className="candidate__mails--content">
            {result?.replies?.length
              ? result?.replies.map((msg) => (
                  <CandidateMailsItem
                    key={msg.id}
                    user={msg?.user}
                    message={msg.message}
                    message_id={result.id}
                    applicant={msg?.applicant}
                    opened_at={msg.opened_at}
                    created_at={msg.created_at}
                    title={'Re: ' + result.subject}
                  />
                ))
              : ''}

            <CandidateMailsItem
              key={result.id}
              user={result?.user}
              title={result.subject}
              message_id={result?.id}
              message={result.message}
              opened_at={result.opened_at}
              applicant={result?.applicant}
              created_at={result.created_at}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateMails;

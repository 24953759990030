import { useState } from 'react';

const useDropdown = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onMenuClick = () => setOpen(!open);

  const closeMenu = () => setOpen(false);

  return { open, onMenuClick, closeMenu };
};

export default useDropdown;

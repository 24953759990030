import React from 'react';

import { Svg } from '../../../../atoms';

const Copy = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.594 12h-1.75a1.75 1.75 0 01-1.75-1.75v-7a1.75 1.75 0 011.75-1.75h7a1.75 1.75 0 011.75 1.75V5m-5.25 10.5h7a1.75 1.75 0 001.75-1.75v-7A1.75 1.75 0 0013.344 5h-7a1.75 1.75 0 00-1.75 1.75v7a1.75 1.75 0 001.75 1.75z"
      stroke="#778CA3"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Copy;

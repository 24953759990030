import React, { FC, forwardRef, Ref } from 'react';
import Select, { components } from 'react-select';
import cx from 'classnames';

//components
import { ArrowDown, Label, SelectedIcon } from '../../../atoms';

//types
import { ReactSelectProps } from './ReactSelect.types';

const ReactSelect: FC<ReactSelectProps> = forwardRef(
  (props: ReactSelectProps, ref?: Ref<HTMLElement>) => {
    //control
    const Control = (rest) => (
      <components.Control
        {...rest}
        className={cx('control', {
          'border-danger': !!props.error,
        })}
      />
    );

    //menu container
    const Menu = (rest) => <components.Menu {...rest} className="menu" />;

    // menulist container
    const MenuList = (rest) => <components.MenuList {...rest} className="menuList" />;

    const DropdownIndicator = (rest) => (
      <components.DropdownIndicator
        {...rest}
        className={cx({ 'dropdown__indicator--none': !!props.isMulti })}
      >
        <ArrowDown />
      </components.DropdownIndicator>
    );

    const ValueContainer = (rest) => {
      return <components.ValueContainer {...rest} className="valueContainer" />;
    };

    const MultiValue = (rest) => {
      return (
        <components.MultiValue
          {...rest}
          className={cx('valueContainer__multiple--item', {
            is__disabled: props?.isDisabled,
          })}
        />
      );
    };

    const MultiValueContainer = (rest) => {
      return (
        <components.MultiValueContainer {...rest} className="valueContainer__multipleContainer" />
      );
    };

    const Option = (rest) => (
      <components.Option {...rest} className="option">
        <span className="option--icon">{rest.isSelected && <SelectedIcon />}</span>
        <span
          className={cx('option--value', {
            'text-primary': rest.isSelected || rest.isFocused,
            'text-secondary': !rest.isFocused && !rest.isSelected,
          })}
        >
          {rest.children}
        </span>
      </components.Option>
    );

    return (
      <>
        <Label {...props.labelProps} className="mb-2" />
        <Select
          id="react-select"
          {...props}
          styles={{
            control: () => ({}),
            menu: () => ({}),
            menuList: () => ({}),
            placeholder: (base) => ({ ...base, color: '#778CA3' }),
            dropdownIndicator: () => ({}),
            valueContainer: () => ({}),
            multiValue: () => ({
              backgroundColor: '#fff',
              display: 'flex',
              margin: '0 4px 4px 0',
            }),
            multiValueLabel: () => ({
              backgroundColor: '#778CA3',
              color: '#fff',
              borderRadius: '30px 0 0 30px',
              padding: '5px 12px',
              fontSize: '12px',
            }),
            multiValueRemove: () => ({
              backgroundColor: '#778CA3',
              color: '#fff',
              borderRadius: '0 30px  30px 0',
              padding: '5px 12px 5px 0',
            }),
            option: () => ({}),
            input: () => ({}),
          }}
          components={{
            Control,
            ValueContainer,
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator,
            Option,
            Menu,
            MenuList,
            MultiValue,
            MultiValueContainer,
          }}
          isMulti={!!props.isMulti}
          isDisabled={!!props.isDisabled}
          defaultValue={{ ...props.defaultValue }}
        />
      </>
    );
  }
);

export default ReactSelect;

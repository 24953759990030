import React, { FC, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Dot } from '../../../atoms';
import { Tabs } from '../../../molecules';
import { DotTypesEnums } from '../../../../enums';
import { JobTabsProps } from './JobTabs.types';
import { parseQueryString, serialize } from '../../../../utils';
import useApplicantsStages from '../../../../gql/hooks/applicants/useApplicantsStages';
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';

import { CustomLinkProps } from '../../../atoms/links/CustomLink.types';
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

const JobsTabs: FC<JobTabsProps> = ({ meta }) => {
  // hook
  const { search } = useLocation();
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  // graphql hook
  const { companyId } = useCompanyUser();
  const { result, loading } = useApplicantsStages({ companyId });

  // helper
  const generateTabs = useMemo(() => {
    if (companyId && !loading && result?.length && meta) {
      const queryParams = parseQueryString(search);
      const serializeQuery = serialize({ ...queryParams });

      const stages: CustomLinkProps[] = result?.map((stage) => {
        const event = stage?.name?.replace(/ /g, '-').toLowerCase();
        return {
          label: stage?.name || '',
          match: `/job/${id}/${event}/${stage?.id}`,
          to: `/job/${id}/${event}/${stage?.id}${serializeQuery}`,
          before: <Dot style={{ backgroundColor: stage?.color || '' }} />,
        };
      });

      stages.unshift({
        label: 'All',
        match: `/job/${id}/all`,
        to: `/job/${id}/all${serializeQuery}`,
        before: <Dot type={DotTypesEnums.DARK_GRAY} />,
      });

      return stages;
    }
    return [];
  }, [companyId, loading, result, meta, search, id]);

  return (
    <div className="jobs__tabs">
      <Tabs tabs={generateTabs} beforeLabel="Stage" />
    </div>
  );
};

export default JobsTabs;

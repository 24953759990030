import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Subscribers: FC<IconProps> = ({ active = false, color = 'white' }) => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={active ? '1' : '0.4'}
      d="M9 2.62833C9.44787 2.12059 10.0397 1.76126 10.6968 1.59816C11.354 1.43507 12.0451 1.47594 12.6785 1.71534C13.3118 1.95473 13.8572 2.38131 14.2421 2.9383C14.627 3.49529 14.8332 4.15629 14.8332 4.83333C14.8332 5.51038 14.627 6.17138 14.2421 6.72837C13.8572 7.28536 13.3118 7.71193 12.6785 7.95133C12.0451 8.19073 11.354 8.2316 10.6968 8.06851C10.0397 7.90541 9.44787 7.54608 9 7.03833M11.5 16.5H1.5V15.6667C1.5 14.3406 2.02678 13.0688 2.96447 12.1311C3.90215 11.1935 5.17392 10.6667 6.5 10.6667C7.82608 10.6667 9.09785 11.1935 10.0355 12.1311C10.9732 13.0688 11.5 14.3406 11.5 15.6667V16.5ZM11.5 16.5H16.5V15.6667C16.5001 14.7889 16.2692 13.9266 15.8304 13.1664C15.3916 12.4062 14.7604 11.7749 14.0003 11.336C13.2402 10.8971 12.3779 10.666 11.5002 10.666C10.6224 10.6659 9.76015 10.897 9 11.3358M9.83333 4.83333C9.83333 5.71739 9.48214 6.56524 8.85702 7.19036C8.2319 7.81548 7.38405 8.16667 6.5 8.16667C5.61594 8.16667 4.7681 7.81548 4.14298 7.19036C3.51786 6.56524 3.16667 5.71739 3.16667 4.83333C3.16667 3.94928 3.51786 3.10143 4.14298 2.47631C4.7681 1.85119 5.61594 1.5 6.5 1.5C7.38405 1.5 8.2319 1.85119 8.85702 2.47631C9.48214 3.10143 9.83333 3.94928 9.83333 4.83333Z"
      stroke={active ? '#08D4A5' : color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Subscribers;

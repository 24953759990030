import React from 'react';
import { useRouteMatch } from 'react-router-dom';

//components
import { LayoutContainer, PagesContainer, HeaderLayout } from '../../components/templates';
import { JobsItem, JobsEmptyView, ReactPagination } from '../../components/molecules';
import { JobsHeader, JobsTabs } from '../../components/organisms';
import { ContentLoading } from '../../components/atoms';

//gql
import useVacancies from '../../gql/hooks/vacancies/useVacancies';
import { useVacancyCountSubscription } from '../../gql/gen/graphql';

export interface RouteMatchProps {
  tab: string;
}

const Jobs = () => {
  const { params } = useRouteMatch<RouteMatchProps>();

  const { result, loading } = useVacancies();

  const { data: { vacanciesAggreate = {} } = {} } = useVacancyCountSubscription();

  const count = vacanciesAggreate.aggregate?.count || 0;

  return (
    <HeaderLayout l10nTitleKey="head.titles.jobs">
      <PagesContainer>
        <div className="jobs">
          <JobsHeader />
          <JobsTabs meta={result.meta} />
          <LayoutContainer className="">
            {loading ? (
              <div className="flex items-center justify-center jobs--content">
                <ContentLoading />
              </div>
            ) : (
              <div className="jobs--content">
                {result && result?.data?.length ? (
                  result.data.map((v) => (
                    <JobsItem
                      prevRoute={`/jobs/${params?.tab || 'all'}`}
                      key={`${v.id}-${v.title}`}
                      vacancy={v}
                    />
                  ))
                ) : (
                  <JobsEmptyView />
                )}
              </div>
            )}
            {!!result.meta[params.tab] && !loading && (
              <ReactPagination
                count={count}
                className="jobs--pagination"
                loading={loading}
                pagination={{ numberOfItems: result.meta[params.tab], count }}
              />
            )}
          </LayoutContainer>
        </div>
      </PagesContainer>
    </HeaderLayout>
  );
};

export default Jobs;

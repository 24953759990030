import React, { FC } from 'react';

//components
import { Button } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { PublishJobProps } from './PublishJob.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const PublishJob: FC<PublishJobProps> = ({ closeModal, handleSubmit, showSteps = false }) => {
  const { t } = useL10N();

  const handleSubmited = () => {
    closeModal && closeModal();
    handleSubmit();
  };

  return (
    <div className="job__modal">
      <p className="job__modal--title">{t('job.publishTitle')}</p>
      <div className="flex items-center justify-between mt-8">
        <span className="text-xs text-tertiary"> {showSteps && '1/2'}</span>
        <div className="job__modal--buttons ">
          <Button
            label={t('button.cancel')}
            btnType={ButtonTypesEnums.WHITE_BORDER}
            btnSize={ButtonSizesEnums.XM}
            onClick={closeModal}
          />
          <Button
            className="ml-2"
            label={t('job.publish')}
            onClick={handleSubmited}
            btnType={ButtonTypesEnums.GREEN}
            btnSize={ButtonSizesEnums.LG}
          />
        </div>
      </div>
    </div>
  );
};

export default PublishJob;

// graphql
import { useVacancySubscription } from '../../gen/graphql';

// utils
import { gqlVar } from '../../../utils';

const useVacancy = ({ id }: { id: string }) => {
  const { data, loading, error } = useVacancySubscription(gqlVar({ id: +id }));

  return { result: data?.vacancies_by_pk || null, loading, error };
};

export default useVacancy;

export const mapEnum = (
  enumerable: any,
  fn: Function,
  type: string = 'number'
): any[] => {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map(
    (key) => enumerable[key]
  );
  let enumValues: string | number[] = [];
  // interested in the numeric identifiers as these represent the values
  if (type === 'number')
    enumValues = enumMembers.filter((v) => typeof v === 'number');
  // interested in the string identifiers as these represent the values
  if (type === 'string')
    enumValues = enumMembers.filter((v) => typeof v === 'string');
    
  // now map through the enum values
  return enumValues.map((m) => fn(m));
};

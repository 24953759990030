import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { useValidateResetTokenQuery } from '../gen/graphql';

export interface QueryVars {
  token: string;
}
export interface QueryResponse {
  password_reset: { expire_at: string }[];
}

type HookResult = {
  isValidToken: boolean;
  loading: boolean;
  error: any;
};

const useValidateResetToken = ({ token }: { token: string }): HookResult => {
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const { replace } = useHistory();

  const query = useValidateResetTokenQuery({
    variables: { token },
    fetchPolicy: 'network-only',
    onError: (_) => _,
    onCompleted: (data: QueryResponse) => {
      if (data?.password_reset?.length) {
        if (dayjs().diff(dayjs(data.password_reset[0].expire_at)) < 0)
          setIsValidToken(true);
        else replace('/dashboard');
      } else replace('/dashboard');
    },
  });

  const { loading, error } = query;

  return { isValidToken, loading, error };
};

export default useValidateResetToken;

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

//components
import { MailFormHeader } from '../../../molecules';
import { MailForm } from '..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useGetApplicantsQuery } from '../../../../gql/gen/graphql';

//utils
import { parseQueryString, gqlVar } from '../../../../utils';

//enums
import { CreateMessageTypes } from '../../../../enums';

const NewMessage = () => {
  const { search } = useLocation();
  const { t } = useL10N();
  const queryParams = parseQueryString(search);

  let selected_applicants: { value: number; label: string }[] = [];
  const { company_user } = useCompanyUser();
  const { data: { applicants = [] } = {}, loading } = useGetApplicantsQuery(
    gqlVar({
      company_id: company_user?.company_id,
    })
  );

  if (queryParams.applicant_ids) {
    applicants?.filter((j) =>
      queryParams.applicant_ids.split(',').map((i) => {
        if (i === j.id.toString())
          return selected_applicants.push({ value: j.id, label: j.email });
        return i;
      })
    );
  }

  const renderContent = useMemo(() => {
    if (!queryParams.applicant_ids) return <MailForm type={CreateMessageTypes.NEW} />;
    else if (!loading && applicants)
      return <MailForm message={{ to: selected_applicants }} type={CreateMessageTypes.NEW} />;
  }, [queryParams, loading, applicants, selected_applicants]);

  return (
    <>
      <MailFormHeader title={t('message.newMessage')} />
      {renderContent}
    </>
  );
};

export default NewMessage;

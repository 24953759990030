import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const PaginationArrows: FC<IconProps> = ({ type = 1 }) => (
  <Svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={
        type === 1
          ? 'M19 5H1M15 1L19 5L15 1ZM19 5L15 9L19 5Z'
          : 'M1 5H19M5 9L1 5L5 9ZM1 5L5 1L1 5Z'
      }
      stroke="#778CA3"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PaginationArrows;

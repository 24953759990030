import React from 'react';

//components
import { JobHeader, JobContent } from '../../components/organisms';
import { PagesContainer, HeaderLayout } from '../../components/templates';

const Job = () => (
  <HeaderLayout l10nTitleKey="head.titles.job">
    <PagesContainer>
      <JobHeader />
      <JobContent />
    </PagesContainer>
  </HeaderLayout>
);

export default Job;

import React, { FC } from 'react';

//components
import { Button, TwitterIcon, FacebookIcon, LinkedinIcon } from '../../atoms';

//types
import { JobShareSubDropdownProps } from '../../../interfaces';

//config
import { DISCOVR_PAGE_URL } from '../../../config';

const JobShareSubDropdown: FC<JobShareSubDropdownProps> = ({ closeMenu, url }) => {
  const handleOnClick = () => {
    closeMenu && closeMenu();
  };

  return (
    <div className="default__dropdown__jobs">
      <a
        id={'job-share-facebook'}
        target="_blank"
        aria-label="Facebook"
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=${DISCOVR_PAGE_URL}${url}`}
      >
        <Button
          id="facebook"
          label="Facebook"
          before={<FacebookIcon />}
          onClick={handleOnClick}
          className="default__dropdown__jobs--item"
        />
      </a>
      <a
        id={'job-share-twitter'}
        target="_blank"
        aria-label="Twitter"
        rel="noopener noreferrer"
        href={`https://twitter.com/intent/tweet?url=${DISCOVR_PAGE_URL}${url}`}
      >
        <Button
          id="twitter"
          label="Twitter"
          before={<TwitterIcon />}
          onClick={handleOnClick}
          className="default__dropdown__jobs--item"
        />
      </a>
      <a
        id={'job-share-linkedin'}
        target="_blank"
        aria-label="Linkedin"
        rel="noopener noreferrer"
        href={`https://www.linkedin.com/shareArticle?url=${DISCOVR_PAGE_URL}${url}`}
      >
        <Button
          id="linkedin"
          label="Linkedin"
          before={<LinkedinIcon />}
          onClick={handleOnClick}
          className="default__dropdown__jobs--item"
        />
      </a>
    </div>
  );
};

export default JobShareSubDropdown;

import React, { FC } from 'react';
import cx from 'classnames';

//components
import { Loading } from '../..';

//types
import { ButtonProps } from './Button.types';

//enums
import { ButtonTypesEnums, ButtonSizesEnums } from '../../../../enums';

const Button: FC<ButtonProps> = ({
  type = 'button',
  className = '',
  label = '',
  children,
  after: After = null,
  before: Before = null,
  buttonRef = null,
  btnSize = ButtonSizesEnums.DEFAULT,
  btnType = ButtonTypesEnums.DEFAULT,
  loading = false,
  disabled = false,
  ...props
}) => {
  const _classnName = Before && After ? 'both' : After ? 'left' : Before ? 'right' : '';
  return (
    <button
      {...props}
      type={type}
      className={cx('btn', {
        [`btn--${btnType}`]: btnType,
        [`btn--${btnSize}`]: btnSize,
        [`${className}`]: className,
        'btn--disabled': disabled,
      })}
      disabled={disabled}
      ref={buttonRef}
    >
      {loading ? <Loading /> : Before && <span className="mr-2 btn__before">{Before}</span>}
      {label ||
        (children && (
          <span className={cx('btn__label', { [_classnName]: _classnName })}>
            {label || children}
          </span>
        ))}
      {After && <span className="ml-2 btn__after">{After}</span>}
    </button>
  );
};

export default Button;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

//components
import {
  Button,
  ErrorMessage as ErrorApi,
  ShowPassword,
  HidePassword,
} from '../../atoms';
import { FieldInput, FileFieldButton, ReactSelect } from '../../molecules';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import { useRegisterMutation } from '../../../gql/gen/graphql';

//types
import { RegisterFormTypes } from './RegisterForm.types';
import { RegisterModelBody } from '../../../interfaces/models';
import { RegisterFormSchema } from '../../../validations';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

//config
import { GQL_ACTIONS_URL } from '../../../config/gql';

//utils
import { gqlData, storeAuthToken } from '../../../utils';

//assets
import { industries } from '../../../static';

const RegisterForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { push } = useHistory();
  const { t } = useL10N();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    control,
  } = useForm<RegisterFormTypes>({
    resolver: yupResolver(RegisterFormSchema({ t })),
  });

  const [registerMutation, { data }] = useRegisterMutation({
    onCompleted: ({ register: rg }) =>
      storeAuthToken({
        statusIsOk: rg?.statusIsOk || false,
        token: rg?.token || '',
      }),
    onError: (err) => console.error(err),
  });

  const handleTogglePassword = () => setShowPassword((prev) => !prev);

  const onFileDelete = (name: string) => {
    if (name === 'company_file' || name === 'user_file')
      setValue(name, undefined);
  };

  const registerAction = (data: RegisterModelBody) =>
    registerMutation(gqlData(data))
      .then(({ data }) => {
        setLoading(false);
        if (data?.register?.statusIsOk) push('/dashboard');
      })
      .catch((err) => setLoading(false));

  const fileUpload = async (file: File) => {
    const data = new FormData();
    data.append('file', file);

    return fetch(`${GQL_ACTIONS_URL}/upload`, {
      method: 'POST',
      body: data,
    }).then((res) => res.json());
  };

  const onSubmit = async (data: RegisterFormTypes) => {
    setLoading(true);
    const { user_file, company_file, industry, ...rest } = data;
    let user_image_id;
    let company_image_id;

    if (user_file && user_file[0])
      user_image_id = await fileUpload(user_file[0])
        .then((res) => res.id)
        .catch((err) => undefined);

    if (company_file && company_file[0])
      company_image_id = await fileUpload(company_file[0])
        .then((res) => res.id)
        .catch((err) => undefined);

    registerAction({
      ...rest,
      industry: industry.label,
      ...(user_image_id && { user_image_id }),
      ...(company_image_id && { company_image_id }),
    });
  };

  return (
    <form
      id="register-form"
      className="auth__form__content"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ErrorApi
        error={data?.register?.statusMessage}
        className="auth__form__content--error"
      />
      <h1 className="auth__form__content__step text-primary opacity-50 text-base leading-7 font-medium mb-6">
        1. {t('register.personalInfo')}
      </h1>
      <div className="auth__form__content--input">
        <FileFieldButton
          name={watch('name', '')}
          file={watch('user_file')}
          onDelete={onFileDelete}
          labelProps={{ children: t('register.profilePic') }}
          inputProps={{
            name: 'user_file',
            inputRef: register,
          }}
          error={errors.user_file?.message}
        />
      </div>
      <div className="auth__form__content--input">
        <FieldInput
          labelProps={{ children: t('register.fullName') }}
          inputProps={{
            name: 'name',
            placeholder: 'Flamur Mavraj',
            inputRef: register,
          }}
          error={errors.name?.message}
        />
      </div>
      <div className="auth__form__content--input">
        <FieldInput
          labelProps={{ children: t('register.email') }}
          inputProps={{
            type: 'email',
            name: 'email',
            placeholder: 'flamur.mavraj@ornio.no',
            inputRef: register,
          }}
          error={errors.email?.message}
        />
      </div>
      <div className="auth__form__content--input">
        <FieldInput
          labelProps={{ children: t('auth.password') }}
          after={
            <button
              type="button"
              className="btn"
              onClick={handleTogglePassword}
            >
              {showPassword ? <HidePassword /> : <ShowPassword />}
            </button>
          }
          inputProps={{
            name: 'password',
            placeholder: t('auth.passwordPlaceholder'),
            inputRef: register,
            type: showPassword ? 'text' : 'password',
          }}
          error={errors.password?.message}
        />
      </div>
      <h1 className="auth__form__content__step text-primary opacity-50 text-base leading-7 font-medium mb-6 mt-12">
        2. {t('register.companyInfo')}
      </h1>

      <div className="auth__form__content--input">
        <FileFieldButton
          name={watch('company_name', '')}
          firstButtonName={t('button.uploadLogo')}
          file={watch('company_file')}
          onDelete={onFileDelete}
          labelProps={{ children: t('register.companyLogo') }}
          inputProps={{
            name: 'company_file',
            inputRef: register,
          }}
          error={errors.company_file?.message}
        />
      </div>
      <div className="auth__form__content--input">
        <FieldInput
          labelProps={{ children: t('register.companyName') }}
          inputProps={{
            name: 'company_name',
            placeholder: 'Ornio As',
            inputRef: register,
          }}
          error={errors.company_name?.message}
        />
      </div>
      <div className="auth__form__content--input">
        <Controller
          as={<ReactSelect />}
          options={industries.map((i, key) => ({ ...i, value: key }))}
          error={!!errors.industry}
          labelProps={{ children: t('register.industry') }}
          placeholder="Design & Development"
          name="industry"
          control={control}
          defaultValue={''}
        />
        <ErrorMessage
          errors={errors}
          name="industry"
          render={({ message }) => {
            return <p className={'error--input mt-3'}>{message}</p>;
          }}
        />
      </div>
      <div className="auth__form__content--input">
        <Button
          type="submit"
          loading={loading}
          disabled={loading}
          label={t('button.register')}
          btnType={ButtonTypesEnums.GREEN}
          className="w-full"
          btnSize={ButtonSizesEnums.XS}
        />
      </div>
    </form>
  );
};

export default RegisterForm;

import React, { FC } from 'react';

import { Button, PaymentConfirmed as PaymentConfirmedIcon } from '../../atoms';

import { useL10N } from '../../../hooks/useL10N';
//types
import { PaymentConfirmedProps } from './PaymentConfirmed.types';
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const PaymentConfirmed: FC<PaymentConfirmedProps> = ({ closeModal }) => {
  const { t } = useL10N();

  const handleCloseModal = () => closeModal();

  return (
    <div className="py-16 pl-5 pr-5 flex flex-col items-center justify-center">
      <PaymentConfirmedIcon />
      <h4 className="mt-6 text-xl text-primary font-bold">
        {t('paymentConfirmed.title')}
      </h4>
      <p className="mt-4 text-base text-primary opacity-75 leading-7 text-center">
        {t('paymentConfirmed.description')}
      </p>
      <Button
        className="mt-8"
        label={t('button.close')}
        btnSize={ButtonSizesEnums['2XL']}
        btnType={ButtonTypesEnums.GREEN}
        onClick={handleCloseModal}
      />
    </div>
  );
};

export default PaymentConfirmed;

import React from 'react';

//components
import { Button, NewTemplate, ContentLoading, ApiTokensEmpty } from '../../../../atoms';
import {
  TokenItem,
  TokenTemplateForm,
  CustomModal,
  SettingsTemplateEmpty,
} from '../../../../molecules';

//hooks
import useModal from '../../../../../hooks/useModal';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../../gql/hooks/useCompanyUser';
import useApiTokens from '../../../../../gql/hooks/settings/useTokens';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ModalTypesEnums,
  ContentLoadingEnums,
} from '../../../../../enums';

const Tokens = () => {
  const { xl } = useWindowSize();
  const { t } = useL10N();

  const { isOpen, onClick, closeModal } = useModal();

  const { company_user } = useCompanyUser();
  const { api_tokens, loading } = useApiTokens({
    company_id: company_user?.company_id,
  });

  return (
    <>
      <CustomModal
        open={isOpen}
        closeModal={closeModal}
        title={t('settings.newApiToken')}
        type={ModalTypesEnums.MD}
      >
        <TokenTemplateForm closeModal={closeModal} />
      </CustomModal>

      <section className="tokens">
        <div className="content__header">
          <span className="content__header--title">{t('settings.apiTokens')}</span>
          <Button
            className="content__header--btn"
            label={xl ? t('button.newToken') : t('button.new')}
            btnSize={!xl ? ButtonSizesEnums.XS : ButtonSizesEnums.MD}
            btnType={ButtonTypesEnums.GREEN}
            before={<NewTemplate />}
            onClick={onClick}
          />
        </div>
        <div className="tokens__body">
          {!loading ? (
            api_tokens.length ? (
              api_tokens.map((token, index) => (
                <TokenItem token={token} id={index + 1} key={token.id} />
              ))
            ) : (
              <SettingsTemplateEmpty
                title={t('settings.tokensEmptyTitle')}
                icon={<ApiTokensEmpty />}
              >
                <Button
                  className="content__header--btn"
                  label={t('button.newToken')}
                  btnSize={ButtonSizesEnums.LG}
                  btnType={ButtonTypesEnums.GREEN}
                  before={<NewTemplate />}
                  onClick={onClick}
                />
              </SettingsTemplateEmpty>
            )
          ) : (
            <ContentLoading size={ContentLoadingEnums.LG} className="my-12" />
          )}
        </div>
      </section>
    </>
  );
};

export default Tokens;

import React, { FC, isValidElement, cloneElement, ReactElement } from 'react';

//types
import { CarouselItemProps } from './CarouselItem.types';

const CarouselItem: FC<CarouselItemProps> = ({
  item: { imgSrc, header, paragraph },
}) => (
  <div className="carousel__content">
    <div className="carousel__content__image mb-12">
      {isValidElement(imgSrc) && cloneElement(imgSrc as ReactElement)}
    </div>
    <div className="carousel__content__body">
      <h1>{header}</h1>
      <p>{paragraph}</p>
    </div>
  </div>
);

export default CarouselItem;

import React, { FC, useEffect, useCallback } from 'react';

//components
import ToastrItem from './ToastrItem';
import ToastrClose from '../../atoms/assets/icons/ToastrClose';

//style
import './Toastr.scss';

//types
import { ToastrProps } from './Toastr.types';
import { ToastrItemProps } from './ToastrItem.types';

const Toastr: FC<ToastrProps> = ({
  setToastList,
  toastList,
  position = 'bottom-right',
  autoDelete,
  dismissTime,
}) => {
  const deleteToast = useCallback(
    (id: ToastrItemProps['toast']['id']) => {
      toastList && setToastList([...toastList.filter((e) => e.id !== id)]);
    },
    [toastList, setToastList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList?.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, dismissTime, deleteToast]);

  return (
    <div className={`notification__container ${position}`}>
      {toastList?.map((toast, idx) => (
        <ToastrItem
          key={idx}
          buttonProps={{
            onClick: () => deleteToast(toast.id),
            icon: <ToastrClose />,
          }}
          position={position}
          toast={{ ...toast }}
        />
      ))}
    </div>
  );
};

export default Toastr;

import React from 'react';

import { Svg } from '../../../atoms';

const NoMessageResults = () => (
  <Svg
    width={68}
    height={62}
    viewBox="0 0 68 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0488 42.667C7.85333 39.7094 4.98441 36.4176 2.49125 32.848C2.11602 32.3057 1.91562 31.6616 1.91692 31.0021C1.91822 30.3426 2.12117 29.6993 2.49854 29.1585C6.19979 23.8895 17.9963 9.12533 33.9883 9.12533C36.9685 9.13456 39.9275 9.62707 42.75 10.5837"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M50.5957 14.4042C56.441 18.3537 61.4959 23.3617 65.4999 29.1698C65.8714 29.7092 66.0703 30.3487 66.0703 31.0036C66.0703 31.6586 65.8714 32.2981 65.4999 32.8375C61.8774 38.1021 50.253 52.875 33.9882 52.875C28.0642 52.7599 22.3006 50.9297 17.3953 47.606"
      fill="white"
    />
    <path
      d="M50.5957 14.4042C56.441 18.3537 61.4959 23.3617 65.4999 29.1698C65.8714 29.7092 66.0703 30.3487 66.0703 31.0036C66.0703 31.6586 65.8714 32.2981 65.4999 32.8375C61.8774 38.1021 50.253 52.875 33.9882 52.875C28.0642 52.7599 22.3006 50.9297 17.3953 47.606"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M21.1884 33.917C20.697 31.7514 20.7626 29.4965 21.3792 27.3631C21.9958 25.2298 23.1433 23.2875 24.7143 21.718C26.2852 20.1485 28.2285 19.0029 30.3625 18.3882C32.4964 17.7736 34.7513 17.71 36.9165 18.2034"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M63.1665 1.83365L4.83316 60.167"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M43.2749 21.7249C45.7363 24.1864 47.1191 27.5248 47.1191 31.0058C47.1191 34.4868 45.7363 37.8252 43.2749 40.2866C40.8135 42.748 37.4751 44.1309 33.9941 44.1309C30.5131 44.1309 27.1747 42.748 24.7132 40.2866"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </Svg>
);

export default NoMessageResults;

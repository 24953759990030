import React, { useContext, useState, FC } from 'react';

// hook form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { FieldInput, CustomModal } from '../../../../molecules';
import {
  Button,
  ErrorMessage,
  ShowPassword,
  HidePassword,
} from '../../../../atoms';

//contexts
import { toastrContext } from '../../../../../contexts/ToastrContexts';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import { useUpdateEmailMutation } from '../../../../../gql/gen/graphql';

//types
import { ChangeEmailSchema } from '../../../../../validations';

// types
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ModalTypesEnums,
  ToastrTypesEnums,
} from '../../../../../enums';
import {
  ChangeEmailDialogProps,
  ChangeEmailDefaultData,
} from './ChangeEmail.types';

const ChangeEmailDialog: FC<ChangeEmailDialogProps> = ({ onClose }) => {
  // l10n
  const { t } = useL10N();

  // toastrs
  const toast = useContext(toastrContext);

  // hook form
  const { register, handleSubmit, errors } = useForm<ChangeEmailDefaultData>({
    resolver: yupResolver(ChangeEmailSchema(t)),
  });

  // state
  const [apiError, setApiError] = useState<string>('');
  const [toggleReadOnly, setToggleReadOnly] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // gql mutation
  const [updateUserEmail, { loading }] = useUpdateEmailMutation();

  // close modal handler
  const handleCloseModal = () => onClose();

  // show hide password handler
  const handleTogglePassword = () => setShowPassword((prev) => !prev);

  // submit handler
  const onSubmit = (data: ChangeEmailDefaultData) => {
    if (!loading) {
      setApiError('');
      updateUserEmail({
        variables: {
          email: data.email,
          password: data.password,
        },
      })
        .then((res) => {
          if (res?.data?.updateEmail?.statusIsOk) {
            toast?.add(
              ToastrTypesEnums.SUCCESS,
              t('settings.userProfile.updateSuccessfully')
            );
            setApiError('');
            onClose(data);
          } else {
            setApiError(
              res?.data?.updateEmail?.statusMessage ||
                t('settings.userProfile.updateError')
            );
          }
        })
        .catch(() => {
          setApiError(t('settings.userProfile.updateError'));
        });
    }
  };

  return (
    <CustomModal
      open
      closeModal={!loading ? handleCloseModal : undefined}
      title={t('settings.changeEmail.dialogTitle')}
      type={ModalTypesEnums.MD}
    >
      <div>
        <ErrorMessage error={apiError} className="mb-6" />
        <form
          noValidate
          autoComplete="off"
          id="reset-email-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FieldInput
            labelProps={{ children: t('settings.newEmailAddress') }}
            className="mt-2"
            inputProps={{
              name: 'email',
              inputRef: register,
              autoComplete: 'off',
              readOnly: toggleReadOnly,
              onMouseDown: () => setToggleReadOnly(false),
              onTouchStart: () => setToggleReadOnly(false),
            }}
            error={errors.email?.message}
          />
          <FieldInput
            labelProps={{ children: t('settings.password') }}
            className="mt-6"
            after={
              <button
                type="button"
                className="btn"
                onClick={handleTogglePassword}
              >
                {showPassword ? <HidePassword /> : <ShowPassword />}
              </button>
            }
            inputProps={{
              name: 'password',
              inputRef: register,
              type: showPassword ? 'text' : 'password',
              autoComplete: 'off',
              readOnly: toggleReadOnly,
              onMouseDown: () => setToggleReadOnly(false),
              onTouchStart: () => setToggleReadOnly(false),
            }}
            error={errors.password?.message}
          />
          <div className="flex flex-row items-center justify-end mt-8">
            <Button
              label={t('button.cancel')}
              btnType={ButtonTypesEnums.WHITE_BORDER}
              btnSize={ButtonSizesEnums.XM}
              onClick={handleCloseModal}
              disabled={loading}
            />
            <Button
              className="ml-2"
              type="submit"
              label={t('button.save')}
              btnType={ButtonTypesEnums.GREEN}
              btnSize={ButtonSizesEnums.LG}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default ChangeEmailDialog;

import React, { FC } from 'react';

//components
import { Button } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { PaymentPlanProps } from './PaymentPlan.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  PromoteJobPriceTypesEnums,
} from '../../../enums';
import { generateFormatedDate } from '../../../utils';

const PaymentPlan: FC<PaymentPlanProps> = ({
  subscriptionClicked,
  cancleSubscription,
  paidAt,
}) => {
  const { t } = useL10N();

  return (
    <div className="border flex items-center flex-col  md:flex-row justify-between border-gray-200 p-4 md:px-8 md:py-6 rounded-md mb-12">
      <div className="flex items-center">
        <span className="text-3xl font-medium text-primary">
          {PromoteJobPriceTypesEnums.MONTHLY}
        </span>
        <span className="text-xs text-primary opacit-75 ml-4">
          <p>{t('settings.paymentsMonthly')}</p>
          <p>{t('settings.paymentsBilledMonthly')}</p>
        </span>
      </div>
      <div className="mt-4 md:mt-0">
        {paidAt ? (
          <div className="flex items-center  flex-col  md:flex-row ">
            <p className="text-gray text-sm mr-6 text-center mb-4 md:mb-0">
              {t('settings.paymentsNextInvoice', {
                date: generateFormatedDate(paidAt?.toString()),
              })}
            </p>
            <Button
              label={t('payment.cancleSubscribe')}
              btnSize={ButtonSizesEnums.MD}
              btnType={ButtonTypesEnums.RED}
              onClick={cancleSubscription}
            />
          </div>
        ) : (
          <Button
            label={t('payment.subscribe')}
            btnSize={ButtonSizesEnums['2XL']}
            btnType={ButtonTypesEnums.GREEN}
            onClick={subscriptionClicked}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentPlan;

import React, { FC, memo, useContext, useEffect } from 'react';

// classnames
import cx from 'classnames';

// components
import { Dot, ThreeDots } from '../../../atoms';
import { Title, DeleteDropdown, DefaultDropdown } from '../../';

// hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

// gql
import { useStageDeleteMutation, useStageUpdateMutation } from '../../../../gql/gen/graphql';

// contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

// enums
import { DotTypesEnums, ToastrTypesEnums } from '../../../../enums';

// utils
import { gqlVar } from '../../../../utils';

// types
import { ManageStageItemProps } from './ManageStageItem.types';
import ManageStageActions from './ManageStageActions';

const ManageStageItem: FC<ManageStageItemProps> = ({ stage, orderId, showSwitch, onEditClick }) => {
  // hooks
  const { t } = useL10N();
  const { xl, width } = useWindowSize();
  const toast = useContext(toastrContext);

  // const
  const { id, name, color, order } = stage;

  // graphql hooks
  const [deleteStage] = useStageDeleteMutation({
    onError: () => {
      toast?.add(ToastrTypesEnums.DANGER, t('manageStage.deletedError'));
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('manageStage.deletedSuccessfully'));
    },
  });

  const [updateStage] = useStageUpdateMutation();

  useEffect(() => {
    if (orderId !== stage?.order) {
      updateStage(gqlVar({ id, set: { order: orderId } }));
    }
  }, [updateStage, orderId, stage, id, order]);

  // handlers
  const handleEditClick = () => onEditClick(stage);
  const handleOnDeleteConfirm = () => deleteStage(gqlVar({ id }));

  return (
    <div
      className={cx(
        width && width > 768
          ? 'template__item flex justify-between items-center'
          : 'pb-6 pr-3 w-full mt-4'
      )}
    >
      <div className="template__item__body">
        <div className="template__item__body--header">
          <span className="template__item__body--order">{orderId}</span>
          <Dot type={DotTypesEnums.DARK_GRAY} style={{ backgroundColor: color || '' }} />
          <Title value={name || ''} />
          {!xl && (
            <div className="md:absolute template__item__body--header__dots">
              <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
                <DeleteDropdown onDeleteConfirm={handleOnDeleteConfirm} />
              </DefaultDropdown>
            </div>
          )}
        </div>

        {!xl && (
          <ManageStageActions
            stage={stage}
            showSwitch={showSwitch}
            handleEditClick={handleEditClick}
          />
        )}
      </div>

      {xl && (
        <ManageStageActions stage={stage} showSwitch={showSwitch} handleEditClick={handleEditClick}>
          <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
            <DeleteDropdown onDeleteConfirm={handleOnDeleteConfirm} />
          </DefaultDropdown>
        </ManageStageActions>
      )}
    </div>
  );
};

export default memo(ManageStageItem);

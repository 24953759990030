import React, { FC } from 'react';

//components
import { CustomLink } from '../../atoms';

//types
import { NavItemProps } from './NavItem.types';

const NavItem: FC<NavItemProps> = ({ Icon, label, to, match, onClick }) => (
  <div className="nav-item">
    <CustomLink
      to={to}
      match={match}
      onClick={onClick}
      before={Icon}
      label={label}
      borderB={false}
      className="nav-item--link"
    />
  </div>
);

export default NavItem;

import { object, string, boolean, number, mixed } from 'yup';

const supportedFileFormats = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const fileSize = 1024 * 1024 * 50;

export const JobFormSchema = ({ t }) =>
  object().shape({
    title: string().trim().required().label(t('shared.forms.validation.title')),
    description: string().trim().required().label(t('job.formValidation.jobDescription')),
    task: string().trim().required().label(t('job.formValidation.jobTasks')),
    type: object({})
      .shape({
        label: string().required().nullable().label(t('job.formValidation.jobType')),
      })
      .nullable()
      .required()
      .label(t('job.formValidation.jobType')),
    location: string().label(t('shared.forms.validation.location')),
    deadline: string().required().label(t('job.formValidation.deadline')),
    subscriber_notified_at: boolean().required().label(t('job.formValidation.subscriber')),
    showOnDashboard: boolean().label(t('job.formValidation.showOnDashboard')),
    salary_from: number()
      .integer(t('shared.forms.validation.invalidNumber'))
      .typeError(t('shared.forms.validation.invalidNumber'))
      .min(0, t('shared.forms.validation.numberMinValue'))
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
      .label(t('job.formValidation.salaryFrom')),
    salary_to: number()
      .integer(t('shared.forms.validation.invalidNumber'))
      .typeError(t('shared.forms.validation.invalidNumber'))
      .min(0, t('shared.forms.validation.numberMinValue'))
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
      .label(t('job.formValidation.salaryTo')),
    file: mixed()
      .test('fileFormat', t('shared.forms.validation.fileFormatInvalid'), (value) => {
        if (value[0]) {
          if (supportedFileFormats.includes(value[0].type)) {
            return value[0].size <= fileSize;
          } else return false;
        } else return true;
      })
      .label(t('shared.forms.validation.thumbnail')),
  });

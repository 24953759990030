import React from 'react';

import { Svg } from '../../../../atoms';

const Email = () => (
  <Svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={0.6} fill="#778CA3">
      <path d="M.003 1.884L8 5.882l7.997-3.998A2 2 0 0014 0H2A2 2 0 00.003 1.884z" />
      <path d="M16 4.118l-8 4-8-4V10a2 2 0 002 2h12a2 2 0 002-2V4.118z" />
    </g>
  </Svg>
);

export default Email;

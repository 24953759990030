import React, { FC, useCallback, useState } from 'react';

//components
import {
  Button,
  ContentLoading,
  NewTeamMember,
  TeamMemberEmpty,
  NewTemplate,
} from '../../../../atoms';
import {
  TeamMemberItem,
  TeamMemberForm,
  CustomModal,
  TeamMembersEmpty,
} from '../../../../molecules';

//hooks
import useWindowSize from '../../../../../hooks/useWindowSize';
import useModal from '../../../../../hooks/useModal';
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import { useTeamMembersSubscription } from '../../../../../gql/gen/graphql';
import useCompanyUser from '../../../../../gql/hooks/useCompanyUser';

//types
import { TeamMemberModel } from '../../../../../interfaces/models';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ModalTypesEnums,
  UserRoleEnums,
  ContentLoadingEnums,
} from '../../../../../enums';
export interface TeamMembersListProps {
  id?: number;
}

const TeamMembersList: FC<TeamMembersListProps> = ({ id }) => {
  const [member, setMember] = useState<TeamMemberModel | null>(null);
  const { xl } = useWindowSize();
  const { t } = useL10N();
  const { company_user } = useCompanyUser();

  const { isOpen, onClick, closeModal } = useModal();

  const { data: { company_users = [] } = {}, loading } = useTeamMembersSubscription({
    variables: { id: id },
  });

  const handleOnEditClick = (item: TeamMemberModel) => {
    item && setMember(item);
    onClick();
  };

  const handleCloseModal = useCallback(() => {
    setMember(null);
    closeModal();
  }, [closeModal]);

  return (
    <>
      <CustomModal
        open={isOpen}
        closeModal={handleCloseModal}
        title={member ? t('settings.editTeamMemeber') : t('settings.addTeamMemeber')}
        type={ModalTypesEnums.MD}
      >
        <TeamMemberForm closeModal={handleCloseModal} member={member} />
      </CustomModal>
      <section className="team__member">
        <div className="content__header">
          <span className="content__header--title">{t('settings.teamMembers')}</span>
          {company_user?.role === UserRoleEnums.ADMIN && (
            <Button
              className="content__header--btn"
              label={xl ? t('button.addTeamMember') : t('button.add')}
              btnSize={!xl ? ButtonSizesEnums.XS : ButtonSizesEnums.MD}
              btnType={ButtonTypesEnums.GREEN}
              before={<NewTeamMember />}
              onClick={onClick}
            />
          )}
        </div>
        {loading && id ? (
          <ContentLoading size={ContentLoadingEnums.LG} className="mt-12" />
        ) : (
          <div className="team__member--body">
            {company_users?.length ? (
              company_users.map((item, index) => (
                <TeamMemberItem
                  item={item}
                  key={item.id}
                  membersCount={company_users?.length || 0}
                  position={index + 1}
                  onEditClick={handleOnEditClick}
                  isAdmin={company_user?.role === UserRoleEnums.ADMIN}
                />
              ))
            ) : (
              <TeamMembersEmpty
                title={t('settings.teamMemberEmptyTitle')}
                icon={<TeamMemberEmpty />}
              >
                <Button
                  className="content__header--btn"
                  label={t('button.addTeamMember')}
                  btnSize={ButtonSizesEnums.LG}
                  btnType={ButtonTypesEnums.GREEN}
                  before={<NewTemplate />}
                  onClick={onClick}
                />
              </TeamMembersEmpty>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default TeamMembersList;

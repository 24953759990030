import React, { FC } from 'react';

//components
import { Button } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { DeleteModalProps } from './DeleteModal.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

const DeleteModal: FC<DeleteModalProps> = ({
  closeModal,
  title,
  onComplete,
  loading,
}) => {
  const { t } = useL10N();

  const onConfirmDelete = () => {
    onComplete();
  };

  return (
    <div className="job__modal">
      <p className="job__modal--title">{title}</p>
      <div className="job__modal--buttons mt-8">
        <Button
          label={t('button.cancel')}
          btnType={ButtonTypesEnums.WHITE_BORDER}
          btnSize={ButtonSizesEnums.XM}
          onClick={closeModal}
        />
        <Button
          className="ml-2"
          label={t('button.delete')}
          onClick={onConfirmDelete}
          btnType={ButtonTypesEnums.RED}
          btnSize={ButtonSizesEnums.LG}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default DeleteModal;

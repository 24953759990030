import React, { FC } from 'react';
import { Link } from 'react-router-dom';

//compoennts
import { BigBag, Button, Edit } from '../../atoms';
import { Title, Status } from '..';

//hooks
import useWindowSize from '../../../hooks/useWindowSize';
import { useL10N } from '../../../hooks/useL10N';

//types
import { JobItemProps } from './JobItem.types';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  VacaniesStatusTypesEnums,
} from '../../../enums';

const JobItem: FC<JobItemProps> = ({ vacancy }) => {
  const { xl } = useWindowSize();
  const { t } = useL10N();

  return (
    <div className="jobs__item">
      <Link to={`job/${vacancy.id}/all`} className="jobs__item--avatar">
        <BigBag />
      </Link>
      <div className="jobs__item--header">
        <div className="jobs__item--header--content">
          <Link to={`job/${vacancy.id}/all`}>
            <Title value={vacancy.title} />
          </Link>
          {vacancy.status === VacaniesStatusTypesEnums.DRAFT && (
            <Status value={t('dashboard.draftJobs')} className="mt-2 md:mt-0" />
          )}
        </div>
        {!xl && (
          <Link to={`job/${vacancy.id}/edit`}>
            <Button
              className="mr-2 mt-4 text-primary"
              label={t('dashboard.editJob')}
              btnType={ButtonTypesEnums.DEFAULT}
              before={<Edit />}
            />
          </Link>
        )}
      </div>
      {xl && (
        <div className="jobs__item--buttons">
          <Link to={`job/${vacancy.id}/edit`}>
            <Button
              className="mr-2"
              label={t('dashboard.editJob')}
              btnSize={ButtonSizesEnums.SM}
              btnType={ButtonTypesEnums.WHITE_BORDER}
              before={<Edit />}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default JobItem;

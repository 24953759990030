import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

//components
import { NotificationSpan, Folder, Published, Draft } from '../../atoms';
import { Tabs } from '../../molecules';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { JobsTabsProps } from './JobsTabs.types';

//utils
import { parseQueryString, serialize } from '../../../utils';

const JobsTabs: FC<JobsTabsProps> = ({ meta }) => {
  const { search } = useLocation();
  const { t } = useL10N();
  const queryParams = parseQueryString(search);
  const serializeQuery = serialize(queryParams);

  return (
    <Tabs
      tabs={[
        {
          to: `/jobs/all${serializeQuery}`,
          match: '/jobs/all',
          label: t('jobs.all'),
          before: <Folder />,
          after: <NotificationSpan value={meta.all} showWithValue />,
        },
        {
          to: `/jobs/published${serializeQuery}`,
          match: `/jobs/published`,
          label: t('jobs.published'),
          before: <Published />,
          after: <NotificationSpan value={meta.published} showWithValue />,
        },
        {
          to: `/jobs/draft${serializeQuery}`,
          match: `/jobs/draft`,
          label: t('jobs.draft'),
          before: <Draft />,
          after: <NotificationSpan value={meta.draft} showWithValue />,
        },
      ]}
    />
  );
};

export default JobsTabs;

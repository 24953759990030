import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Dashboard: FC<IconProps> = ({ active = false, color = 'white' }) => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={active ? '1' : '0.4'}
      d="M1.5 9L3.16667 7.33333M3.16667 7.33333L9 1.5L14.8333 7.33333M3.16667 7.33333V15.6667C3.16667 15.8877 3.25446 16.0996 3.41074 16.2559C3.56702 16.4122 3.77899 16.5 4 16.5H6.5M14.8333 7.33333L16.5 9M14.8333 7.33333V15.6667C14.8333 15.8877 14.7455 16.0996 14.5893 16.2559C14.433 16.4122 14.221 16.5 14 16.5H11.5M6.5 16.5C6.72101 16.5 6.93298 16.4122 7.08926 16.2559C7.24554 16.0996 7.33333 15.8877 7.33333 15.6667V12.3333C7.33333 12.1123 7.42113 11.9004 7.57741 11.7441C7.73369 11.5878 7.94565 11.5 8.16667 11.5H9.83333C10.0543 11.5 10.2663 11.5878 10.4226 11.7441C10.5789 11.9004 10.6667 12.1123 10.6667 12.3333V15.6667C10.6667 15.8877 10.7545 16.0996 10.9107 16.2559C11.067 16.4122 11.279 16.5 11.5 16.5M6.5 16.5H11.5"
      stroke={active ? '#08D4A5' : color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Dashboard;

import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import './styles/main.scss';

import Routes from './routes/Routes';
import AuthRoutes from './routes/AuthRoutes';

import EmailResetConfirm from './pages/auth/EmailResetConfirm';
import EmailResetRevert from './pages/auth/EmailResetRevert';

import { ToastrProvider } from './contexts/ToastrContexts';
import { PaymentProvider } from './contexts/PaymentContext';

import { AuthProvider } from './contexts/AuthContext';
import { history } from './utils';

function App() {
  return (
    <Router history={history}>
      <AuthProvider>
        <PaymentProvider>
          <ToastrProvider>
            <Suspense fallback={<div>loading...</div>}>
              <Switch>
                <Route exact path="/auth/email/revert/reset/:token" component={EmailResetRevert} />
                <Route exact path="/auth/email/reset/:token" component={EmailResetConfirm} />
                <Route
                  exact
                  path={['/auth/(login|register|password/forgot)', '/auth/password/reset/:token']}
                  component={AuthRoutes}
                />
                <Route exact path="/terms" component={() => <>Terms</>} />
                <Route exact path="/privacy" component={() => <>Privacy</>} />
                <Route exact path="*" component={Routes} />
              </Switch>
            </Suspense>
          </ToastrProvider>
        </PaymentProvider>{' '}
      </AuthProvider>
    </Router>
  );
}

export default App;

export const industries = [
  { label: 'Accounting' },
  { label: 'Airlines/Aviation' },
  { label: 'Alternative Dispute Resolution' },
  { label: 'Alternative Medicine' },
  { label: 'Animation' },
  { label: 'Apparel & Fashion' },
  { label: 'Architecture & Planning' },
  { label: 'Arts and Crafts' },
  { label: 'Automotive' },
  { label: 'Aviation & Aerospace' },
  { label: 'Banking' },
  { label: 'Biotechnology' },
  { label: 'Broadcast Media' },
  { label: 'Building Materials' },
  { label: 'Business Supplies and Equipment' },
  { label: 'Capital Markets' },
  { label: 'Chemicals' },
  { label: 'Civic & Social Organization' },
  { label: 'Civil Engineering' },
  { label: 'Commercial Real Estate' },
  { label: 'Computer & Network Security' },
  { label: 'Computer Games' },
  { label: 'Computer Hardware' },
  { label: 'Computer Networking' },
  { label: 'Computer Software' },
  { label: 'Construction' },
  { label: 'Consumer Electronics' },
  { label: 'Consumer Goods' },
  { label: 'Consumer Services' },
  { label: 'Cosmetics' },
  { label: 'Dairy' },
  { label: 'Defense & Space' },
  { label: 'Design' },
  { label: 'Education Management' },
  { label: 'E-Learning' },
  { label: 'Electrical/Electronic Manufacturing' },
  { label: 'Entertainment' },
  { label: 'Environmental Services' },
  { label: 'Events Services' },
  { label: 'Executive Office' },
  { label: 'Facilities Services' },
  { label: 'Farming' },
  { label: 'Financial Services' },
  { label: 'Art' },
  { label: 'Fishery' },
  { label: 'Food & Beverages' },
  { label: 'Food Production' },
  { label: 'Fund-Raising' },
  { label: 'Furniture' },
  { label: 'Gambling & Casinos' },
  { label: 'Glass, Ceramics & Concrete' },
  { label: 'Government Administration' },
  { label: 'Government Relations' },
  { label: 'Graphic Design' },
  { label: 'Health, Wellness and Fitness' },
  { label: 'Higher Education' },
  { label: 'Hospital & Health Care' },
  { label: 'Hospitality' },
  { label: 'Human Resources' },
  { label: 'Import and Export' },
  { label: 'Individual & Family Services' },
  { label: 'Industrial Automation' },
  { label: 'Information Services' },
  { label: 'Information Technology and Services' },
  { label: 'Insurance' },
  { label: 'International Affairs' },
  { label: 'International Trade and Development' },
  { label: 'Internet' },
  { label: 'Investment Banking' },
  { label: 'Investment Management' },
  { label: 'Judiciary' },
  { label: 'Law Enforcement' },
  { label: 'Law Practice' },
  { label: 'Legal Services' },
  { label: 'Legislative Office' },
  { label: 'Leisure, Travel & Tourism' },
  { label: 'Libraries' },
  { label: 'Logistics and Supply Chain' },
  { label: 'Luxury Goods & Jewelry' },
  { label: 'Machinery' },
  { label: 'Management Consulting' },
  { label: 'Maritime' },
  { label: 'Market Research' },
  { label: 'Marketing and Advertising' },
  { label: 'Mechanical or Industrial Engineering' },
  { label: 'Media Production' },
  { label: 'Medical Devices' },
  { label: 'Medical Practice' },
  { label: 'Mental Health Care' },
  { label: 'Military' },
  { label: 'Mining & Metals' },
  { label: 'Mobile Games' },
  { label: 'Motion Pictures and Film' },
  { label: 'Museums and Institutions' },
  { label: 'Music' },
  { label: 'Nanotechnology' },
  { label: 'Newspapers' },
  { label: 'Non-Profit Organization Management' },
  { label: 'Oil & Energy' },
  { label: 'Online Media' },
  { label: 'Outsourcing/Offshoring' },
  { label: 'Package/Freight Delivery' },
  { label: 'Packaging and Containers' },
  { label: 'Paper & Forest Products' },
  { label: 'Performing Arts' },
  { label: 'Pharmaceuticals' },
  { label: 'Philanthropy' },
  { label: 'Photography' },
  { label: 'Plastics' },
  { label: 'Political Organization' },
  { label: 'Primary/Secondary Education' },
  { label: 'Printing' },
  { label: 'Professional Training & Coaching' },
  { label: 'Program Development' },
  { label: 'Public Policy' },
  { label: 'Public Relations and Communications' },
  { label: 'Public Safety' },
  { label: 'Publishing' },
  { label: 'Railroad Manufacture' },
  { label: 'Ranching' },
  { label: 'Real Estate' },
  { label: 'Recreational Facilities and Services' },
  { label: 'Religious Institutions' },
  { label: 'Renewables & Environment' },
  { label: 'Research' },
  { label: 'Restaurants' },
  { label: 'Retail' },
  { label: 'Security and Investigations' },
  { label: 'Semiconductors' },
  { label: 'Shipbuilding' },
  { label: 'Sporting Goods' },
  { label: 'Sports' },
  { label: 'Staffing and Recruiting' },
  { label: 'Supermarkets' },
  { label: 'Telecommunications' },
  { label: 'Textiles' },
  { label: 'Think Tanks' },
  { label: 'Tobacco' },
  { label: 'Translation and Localization' },
  { label: 'Transportation/Trucking/Railroad' },
  { label: 'Utilities' },
  { label: 'Venture Capital & Private Equity' },
  { label: 'Veterinary' },
  { label: 'Warehousing' },
  { label: 'Wholesale' },
  { label: 'Wine and Spirits' },
  { label: 'Wireless' },
  { label: 'Writing and Editing' },
];

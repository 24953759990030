import React, { FC } from 'react';
import cx from 'classnames';

//icons
import { Close } from '../../assets/icons';

//types
import { PillProps } from './Pill.types';

const Pill: FC<PillProps> = ({
  pill,
  pillRef,
  Icon,
  className = '',
  ...props
}) => (
  <div className={cx('pill', { [className]: className })}>
    <span className="pill__name">{pill.name}</span>
    <button
      {...props}
      id={pill.id.toString()}
      type="button"
      className="pill__btn"
      ref={pillRef}
    >
      {Icon || <Close type={2} />}
    </button>
  </div>
);

export default Pill;

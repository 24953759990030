import React, { FC } from 'react';
import cx from 'classnames';

//components
import { InputErrorMessage, Label, Textarea } from '../../../atoms';

//types
import { FieldTextareaProps } from './FieldTextarea.types';

const FieldTextarea: FC<FieldTextareaProps> = ({
  labelProps,
  textareaProps,
  error = false,
}) => (
    <div className="fieldTextarea">
      {labelProps?.children && (
        <Label
          {...labelProps}
          htmlFor={labelProps?.htmlFor ? labelProps.htmlFor : textareaProps.name}
          error={!!error}
          className={cx('fieldTextarea__label', `${labelProps?.className}`)}
        />
      )}
      <Textarea
        {...textareaProps}
        error={!!error}
        className={cx(`fieldTextarea__textarea`, `${textareaProps.className}`)}
      />

      <InputErrorMessage error={error} className="mt-1" />
    </div>
);

export default FieldTextarea;

import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

//components
import { Button, ThreeDots, Share, Edit, Eye, Dropdown } from '../../atoms';
import { DefaultDropdown, JobHeaderDropdown, JobShareSubDropdown } from '../../molecules';

//hooks
import { useL10N } from '../../../hooks/useL10N';
import useDropdown from '../../../hooks/useDropdown';

// config
import { DISCOVR_PAGE_URL } from '../../../config';

//types
import { JobHeaderActionsProps } from './JobHeaderActions.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums, ThreeDotsEnums } from '../../../enums';

const JobHeaderActions: FC<JobHeaderActionsProps> = ({ vacancyId, vacancySlug }) => {
  const { t } = useL10N();

  const { open, onMenuClick, closeMenu: closeSubDropdown } = useDropdown();

  const onSubMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onMenuClick();
  };

  return (
    <>
      <div className="job__header__content__btns">
        <Dropdown
          className={cx('default__dropdown', 'default__subDropdownShare')}
          open={open}
          closeIcon={false}
          withOpacity={false}
          closeMenu={closeSubDropdown}
          trigger={
            <Button
              label={t('jobs.share')}
              btnSize={ButtonSizesEnums.MD}
              btnType={ButtonTypesEnums.GRAY}
              before={<Share />}
              className="mr-3"
              onClick={onSubMenuClick}
            />
          }
        >
          <JobShareSubDropdown closeMenu={closeSubDropdown} url={`/vacancy/view/${vacancySlug}`} />
        </Dropdown>
        <Link to={`/job/${vacancyId}/edit`}>
          <Button
            label={t('jobs.edit')}
            btnSize={ButtonSizesEnums.MD}
            btnType={ButtonTypesEnums.GRAY}
            before={<Edit type={2} />}
            className="mr-3"
            onClick={() => null}
          />
        </Link>
        <a
          rel="noopener noreferrer"
          aria-label={vacancySlug.split('-').join(' ')}
          href={`${DISCOVR_PAGE_URL}/vacancy/view/${vacancySlug}`}
        >
          <Button
            label={t('jobs.view')}
            btnSize={ButtonSizesEnums.MD}
            btnType={ButtonTypesEnums.GREEN}
            before={<Eye />}
            onClick={() => null}
          />
        </a>
      </div>
      <div className="job__header__content__dots">
        <DefaultDropdown
          triggerProps={{
            after: <ThreeDots type={ThreeDotsEnums.WHITE} />,
          }}
        >
          <JobHeaderDropdown vacancySlug={vacancySlug} vacancyId={vacancyId} />
        </DefaultDropdown>
      </div>
    </>
  );
};

export default JobHeaderActions;

import React from 'react';

import { Svg } from '../../../../atoms';

const ApiTokens = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.6}
      d="M8.167 16.334l3.666-14.667M15.5 5.334L19.167 9 15.5 12.667m-11 0L.833 9 4.5 5.334"
      stroke="#778CA3"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ApiTokens;

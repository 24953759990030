import React, { FC } from 'react';
import cx from 'classnames';

//types
import { InputErrorMessageProps } from './InputErrorMessage.types';

const InputErrorMessage: FC<InputErrorMessageProps> = ({
  error,
  className,
}) => {
  return typeof error === 'string' ? (
    <p className={cx('error--input', { [`${className}`]: className })}>
      {error}
    </p>
  ) : null;
};

export default InputErrorMessage;

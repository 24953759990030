import React, { FC, ReactElement } from 'react';
import cx from 'classnames';

// tooltip
import ReactTooltip from 'react-tooltip';

// hooks
import { useL10N } from '../../../../hooks/useL10N';
import useWindowSize from '../../../../hooks/useWindowSize';

// components
import { Label, Input } from '../../../atoms';

// types
import { ToggleProps } from './Toggle.types';

const ToggleStage: FC<ToggleProps> = ({ name, inputProps, className }): ReactElement => {
  // hooks
  const { t } = useL10N();
  const { xl } = useWindowSize();

  // destructing inputProps
  const { id, disabled } = inputProps;

  return (
    <Label
      htmlFor={id}
      className={cx('toggle', xl ? 'flex-row' : 'flex-row-reverse mb-6 opacity-50 mx-8', {
        [`toggle__disabled`]: !!disabled,
        className,
      })}
    >
      <p className="mx-3 toggle__text">{name}</p>
      <div data-tip data-for={disabled && 'stageTooltip'} className="relative mr-3 toggle__button">
        {disabled && (
          <ReactTooltip
            id="stageTooltip"
            multiline={true}
            className="w-56"
            place="bottom"
            effect="solid"
          >
            <p className="text-sm text-center text-white">{t('tooltip.stages')}</p>
          </ReactTooltip>
        )}
        <Input type="checkbox" className="toggle__input" {...inputProps} />
        <div className="z-50 toggle__line" />
        <div className="toggle__dot" />
      </div>
    </Label>
  );
};

export default ToggleStage;

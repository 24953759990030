import { useState, useRef, useEffect } from 'react';
import { clamp, distance } from 'popmotion';
import { arrayMoveImmutable } from 'array-move';
import { StageFieldsFragment } from '../gql/gen/graphql';

interface OffsetPosition {
  top: number;
  height: number;
}

export const usePositionReorder = (list: StageFieldsFragment[]) => {
  const [order, setOrder] = useState<StageFieldsFragment[]>(list);

  useEffect(() => {
    if (list.length !== order?.length) {
      setOrder(list);
    }
  }, [list, order]);

  // We need to collect an array of height and position data for all of this component's
  // `Item` children, so we can later us that in calculations to decide when a dragging
  // `Item` should swap places with its siblings.
  const positions = useRef<OffsetPosition[]>([]).current;
  const updatePosition = (id: number, offset: OffsetPosition) =>
    (positions[id] = offset);

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const updateOrder = (id: number, offsetDragY: number) => {
    const targetIndex = findIndex(id, offsetDragY, positions);
    if (targetIndex !== id) {
      setOrder(arrayMoveImmutable(order, id, targetIndex));
    }
  };

  return { updatedList: order, updatePosition, updateOrder };
};

export const findIndex = (
  id: number,
  offsetY: number,
  positions: OffsetPosition[]
) => {
  const { top, height } = positions[id];
  let target = id;

  const item = offsetY > 0 ? positions[id + 1] : positions[id - 1];

  // If moving down
  if (offsetY > 0) {
    if (item === undefined) return id;

    const swapOffset = distance(top + height, item.top + item.height / 2);
    if (offsetY > swapOffset) target = id + 1;

    // If moving up
  } else if (offsetY < 0) {
    if (item === undefined) return id;

    const prevBottom = item.top + item.height;
    const swapOffset = distance(top, prevBottom - item.height / 2);
    if (offsetY < -swapOffset) target = id - 1;
  }

  return clamp(0, positions?.length, target);
};

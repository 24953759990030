import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

//components
import { LayoutContainer } from '../../../templates';
import { Breadcrumbs, Settings } from '../../../atoms';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//types
import { BreadcrumbsPropsItem } from '../../../atoms/ui/navigation/Breadcrumbs.types';

//enums
import { SettingsHeaderTypes } from './SettingsHeader.enum';

const SettingsHeader = () => {
  const { tab } = useParams<{ tab?: string }>();
  const { xl } = useWindowSize();
  const { t } = useL10N();

  const [breadcrumbTranslations] = useState({
    title: t('settings.title'),
    myAccount: t('settings.myAccount'),
    teamMember: t('settings.teamMembers'),
    messageTemplate: t('settings.messagesTemplates'),
    manageStages: t('settings.manageStages'),
    apiTokens: t('settings.apiTokens'),
  });
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbsPropsItem>>(
    []
  );

  useEffect(() => {
    let _breadcrumbs: BreadcrumbsPropsItem[] = [
      { name: breadcrumbTranslations.title, to: '/settings/account' },
    ];
    if (tab !== undefined) {
      if (tab === SettingsHeaderTypes.TOKEN) {
        _breadcrumbs.push({ name: breadcrumbTranslations.apiTokens });
      } else if (tab === SettingsHeaderTypes.ACCOUNT) {
        _breadcrumbs.push({ name: breadcrumbTranslations.myAccount });
      } else if (tab === SettingsHeaderTypes.MESSAGES) {
        _breadcrumbs.push({ name: breadcrumbTranslations.messageTemplate });
      } else if (tab === SettingsHeaderTypes.STAGES) {
        _breadcrumbs.push({ name: breadcrumbTranslations.manageStages });
      } else {
        _breadcrumbs.push({ name: breadcrumbTranslations.apiTokens });
      }
    }
    setBreadcrumbs(_breadcrumbs);
  }, [tab, xl, breadcrumbTranslations]);

  return (
    <LayoutContainer>
      <div className="settings__header">
        {!xl && (
          <Breadcrumbs withArrow={breadcrumbs.length >= 2} list={breadcrumbs} />
        )}
        {xl && (
          <div className="settings__header--content">
            <svg
              width={6}
              height={10}
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity={0.6}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.707.293a1 1 0 010 1.414L2.414 5l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                fill="#fff"
              />
            </svg>
            <Link className="settings__header--content--title" to="/dashboard">
              {t('button.backToDashboard')}
            </Link>
          </div>
        )}
        <div className="settings__header--title">
          <Settings />
          {t('settings.title')}
        </div>
      </div>
    </LayoutContainer>
  );
};

export default SettingsHeader;

import React from 'react';

import { Svg } from '../../../atoms';

const Send = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.39978 10.8L3.97678 12.752L12.9768 8.75199L5.99978 14.251V21C5.99999 21.205 6.0632 21.405 6.18084 21.5728C6.29848 21.7407 6.46487 21.8684 6.65748 21.9386C6.85009 22.0087 7.05962 22.018 7.25768 21.9651C7.45573 21.9123 7.63276 21.7998 7.76478 21.643L10.8948 17.924L17.3998 22.8C17.5347 22.9015 17.6931 22.9671 17.8602 22.9908C18.0274 23.0146 18.1978 22.9956 18.3556 22.9357C18.5135 22.8758 18.6536 22.7769 18.7629 22.6483C18.8722 22.5196 18.9472 22.3654 18.9808 22.2L22.9808 2.19999C23.0162 2.02331 23.0032 1.84035 22.9432 1.67044C22.8832 1.50053 22.7784 1.34999 22.6399 1.23473C22.5014 1.11948 22.3343 1.04379 22.1563 1.01567C21.9784 0.98755 21.7961 1.00805 21.6288 1.07499L1.62878 9.07499C1.46472 9.14118 1.32115 9.24972 1.21272 9.38952C1.1043 9.52932 1.03491 9.69538 1.01163 9.87076C0.988345 10.0461 1.01201 10.2246 1.0802 10.3878C1.14839 10.551 1.25867 10.6933 1.39978 10.8Z"
      fill="#08D4A5"
    />
  </Svg>
);

export default Send;

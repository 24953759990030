import React from 'react';
import { Route, Switch } from 'react-router-dom';

//components
import { LayoutContainer } from '../../../templates';
import { Inbox, Compose, Reply, CandidateMails, NewMessage } from '..';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';

const MessagesContent = () => {
  const { xl } = useWindowSize();

  return (
    <LayoutContainer className="messages__content">
      <div className="messages__content--body">
        {xl && (
          <div className="messages__box messages__box--left">
            <Inbox />
          </div>
        )}

        <div className="messages__box messages__box--right">
          <div className="messages">
            <Switch>
              {!xl && <Route exact path="/messages/inbox" component={Inbox} />}
              <Route
                exact
                path="/messages/inbox/:tab(new)?"
                component={NewMessage}
              />
              <Route
                exact
                path="/messages/inbox/(compose)?"
                component={Compose}
              />
              <Route
                exact
                path="/messages/inbox/:tab(reply)?"
                component={Reply}
              />
              <Route
                exact
                path="/messages/inbox/:id(\d+)?"
                component={CandidateMails}
              />
            </Switch>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default MessagesContent;

import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

//components
import { Search as SearchIcon } from '../../../atoms';
import { FieldInput } from '../..';
import { MessagesFilterDropdown } from '../../index';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//enums
import { InputTypesEnums } from '../../../../enums';

//utils
import { parseQueryString, serialize } from '../../../../utils';

const Search = () => {
  const {
    push,
    replace,
    location: { search },
  } = useHistory();
  const { t } = useL10N();

  const { query, type, ...restQuery } = parseQueryString(search);
  
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.value.length > 1
      ? replace(serialize({ ...restQuery, type, query: event.target.value }))
      : push(serialize({ ...restQuery, type, query: event.target.value }));

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
      }}
    >
      <div className="ml-3 mr-3">
        <FieldInput
          before={<SearchIcon />}
          after={<MessagesFilterDropdown label={type || 'all'} />}
          inputType={InputTypesEnums.ROUNDED_BORDER}
          inputProps={{
            value: query || '',
            onChange: handleSearchChange,
            name: 'search',
            placeholder: t('messages.searchPlaceholder'),
          }}
        />
      </div>
    </form>
  );
};

export default Search;

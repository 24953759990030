import React from 'react';

import { Svg } from '../../../../atoms';

const MessageTemplate = () => (
  <Svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.6}
      d="M5.417 5.334h9.166-9.166zm0 3.666h3.666-3.666zM10 16.334l-3.667-3.667h-2.75a1.833 1.833 0 01-1.833-1.833V3.5a1.833 1.833 0 011.833-1.833h12.834A1.833 1.833 0 0118.25 3.5v7.334a1.833 1.833 0 01-1.833 1.833h-2.75L10 16.334z"
      stroke="#778CA3"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default MessageTemplate;

import React, { BaseSyntheticEvent, FC } from 'react';
import cx from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Label, Location } from '../../../atoms';
import {
  FieldInput,
  FieldTextarea,
  FileField,
  Toggle,
  ReactSelect,
  Datepicker,
} from '../../../molecules';
import { JobFormHeader } from '..';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//types
import { JobFormProps, JobFormTypes } from './JobForm.types';
import { JobFormSchema } from '../../../../validations';

//enums
import { VacaniesTypesEnums } from '../../../../enums';

//utils
import { mapEnum } from '../../../../utils';
import dayjs from 'dayjs';

const JobForm: FC<JobFormProps> = ({ headerProps, onSubmit: parentSubmit, vacancy }) => {
  const { t } = useL10N();

  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    trigger,
    clearErrors,
    getValues,
    formState: { isDirty },
  } = useForm<JobFormTypes>({
    mode: 'all',
    defaultValues: vacancy,
    resolver: yupResolver(JobFormSchema({ t })),
  });

  const onSubmit = (data: JobFormTypes, event?: BaseSyntheticEvent<Object | any | undefined>) => {
    if (data) parentSubmit(data, event?.nativeEvent.submitter.id);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx('job__form__content', {
        'job__form__content--edit': headerProps.id,
      })}
    >
      <JobFormHeader
        {...headerProps}
        submitCallback={parentSubmit}
        handleSubmit={handleSubmit}
        trigger={trigger}
        isDirty={isDirty}
        error={Object.keys(errors)?.length ? true : false}
        clearErrors={clearErrors}
        data={getValues()}
        jobTitle={watch('title')}
        deadline={watch('deadline')}
        location={watch('location')}
        type={watch('type')}
        salaryFrom={watch('salary_from')}
        salaryTo={watch('salary_to')}
      />
      <div className="job__form__content--white form__left">
        <div className="form__left--input">
          <FieldInput
            labelProps={{ children: t('createJob.title') }}
            inputProps={{
              inputRef: register,
              name: 'title',
              placeholder: t('createJob.titlePlaceholder'),
            }}
            error={errors.title?.message}
          />
        </div>
        <div className="form__left--input">
          <FieldTextarea
            labelProps={{ children: t('createJob.description') }}
            textareaProps={{
              textareaRef: register,
              name: 'description',
              placeholder: t('createJob.descriptionPlaceholder'),
              className: 'h-24',
            }}
            error={errors?.description?.message}
          />
        </div>
        <div className="form__left--input">
          <FieldTextarea
            labelProps={{ children: t('createJob.tasks') }}
            textareaProps={{
              textareaRef: register,
              name: 'task',
              placeholder: t('createJob.tasksPlaceholder'),
              className: 'h-64',
            }}
            error={errors.task?.message}
          />
        </div>
        <div className="form__left--input">
          <FileField
            file={watch('file')}
            file_path={vacancy?.file_path}
            inputProps={{ name: 'file', inputRef: register }}
            labelProps={{
              children: (
                <>
                  {t('createJob.thumbnailTitle')}
                  <span className="font-normal text-secondary">{t('createJob.thumbnailDesc')}</span>
                </>
              ),
            }}
            error={errors.file?.message}
          />
        </div>
      </div>
      <div className="job__form__content--white form__right">
        <div className="form__right--input">
          <FieldInput
            labelProps={{ children: t('job.location') }}
            inputProps={{
              inputRef: register,
              name: 'location',
              placeholder: 'Prishtine, Kosove',
            }}
            after={<Location />}
            error={errors.location?.message}
          />
        </div>
        <div className="form__right--input">
          <Controller
            as={
              <Datepicker
                labelProps={{ children: t('job.deadline') }}
                inputProps={{
                  placeholder: dayjs().format('DD.MM.YYYY').toString(),
                }}
                error={errors.deadline?.message}
              />
            }
            onSetValue={setValue}
            control={control}
            name="deadline"
            defaultValue={''}
          />
        </div>
        <div className="form__right--input">
          <Controller
            as={<ReactSelect />}
            isSearchable={false}
            options={mapEnum(VacaniesTypesEnums, (t) => ({
              label: VacaniesTypesEnums[t],
              value: t,
            }))}
            error={!!errors.type}
            labelProps={{ children: t('job.type') }}
            placeholder={t('createJob.typePlaceholder')}
            name="type"
            control={control}
            defaultValue={''}
          />
          <ErrorMessage
            errors={errors}
            name="type"
            render={({ message }) => {
              return <p className={'error--input mt-3'}>{message}</p>;
            }}
          />
        </div>
        <div className="form__right--input">
          <Label className="mb-2" htmlFor="salary">
            {t('job.salary')}
          </Label>
          <div className="form__right--input--salary">
            <div className="form__right--input--salary--input">
              <FieldInput
                inputProps={{
                  inputRef: register,
                  type: 'text',
                  name: 'salary_from',
                  placeholder: '800€',
                }}
                error={errors.salary_from?.message}
              />
            </div>
            <span className="form__right--input--salary--middleText">to</span>
            <div className="form__right--input--salary--input">
              <FieldInput
                inputProps={{
                  inputRef: register,
                  type: 'text',
                  name: 'salary_to',
                  placeholder: '1200€',
                }}
                error={errors.salary_to?.message}
              />
            </div>
          </div>
        </div>
        <div className="border-b-2 border-gray-100 form__right--input"></div>
        {/* <div className="form__right--input">
          <Toggle
            name={t('createJob.showOnDashboard')}
            inputProps={{
              id: 'showOnDashboard',
              name: `showOnDashboard`,
              inputRef: register,
            }}
          />
        </div> */}
        <div className="form__right--input">
          <Toggle
            name={t('createJob.notifySubscribers')}
            inputProps={{
              id: 'subscriber_notified_at',
              name: `subscriber_notified_at`,
              inputRef: register,
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default JobForm;

import React, { FC, MouseEvent } from 'react';
import cx from 'classnames';

// router
import { useHistory } from 'react-router-dom';

//components
import { Button, Dropdown, Edit, Share, Eye } from '../../atoms';
import JobShareSubDropdown from './JobShareSubDropdown';

//hooks
import { useL10N } from '../../../hooks/useL10N';
import useDropdown from '../../../hooks/useDropdown';

// config
import { DISCOVR_PAGE_URL } from '../../../config';

//types
import { JobHeaderDropdownProps } from '../../../interfaces';

const JobHeaderDropdown: FC<JobHeaderDropdownProps> = ({ closeMenu, vacancyId, vacancySlug }) => {
  const { push } = useHistory();
  const { t } = useL10N();

  const { open, onMenuClick, closeMenu: closeSubDropdown } = useDropdown();

  // handlers
  const onEditClick = () => push(`/job/${vacancyId}/edit`);

  const handleOnSubMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onMenuClick();
  };

  return (
    <div className="default__dropdown__job__header">
      <a
        rel="noopener noreferrer"
        aria-label={vacancySlug.split('-').join(' ')}
        href={`${DISCOVR_PAGE_URL}/vacancy/view/${vacancySlug}`}
        className="default__dropdown__job__header--item"
      >
        <Button
          id="view_job"
          label={t('jobs.viewJob')}
          before={<Eye type={2} />}
          className="default__dropdown__job__header--link"
        />
      </a>
      <Button
        id="edit_job"
        label={t('jobs.editJob')}
        before={<Edit />}
        onClick={onEditClick}
        className="default__dropdown__job__header--item"
      />
      <Button
        id="share_job"
        label={t('jobs.shareJob')}
        before={<Share type={3} />}
        onClick={handleOnSubMenuClick}
        className="default__dropdown__job__header--item"
      />
      <Dropdown
        className={cx('default__dropdown', 'default__subDropdown')}
        open={open}
        closeIcon={false}
        withOpacity={false}
        closeMenu={closeSubDropdown}
        trigger={<Button className="text-primary" onClick={handleOnSubMenuClick} />}
      >
        <JobShareSubDropdown closeMenu={closeMenu} url={`/vacancy/view/${vacancySlug}`} />
      </Dropdown>
    </div>
  );
};

export default JobHeaderDropdown;

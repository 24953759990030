export const findEventDescription = (obj: any, description: string) => {
  const matchEventKey = (eventKey: string) => {
    return Object.keys(obj)
      .map((key, index) =>
        eventKey.includes(`{{${key}}}`) ? Object.values(obj)[index] : ''
      )
      .join('');
  };

  const result = description
    .split(' ')
    .map((word) => matchEventKey(word) || word)
    .join(' ');

  return result;
};

import React, { useEffect } from 'react';

// router
import { useHistory, useParams } from 'react-router-dom';

// gql
import { useConfirmEmailResetMutation } from '../../gql/gen/graphql';

// components
import EmailResetContainer from '../../components/templates/auth/EmailResetContainer';
import { HeaderLayout } from '../../components/templates';

const EmailResetConfirm = () => {
  // router
  const { push } = useHistory();
  const { token } = useParams<{ token: string }>();

  // confirm mutation
  const [confirmEmailReset] = useConfirmEmailResetMutation({
    onError: () => push('/error/404'),
    onCompleted: ({ confirmEmailReset }) => {
      if (confirmEmailReset?.statusIsOk) {
        push('/auth/login');
      } else push('/error/404');
    },
  });

  // revert confirm effect
  useEffect(() => {
    if (token) {
      confirmEmailReset({
        variables: {
          token,
        },
      });
    }
  }, [token, confirmEmailReset]);

  return (
    <HeaderLayout l10nTitleKey="head.titles.emailReset">
      <EmailResetContainer>
        <p className="text-sm text-primary mt-5">Reverting changes...</p>
      </EmailResetContainer>
    </HeaderLayout>
  );
};

export default EmailResetConfirm;

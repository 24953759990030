import { useEffect, useMemo, useState } from 'react';

//enums
import { CreateMessageTypes } from '../enums';

export const useGenerateDraftMessage = (type: string, message, queryParams) => {
  const [clearLocalStorage, toggleClearLocalStorage] = useState(false);

  const localStorageData = useMemo(() => {
    if (localStorage.getItem(type)) {
      return JSON.parse(localStorage.getItem(type) || '');
    }
    return {};
  }, [type]);

  useEffect(() => {
    if (localStorageData?.clearLocalStorage && type) {
      localStorage.removeItem(type);
    }
  }, [localStorageData, type]);

  const generateDefaultValues = () => {
    if (localStorageData?.values?.length) {
      switch (type) {
        case CreateMessageTypes.NEW:
          return (
            localStorageData.values.find((storedMails) => {
              if (
                typeof storedMails?.data?.to !== 'string' &&
                storedMails?.data?.to?.length
              ) {
                return storedMails.data.to.find(
                  (storedReciever) =>
                    storedReciever.value &&
                    message?.to
                      ?.map((reciver) => reciver.value)
                      .indexOf(storedReciever.value) > -1
                );
              }
              return false;
            }) || {
              data: {
                subject: message?.subject,
                to: message?.to,
                message: message?.message,
                template: '',
              },
            }
          );
        case CreateMessageTypes.COMPOSE:
          const composeItem = localStorageData?.values?.find(
            (item) => item?.messageId === queryParams.message_id
          );
          return {
            data: {
              subject: composeItem?.data?.subject || message?.subject,
              to: composeItem?.data?.to || message?.to,
              message: composeItem?.data?.message || message?.message,
              template: composeItem?.data?.template || '',
            },
          };
        case CreateMessageTypes.REPLY:
          const item = localStorageData?.values?.find(
            (item) => item?.messageId === queryParams.message_id
          );
          return {
            data: {
              subject: item?.data?.subject || message?.subject,
              to: item?.data?.to || message?.to,
              message: item?.data?.message || message?.message,
              template: item?.data?.template || '',
            },
          };
        default:
          return {};
      }
    }
    return {};
  };

  return {
    localStorageData: localStorageData,
    returnDefaultValues: generateDefaultValues(),
    toggleLocalStorage: toggleClearLocalStorage,
    clearLocalStorage: clearLocalStorage,
  };
};

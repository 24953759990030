import React from 'react';

import { Svg } from '../../../../atoms';

const Applicants = () => (
  <Svg
    width={84}
    height={67}
    viewBox="0 0 84 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="42" cy="57" rx="42" ry="10" fill="#EDEFF4" />
    <path
      d="M48.6958 56.2695H72.9998V46.2331C72.9997 45.3375 72.7239 44.4635 72.21 43.73C71.6961 42.9964 70.9689 42.4388 70.1271 42.1328L58.6362 37.9553C58.1394 37.7757 57.7009 37.4641 57.3678 37.0541C57.0347 36.6441 56.8195 36.1511 56.7453 35.6281L56.0093 30.4746C58.0378 29.5509 59.7581 28.0636 60.9652 26.1898C62.1723 24.3161 62.8155 22.1349 62.818 19.9059V11.2849C62.8169 9.60172 62.1477 7.98785 60.9576 6.79769C59.7674 5.60753 58.1535 4.9384 56.4704 4.93724L54.8282 1.65143C54.6928 1.3962 54.4716 1.19699 54.2036 1.08895C53.9356 0.980908 53.6381 0.970969 53.3635 1.06089L45.5264 3.85797C44.5996 4.20129 43.743 4.71044 42.9984 5.36051"
      fill="white"
    />
    <path
      d="M48.6958 56.2695H72.9998V46.2331C72.9997 45.3375 72.7239 44.4635 72.21 43.73C71.6961 42.9964 70.9689 42.4388 70.1271 42.1328L58.6362 37.9553C58.1394 37.7757 57.7009 37.4641 57.3678 37.0541C57.0347 36.6441 56.8195 36.1511 56.7453 35.6281L56.0093 30.4746C58.0378 29.5509 59.7581 28.0636 60.9652 26.1898C62.1723 24.3161 62.8155 22.1349 62.818 19.9059V11.2849C62.8169 9.60172 62.1477 7.98785 60.9576 6.79769C59.7674 5.60753 58.1535 4.9384 56.4704 4.93724L54.8282 1.65143C54.6928 1.3962 54.4716 1.19699 54.2036 1.08895C53.9356 0.980908 53.6381 0.970969 53.3635 1.06089L45.5264 3.85797C44.5996 4.20129 43.743 4.71044 42.9984 5.36051"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M37.9185 39.8837L37.3978 36.2357C44.4843 37.8459 48.3635 35.907 48.3635 35.907C45.8245 31.983 44.3231 27.4787 43.9999 22.8161V20.3055C44.0389 17.3026 42.9412 14.396 40.9269 12.1686C38.9125 9.94111 36.1306 8.55757 33.1389 8.29539C31.5489 8.18922 29.9541 8.41075 28.4532 8.94624C26.9524 9.48174 25.5775 10.3198 24.4139 11.4084C23.2502 12.4971 22.3225 13.8132 21.6884 15.275C21.0542 16.7369 20.7271 18.3135 20.7273 19.907V22.8161C20.4041 27.4787 18.9027 31.983 16.3636 35.907C16.3636 35.907 20.2429 37.8459 27.3294 36.2357L26.8087 39.8837C26.7308 40.4325 26.4978 40.9476 26.137 41.3684C25.7763 41.7892 25.3028 42.0982 24.7723 42.2589L15.1098 45.1564C14.2111 45.426 13.4231 45.9781 12.863 46.7309C12.3028 47.4837 12.0002 48.397 12 49.3353V56.2705H52.7272V49.3353C52.727 48.397 52.4244 47.4837 51.8642 46.7309C51.304 45.9781 50.5161 45.426 49.6174 45.1564L39.9621 42.2589C39.4303 42.0993 38.9553 41.7909 38.5932 41.37C38.231 40.9491 37.9969 40.4333 37.9185 39.8837Z"
      fill="white"
      stroke="#ADBAC8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </Svg>
);

export default Applicants;

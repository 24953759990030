
import { ApplicantLinkTypesEnums } from '../enums';
import { validateLinks } from '../utils';

export const splitLinks = (links): any => {
    let socialLinks: any = [];
    let personalLinks: any = [];

    if (links) {
        links.forEach((x) => {
            const link = validateLinks(x.link);
            if (link.type === ApplicantLinkTypesEnums.PERSONAL_LINKS)
                personalLinks.push(link);
            else socialLinks.push(link);
            return link;
        });
    }

    return { socialLinks, personalLinks };
};

import React from 'react';
import { useRouteMatch } from 'react-router-dom';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//components
import { ContentLoading } from '../../../atoms';

//gql
import useApplicant from '../../../../gql/hooks/applicants/useApplicant';

//types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

//utils
import useFindPreviousApplications from '../../../../gql/hooks/applicants/useFindPreviousApplications';

//component
import PreviousApplicationsItem from './PreviousApplicationsItem';

const PreviousApplications = () => {
  // hooks
  const { t } = useL10N();
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const { result } = useApplicant({ id });

  const { applicants, loading } = useFindPreviousApplications({
    applicantId: Number(result?.id),
    applicantEmail: result?.email || '',
  });

  return (
    <div className="previous--applications">
      {applicants.length !== 0 && (
        <h1 className="previous--applications--header">{t('candidate.also.applied.for')}</h1>
      )}

      {loading ? (
        <div className="jobs--content flex justify-center items-center">
          <ContentLoading />
        </div>
      ) : (
        <div className="jobs--content">
          {applicants &&
            applicants.map((applicant, key) => (
              <PreviousApplicationsItem key={key} applicants={applicant}></PreviousApplicationsItem>
            ))}
        </div>
      )}
    </div>
  );
};

export default PreviousApplications;

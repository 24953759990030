import React, { FC } from 'react';

import { LetterSvgProps } from './LetterSvg.types';
import { generateBackgroundColor } from '../../../../utils';

const LetterSvg: FC<LetterSvgProps> = ({ first, second, onClick }) => (
  <div
    className="letter_svg"
    style={{ backgroundColor: generateBackgroundColor(first || '') }}
    onClick={onClick && onClick}
  >
    {first && first.charAt(0).toUpperCase() + second?.charAt(0).toUpperCase()}
  </div>
);

export default LetterSvg;

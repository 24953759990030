import React from 'react';
import { useRouteMatch } from 'react-router-dom';

//components
import { NotificationSpan, Published, LinkEdit } from '../../../atoms';
import { Tabs } from '../../../molecules';

//gql
import useNotes from '../../../../gql/hooks/useNotes';

//types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

const CandidateTabs = () => {
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const { numberOffNotes } = useNotes({ applicantId: +id });

  return (
    <Tabs
      tabs={[
        {
          to: `/candidate/${id}/overview`,
          label: 'Overview',
          before: <Published />,
        },
        {
          to: `/candidate/${id}/notes`,
          label: 'Notes',
          before: <LinkEdit />,
          after: <NotificationSpan value={numberOffNotes} />,
        },
      ]}
    />
  );
};

export default CandidateTabs;

import React from 'react';

import { Svg } from '../../../atoms';

const Arrows = () => (
  <Svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.36569 4.63431C0.861714 5.13829 1.21865 6 1.93137 6H10.0686C10.7814 6 11.1383 5.13829 10.6343 4.63431L6.56569 0.565686C6.25327 0.253266 5.74673 0.253266 5.43431 0.565686L1.36569 4.63431Z"
      fill="#778CA3"
    />
    <path
      d="M5.43431 15.4343C5.74673 15.7467 6.25327 15.7467 6.56569 15.4343L10.6343 11.3657C11.1383 10.8617 10.7814 10 10.0686 10H1.93137C1.21865 10 0.861714 10.8617 1.36569 11.3657L5.43431 15.4343Z"
      fill="#778CA3"
    />
  </Svg>
);

export default Arrows;

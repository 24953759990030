import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Envelope: FC<IconProps> = ({ type = 1 }) => {

  const primary = (
    <Svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3.66663L8.075 8.04996C8.34888 8.23269 8.67075 8.33021 9 8.33021C9.32925 8.33021 9.65112 8.23269 9.925 8.04996L16.5 3.66663M3.16667 12.8333H14.8333C15.2754 12.8333 15.6993 12.6577 16.0118 12.3451C16.3244 12.0326 16.5 11.6087 16.5 11.1666V2.83329C16.5 2.39127 16.3244 1.96734 16.0118 1.65478C15.6993 1.34222 15.2754 1.16663 14.8333 1.16663H3.16667C2.72464 1.16663 2.30072 1.34222 1.98816 1.65478C1.67559 1.96734 1.5 2.39127 1.5 2.83329V11.1666C1.5 11.6087 1.67559 12.0326 1.98816 12.3451C2.30072 12.6577 2.72464 12.8333 3.16667 12.8333Z"
        stroke="#FFA502"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
  const secondary = (
    <Svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99993 10.882L2.00293 6.884C2.03253 6.37444 2.25584 5.89549 2.62711 5.54523C2.99838 5.19497 3.48951 4.99991 3.99993 5H15.9999C16.5103 4.99991 17.0015 5.19497 17.3728 5.54523C17.744 5.89549 17.9673 6.37444 17.9969 6.884L9.99993 10.882ZM10 13.118L18 9.118V15C18 15.5304 17.7893 16.0391 17.4142 16.4142C17.0391 16.7893 16.5304 17 16 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V9.118L10 13.118Z"
          fill="#ADBAC8"
        />
    </Svg>
  );

  return type === 1 ? primary :secondary
};

export default Envelope;

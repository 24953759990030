import React, { FC } from 'react';
import cx from 'classnames';

//types
import { InitialsProps } from './Initials.types';

//enums
import { InitialsTypesEnums } from '../../../../enums';

const Initials: FC<InitialsProps> = ({
  name,
  type = InitialsTypesEnums.LARGE_MD,
}) => {
  const full = name.split(' ').slice(0.2);
  const first = full[0] ? full[0].charAt(0).toUpperCase() : '';
  const seconde = full[1] ? full[1].charAt(0).toUpperCase() : '';

  return (
    <span className={cx('initials', { [`initials--${type}`]: type })}>
      {first && first}
      {seconde && seconde}
    </span>
  );
};

export default Initials;

import React, { useState, useMemo } from 'react';

// motion
import { AnimatePresence } from 'framer-motion';

// atoms
import { Button, NewTemplate, ContentLoading } from '../../../../atoms';

// molecules
import { CustomModal, ManageStageForm, StagesEmpty, StageItem } from '../../../../molecules';

// hooks
import useWindowSize from '../../../../../hooks/useWindowSize';
import { useL10N } from '../../../../../hooks/useL10N';
import useModal from '../../../../../hooks/useModal';
import { usePositionReorder } from '../../../../../hooks/usePositionReorder';

// gql
import useApplicantsStages from '../../../../../gql/hooks/applicants/useApplicantsStages';
import useCompanyUser from '../../../../../gql/hooks/useCompanyUser';
import { StageFieldsFragment } from '../../../../../gql/gen/graphql';

// enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ModalTypesEnums,
  ContentLoadingEnums,
} from '../../../../../enums';

const ManageStages = () => {
  // state
  const [stage, setStage] = useState<StageFieldsFragment>();

  // hooks
  const { t } = useL10N();
  const { xl } = useWindowSize();
  const { isOpen, onClick, closeModal } = useModal();

  // graphql hook
  const { companyId } = useCompanyUser();
  const { result, loading } = useApplicantsStages({ companyId });

  // hooks
  const { updatedList, updatePosition, updateOrder } = usePositionReorder(result);

  // handlers
  const handleEditClick = (stage: StageFieldsFragment) => {
    stage && setStage(stage);
    onClick();
  };

  const handleNewStageItemClick = () => onClick();

  const getStage = (stageId: number) => result && result.find(({ id }) => id === stageId);

  const showSwitch = useMemo(
    () => result?.filter(({ is_shown }) => is_shown)?.length < 2,
    [result]
  );

  // loader
  if (loading) {
    return <ContentLoading size={ContentLoadingEnums.LG} className="my-12" />;
  }

  return (
    <>
      {isOpen && (
        <CustomModal
          open={isOpen}
          closeModal={closeModal}
          type={ModalTypesEnums.MD}
          title={stage ? t('settings.editStage') : t('settings.newStage')}
        >
          <ManageStageForm
            stage={stage}
            closeModal={closeModal}
            company_id={companyId}
            orderId={result?.length + 1 || 1}
          />
        </CustomModal>
      )}
      <section className="managestages">
        <div className="content__header">
          <span className="content__header--title">{t('settings.manageStages')}</span>
          <Button
            before={<NewTemplate />}
            className="content__header--btn"
            btnType={ButtonTypesEnums.GREEN}
            onClick={handleNewStageItemClick}
            label={xl ? t('button.createNewStage') : t('button.create')}
            btnSize={!xl ? ButtonSizesEnums.XS : ButtonSizesEnums.MD}
          />
        </div>

        {!loading && result?.length && updatedList?.length ? (
          <div className="mx-0 managestages__body md:mx-4">
            <ul className="relative w-full p-0 m-0 list-none">
              <AnimatePresence>
                {updatedList.map((stage, index) => (
                  <StageItem
                    key={stage?.name}
                    id={index}
                    orderId={index + 1}
                    showSwitch={showSwitch}
                    stage={getStage(stage?.id) || stage}
                    handleEditClick={handleEditClick}
                    updateOrder={updateOrder}
                    updatePosition={updatePosition}
                  />
                ))}
              </AnimatePresence>
            </ul>
          </div>
        ) : (
          <StagesEmpty handleCreate={handleNewStageItemClick} />
        )}
      </section>
    </>
  );
};

export default ManageStages;

import React, { FC, ReactElement, isValidElement, cloneElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

//types
import { CustomLinkProps } from './CustomLink.types';

const CustomLink: FC<CustomLinkProps> = ({
  before: Before = null,
  after: After = null,
  label,
  to,
  match,
  borderB = true,
  activeColorText = false,
  className,
  ...props
}) => {
  const location = useLocation();
  const _active =
    location.pathname !== '/' && location.pathname.includes(match || to);
  return (
    <Link
      to={to}
      {...props}
      className={`customLink ${
        _active && borderB ? 'customLink--active' : ''
      } ${className}`}
    >
      {isValidElement(Before) && (
        <div className="customLink__before">
          {cloneElement(Before as ReactElement, {
            active: _active,
          })}
        </div>
      )}
      <span
        className={`customLink__label ${cx({
          'text-primary': !activeColorText,
          'customLink__label--active': _active && activeColorText,
        })}`}
      >
        {label}
      </span>
      {isValidElement(After) && (
        <div className="customLink__after">
          {cloneElement(After as ReactElement, {
            active: _active,
          })}
        </div>
      )}
    </Link>
  );
};

export default CustomLink;

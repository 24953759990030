import React, { FC } from 'react';

//components
import {
  PaymentHistoryHeader,
  PaymentHistoryItem,
} from '../../../../molecules';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

// typpes
import { PaymentHistoryProps } from './PaymentHistory.types';

const PaymentHistory: FC<PaymentHistoryProps> = ({ payments }) => {
  const { t } = useL10N();
  return (
    <section className="payments__history">
      <p className="text-sm text-primar mb-4">{t('settings.paymentHistory')}</p>
      <div className="payments__history--holder">
        <PaymentHistoryHeader />
        {payments?.map((item) => (
          <PaymentHistoryItem
            key={item.id}
            item={{
              date: item?.paidAt,
              invoiceNumber: item?.invoiceNumber,
              ammount: item?.amount,
              invoiceUrl: item?.invoiceUrl,
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default PaymentHistory;

import React, { FC } from 'react';
import cx from 'classnames';
import { Link, useHistory } from 'react-router-dom';

//types
import { BreadcrumbsProps } from './Breadcrumbs.types';

const Breadcrumbs: FC<BreadcrumbsProps> = ({ list, withArrow = true }) => {
  const { push } = useHistory();

  const handlePush = (path) => {
    if (path && list?.length <= 1 && withArrow) {
      push(path);
    }
  };

  if (!list.length) return null;
  return (
    <ul className="breadcrumb">
      {list.map(({ to, name }, key) => {
        if (!name) return null;
        return (
          <li
            key={key}
            onClick={() => handlePush(to)}
            className={cx({
              after: list.length <= 1 && withArrow,
              before: list.length >= 1 && withArrow,
              'cursor-pointer': to && list?.length <= 1 && withArrow,
            })}
          >
            {to ? <Link to={to}>{name}</Link> : name}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;

// react
import React, { FC, useState } from 'react';

// hooks
import { useL10N } from '../../../hooks/useL10N';
import useModal from '../../../hooks/useModal';

// atoms
import { Button, ContentLoading, Create, Dot } from '../../atoms';

// graphql
import {
  useUpdateApplicantMutation,
  useInsertActivityApplicantMutation,
} from '../../../gql/gen/graphql';
import useAuth from '../../../gql/hooks/auth/useAuth';
import useApplicantsStages from '../../../gql/hooks/applicants/useApplicantsStages';
import useCompanyUser from '../../../gql/hooks/useCompanyUser';

// utils
import { gqlObject, gqlVar, findEventId } from '../../../utils';

// types
import { CandidateDropdownProps } from '../../../interfaces';
import { ActivityEventTypesEnums, ButtonSizesEnums, ModalTypesEnums } from '../../../enums';

// components
import CustomModal from '../modal/CustomModal';
import ManageStageForm from '../settings/forms/ManageStageForm';

const CandidateDropdown: FC<CandidateDropdownProps> = ({ closeMenu, applicant_id }) => {
  // state
  const [stage, setStage] = useState<string | null>(null);

  // hooks
  const { t } = useL10N();
  const { isOpen, onClick, closeModal } = useModal();

  // graphql hooks
  const auth = useAuth();
  const { companyId } = useCompanyUser();
  const { result, loading } = useApplicantsStages({ companyId });

  const [insertActivityApplicant] = useInsertActivityApplicantMutation({
    onError: (err) => console.error(err),
  });

  const [updateApplicant] = useUpdateApplicantMutation({
    onCompleted: () => {
      insertActivityApplicant(
        gqlObject({
          applicant_id,
          event_id: ActivityEventTypesEnums.MOVE,
          data: { user_name: auth?.user?.name, stage_name: stage },
        })
      );
    },
    onError: (err) => console.error(err),
  });

  // handler
  const handleOnClick = (stageName: string) => (e) => {
    e.stopPropagation();
    const stageId = findEventId(result, stageName);

    setStage(stageName);
    updateApplicant(gqlVar({ id: applicant_id, set: { status_id: stageId } }));

    closeMenu && setTimeout(() => closeMenu(), 300);
  };

  if (loading) {
    return <ContentLoading className="mt-10" />;
  }

  return (
    <div className="default__dropdown__candidates">
      <CustomModal
        open={isOpen}
        closeModal={closeModal}
        title={stage ? t('settings.editStage') : t('settings.newStage')}
        type={ModalTypesEnums.MD}
      >
        <ManageStageForm closeModal={closeModal} company_id={companyId} />
      </CustomModal>
      {!loading && result?.length ? (
        result.map(({ id, name, color }) => (
          <Button
            key={id}
            label={name || ''}
            btnSize={ButtonSizesEnums.MD}
            onClick={handleOnClick(name || '')}
            className="default__dropdown__candidates--item"
            before={<Dot style={{ backgroundColor: color || '' }} />}
          />
        ))
      ) : (
        <Button
          onClick={onClick}
          before={<Create />}
          btnSize={ButtonSizesEnums.MD}
          label={t('button.createFirstStage')}
          className="default__dropdown__candidates--btn"
        />
      )}
    </div>
  );
};

export default CandidateDropdown;

import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const ArrowDown: FC<IconProps> = ({ active = false, className = '' }) => {
  return active ? (
    <Svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 1.5L6.00004 6.16667L1.33337 1.5"
        stroke="#08D4A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  ) : (
    <>
      <Svg
        className={className}
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M10.6666 1L5.99992 5.66667L1.33325 1"
          stroke="#778CA3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </>
  );
};

export default ArrowDown;

import { InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        company_users: {
          merge(_, incoming) {
            return incoming;
          },
        },
        vacancies: {
          merge(_, incoming) {
            return incoming;
          },
        },
        notes: offsetLimitPagination(['type', 'id']),
      },
    },
    Subscription: {
      fields: {
        company_users: {
          merge(_, incoming) {
            return incoming;
          },
        },
        vacancies: {
          merge(_, incoming) {
            return incoming;
          },
        },
        stages: {
          merge(_, incoming) {
            return incoming;
          },
        },
        subscribers_aggregate: {
          merge(_, incoming) {
            return incoming;
          },
        },
        subscribers: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

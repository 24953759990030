import React from 'react';

import { Svg } from '../../../atoms';

const Check = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 11L2.4 8.5L8 13L21.6 2.5L24 5L8 20L0 11Z" fill="#08D4A5" />
  </Svg>
);

export default Check;

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { PasswordMessageProps } from './PasswordMessage.types';

const PasswordMessage: FC<PasswordMessageProps> = ({
  icon: Icon,
  status,
  message,
}) => {
  const { t } = useL10N();

  return (
    <div className="grid gap-x-4 gap-y-2 grid-row-1">
      {Icon}
      <h1 className="not-italic font-medium text-lg leading-7 text-primary">
        {status}
      </h1>
      <p className="col-start-2 not-italic font-medium text-sm leading-4 text-secondary">
        {message}
      </p>
      <Link
        to="/auth/login"
        className="col-start-2 not-italic font-medium text-base leading-5 text-green-300 mt-4"
      >
        {t('button.backToLogin')}
      </Link>
    </div>
  );
};

export default PasswordMessage;

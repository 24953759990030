import React from 'react';

//components
import { NewNote } from '../../../molecules';
import { CandidateNoteList } from '..';

const CandidateNotes = () => (
  <div className="candidate__content__notes">
    <div className="candidate__content__notes--list">
      <CandidateNoteList />
    </div>
    <div className="candidate__content__notes--new__note">
      <NewNote />
    </div>
  </div>
);

export default CandidateNotes;

import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';

//components
import Toastr from '../components/molecules/toastr/Toastr';
import ToastrSuccess from '../components/atoms/assets/icons/ToastrSuccess';
import ToastrDanger from '../components/atoms/assets/icons/ToastrDanger';

//types
import { Nullable } from '../interfaces/types/Nullable.types';
import { ToastrItemProps } from '../components/molecules/toastr/ToastrItem.types';

//enums
import { ToastrTypesEnums } from '../enums';
export interface ToastrContext {
  add: (type: ToastrTypesEnums, msg: string) => void;
}

export const toastrContext = createContext<Nullable<ToastrContext>>(null);
export const ToastrProvider = ({
  children,
}: PropsWithChildren<any>): ReactElement => {
  const [list, setList] = useState<Nullable<ToastrItemProps['toast'][]>>(null);

  const getToastrType = (type: ToastrTypesEnums) => {
    switch (type) {
      case ToastrTypesEnums.SUCCESS:
        return {
          type: ToastrTypesEnums.SUCCESS,
          icon: <ToastrSuccess />,
          classNames: 'toast__success',
        };
      case ToastrTypesEnums.DANGER:
        return {
          type: ToastrTypesEnums.DANGER,
          icon: <ToastrDanger />,
          classNames: 'toast__danger',
        };
    }
  };

  const add = (type: ToastrTypesEnums, msg: string) => {
    setList([
      ...(list ? list : []),
      {
        id: Date.now(),
        description: msg,
        ...getToastrType(type),
      },
    ]);
  };

  return (
    <toastrContext.Provider
      value={{
        add,
      }}
    >
      {children}
      <Toastr
        setToastList={setList}
        toastList={list}
        autoDelete={true}
        dismissTime={3000}
      />
    </toastrContext.Provider>
  );
};

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// components
import { Dot } from '../../../atoms';
import { Tabs } from '../../../molecules';

// gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import useApplicantsStages from '../../../../gql/hooks/applicants/useApplicantsStages';

// utils
import { parseQueryString, serialize } from '../../../../utils';

// typings
import { CustomLinkProps } from '../../../atoms/links/CustomLink.types';
import { DotTypesEnums } from '../../../../enums';

const ApplicantTabs = () => {
  // hooks
  const { search } = useLocation();

  // graphql hooks
  const { companyId } = useCompanyUser();
  const { result, loading } = useApplicantsStages({ companyId });

  // helper
  const generateTabs = useMemo(() => {
    if (companyId && !loading && result?.length) {
      const queryParams = parseQueryString(search);
      const serializeQuery = serialize({ ...queryParams, page: 1 }); // revert to first page

      const stages: CustomLinkProps[] = result?.map(({ id, name, color }) => {
        const event = name?.replace(/ /g, '-').toLowerCase();
        return {
          label: name || '',
          match: `/applicants/${companyId}/${event}/${id}`,
          to: `/applicants/${companyId}/${event}/${id}${serializeQuery}`,
          before: <Dot style={{ backgroundColor: color || '' }} />,
        };
      });

      stages.unshift({
        label: 'All',
        match: `/applicants/${companyId}/all`,
        to: `/applicants/${companyId}/all${serializeQuery}`,
        before: <Dot type={DotTypesEnums.DARK_GRAY} />,
      });

      return stages;
    }
    return [];
  }, [loading, result, search, companyId]);

  return (
    <div className="applicants__tabs">
      <Tabs tabs={generateTabs} beforeLabel="Stage" />
    </div>
  );
};

export default ApplicantTabs;

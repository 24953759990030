import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import dayjs from 'dayjs';

//components
import { LayoutContainer } from '../../templates';
import { Logo } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

// types
import { AuthFooterProps } from './AuthFooter.types';

const AuthFooter: FC<AuthFooterProps> = ({ borderTop = true }) => {
  const { t } = useL10N();

  return (
    <LayoutContainer className="auth-footer">
      <div
        className={cx(`auth-footer__content`, {
          'footer__content--border': borderTop,
        })}
      >
        <div>
          <Logo withName={false} opacity={true} />
        </div>
        <p className="auth-footer__content__copyright">
          {t('footer.content', { date: dayjs().format('YYYY') })}
        </p>
        <div className="auth-footer__content__links">
          <Link className="footer__content__links--item mr-3" to="/privacy">
            {t('footer.privacy')}
          </Link>
          <Link className="auth-footer__content__links--item" to="/terms">
            {t('footer.termsAndCondition')}
          </Link>
        </div>
      </div>
    </LayoutContainer>
  );
};
export default AuthFooter;

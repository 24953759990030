import React, {
  FC,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
} from 'react';
import cx from 'classnames';

//components
import { Button, Dropdown } from '../../atoms';

//hooks
import useDropdown from '../../../hooks/useDropdown';

//types
import { DefaultDropdownProps } from '../../../interfaces';

const DefaultDropdown: FC<DefaultDropdownProps> = ({
  className,
  triggerProps,
  closeIcon = false,
  withOpacity = false,
  children,
}) => {
  const { open, onMenuClick, closeMenu } = useDropdown();

  const handleOnMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (triggerProps?.onClick) triggerProps.onClick(event);
    onMenuClick();
  };

  return (
    <Dropdown
      className={cx('default__dropdown', className)}
      open={open}
      closeIcon={closeIcon}
      withOpacity={withOpacity}
      closeMenu={closeMenu}
      trigger={
        <Button
          className="text-primary"
          {...triggerProps}
          onClick={handleOnMenuClick}
        />
      }
    >
      {isValidElement(children) &&
        cloneElement(children as ReactElement, { closeMenu: closeMenu })}
    </Dropdown>
  );
};

export default DefaultDropdown;

import React from 'react';

//components
import { LayoutContainer, PagesContainer, HeaderLayout } from '../../components/templates';
import { JobEmptyView, ReactPagination } from '../../components/molecules';
import { ApplicantsHeader } from '../../components/organisms';
import ApplicantTabs from '../../components/organisms/applicants/tabs/ApplicantsTabs';
import { ContentLoading } from '../../components/atoms';
import CandidateItem from '../../components/molecules/candidate/CandidateItem';

//gql
import useCompanyUser from '../../gql/hooks/useCompanyUser';
import useAllCompanyApplicants from '../../gql/hooks/applicants/useAllCompanyApplicants';

export interface MatchParams {
  id: string;
  tab: string;
}

const Applicants = () => {
  const { company_user } = useCompanyUser();

  const { result, loading } = useAllCompanyApplicants({
    id: company_user?.company_id,
  });

  return (
    <HeaderLayout l10nTitleKey="head.titles.applicants">
      <PagesContainer>
        <div className="applicants">
          <ApplicantsHeader />
          <ApplicantTabs />
          <LayoutContainer className="">
            {loading ? (
              <div className="applicants--content flex justify-center items-center">
                <ContentLoading />
              </div>
            ) : (
              <div className="applicants--content">
                {result.data?.length ? (
                  result?.data.map((el) => <CandidateItem key={el.id} candidate={el} />)
                ) : (
                  <JobEmptyView />
                )}
              </div>
            )}

            {!!result.meta.all && !loading && (
              <ReactPagination
                className="job--pagination"
                loading={loading}
                pagination={{ numberOfItems: result.meta.all }}
              />
            )}
          </LayoutContainer>
        </div>
      </PagesContainer>
    </HeaderLayout>
  );
};

export default Applicants;

import React, { FC, useEffect, useState } from 'react';

// router
import { useHistory } from 'react-router-dom';

// query-string
import queryString from 'query-string';

// classNames
import cx from 'classnames';

// components
import Pagination from './Pagination';

// enums
import { PaginationTypeEnums } from '../../../enums/Pagination.enum';

// types
import { ReactPaginationProps } from './ReactPagination.types';

const ReactPagination: FC<ReactPaginationProps> = ({ className, loading, pagination, count }) => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(0);
  //hook
  const { push, location } = useHistory();

  // const
  const { itemsPerPage, numberOfItems } = pagination;

  // query string
  const queryParams = queryString.parse(location.search);

  const page = queryParams?.page;

  const limit = itemsPerPage || PaginationTypeEnums.LIMIT;

  useEffect(() => {
    if (!!page) {
      setCurrentPage(Number(page));
    }
    if (numberOfItems <= limit && currentPage === 2) {
      push({
        search: `${queryString.stringify({
          ...queryParams,
          page: 1,
        })}`,
      });
    }
  }, [currentPage, limit, numberOfItems, page, push, queryParams]);

  return (
    <>
      {numberOfItems > limit && !loading && (
        <div className={cx(className)}>
          <Pagination count={count} {...pagination} />
        </div>
      )}
    </>
  );
};

export default ReactPagination;

import { useLocation } from 'react-router-dom';

import { gqlVar, parseQueryString } from '../../../utils';
import { PaginationTypeEnums } from '../../../enums';

import {
    useSubscribersSubscription,
    useAllSubscribersSubscription,
} from '../../gen/graphql';

const useSubscribers = ({ company_id }) => {
    const { search } = useLocation();

    const { query: q, page } = parseQueryString(search);

    const where = {
        company_id: { _eq: company_id },
        ...(q && {
            _or: [
                { name: { _ilike: `%${q}%` } },
                { email: { _ilike: `%${q}%` } },
            ],
        }),
    };

    // Applicants query
    const query = useSubscribersSubscription(
        gqlVar({
            limit: PaginationTypeEnums.LIMIT,
            offset: ((page || 1) - 1) * PaginationTypeEnums.LIMIT,
            where
        })
    );

    // All applicants count query
    const queryAll = useAllSubscribersSubscription(
        gqlVar({
            where
        })
    );

    // Applicants data
    const { data: { subscribers: data = null } = {}, loading, error } = query;

    // All applicants count data
    const { data: { count_all } = {} } = queryAll;


    return {
        result: {
            data,
            meta: {
                all: count_all?.aggregate?.count || 0,
            },
        },
        loading,
        error,
    };
};

export default useSubscribers;

import React, { FC } from 'react';

//components
import DeleteModal from '../modal/DeleteModal';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { DeleteSubscriberProps } from './DeleteSubscriber.types';

const DeleteSubscriber: FC<DeleteSubscriberProps> = ({
  closeModal,
  onDeleteSubscriber,
  loading,
}) => {
  const { t } = useL10N();

  return (
    <DeleteModal
      title={t('subsciber.deleteSubscriberTitle')}
      onComplete={onDeleteSubscriber}
      closeModal={() => {
        closeModal && closeModal();
      }}
      loading={loading}
    />
  );
};

export default DeleteSubscriber;

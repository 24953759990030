import React from 'react';
import { Link } from 'react-router-dom';

//components
import { ContentLoading } from '../../../atoms';
import { DashboardJobItem, EmptyDraftJobs } from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useVacanciesSubscription } from '../../../../gql/gen/graphql';

//enums
import { VacaniesStatusTypesEnums } from '../../../../enums';

//utils
import { gqlVar } from '../../../../utils';

const JobsHolder = () => {
  const { company_user } = useCompanyUser();
  const { t } = useL10N();

  const query = useVacanciesSubscription(
    gqlVar({
      limit: 3,
      where: {
        status: { _eq: VacaniesStatusTypesEnums.DRAFT },
        company_id: { _eq: company_user?.company_id },
      },
    })
  );

  const { data: { vacancies: data = null } = {}, loading } = query;

  const renderContent = () => {
    if (data?.length === 0) return <EmptyDraftJobs />;
    return (
      <div className="job__content--content">
        {data?.map((job) => (
          <DashboardJobItem
            vacancy={{ title: job.title, status: job?.status || 0, id: job.id }}
            key={job.id}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="job__content  jobDraft__content mt-5">
      <div className="job__content--header">
        <h1 className="job__content--header--title">{t('dashboard.draftJobsTitle')}</h1>
        {data && data.length ? (
          <Link className="job__content--header--desc" to="/jobs/draft">
            {t('dashboard.seeAll')}
          </Link>
        ) : (
          ''
        )}
      </div>
      {!loading ? (
        renderContent()
      ) : (
        <div className="pt-8 pb-16">
          <ContentLoading />
        </div>
      )}
    </div>
  );
};

export default JobsHolder;

import React, { FC, MouseEvent } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

//components
import { Button, Edit, Candidates, Eye, Delete } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { JobFormDropdownProps } from '../../../interfaces';
import { RouteMatchProps } from '../../../interfaces/types/RouteMatch.types';

const JobFormDropdown: FC<JobFormDropdownProps> = ({
  closeMenu,
  id,
  onDeleteClick,
  onPublishClick,
  onDraftClick,
}) => {
  const { push } = useHistory();
  const { t } = useL10N();

  const {
    params: { id: job_id },
  } = useRouteMatch<RouteMatchProps>();

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    switch (event.currentTarget.id) {
      case 'delete':
        return onDeleteClick();
      case 'publish':
        return onPublishClick();
      case 'save_draft':
        return onDraftClick();
      default:
        push(`/job/${job_id}/all`);
    }
    closeMenu && closeMenu();
  };

  return (
    <div className="default__dropdown__job__header">
      <Button
        id="publish"
        label={t('job.publish')}
        before={<Eye type={2} />}
        onClick={handleOnClick}
        className="default__dropdown__job__header--item md:hidden"
      />
      <Button
        id="save_draft"
        label={t('job.saveDraft')}
        before={<Edit />}
        onClick={handleOnClick}
        className="default__dropdown__job__header--item md:hidden"
      />
      {id && (
        <>
          <Button
            id="view_applicants"
            label={t('job.viewApplicants')}
            before={<Candidates />}
            onClick={handleOnClick}
            className="default__dropdown__job__header--item"
          />
          <Button
            id="delete"
            label={t('jobs.delete')}
            before={<Delete />}
            onClick={handleOnClick}
            className="default__dropdown__job__header--item"
          />
        </>
      )}
    </div>
  );
};

export default JobFormDropdown;

import React, { FC } from 'react';
import cx from 'classnames';

//types
import { SkeletonProps } from './Skeleton.types';

//enums
import { SkeletonBgEnums } from '../../../../enums';

const Skeleton: FC<SkeletonProps> = ({
  bgType = SkeletonBgEnums.DEFAULT,
  className,
}) => (
  <div
    className={cx('animate-pulse', className, {
      [`skeleton--${bgType}`]: bgType,
    })}
  />
);

export default Skeleton;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { ContentLoading } from '../components/atoms';
import { ContentLoadingEnums } from '../enums';

import useAuthUser from '../gql/hooks/auth/useAuthUser';

import { removeAuthToken, validateAuthToken } from '../utils';

const withAuth = (WrappedComponent: any, isProtected: boolean) => {
  if (isProtected)
    return (props: any) => {
      const isAuth = validateAuthToken();

      const { loading, isAuthenticated } = useAuthUser(false, 'network-only');

      if (loading)
        return (
          <ContentLoading size={ContentLoadingEnums.LG} className="mt-64" />
        );

      if (isAuth && !isAuthenticated) removeAuthToken();

      if (!isAuth && !isAuthenticated) return <Redirect to={'/auth/login'} />;

      return <WrappedComponent {...props} />;
    };
  else
    return (props: any) => {
      const isAuth = validateAuthToken();

      if (isAuth) return <Redirect to={'/dashboard'} />;

      return <WrappedComponent {...props} />;
    };
};

export default withAuth;

// react
import React from 'react';

// router
import { useRouteMatch } from 'react-router-dom';

// hooks
import { useL10N } from '../../../../hooks/useL10N';

// graphql
import { useApplicantActivitySubscription } from '../../../../gql/gen/graphql';

// types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

// utils
import { gqlVar } from '../../../../utils';

// component
import ActivityCard from './ActivityCard';

const Activity = () => {
  // hook
  const { t } = useL10N();

  // router hook
  const { params } = useRouteMatch<RouteMatchProps>();

  // graphql hook
  const { loading, data: { activity_log_applicants: result = null } = {} } =
    useApplicantActivitySubscription(gqlVar({ applicant_id: +params.id }));

  return (
    <div className="activity">
      <h1 className="activity--header">{t('candiate.activityTitle')}</h1>
      <div className="activity__content">
        {!loading &&
          result?.length &&
          result.map((activity, index) => (
            <ActivityCard
              key={activity.id}
              activity={activity}
              className={
                (result.length > 1 && index !== result.length - 1 && 'border-b pb-4') || ''
              }
            />
          ))}
      </div>
    </div>
  );
};

export default Activity;

import React from 'react';

import { Svg } from '../../../../atoms';

const MessageTemplateEmpty = () => (
  <Svg
    width={84}
    height={69}
    viewBox="0 0 84 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx={42} cy={59} rx={42} ry={10} fill="#EDEFF4" />
    <path
      d="M67 18H50V1"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M67 18v41H19V1h31l17 17z"
      fill="#fff"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M30 47h26M30 37h26M30 27h26M30 17h11"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);

export default MessageTemplateEmpty;

import React, { FC, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

//components
import DeleteModal from '../modal/DeleteModal';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import { useDeleteVacanciesMutation } from '../../../gql/gen/graphql';

//contexts
import { toastrContext } from '../../../contexts/ToastrContexts';

//types
import { DeleteJobProps } from './DeleteJob.types';
import { RouteMatchProps } from '../../../interfaces/types/RouteMatch.types';

//enums
import { ToastrTypesEnums } from '../../../enums';

//utils
import { gqlVar } from '../../../utils';

const DeleteJob: FC<DeleteJobProps> = ({ closeModal, vacancyId }) => {
  const toast = useContext(toastrContext);
  const { push } = useHistory();
  const { t } = useL10N();

  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const [deleteMut, { loading }] = useDeleteVacanciesMutation({
    onError: (err) => {
      toast?.add(ToastrTypesEnums.DANGER, t('job.deletedError'));
      closeModal && closeModal();
    },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('job.deletedSuccessfully'));
      push(`/jobs/all`);
    },
  });

  const onConfirmDelete = () => {
    deleteMut(gqlVar({ id: vacancyId ? +vacancyId : +id }));
  };

  const handleCloseModal = () => {
    closeModal && closeModal();
  };

  return (
    <DeleteModal
      title={t('job.deleteJobTitle')}
      onComplete={onConfirmDelete}
      closeModal={handleCloseModal}
      loading={loading}
    />
  );
};

export default DeleteJob;

import React, { FC } from 'react';

//icons
import { Links } from '../../atoms';

import { CandidateLinksProps } from './CandidateLinks.types';
//hooks
import { useL10N } from '../../../hooks/useL10N';

//gql
import { useApplicantsLinkQuery } from '../../../gql/gen/graphql';

//utils
import { getValidUrl, gqlVar, splitLinks } from '../../../utils';

const CandidateLinks: FC<CandidateLinksProps> = ({ candidate_id }) => {
  const { t } = useL10N();

  const query = useApplicantsLinkQuery(gqlVar({ applicant_id: parseInt(candidate_id) }));
  const { data: { links = [] } = {} } = query;

  const { socialLinks, personalLinks: personal } = splitLinks(links);

  if (!links) return null;

  return (
    <div className="overview__content--accounts">
      <div className="overview__content__item overview__content--accounts--links">
        <span className="overview__content__item--label">{t('candidate.links')}</span>
        <div className="overview__content__item--content overview__content--accounts--links--content">
          {personal.length ? (
            personal.map(({ link, name, linkType }) => (
              <a
                className="overview__content--accounts--links--content--item"
                href={getValidUrl(link)}
                rel="noopener noreferrer"
                target="_blank"
                key={`personal-${link}`}
              >
                <Links type={linkType} />
                <span>{name}</span>
              </a>
            ))
          ) : (
            <span className="overview__content--accounts--links--content--item">-</span>
          )}
        </div>
      </div>
      <div className="overview__content__item overview__content--accounts--links">
        <span className="overview__content__item--label">{t('candidate.socialMediaLinks')}</span>
        <div className="overview__content__item--content overview__content--accounts--links--content">
          {socialLinks.length ? (
            socialLinks.map(({ link, name, linkType }) => (
              <a
                className="overview__content--accounts--links--content--item"
                href={getValidUrl(link)}
                rel="noopener noreferrer"
                target="_blank"
                key={`social-${link}`}
              >
                <Links type={linkType} />
                <span>{name}</span>
              </a>
            ))
          ) : (
            <span className="overview__content--accounts--links--content--item">-</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateLinks;

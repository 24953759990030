import React, { FC } from 'react';

//types
import { MailFormHeaderProps } from './MailFormHeader.types';

const MailFormHeader: FC<MailFormHeaderProps> = ({ title }) => (
  <div className="mail__form__header">
    <h1>{title}</h1>
  </div>
);

export default MailFormHeader;

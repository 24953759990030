import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { LayoutContainer } from '../../../templates';
import {
  TeamMember,
  Tokens,
  MyAccount,
  Messages,
  ManageStages,
  Payments,
} from '../../settings/index';
import {
  MyAccounts,
  MessageTemplate,
  ApiTokens,
  TeamMembers,
  ManageStagesIcon,
  Cart,
} from '../../../atoms';
import { Tabs } from '../../../molecules';

import SettingsNav from './leftSide/SettingsNav';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

const SettingsContent = () => {
  const { xl } = useWindowSize();
  const { pathname } = useLocation();
  const { t } = useL10N();

  const isManagingStages = pathname === '/settings/stages';

  const tabs = [
    {
      id: '1',
      to: '/settings/account',
      label: t('settings.myAccount'),
      before: <MyAccounts />,
      type: 'account',
    },
    {
      id: '2',
      to: '/settings/team',
      label: t('settings.teamMembers'),
      before: <TeamMembers />,
      type: 'team',
    },
    {
      id: '3',
      to: '/settings/payments',
      label: t('settings.payments'),
      before: <Cart />,
      type: 'payments',
    },
    {
      id: '4',
      to: '/settings/messages',
      label: t('settings.messagesTemplates'),
      before: <MessageTemplate />,
      type: 'messages',
    },
    {
      id: '5',
      to: '/settings/stages',
      label: t('settings.manageStages'),
      before: <ManageStagesIcon />,
      type: 'stages',
    },
    {
      id: '6',
      to: '/settings/token',
      label: t('settings.apiTokens'),
      before: <ApiTokens />,
      type: 'token',
    },
  ];
  return (
    <>
      {!xl && <Tabs tabs={tabs} />}
      <LayoutContainer className="settings__content">
        <div className="settings__content--body">
          {xl && (
            <div className="settings__content--body--left">
              <SettingsNav items={tabs} />
            </div>
          )}
          <div
            className={cx(
              'settings__content--body--right',
              !xl && isManagingStages && 'settings__content--body--right--scrollbar'
            )}
          >
            <Switch>
              <Route exact path="/settings/account" component={MyAccount} />
              <Route exact path="/settings/team" component={TeamMember} />
              <Route exact path="/settings/messages" component={Messages} />
              <Route exact path="/settings/token" component={Tokens} />
              <Route exact path="/settings/stages" component={ManageStages} />
              <Route exact path="/settings/payments" component={Payments} />
            </Switch>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default SettingsContent;

import React from 'react';

// libs
import { Link } from 'react-router-dom';

// hooks
import { useL10N } from '../../../../hooks/useL10N';

// components
import CandidateItem from '../../../molecules/candidate/CandidateItem';
import { ContentLoading, ApplicantsIcon } from '../../../atoms';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import useAllCompanyApplicants from '../../../../gql/hooks/applicants/useAllCompanyApplicants';

const RecentApplicants = () => {
  // l10n
  const { t } = useL10N();

  // gql
  const { companyId } = useCompanyUser();
  const { result, loading } = useAllCompanyApplicants({
    id: companyId,
  });

  // render fn
  const renderContent = () => {
    // loading
    if (loading) return <ContentLoading />;

    // empty
    if (result.data === null || result?.data?.length === 0) {
      return (
        <div className="w-full h-auto flex flex-col items-center justify-center my-24">
          <ApplicantsIcon />
          <span className="mt-3 text-secondary text-sm">
            {t('dashboard.recentApplicants.data')}
          </span>
        </div>
      );
    }

    // normal
    const mappable = result.data.slice(0, 4);
    const last = mappable.length - 1 || 0;
    return mappable.map((candidate, index) => (
      <CandidateItem
        key={`applicant-${candidate.id}-${candidate.email}`}
        className={
          index === last
            ? 'cadidate__item--dashboard pb-6 mb-6'
            : 'cadidate__item--dashboard pb-6 mb-6 border-b border-gray-200'
        }
        displayPhoneNumber={false}
        candidate={candidate}
      />
    ));
  };

  return (
    <div className="job__content  jobDraft__content mt-5">
      <div className="job__content--header">
        <h1 className="job__content--header--title">{t('dashboard.recentApplicants.title')}</h1>
        {result?.data?.length ? (
          <Link className="job__content--header--desc" to="/applicants/all">
            {t('dashboard.recentApplicants.link')}
          </Link>
        ) : (
          ''
        )}
      </div>
      <div className="px-6 py-5">{renderContent()}</div>
    </div>
  );
};

export default RecentApplicants;

import React from 'react';

//components
import { SubscriberEmpty } from '../../atoms';

//hooks
import { useL10N } from '../../../hooks/useL10N';

const SubscribersEmptyView = () => {
  const { t } = useL10N();
  return (
    <div className="empty">
      <div className="empty--icon">
        <SubscriberEmpty />
      </div>
      <h1 className="empty__heading">{t('subscribers.emptyTitle')}</h1>
    </div>
  );
};

export default SubscribersEmptyView;

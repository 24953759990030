import React from 'react';

import { Svg } from '../../../../atoms';

const SubscriberEmpty = () => (
  <Svg
    width={84}
    height={67}
    viewBox="0 0 84 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx={42} cy={57} rx={42} ry={10} fill="#EDEFF4" />
    <path
      d="M48.696 56.27H73V46.233a4.363 4.363 0 00-2.873-4.1l-11.49-4.178a2.908 2.908 0 01-1.892-2.327l-.736-5.153a11.627 11.627 0 006.809-10.57v-8.62a6.352 6.352 0 00-6.348-6.348l-1.642-3.286a1.226 1.226 0 00-1.465-.59l-7.837 2.797a8.147 8.147 0 00-2.528 1.503"
      fill="#fff"
    />
    <path
      d="M48.696 56.27H73V46.233a4.363 4.363 0 00-2.873-4.1l-11.49-4.178a2.908 2.908 0 01-1.892-2.327l-.736-5.153a11.627 11.627 0 006.809-10.57v-8.62a6.352 6.352 0 00-6.348-6.348l-1.642-3.286a1.226 1.226 0 00-1.465-.59l-7.837 2.797a8.147 8.147 0 00-2.528 1.503"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M37.919 39.884l-.521-3.648c7.086 1.61 10.965-.329 10.965-.329A27.613 27.613 0 0144 22.817v-2.512a11.9 11.9 0 00-10.861-12.01 11.637 11.637 0 00-12.412 11.612v2.91a27.612 27.612 0 01-4.363 13.09s3.879 1.939 10.965.329l-.52 3.648a2.909 2.909 0 01-2.037 2.375l-9.662 2.897a4.363 4.363 0 00-3.11 4.18v6.934h40.727v-6.935a4.364 4.364 0 00-3.11-4.179l-9.655-2.897a2.909 2.909 0 01-2.044-2.375z"
      fill="#fff"
      stroke="#ADBAC8"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);

export default SubscriberEmpty;

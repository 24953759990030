import React from 'react';

import { Svg } from '../../../atoms';

const Add = () => (
  <Svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8.5H1M8 1.5V15.5V1.5Z"
      stroke="#08D4A5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Add;

import { gqlVar } from '../../../utils';

import {
    useAllApplicantsSubscription,
    useStagesStatsSubscription
} from '../../gen/graphql';

const useDashboardStatistics = ({ id }) => {

    // All applicants count query
    const queryAll = useAllApplicantsSubscription(
        gqlVar({
            where_count: {
                vacancy: { company_id: { _eq: id } }
            },
        })
    );
    // All Stages count query
    const queryStages = useStagesStatsSubscription(
        gqlVar({
            limit: 2,

            where: {
                company_id: { _eq: id },
                is_shown: { _eq: 1 }
            },
        })
    );

    // All applicants count data
    const { data: { count_all } = {}, loading, error } = queryAll;

    // All stages count data
    const { data: { stages = null } = {} } = queryStages;

    return {
        result: {
            total_applicants: count_all?.aggregate?.count || 0,
            stages
        },
        loading,
        error,
    };
};

export default useDashboardStatistics;

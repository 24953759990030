import React from 'react';
import cx from 'classnames';

//components
import { Button, Dropdown, Filter } from '../../atoms';
import { FilterFormDropdown } from '..';

//hooks
import useDropdown from '../../../hooks/useDropdown';
import { useL10N } from '../../../hooks/useL10N';

const FilterDropdown = () => {
  const { open, onMenuClick, closeMenu } = useDropdown();
  const { t } = useL10N();

  return (
    <Dropdown
      className="filter__dropdown z-30"
      open={open}
      closeMenu={closeMenu}
      trigger={
        <Button
          label={t('dropdown.filters')}
          className={cx('filter__dropdown__btn', {
            'filter__dropdown__btn--close': !open,
          })}
          onClick={onMenuClick}
          before={<Filter active={open} />}
        />
      }
    >
      <div className="filter__dropdown__form">
        <FilterFormDropdown />
      </div>
    </Dropdown>
  );
};

export default FilterDropdown;

// router
import { useLocation, useRouteMatch } from 'react-router-dom';

// gql
import {
  useVacanciesSubscription,
  useAllVacanciesCountSubscription,
  useDraftVacanciesCountSubscription,
  usePublishedVacanciesCountSubscription,
} from '../../gen/graphql';
import useAuth from '../auth/useAuth';

// utils
import { gqlVar, parseQueryString } from '../../../utils';

// enums
import { PaginationTypeEnums, VacaniesStatusTypesEnums } from '../../../enums';

export interface RouteMatchProps {
  tab: string;
}

const useVacancies = () => {
  const auth = useAuth();
  const { search } = useLocation();
  const {
    params: { tab },
  } = useRouteMatch<RouteMatchProps>();

  const { query: q, location, type, page } = parseQueryString(search);

  const where = {
    company: { company_users: { user_id: { _eq: auth?.user?.id } } },
    ...(q && { title: { _ilike: `%${q}%` } }),
    ...(location && { location: { _ilike: `%${location}%` } }),
    ...(type && {
      _or: type.split(',').map((t) => ({ type: { _eq: `${t}` } })),
    }),
  };

  // Vacancies query
  const query = useVacanciesSubscription(
    gqlVar({
      limit: PaginationTypeEnums.LIMIT,
      offset: ((page || 1) - 1) * PaginationTypeEnums.LIMIT,
      where: {
        ...(VacaniesStatusTypesEnums[tab.toUpperCase()] && {
          status: { _eq: VacaniesStatusTypesEnums[tab.toUpperCase()] },
        }),
        ...where,
      },
    })
  );

  // Count of all vacancies query
  const queryAll = useAllVacanciesCountSubscription(
    gqlVar({
      where_all: where,
    })
  );

  // Count of published vacancies query
  const queryPublished = usePublishedVacanciesCountSubscription(
    gqlVar({
      where_published: {
        status: { _eq: VacaniesStatusTypesEnums.PUBLISHED },
        ...where,
      },
    })
  );

  // Count of draft vacancies query
  const queryDraft = useDraftVacanciesCountSubscription(
    gqlVar({
      where_draft: {
        status: { _eq: VacaniesStatusTypesEnums.DRAFT },
        ...where,
      },
    })
  );

  // Vacancies data
  const { data: { vacancies: data = null } = {}, loading, error } = query;

  // All vacancies count data
  const { data: { count_all } = {} } = queryAll;

  // Published vacancies count data
  const { data: { count_published } = {} } = queryPublished;

  // Draft vacancies count data
  const { data: { count_draft } = {} } = queryDraft;

  return {
    result: {
      data,
      meta: {
        all: count_all?.aggregate?.count || 0,
        published: count_published?.aggregate?.count || 0,
        draft: count_draft?.aggregate?.count || 0,
      },
    },
    loading,
    error,
  };
};

export default useVacancies;

import React from 'react';

import { Svg } from '../../../../atoms';

const UserIcon = () => (
  <Svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 35c6.282 0 11.375-5.093 11.375-11.375S25.532 12.25 19.25 12.25 7.875 17.343 7.875 23.625 12.968 35 19.25 35zM33.997 12.674a11.375 11.375 0 113.086 22.327M3.499 43.18a19.255 19.255 0 0131.501 0M37.083 35a19.228 19.228 0 0115.75 8.18"
      stroke="#CBD5E0"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default UserIcon;

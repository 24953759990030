import React from 'react';
import { Route, Switch } from 'react-router-dom';

//components
import {
  LayoutContainer,
  PagesContainer,
  HeaderLayout,
} from '../../components/templates';
import {
  CandidateTabs,
  CandidateNotes,
  CandidateHeader,
  CandidateContent,
} from '../../components/organisms';

const CandidateProfile = () => (
  <HeaderLayout l10nTitleKey="head.titles.candidateProfile">
    <PagesContainer>
      <CandidateHeader />
      <CandidateTabs />
      <Switch>
        <LayoutContainer className="">
          <Route
            exact
            path={'/candidate/:id/overview'}
            component={CandidateContent}
          />
          <Route
            exact
            path={'/candidate/:id/notes'}
            component={CandidateNotes}
          />
        </LayoutContainer>
      </Switch>
    </PagesContainer>
  </HeaderLayout>
);

export default CandidateProfile;

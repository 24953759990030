import React, { FC } from 'react';
import cx from 'classnames';

//types
import { StatusProps } from './Status.types';

const Status: FC<StatusProps> = ({ value, className = '' }) =>
  (value && <span className={cx('status', className)}>{value}</span>) || null;

export default Status;

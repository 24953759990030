import React, { FC } from 'react';
import cx from 'classnames';

import './PromoteJobCard.scss';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//types
import { PromoteJobCardProps } from './PromoteJobCard.types';

const PromoteJobCard: FC<PromoteJobCardProps> = ({
  title,
  price,
  checked,
  type,
  remainingDays,
  onPromoteJobChange,
}) => {
  const { t } = useL10N();

  const handlePromoteJobChange = () => {
    onPromoteJobChange(type);
  };

  return (
    <>
      <div
        className={cx('job__planCard cursor-pointer mb-4', {
          'job__planCard--checked': checked,
        })}
        onClick={handlePromoteJobChange}
      >
        <div className="flex items-center justify-between">
          <p className="text-base text-primary font-semiBold">{title}</p>
          {price && (
            <p className="text-sm text-primary">
              <span className="text-green-200 text-base">{price}€</span> /{' '}
              {remainingDays} days
            </p>
          )}
        </div>
        <p className="text-secondary text-sm">
          {price ? t('promoteJob.getPromoted') : t('promoteJob.noPromoted')}
        </p>
      </div>
    </>
  );
};

export default PromoteJobCard;

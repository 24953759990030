interface ToCamelCaseProps {
  text: string;
  splitChar: string;
}

export const toCamelCase = ({ text, splitChar }: ToCamelCaseProps) =>
  text
    .toLowerCase()
    .split(splitChar)
    .map((str, idx) =>
      idx === 0
        ? str
        : str.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          )
    )
    .join('');

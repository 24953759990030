import React, { FC } from 'react';
import { Link } from 'react-router-dom';

//components
import { DefaultDropdown, CandidateMailsDropdown } from '../..';
import { Button, RoundedImage, ThreeDots, LetterSvg } from '../../../atoms';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

// utils
import { splitFullName } from '../../../../utils/splitFullName';

//types
import { CandidateMailsHeaderProps } from './CandidateMailsHeader.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../../enums';

const CandidateMailsHeader: FC<CandidateMailsHeaderProps> = ({
  isSeen,
  applicant,
  messageId,
  handleUpdate,
}) => {
  // hooks
  const { t } = useL10N();

  return (
    <div className="candidate__mails__header">
      <div className="candidate__mails__header--content">
        <div className="candidate__mails__header--content__left">
          <div className="candidate__mails__header--content__left--img">
            {applicant?.file_store?.file_name ? (
              <RoundedImage src={applicant.file_store.file_path} />
            ) : (
              <LetterSvg
                first={splitFullName(applicant?.name || '')?.firstName || ''}
                second={splitFullName(applicant?.name || '')?.lastName || ''}
              />
            )}
          </div>
          <div className="candidate__mails__header--content__left--name">
            <h1>{applicant?.name || ''}</h1>
            <Link to={`/candidate/${applicant?.id}/overview`}>{t('candidate.viewProfile')}</Link>
          </div>
        </div>
        <div className="candidate__mails__header--content__right">
          <Link to={`/messages/inbox/compose?message_id=${messageId}`}>
            <Button
              className="hidden mr-2 md:block"
              label={t('messages.compose')}
              btnSize={ButtonSizesEnums.MD}
              btnType={ButtonTypesEnums.GREEN}
            />
          </Link>
          <DefaultDropdown triggerProps={{ after: <ThreeDots /> }}>
            <CandidateMailsDropdown isSeen={isSeen} handleOnChange={handleUpdate} />
          </DefaultDropdown>
        </div>
      </div>
    </div>
  );
};

export default CandidateMailsHeader;

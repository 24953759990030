// graphql
import { useNotesQuery, useNumberOffNotesSubscription } from '../gen/graphql';

// utils
import { gqlVar } from '../../utils';

const useNotes = ({ applicantId }: { applicantId: number }) => {
  const { data, loading, error, fetchMore } = useNotesQuery(
    gqlVar({ applicantId, offset: 0, limit: 10 })
  );

  const { data: numberOffNotes = null } = useNumberOffNotesSubscription(
    gqlVar({ applicantId })
  );

  const handleLoadMore = () =>
    fetchMore(gqlVar({ offset: data?.notes?.length }));

  return {
    loading,
    error,
    result: data?.notes,
    numberOffNotes: numberOffNotes?.notesAggregate?.aggregate?.count || 0,
    handleLoadMore,
  };
};

export default useNotes;

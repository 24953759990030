import React, { FC } from 'react';
import cx from 'classnames';

//types
import { LabelProps } from './Label.types';

const Label: FC<LabelProps> = ({
  error = false,
  children = null,
  ...props
}) => {
  const { className = '', ...rest } = props;
  return (
    <label
      {...rest}
      className={cx('label', {
        [`${className}`]: className,
        'text-danger': !!error,
      })}
    >
      {children && children}
    </label>
  );
};

export default Label;

import React, { useEffect } from 'react';

//types
import { PagesContainerProps } from './PagesContainer.types';

const PagesContainer = ({ children }: PagesContainerProps) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <> {children && children} </>
    );
};

export default PagesContainer;
import React, { FC } from 'react';
import cx from 'classnames';

//types
import { PaginationItemProps } from './PaginationItem.types';

const PaginationItem: FC<PaginationItemProps> = ({
  disabled,
  isActive,
  after: After,
  before: Before,
  className = 'btn',
  pageItem: { id, name },
  handleItemClick,
}) => {
  // handler
  const handleOnClick = () => {
    if (!disabled) {
      handleItemClick();
      window.scrollTo({ behavior: 'smooth', top: window.pageXOffset });
    }
  };

  return (
    <li
      className={cx(`pagination__item pagination__item--${className}`, {
        'pagination__item--active': name !== '...' && isActive,
        'cursor-default': id === '...',
        'cursor-not-allowed': disabled,
      })}
      onClick={handleOnClick}
      id={id}
    >
      {After && After}
      <span>{name}</span>
      {Before && Before}
    </li>
  );
};

export default PaginationItem;

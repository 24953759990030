import { findDotColorsObjTypes } from './findDotColors.types'

export const findDotColor = (obj?: findDotColorsObjTypes[] | null, stage_id?: number, stage_name?: string) => {
    let dotColor = '#778CA3';
    obj?.length && obj.map(x => {
        if (stage_id && x.id === stage_id) x.color && (dotColor = x.color);
        if (stage_name && x.name === stage_name) x.color && (dotColor = x.color);
        return x;
    });
    return dotColor;
};
import React, { FC, useState, useContext } from 'react';

//components
import { ArrowDown } from '../../../atoms';
import {
  DefaultDropdown,
  Checkbox,
  SubscribersActionsDropdown,
  DeleteSubscriber,
  CustomModal,
} from '../../../molecules';

//hooks
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';
import { useDeleteSubscribersMutation } from '../../../../gql/gen/graphql';
import useSubscribers from '../../../../gql/hooks/subscribers/useSubscribers';

//contexts
import { toastrContext } from '../../../../contexts/ToastrContexts';

//types
import { SubscribersActionsProps } from './SubscribersActions.types';

//config
import { GQL_ACTIONS_URL } from '../../../../config/gql';
//enums
import { ModalTypesEnums, ToastrTypesEnums } from '../../../../enums';

const SubscribersActions: FC<SubscribersActionsProps> = ({ all, onSelect, selected }) => {
  const { xl } = useWindowSize();
  const { t } = useL10N();
  const { company_user } = useCompanyUser();

  const { result } = useSubscribers({
    company_id: company_user?.company_id,
  });
  const toast = useContext(toastrContext);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [deleteMut, { loading }] = useDeleteSubscribersMutation({
    variables: { ids: selected },
    onCompleted: () => {
      toast?.add(ToastrTypesEnums.SUCCESS, t('subscriber.deletedSuccess'));
      setShowDeleteModal(false);
    },
    onError: (err) => {
      toast?.add(ToastrTypesEnums.DANGER, t('subscriber.deletedError'));
    },
  });

  const handleExportSubscribers = async () => {
    const response = await fetch(
      `${GQL_ACTIONS_URL}/export-subscribers/${company_user?.company_id}/false/${selected.join(
        ','
      )}`,
      {
        method: 'GET',
      }
    );

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', response?.url);
    document.body.appendChild(a);
    a.click();
  };

  const closeDeleteModal = () => setShowDeleteModal(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const handleDeleteSubscriber = () => deleteMut();

  const renderDropdown = (classname) => {
    if (!!result.data && result.data?.length > 0 && selected.length > 0)
      return (
        <div className={classname}>
          <DefaultDropdown
            triggerProps={{
              label: t('dropdown.bulkActions'),
              className: 'text-tertiary',
              after: <ArrowDown />,
            }}
          >
            <SubscribersActionsDropdown
              subscribers_ids={selected}
              onExportSubscribers={handleExportSubscribers}
              onDeleteSubscriber={openDeleteModal}
            />
          </DefaultDropdown>
        </div>
      );
    else return null;
  };

  return (
    <>
      {showDeleteModal && (
        <CustomModal
          open={showDeleteModal}
          closeModal={closeDeleteModal}
          title="Delete"
          type={ModalTypesEnums.SM}
        >
          <DeleteSubscriber
            closeModal={closeDeleteModal}
            onDeleteSubscriber={handleDeleteSubscriber}
            loading={loading}
          />
        </CustomModal>
      )}
      {!xl && renderDropdown('subscribers__actions--content--dropdownMobile mb-4')}
      <div className="subscribers__actions--content">
        <Checkbox
          inputProps={{
            id: '0',
            name: 'all',
            checked: all,
            onChange: onSelect,
          }}
        />
        <span>{t('subscriber.number')}</span>
        <p>{t('subscriber.name')}</p>
        <p>{t('subscriber.email')}</p>
        <p>{t('subscriber.date')}</p>
        {xl && renderDropdown('subscribers__actions--content--dropdown')}
      </div>
    </>
  );
};
export default SubscribersActions;

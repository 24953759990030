import React from 'react';
import { useRouteMatch } from 'react-router-dom';

//components
import { Upload, Dot, ContentLoading } from '../../../atoms';
import { CandidateLinks } from '../../../molecules';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useApplicant from '../../../../gql/hooks/applicants/useApplicant';
import useApplicantsStages from '../../../../gql/hooks/applicants/useApplicantsStages';
import useCompanyUser from '../../../../gql/hooks/useCompanyUser';

//types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

//utils
import { findEventName } from '../../../../utils/event/findEventName';
import { findDotColor } from '../../../../utils/findDotColor';

const Overview = () => {
  const { t } = useL10N();

  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const { companyId } = useCompanyUser();

  const { result: stages } = useApplicantsStages({ companyId });

  const { result, loading, resultFileStore, loadingFileStore } = useApplicant({
    id,
  });

  const findStage = (stage_id: number) => {
    const stage = findEventName(stages, stage_id);
    const dotColor = findDotColor(stages, stage_id);

    return (
      <>
        <Dot style={{ backgroundColor: dotColor }} />
        <span className="ml-2">{stage}</span>
      </>
    );
  };

  return (
    <div className="overview">
      <h1 className="overview--header">{t('candiate.overviewTitle')}</h1>
      {!loading ? (
        <div className="overview__content">
          <div className="overview__content__item overview__content--stage">
            <span className="overview__content__item--label">{t('candidate.stage')}</span>
            <div className="overview__content__item--content">
              {findStage(result?.status_id || 0)}
            </div>
          </div>
          <div className="overview__content__item overview__content--name">
            <span className="overview__content__item--label">{t('candiate.applicationFor')}</span>
            <div className="overview__content__item--content">
              <span>{result?.vacancy.title}</span>
            </div>
          </div>
          <div className="overview__content__item overview__content--email">
            <span className="overview__content__item--label">{t('candiate.email')}</span>
            <div className="overview__content__item--content">
              <span>{result?.email}</span>
            </div>
          </div>
          <div className="overview__content__item overview__content--phone">
            <span className="overview__content__item--label">{t('candiate.phone')}</span>
            <div className="overview__content__item--content">
              <span>{result?.phone}</span>
            </div>
          </div>
          <div className="overview__content__item overview__content--describe">
            <span className="overview__content__item--label">{t('candidate.description')}</span>
            <div className="overview__content__item--content">
              <span>{result?.description ? result.description : '-'}</span>
            </div>
          </div>
          <div className="overview__content__item overview__content--attachments">
            <span className="overview__content__item--label">{t('candidate.attachments')}</span>

            {!loadingFileStore
              ? (resultFileStore?.length ? resultFileStore : [result?.file_store]).map(
                  (fileStore) =>
                    fileStore && (
                      <div
                        key={fileStore?.id}
                        className="overview__content__item--content overview__content--attachments--content"
                      >
                        <div className="overview__content--attachments--content--title">
                          <Upload />
                          <span>{fileStore?.original_name}</span>
                        </div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={fileStore?.file_path}
                          download={fileStore?.original_name}
                          className="w-full pl-8 md:w-auto md:pl-0 overview__content__item--content overview__content--attachments--content--link"
                        >
                          {t('file.download')}
                        </a>
                      </div>
                    )
                )
              : '-'}
          </div>
          <CandidateLinks candidate_id={id} />
        </div>
      ) : (
        <div className="p-24 mt-4 bg-white rounded-md">
          <ContentLoading />
        </div>
      )}
    </div>
  );
};

export default Overview;

import React, { FC } from 'react';

// types
import { ContainerProps } from './Container.types';

export const LayoutContainer: FC<ContainerProps> = ({ className = 'bg-primary', children }) => (
  <div className={className}>
    <div className="mx-4 xl:container xl:mx-auto">{children && children}</div>
  </div>
);

export default LayoutContainer;

import React, { FC } from 'react';
import cx from 'classnames';

//components
import { CustomLink } from '../../atoms';
import { PhoneTabs } from '..';
import { LayoutContainer } from '../../templates';

//hooks
import useWindowSize from '../../../hooks/useWindowSize';

//types
import { TabsProps } from './Tabs.types';

const Tabs: FC<TabsProps> = ({ tabs, beforeLabel }) => {
  const { md, sm } = useWindowSize();

  return (
    <LayoutContainer className="container__tabs">
      <div className="tabs">
        {md || sm ? (
          <PhoneTabs tabs={tabs} beforeLabel={beforeLabel} />
        ) : (
          tabs.map((tab, key) => (
            <CustomLink
              key={key}
              activeColorText
              {...tab}
              className={cx('tabs__link', {
                [`${tab.className}`]: tab.className,
              })}
            />
          ))
        )}
      </div>
    </LayoutContainer>
  );
};

export default Tabs;

import React, { FC } from 'react';

//hooks
import { useL10N } from '../../../hooks/useL10N';

//types
import { MessageVariablesDropdownProps } from '../../../interfaces';

const MessageVariablesDropdown: FC<MessageVariablesDropdownProps> = ({
  closeMenu,
  onAddKeyword,
}) => {
  const { t } = useL10N();

  const handleApplicantNameClick = () => {
    onAddKeyword(t('applicant_name'));
    closeMenu && closeMenu();
  };

  const handleAJobTitleClick = () => {
    onAddKeyword(t('job_title'));
    closeMenu && closeMenu();
  };

  return (
    <div className="default__dropdown__messageVariables ">
      <div
        onClick={handleApplicantNameClick}
        className="cursor-pointer default__dropdown__messageVariables--item"
      >
        <p className="text-sm">
          {`{{ applicant_name }}`} - <span className="opacity-50">First Name</span>
        </p>
      </div>
      <div
        onClick={handleAJobTitleClick}
        className="cursor-pointer default__dropdown__messageVariables--item"
      >
        <p className="text-sm">
          {`{{ job_title }}`} - <span className="opacity-50">Job Name</span>
        </p>
      </div>
    </div>
  );
};

export default MessageVariablesDropdown;

import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

//components
import { Button, ContentLoading, Add } from '../../../atoms';
import { CustomModal, NoteForm, NoteItem, NotesEmpty } from '../../../molecules';

//hooks
import useModal from '../../../../hooks/useModal';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useL10N } from '../../../../hooks/useL10N';

//gql
import useNotes from '../../../../gql/hooks/useNotes';

//types
import { RouteMatchProps } from '../../../../interfaces/types/RouteMatch.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../../enums';

const CandidateNoteList = () => {
  const { t } = useL10N();
  const { md, sm } = useWindowSize();
  const { isOpen, onClick, closeModal } = useModal();
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const { result, loading, handleLoadMore, numberOffNotes } = useNotes({
    applicantId: +id,
  });

  const renderResults = useMemo(
    () =>
      !result?.length ? (
        <NotesEmpty />
      ) : (
        result?.map((note) => <NoteItem key={note?.id} note={note} />)
      ),
    [result]
  );

  return (
    <div className="notes">
      <div className="notes--title">
        <h1>Notes</h1>
        {(md || sm) && (
          <>
            <Button before={<Add />} label={t('note.addNewTitle')} onClick={onClick} />
            <CustomModal title={t('Add new note')} open={isOpen} closeModal={closeModal}>
              <NoteForm closeModal={closeModal} />
            </CustomModal>
          </>
        )}
      </div>
      {loading ? <ContentLoading className="mt-20" /> : renderResults}

      {numberOffNotes !== 0 && result?.length && result?.length < numberOffNotes ? (
        <div>
          <Button
            className="candidate__item__body__buttons--full"
            label={t('note.loadMore')}
            btnSize={ButtonSizesEnums.SM}
            btnType={ButtonTypesEnums.GREEN}
            onClick={handleLoadMore}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CandidateNoteList;

import React, { FC, MouseEvent } from 'react';

//components
import { Pill } from '../../atoms';

//types
import { PillsProps } from './Pills.types';

const Pills: FC<PillsProps> = ({ pills = [], onPillClick }) => {
  const handleOnPillClick = (event: MouseEvent<HTMLButtonElement>) =>
    onPillClick(event.currentTarget.id);

  if (!pills.length) return null;

  return (
    <>
      {pills.map((pill, key) => (
        <Pill
          key={key}
          id={pill.id.toString()}
          className={`${pills.length !== 1 ? 'mr-2' : ''}`}
          pill={pill}
          onClick={handleOnPillClick}
        />
      ))}
    </>
  );
};

export default Pills;

// router
import { useLocation, useRouteMatch } from 'react-router-dom';

// gql
import {
  useApplicantsSubscription,
  useAllApplicantsSubscription,
  useActiveApplicantsSubscription,
} from '../../gen/graphql';

// utils
import { gqlVar, parseQueryString } from '../../../utils';

// enums
import { PaginationTypeEnums } from '../../../enums';

export interface RouteMatchProps {
  tab: string;
  tabId: string;
}

const useApplicants = ({ id }: { id: string }) => {
  const { search } = useLocation();
  const {
    params: { tab, tabId },
  } = useRouteMatch<RouteMatchProps>();

  const { query: q, page } = parseQueryString(search);

  const where = {
    vacancy_id: { _eq: +id ? +id : 0 },
    ...(q && {
      _or: [
        { name: { _ilike: `%${q}%` } },
        { phone: { _ilike: `%${q}%` } },
        { email: { _ilike: `%${q}%` } },
      ],
    }),
  };

  // Applicants query
  const query = useApplicantsSubscription(
    gqlVar({
      limit: PaginationTypeEnums.LIMIT,
      offset: ((page || 1) - 1) * PaginationTypeEnums.LIMIT,
      where: {
        ...where,
        ...(tab &&
          tab !== 'all' && {
            status_id: { _eq: +tabId },
          }),
      },
    })
  );

  // All applicants count query
  const queryAll = useAllApplicantsSubscription(
    gqlVar({
      where_count: {
        ...where,
        ...(tab &&
          tab !== 'all' && {
            status_id: { _eq: +tabId },
          }),
      },
    })
  );

  // Active applicants count query
  const queryActive = useActiveApplicantsSubscription(
    gqlVar({
      limit: +PaginationTypeEnums.LIMIT,
      offset: +((page || 1) - 1) * PaginationTypeEnums.LIMIT,
      where_count: {
        ...where,
        ...(tab &&
          tab !== 'all' && {
            status_id: { _eq: +tabId },
          }),
      },
    })
  );

  // Applicants data
  const { data: { applicants: data = null } = {}, loading, error } = query;

  // All applicants count data
  const { data: { count_all } = {} } = queryAll;

  // Active applicants count data
  const { data: { count_active } = {} } = queryActive;

  return {
    result: {
      data,
      meta: {
        all: count_all?.aggregate?.count || 0,
        activeCount: count_active?.aggregate?.count || 0,
      },
    },
    loading,
    error,
  };
};

export default useApplicants;

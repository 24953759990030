import React from 'react';

//components
import { AuthFormFooter } from '../../components/molecules';
import { LoginForm } from '../../components/organisms';
import { PagesContainer, HeaderLayout } from '../../components/templates';

//hooks
import { useL10N } from '../../hooks/useL10N';

const Login = () => {
  const { t } = useL10N();

  return (
    <HeaderLayout l10nTitleKey="head.titles.login">
      <PagesContainer>
        <h1 className="auth__form--header">{t('auth.login.title')}</h1>
        <LoginForm />
        <AuthFormFooter
          paragraph={t('auth.dontHaveAccount')}
          to="/auth/register"
          name={t('auth.signUp')}
        />
      </PagesContainer>
    </HeaderLayout>
  );
};

export default Login;

import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { Button } from '../../atoms';
import { FieldTextarea } from '..';

//hooks
import { useL10N } from '../../../hooks/useL10N';
import useWindowSize from '../../../hooks/useWindowSize';

//gql
import { insertNotesCache } from '../../../gql/utils';
import useAuth from '../../../gql/hooks/auth/useAuth';
import { useInsertNoteMutation } from '../../../gql/gen/graphql';

//types
import { NoteSchema } from '../../../validations';
import { NoteFormProps } from './NoteForm.types';
import { RouteMatchProps } from '../../../interfaces/types/RouteMatch.types';

//enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';
export type NoteFormTypes = {
  description: string;
};

const NoteForm: FC<NoteFormProps> = ({ closeModal }) => {
  const { t } = useL10N();
  const { md, sm } = useWindowSize();

  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>();

  const auth = useAuth();

  const [insertMut, { loading }] = useInsertNoteMutation({
    update: insertNotesCache,
    onError: (err) => console.error(err),
    onCompleted: () => {
      reset({ description: '' });
      closeModal && closeModal();
    },
  });

  const { register, handleSubmit, errors, reset } = useForm<NoteFormTypes>({
    resolver: yupResolver(NoteSchema({ t })),
  });

  const onSubmit = (data: NoteFormTypes) => {
    insertMut({
      variables: {
        object: {
          user_id: auth?.user?.id || 0,
          applicant_id: parseInt(id),
          description: data.description,
        },
      },
    }).then(() => {});
  };

  return (
    <form className="note__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="note__form__textarea">
        <FieldTextarea
          labelProps={md || sm ? { children: t('note.comment') } : {}}
          textareaProps={{
            name: 'description',
            textareaRef: register,
            className: 'note__form__textarea--textarea',
            placeholder: t('note.commentPlaceholder'),
          }}
          error={!!errors?.description?.message}
        />
      </div>
      <div className="note__form__buttons">
        <Button
          className="md:hidden"
          label={t('button.cancel')}
          btnType={ButtonTypesEnums.WHITE_BORDER}
          btnSize={ButtonSizesEnums.XM}
          onClick={closeModal}
        />
        <Button
          className="note__form__buttons--main"
          label={t('button.comment')}
          type="submit"
          disabled={loading}
          loading={loading}
          btnType={ButtonTypesEnums.GREEN}
          btnSize={ButtonSizesEnums.LG}
        />
      </div>
    </form>
  );
};

export default NoteForm;

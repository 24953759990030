import React, { FC, useEffect, useRef, MouseEvent } from 'react';
import cx from 'classnames';

//icons
import { Close } from '../assets/icons';

//components
import { Button, Opacity } from '../../atoms';

//types
import { DropdownProps } from './Dropdown.types';

//enums
import { ButtonSizesEnums } from '../../../enums';

const Dropdown: FC<DropdownProps> = ({
  className = '',
  open,
  trigger,
  closeMenu,
  closeIcon = false,
  withOpacity = false,
  children = null,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open && withOpacity) {
      document.querySelector('body')?.classList.add('overflow-hidden');
    }
    return () => {
      document.querySelector('body')?.classList.remove('overflow-hidden');
    };
  }, [open, withOpacity]);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (withOpacity) return;
      else if (event.target instanceof HTMLElement) {
        if (!ref.current || ref.current.contains(event.target)) return;
        closeMenu();
      }
    };
    document.addEventListener('mousedown', (event: any) => listener(event));
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', (event: any) => listener(event));
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, withOpacity, closeMenu]);

  return (
    <>
      {withOpacity && (
        <Opacity show={open} className="dropdown__opacity__hidden" onClick={closeMenu} />
      )}
      <div className="dropdown" ref={ref}>
        {trigger}
        <div
          className={cx('dropdown__body', {
            hidden: !open,
            [className]: className,
          })}
        >
          {closeIcon && (
            <div className="dropdown__body__close">
              <Button
                className="dropdown__body__close--btn"
                btnSize={ButtonSizesEnums.XS}
                onClick={closeMenu}
                after={<Close />}
              />
            </div>
          )}
          {children && children}
        </div>
      </div>
    </>
  );
};

export default Dropdown;

import { WebSocketLink } from "@apollo/client/link/ws";
import { GQL_WS_API_URI } from "../../../config/gql";
import { getAuthToken } from "../../../utils/auth/getAuthToken";
import { validateAuthToken } from "../../../utils/auth/validateAuthToken";
import { SubscriptionClient } from "subscriptions-transport-ws";

export const wsClient = new SubscriptionClient(GQL_WS_API_URI, {
  
    reconnect: true,
    lazy: true,
    connectionParams: () => {
        const token = getAuthToken();
        return {
            headers: {
                ...(token &&
                    validateAuthToken(token) && {
                        Authorization: `Bearer ${token}`,
                    }),
            },
        };
    },
});

export const wsLink = new WebSocketLink(wsClient);

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

//types
import { AuthFormFooterProps } from './AuthFormFooter.types';

const AuthFormFooter: FC<AuthFormFooterProps> = ({ paragraph, to, name }) => (
  <div className="auth__form__footer">
    <p>{paragraph}</p>
    <Link className="auth__form__footer--link" to={to}>
      {name}
    </Link>
  </div>
);

export default AuthFormFooter;

import React, { FC } from 'react';

import { Svg } from '../../../atoms';

import { IconProps } from './Icon.types';

const Folder: FC<IconProps> = ({ active = false }) => (
  <Svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M1.75 3.41671V12.5834C1.75 13.0696 1.94315 13.5359 2.28697 13.8797C2.63079 14.2236 3.0971 14.4167 3.58333 14.4167H16.4167C16.9029 14.4167 17.3692 14.2236 17.713 13.8797C18.0568 13.5359 18.25 13.0696 18.25 12.5834V5.25004C18.25 4.76381 18.0568 4.2975 17.713 3.95368C17.3692 3.60986 16.9029 3.41671 16.4167 3.41671H10.9167L9.08333 1.58337H3.58333C3.0971 1.58337 2.63079 1.77653 2.28697 2.12034C1.94315 2.46416 1.75 2.93048 1.75 3.41671V3.41671Z"
      stroke={active ? '#08D4A5' : '#778CA3'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Folder;

import React, { FC } from 'react';

//types
import { SvgProps } from './Svg.types';

const Svg: FC<SvgProps> = ({ children, ...props }) => {
  return <svg {...props}>{children && children}</svg>;
};

export default Svg;

import React, { useContext, useState, FC } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//components
import { FieldInput, CustomModal } from '../../../../molecules';
import {
  Button,
  ErrorMessage,
  ShowPassword,
  HidePassword,
} from '../../../../atoms';

//contexts
import { toastrContext } from '../../../../../contexts/ToastrContexts';

//hooks
import { useL10N } from '../../../../../hooks/useL10N';

//gql
import { useUpdateAccountSettingsMutation } from '../../../../../gql/gen/graphql';

//types
import { ChangePasswordSchema } from '../../../../../validations';

//enums
import {
  ButtonSizesEnums,
  ButtonTypesEnums,
  ModalTypesEnums,
  ToastrTypesEnums,
} from '../../../../../enums';
import {
  ChangePasswordDialogProps,
  ChangePasswordDefaultData,
} from './ChangePassword.types';

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({
  onClose,
  companyId,
}) => {
  // l10n
  const { t } = useL10N();

  // toastrs
  const toast = useContext(toastrContext);

  // hook form
  const { register, handleSubmit, errors } = useForm<ChangePasswordDefaultData>(
    {
      resolver: yupResolver(ChangePasswordSchema(t)),
    }
  );

  // state
  const [apiError, setApiError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
    false
  );

  // gql mutation
  const [updatePasswords, { loading }] = useUpdateAccountSettingsMutation({
    onError: () => {
      setApiError(t('settings.userProfile.updateError'));
    },
    onCompleted: ({ update_profile }) => {
      if (update_profile?.statusIsOk) {
        toast?.add(
          ToastrTypesEnums.SUCCESS,
          t('settings.userProfile.updateSuccessfully')
        );
        setApiError('');
        onClose();
      } else {
        setApiError(
          update_profile?.statusMessage || t('settings.userProfile.updateError')
        );
      }
    },
  });

  // show hide password handler
  const handleTogglePassword = () => setShowPassword((prev) => !prev);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  // submit handler
  const onSubmit = (data: ChangePasswordDefaultData) => {
    if (!loading) {
      setApiError('');
      updatePasswords({
        variables: {
          user_profile_data: {
            password: data.old_password,
            new_password: data.new_password,
          },
          company_profile_data: {
            company_id: companyId,
          },
        },
      });
    }
  };

  return (
    <CustomModal
      open
      closeModal={!loading ? onClose : undefined}
      title={t('settings.changePassword.dialogTitle')}
      type={ModalTypesEnums.MD}
    >
      <div>
        <ErrorMessage error={apiError} className="mb-6" />
        <form id="reset-password-form" onSubmit={handleSubmit(onSubmit)}>
          <FieldInput
            labelProps={{ children: t('shared.forms.validation.oldPassword') }}
            className="mt-2"
            after={
              <button
                type="button"
                className="btn"
                onClick={handleTogglePassword}
              >
                {showPassword ? <HidePassword /> : <ShowPassword />}
              </button>
            }
            inputProps={{
              name: 'old_password',
              inputRef: register,
              type: showPassword ? 'text' : 'password',
            }}
            error={errors.old_password?.message}
          />
          <FieldInput
            labelProps={{ children: t('shared.forms.validation.newPassword') }}
            className="mt-6"
            after={
              <button
                type="button"
                className="btn"
                onClick={handleToggleConfirmPassword}
              >
                {showConfirmPassword ? <HidePassword /> : <ShowPassword />}
              </button>
            }
            inputProps={{
              name: 'new_password',
              inputRef: register,
              type: showConfirmPassword ? 'text' : 'password',
            }}
            error={errors.new_password?.message}
          />
          <div className="flex flex-row items-center justify-end mt-8">
            <Button
              label={t('button.cancel')}
              btnType={ButtonTypesEnums.WHITE_BORDER}
              btnSize={ButtonSizesEnums.XM}
              onClick={onClose}
              disabled={loading}
            />
            <Button
              className="ml-2"
              type="submit"
              label={t('button.save')}
              btnType={ButtonTypesEnums.GREEN}
              btnSize={ButtonSizesEnums.LG}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default ChangePasswordDialog;

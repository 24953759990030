// react
import React, { FC } from 'react';

// hooks
import { useL10N } from '../../../hooks/useL10N';

// components
import { Button, StagesEmptyIcon, Create } from '../../atoms';

// enums
import { ButtonSizesEnums, ButtonTypesEnums } from '../../../enums';

// types
import { StagesEmptyProps } from './StagesEmpty.types';

const StagesEmpty: FC<StagesEmptyProps> = ({ handleCreate }) => {
  // hooks
  const { t } = useL10N();

  return (
    <div className="empty shadow-none">
      <div className="empty--icon">
        <StagesEmptyIcon />
      </div>
      <p className="empty__desc">{t('stages.emptyDesc')}</p>
      <Button
        before={<Create />}
        onClick={handleCreate}
        btnSize={ButtonSizesEnums.MD}
        btnType={ButtonTypesEnums.GREEN}
        label={t('button.createNewStage')}
      />
    </div>
  );
};

export default StagesEmpty;

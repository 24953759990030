import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { TooltipProps } from './Tooltip.types';

const Tooltip: FC<TooltipProps> = ({ id, label, children, tooltipId }) => {
  return (
    <>
      <div
        data-tip
        data-for={id.toString()}
        className="w-full tooltip__sibling md:w-auto"
        id={tooltipId}
      >
        {children}
      </div>
      <ReactTooltip id={id.toString()} place="right" effect="solid" className="tooltip">
        {label}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;

import React from 'react';

import { Svg } from '../../../atoms';

const LogoAuth = () => (
  <Svg
    width="115"
    height="42"
    viewBox="0 0 115 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96842 14.7123V19.6654H0V22.7673H4.96842V27.7204H8.08066V22.7673H13.0491V19.6654H8.08066V14.7123H4.96842Z"
      fill="#08D4A5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5271 25.2862H40.6209C41.1942 25.2862 41.7244 25.1776 42.2104 24.96C42.6963 24.7428 43.1105 24.4414 43.4533 24.0561C43.7961 23.6709 44.0642 23.208 44.2576 22.6674C44.4506 22.1268 44.5474 21.5276 44.5474 20.8691C44.5474 20.2231 44.4506 19.6268 44.2576 19.0801C44.0642 18.5334 43.7961 18.0672 43.4533 17.682C43.1105 17.2972 42.6963 16.9962 42.2104 16.7781C41.7244 16.5609 41.1942 16.4519 40.6209 16.4519H38.5271V25.2862ZM36.0967 14.4579H40.7519C41.6741 14.4579 42.5217 14.6166 43.2946 14.9334C44.0675 15.2503 44.731 15.6908 45.286 16.2562C45.8401 16.8221 46.2735 17.4989 46.5852 18.2876C46.8966 19.0768 47.0525 19.9371 47.0525 20.8691C47.0525 21.801 46.8966 22.6613 46.5852 23.4505C46.2735 24.2396 45.8401 24.9165 45.286 25.4819C44.731 26.0469 44.0675 26.4882 43.2946 26.8047C42.5217 27.122 41.6741 27.2802 40.7519 27.2802H36.0967V14.4579Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.7446 27.2802H52.1751V14.4579H49.7446V27.2802Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.2039 24.3913C56.413 25.1617 57.6095 25.5469 58.7937 25.5469C59.5042 25.5469 60.0803 25.3789 60.5236 25.0442C60.9654 24.7086 61.1871 24.2672 61.1871 23.7201C61.1871 23.2857 61.0345 22.9407 60.7288 22.6856C60.4236 22.4315 60.0366 22.2147 59.5699 22.0341C59.1022 21.8539 58.6003 21.6802 58.0645 21.5122C57.5287 21.3441 57.0272 21.1176 56.5595 20.8316C56.0923 20.5461 55.7054 20.1796 55.4002 19.7321C55.095 19.2851 54.9424 18.6883 54.9424 17.9431C54.9424 17.3964 55.0546 16.8965 55.2791 16.443C55.503 15.9894 55.8176 15.5981 56.2229 15.2686C56.6281 14.94 57.1141 14.6849 57.6813 14.5047C58.2481 14.3245 58.8684 14.2341 59.5417 14.2341C60.6884 14.2341 61.8412 14.471 63.0005 14.9423V17.1605C62.4516 16.8371 61.8999 16.5951 61.3462 16.4336C60.7912 16.2721 60.2456 16.1911 59.7098 16.1911C59.0243 16.1911 58.4664 16.3465 58.0368 16.6573C57.6067 16.9677 57.3916 17.3838 57.3916 17.9061C57.3916 18.3279 57.5442 18.6607 57.8494 18.9027C58.1546 19.1451 58.5416 19.3567 59.0088 19.5364C59.476 19.7171 59.9779 19.8908 60.5137 20.0583C61.05 20.2259 61.5514 20.4562 62.0187 20.7478C62.4859 21.0399 62.8728 21.4162 63.178 21.8759C63.4832 22.335 63.6363 22.9379 63.6363 23.6831C63.6363 24.2424 63.5203 24.7582 63.2898 25.2301C63.0592 25.7023 62.7385 26.1063 62.3272 26.4414C61.9158 26.777 61.4237 27.0377 60.8504 27.2245C60.2771 27.4103 59.6469 27.5039 58.9618 27.5039C58.3387 27.5039 57.7123 27.4323 57.0831 27.2896C56.4534 27.1468 55.827 26.9329 55.2039 26.6464V24.3913Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.4663 20.8692C65.4663 19.8998 65.6217 19.0086 65.9331 18.1946C66.2444 17.3811 66.6815 16.6823 67.2422 16.0981C67.8033 15.5144 68.4696 15.0576 69.2425 14.7281C70.0154 14.399 70.863 14.2343 71.7856 14.2343C73.0445 14.2343 74.2785 14.5451 75.4872 15.1662V17.3656C74.9261 17.0179 74.3687 16.7506 73.8142 16.5638C73.2596 16.3775 72.7201 16.2844 72.197 16.2844C71.5734 16.2844 71.0029 16.3963 70.4859 16.62C69.9689 16.8433 69.5261 17.1574 69.158 17.5613C68.7908 17.9652 68.5044 18.4497 68.2987 19.0147C68.093 19.5806 67.9897 20.1984 67.9897 20.8692C67.9897 21.5399 68.093 22.1554 68.2987 22.7143C68.5044 23.2732 68.7908 23.7576 69.158 24.1677C69.5261 24.5777 69.9689 24.895 70.4859 25.1183C71.0029 25.3416 71.5734 25.4539 72.197 25.4539C72.7201 25.4539 73.2596 25.3603 73.8142 25.1745C74.3687 24.9877 74.9261 24.7209 75.4872 24.3727V26.5717C74.2785 27.1937 73.0445 27.5041 71.7856 27.5041C70.863 27.5041 70.0154 27.3393 69.2425 27.0102C68.4696 26.6812 67.8033 26.2244 67.2422 25.6402C66.6815 25.056 66.2444 24.3577 65.9331 23.5432C65.6217 22.7302 65.4663 21.8385 65.4663 20.8692Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.7333 20.8692C79.7333 21.5399 79.823 22.1554 80.0038 22.7143C80.1846 23.2732 80.44 23.7576 80.7706 24.1677C81.1007 24.5777 81.4965 24.895 81.9581 25.1183C82.4192 25.3416 82.9306 25.4539 83.4912 25.4539C84.0514 25.4539 84.5628 25.3416 85.0239 25.1183C85.485 24.895 85.8813 24.5777 86.2114 24.1677C86.5415 23.7576 86.7969 23.2732 86.9782 22.7143C87.1585 22.1554 87.2491 21.5399 87.2491 20.8692C87.2491 20.1984 87.1585 19.5829 86.9782 19.024C86.7969 18.4651 86.5415 17.9802 86.2114 17.5702C85.8813 17.1606 85.485 16.8433 85.0239 16.62C84.5628 16.3963 84.0514 16.2844 83.4912 16.2844C82.9306 16.2844 82.4192 16.3963 81.9581 16.62C81.4965 16.8433 81.1007 17.1606 80.7706 17.5702C80.44 17.9802 80.1846 18.4651 80.0038 19.024C79.823 19.5829 79.7333 20.1984 79.7333 20.8692ZM77.209 20.8692C77.209 19.8998 77.3644 19.0086 77.6767 18.1946C77.988 17.3811 78.4209 16.6823 78.9759 16.0981C79.53 15.5144 80.1912 15.0576 80.957 14.7281C81.7243 14.399 82.5685 14.2343 83.4912 14.2343C84.4134 14.2343 85.2577 14.399 86.0245 14.7281C86.7908 15.0576 87.4515 15.5144 88.0061 16.0981C88.5606 16.6823 88.994 17.3811 89.3053 18.1946C89.6166 19.0086 89.773 19.8998 89.773 20.8692C89.773 21.8385 89.6166 22.7302 89.3053 23.5432C88.994 24.3577 88.5606 25.056 88.0061 25.6402C87.4515 26.2244 86.7908 26.6812 86.0245 27.0102C85.2577 27.3393 84.4134 27.5041 83.4912 27.5041C82.5685 27.5041 81.7243 27.3393 80.957 27.0102C80.1912 26.6812 79.53 26.2244 78.9759 25.6402C78.4209 25.056 77.988 24.3577 77.6767 23.5432C77.3644 22.7302 77.209 21.8385 77.209 20.8692Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.6999 27.2801H95.6061L90.5391 14.4578H93.0446L96.6532 23.9254L100.243 14.4578H102.767L97.6999 27.2801Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.104 20.5896H108.843C109.516 20.5896 110.064 20.394 110.488 20.0027C110.911 19.6113 111.124 19.1114 111.124 18.502C111.124 17.8814 110.911 17.3744 110.488 16.9831C110.064 16.5918 109.516 16.3966 108.843 16.3966H107.104V20.5896ZM104.711 14.4579H109.179C109.827 14.4579 110.423 14.5576 110.965 14.7565C111.507 14.955 111.974 15.2316 112.367 15.5855C112.76 15.9398 113.065 16.3657 113.283 16.8619C113.501 17.3595 113.61 17.9062 113.61 18.502C113.61 19.3722 113.382 20.13 112.927 20.7759C112.473 21.4223 111.834 21.8941 111.011 22.1923L114.059 27.2802H111.404L108.637 22.5279H107.104V27.2802H104.711V14.4579Z"
      fill="#152E6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6551 21C27.6551 9.90812 19.0072 0.797593 8.08057 0V3.11033C17.2901 3.89997 24.5433 11.6189 24.5433 21C24.5433 30.3806 17.2901 38.1 8.08057 38.8901V42C19.0072 41.2024 27.6551 32.0914 27.6551 21Z"
      fill="#152E6F"
    />
  </Svg>
);

export default LogoAuth;

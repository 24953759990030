import React from 'react';
import { useLocation } from 'react-router-dom';

//components
import { NoMessageResults } from '../../../atoms';

//hooks
import { useL10N } from '../../../../hooks/useL10N';

//utils
import { parseQueryString } from '../../../../utils';

const NoQueryResults = () => {
  const { t } = useL10N();

  const { search } = useLocation();
  const { query } = parseQueryString(search);

  const renderNoDataContent = () => {
    if (query) {
      return (
        <>
          <p className="mt-4">
            {t('messages.noQuery')} "<b>{query}</b>".
          </p>
          <p>{t('message.tryAnotherQuery')}</p>
        </>
      );
    }

    return (
      <>
        <p className="mt-4">{t('messages.noFilter')}</p>
        <p>{t('message.tryAnotherFilter')}</p>
      </>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center mt-20">
      <NoMessageResults />
      {renderNoDataContent()}
    </div>
  );
};

export default NoQueryResults;
